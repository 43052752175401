import { Injectable } from '@angular/core';
import { RevenueAreaType } from '../client-api.service';
import { AggregateUtil, AggregationType } from '../shared/aggregate-util';
import { AreaEstimates, OrgAreaEstimates, UnitAreaEstimates, UnitTypeAreaEstimates } from './models/area-estimates';
import { ExtendedRevenueAreaType } from './models/area-type';
import { PeriodEstimatesBase } from './models/period-estimates';

// tslint:disable: max-line-length
export interface PeriodCalculationInfo {
  /** performs aggregation calculation */
  func: (area: AreaEstimates, periodIndex: number) => number;
  setter: (aggTgt: PeriodEstimatesBase, value: number) => void;
  /** if true then it only has to be calculated after the initial load */
  isInitialOnly?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class RevenueCalcService {
  private cyJanPeriodIndex = 2;

  private unitTypeAggFunctions: [AggregationType, PeriodCalculationInfo[]][] = [
    ['min', [
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].origFpRate))!, setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].aggs.sum!.origFpRate))!, setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].origAvgRate))!, setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].estRenewalRate))!, setter: (x, value) => x.estRenewalRate = value },
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].estRenewalRateVsOrigFpPct))!, setter: (x, value) => x.estRenewalRateVsOrigFpPct = value },
      { func: (x, i) => AggregateUtil.min(x.children!.map(y => y.periods[i].estRenewalRateVsOrigAvgRatePct))!, setter: (x, value) => x.estRenewalRateVsOrigAvgRatePct = value }
    ]],
    ['max', [
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].origFpRate))!, setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].aggs.sum!.origFpRate))!, setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].origAvgRate))!, setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].estRenewalRate))!, setter: (x, value) => x.estRenewalRate = value },
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].estRenewalRateVsOrigFpPct))!, setter: (x, value) => x.estRenewalRateVsOrigFpPct = value },
      { func: (x, i) => AggregateUtil.max(x.children!.map(y => y.periods[i].estRenewalRateVsOrigAvgRatePct))!, setter: (x, value) => x.estRenewalRateVsOrigAvgRatePct = value }
    ]],
    ['sum', [
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].origFpRate!)), setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.origFpRate!)), setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].origAvgRate!)), setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.estNewRate!)), setter: (x, value) => x.estAmenityNewRate = value },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].estRenewalRate!)), setter: (x, value) => x.estRenewalRate = value },
    ]],
    ['avg', [
      { func: (x, i) => AggregateUtil.avg(x.children!.map(y => y.periods[i].origFpRate!))!, setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.avg(x.children!.map(y => y.periods[i].aggs.sum!.origFpRate!))!, setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.avg(x.children!.map(y => y.periods[i].origAvgRate!), { skipBlanks: true })!, setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.avg(x.children!.map(y => y.periods[i].aggs.sum!.estNewRate!))!, setter: (x, value) => x.estAmenityNewRate = value },
      { func: (x, i) => AggregateUtil.avg(x.children!.map(y => y.periods[i].estRenewalRate!), { skipBlanks: true })!, setter: (x, value) => x.estRenewalRate = value },
      {
        func: (x, i) => {
          const validPeriodChildren = x.children?.map(y => y.periods[i]).filter(y => !!y.origFpRate && !!y.estRenewalRate);
          const fpRateSum = AggregateUtil.sum(validPeriodChildren!.map(y => y.origFpRate!));
          const origAvgRateSum = AggregateUtil.sum(validPeriodChildren!.map(y => y.origAvgRate!));
          return (fpRateSum - origAvgRateSum) / origAvgRateSum;
        },
        setter: (x, value) => x.estRenewalRateVsOrigFpPct = value
      },
      {
        func: (x, i) => {
          const validPeriodChildren = x.children!.map(y => y.periods[i]).filter(y => !!y.origAvgRate && !!y.estRenewalRate);
          const renewalRateSum = AggregateUtil.sum(validPeriodChildren!.map(y => y.estRenewalRate!));
          const origAvgRateSum = AggregateUtil.sum(validPeriodChildren!.map(y => y.origAvgRate!));
          return (renewalRateSum - origAvgRateSum) / origAvgRateSum;
        },
        setter: (x, value) => x.estRenewalRateVsOrigAvgRatePct = value
      },
    ]]
  ];

  /** Aggregates from a unit's amenities */
  private unitAggFunctions: [AggregationType, PeriodCalculationInfo[]][] = [
    ['sum', [
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].origFpRate!)), setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].estNewRate!)), setter: (x, value) => x.estNewRate = value },
    ]]
  ];

  /** Aggregates from an org's unit types */
  private orgAggFunctions: [AggregationType, PeriodCalculationInfo[]][] = [
    ['sum', [
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.origFpRate!)), setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.origAmenityFpRate!)), setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.origAvgRate!)), setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].aggs.sum!.estRenewalRate!)), setter: (x, value) => x.estRenewalRate = value },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].estNewLeases!)), setter: (x, value) => x.estNewLeases = value },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].estRenewalLeases!)), setter: (x, value) => x.estRenewalLeases = value },
      { func: (x, i) => AggregateUtil.sum(x.children!.map(y => y.periods[i].estGpr!)), setter: (x, value) => x.estGpr = value }
    ]],
    ['avg', [
      { func: (x, i) => AggregateUtil.avg((x.children || []).map(y => y.children).flat().map(y => y!.periods[i].origFpRate!))!, setter: (x, value) => x.origFpRate = value, isInitialOnly: true },
      { func: (x, i) => x.periods[i].aggs.sum!.origAmenityFpRate! / AggregateUtil.sum(x.children!.map(y => y.periods[i].unitCount!)), setter: (x, value) => x.origAmenityFpRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.avg((x.children || []).map(y => y.children).flat().map(y => y!.periods[i].origAvgRate!), { skipBlanks: true })!, setter: (x, value) => x.origAvgRate = value, isInitialOnly: true },
      { func: (x, i) => AggregateUtil.avg((x.children || []).map(y => y.children).flat().map(y => y!.periods[i].estRenewalRate!), { skipBlanks: true })!, setter: (x, value) => x.estRenewalRate = value },
      {
        func: (x, i) => {
          const validPeriodChildren = x.children!
            .flatMap(y => y.children)
            .map(y => y!.periods[i])
            .filter(y => !!y.origFpRate && !!y.estRenewalRate);
          return AggregateUtil.avg(validPeriodChildren.map(y => {
            const totalFpRate = y.origFpRate! + (y.origAmenityFpRate || 0);
            return (y.estRenewalRate! - totalFpRate) / totalFpRate;
          })) || 0;
        },
        setter: (x, value) => x.estRenewalRateVsOrigFpPct = value
      },
      {
        func: (x, i) => {
          const validPeriodChildren = x.children!
            .flatMap(y => y.children)
            .map(y => y!.periods[i])
            .filter(y => !!y.origAvgRate && !!y.estRenewalRate);
          return AggregateUtil.avg(validPeriodChildren.map(y => (y.estRenewalRate! - y.origAvgRate!) / y.origAvgRate!)) || 0;
        },
        setter: (x, value) => x.estRenewalRateVsOrigAvgRatePct = value
      },
    ]]
  ];

  readonly areaTypeCalculationOrder = [
    RevenueAreaType.Unit,
    RevenueAreaType.UnitType,
    ExtendedRevenueAreaType.ConsolidatedUnitTypeAmenity,
    RevenueAreaType.Self
  ];

  updatePeriodCalculations(area: AreaEstimates, periodIndex: number, includeInitialCalcs: boolean) {
    if (!area) {
      return;
    }
    let aggCalcs: [AggregationType, PeriodCalculationInfo[]][];

    switch (area.areaType) {
      case RevenueAreaType.Self: aggCalcs = this.orgAggFunctions; break;
      case RevenueAreaType.Unit: aggCalcs = this.unitAggFunctions; break;
      case RevenueAreaType.UnitType: aggCalcs = this.unitTypeAggFunctions; break;
      default: aggCalcs = [];
    }
    this.updatePeriodAggregates(area, periodIndex, aggCalcs, includeInitialCalcs);

    switch (area.areaType) {
      case RevenueAreaType.Self:
        if (includeInitialCalcs) {
          this.updateOrgCalcsInitial(<OrgAreaEstimates>area, periodIndex);
        }
        this.updateOrgCalcs(<OrgAreaEstimates>area, periodIndex);
        break;
      case RevenueAreaType.Unit:
        this.updateUnitCalcs(<UnitAreaEstimates>area, periodIndex);
        break;
      case RevenueAreaType.UnitType:
        if (includeInitialCalcs) {
          this.updateUnitTypeCalcsInitial(<UnitTypeAreaEstimates>area, periodIndex);
        }
        this.updateUnitTypeCalcs(<UnitTypeAreaEstimates>area, periodIndex);
        break;
      case ExtendedRevenueAreaType.ConsolidatedUnitTypeAmenity:
        if (includeInitialCalcs) {
          this.updateUtConsolidatedAmenitiesInitial(area, periodIndex);
        }
        this.updateUtConsolidatedAmenities(area, periodIndex);
        break;
    }
  }

  updateSummaryCalculations(area: AreaEstimates) {
    const startPeriodIdx = area.periods.length - 12;
    const endPeriodIdx = area.periods.length - 1;
    const cyPeriods = area.periods.slice(startPeriodIdx, area.periods.length);
    const startGpr = area.periods[startPeriodIdx - 1].estGpr;
    const endGpr = area.periods[endPeriodIdx].estGpr;

    area.summary = {
      ...area.summary!,
      estNewLeases: AggregateUtil.avg(cyPeriods.map(x => x.estNewLeases)),
      estRenewalLeases: AggregateUtil.avg(cyPeriods.map(x => x.estRenewalLeases)),
      estBaseFpRate: AggregateUtil.avg(cyPeriods.map(x => x.estBaseFpRate)),
      estAmenityNewRate: AggregateUtil.avg(cyPeriods.map(x => x.estAmenityNewRate)),
      estGainLoss: AggregateUtil.sum(cyPeriods.map(x => x.estGainLoss)),
      estGpr: AggregateUtil.sum(cyPeriods.map(x => x.estGpr)),
      estGprMonthVariance: (endGpr! - startGpr!) / startGpr!,
      estNewRate: AggregateUtil.avg(cyPeriods.map(x => x.estNewRate)),
      estOccNewPct: AggregateUtil.avg(cyPeriods.map(x => x.estOccNewPct)),
      estOccPct: AggregateUtil.avg(cyPeriods.map(x => x.estOccPct)),
      estOccRenewalPct: AggregateUtil.avg(cyPeriods.map(x => x.estOccRenewalPct)),
      estRenewalRate: AggregateUtil.avg(cyPeriods.map(x => x.estOccRenewalPct)),
      estRateGrowth: AggregateUtil.avg(cyPeriods.map(x => x.estRateGrowth)),
      estRevenueGrowth: AggregateUtil.avg(cyPeriods.map(x => x.estRevenueGrowth)),
      estVacancyLoss: AggregateUtil.sum(cyPeriods.map(x => x.estVacancyLoss)),
      pyRate: AggregateUtil.avg(cyPeriods.map(x => x.pyRate)),
      pyRevenue: AggregateUtil.sum(cyPeriods.map(x => x.pyRevenue)),
      calcTotalRevenue: AggregateUtil.sum(cyPeriods.map(x => x.calcTotalRevenue)),
      calcAvgRate: AggregateUtil.avg(cyPeriods.map(x => x.calcAvgRate))
    };
  }
  updateSummaryCalculationsInitial(area: AreaEstimates) {
    const startPeriodIdx = area.periods.length - 12;
    const cyPeriods = area.periods.slice(startPeriodIdx, area.periods.length);
    area.summary = {
      aggs: undefined!,
      estNewRate: AggregateUtil.avg(area.periods.map(x => x.estNewRate)),
      pyGainLoss: AggregateUtil.sum(cyPeriods.map(x => x.pyGainLoss)),
      pyGpr: AggregateUtil.sum(cyPeriods.map(x => x.pyGpr)),
      pyVacancyLoss: AggregateUtil.sum(cyPeriods.map(x => x.pyVacancyLoss)),
      tgtOccPct: area.periods[startPeriodIdx].tgtOccPct,
      unitCount: AggregateUtil.avg(cyPeriods.map(x => x.unitCount)),
    };
  }

  private updatePeriodAggregates(area: AreaEstimates, periodIndex: number,
    aggCalcs: [AggregationType, PeriodCalculationInfo[]][], includeInitial?: boolean) {
    if (!area) {
      return;
    }
    const aggs = area.periods[periodIndex].aggs;

    for (const [aggType, aggSettings] of aggCalcs) {
      const targetAggEstimates = aggs[aggType] || (aggs[aggType] = {});
      for (const { func, setter, isInitialOnly } of aggSettings) {

        if (includeInitial || !isInitialOnly) {
          setter(targetAggEstimates, func(area, periodIndex));
        }
      }
    }
  }

  private updateOrgCalcs(area: OrgAreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    estimates.estBaseFpRate = estimates.estNewRate! - estimates.aggs.avg!.estAmenityNewRate! || 0;
    estimates.estNewLeases = estimates.aggs.sum!.estNewLeases || 0;
    estimates.estRenewalLeases = estimates.aggs.sum!.estRenewalLeases || 0;
    estimates.estTotalLeases = estimates.estRenewalLeases + estimates.estNewLeases;
    estimates.estOccRenewalPct = estimates.estRenewalLeases / estimates.unitCount!;
    estimates.estOccNewPct = estimates.estNewLeases / estimates.unitCount!;
    estimates.estOccPct = estimates.estOccRenewalPct + estimates.estOccNewPct;
    estimates.estGpr = estimates.aggs.sum!.estGpr;

    if (periodIndex >= this.cyJanPeriodIndex) {
      estimates.calcTotalRevenue = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcTotalRevenue));
      estimates.calcLeaseCntDelta = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcLeaseCntDelta));
      estimates.calcAvgRate = estimates.calcTotalRevenue / estimates.estTotalLeases;
      const priorGpr = area.periods[periodIndex - 1].estGpr;
      estimates.estGprMonthVariance = (estimates.estGpr! - priorGpr!) / priorGpr!;
      estimates.estVacancyLoss = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].estVacancyLoss));
      estimates.calcOrigEarlyExpiryCnt = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcOrigEarlyExpiryCnt));
      estimates.calcOrigRevisedLeaseCnt = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcOrigRevisedLeaseCnt));
      estimates.calcOrigRevisedRevenue = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcOrigRevisedRevenue));
      estimates.estGainLoss = estimates.calcTotalRevenue - estimates.estGpr! - estimates.estVacancyLoss;
      estimates.estNewRate = estimates.estGpr! / estimates.unitCount!;
      estimates.estRevenueGrowth = (estimates.calcTotalRevenue - estimates.pyRevenue!) / estimates.pyRevenue!;
      estimates.calcGrowthRateRevenue = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].calcGrowthRateRevenue));
      const comparedRevenue = area.periods[this.cyJanPeriodIndex - 1].calcGrowthRateRevenue;
      estimates.estRateGrowth = (estimates.calcGrowthRateRevenue && comparedRevenue)
        ? (estimates.calcGrowthRateRevenue - comparedRevenue) / comparedRevenue
        : 0;

      // const calcPyTotalRev = area.periods[this.cyJanPeriodIndex - 1].calcTotalRevenue;
      // estimates.estRateGrowth = undefined;
      // if (calcPyTotalRev) {
      //   const estNewRateOfOrigLeased = AggregateUtil
      //     .sum(area.children.map(x => {
      //       const origLeaseCount = x.periods[this.cyJanPeriodIndex - 1].origLeaseCount;
      //       const estNewRate = x.periods[periodIndex].estNewRate || x.periods[periodIndex].origFpRate;
      //       return (origLeaseCount && estNewRate) ? (estNewRate * origLeaseCount) : 0;
      //     }));
      //   estimates.estRateGrowth = (estNewRateOfOrigLeased - calcPyTotalRev) / calcPyTotalRev;
      // }
    }
  }

  private updateOrgCalcsInitial(area: OrgAreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];

    estimates.unitCount = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].unitCount));
    estimates.origLeaseCount = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].origLeaseCount));
    estimates.origTotalRevenue = AggregateUtil.sum(area.children!.map(x => x.periods[periodIndex].origTotalRevenue || 0));
    estimates.origAvgRate = estimates.origTotalRevenue / estimates.origLeaseCount;
    estimates.estGpr = estimates.aggs.sum!.estGpr;

    if (periodIndex < this.cyJanPeriodIndex) {
      estimates.calcTotalRevenue = estimates.origTotalRevenue;
      estimates.calcOrigRevisedRevenue = estimates.origTotalRevenue;
      estimates.calcAvgRate = estimates.origAvgRate;
      estimates.estNewRate = estimates.estGpr! / estimates.unitCount;
      estimates.calcGrowthRateRevenue = estimates.origTotalRevenue;
    }
    else {
      estimates.pyRate = (estimates.pyGpr! + estimates.pyGainLoss!) / estimates.unitCount!;
      estimates.pyRevenue = estimates.pyGpr! + estimates.pyGainLoss! + estimates.pyVacancyLoss!;
    }
  }

  /** Updates calcs for a unit area. */
  private updateUnitCalcs(area: UnitAreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    const fullFpRate = estimates.aggs.sum!.origFpRate! + estimates.origFpRate!;
    const renewalRate = estimates.estRenewalRate;

    estimates.estRenewalRateVsOrigFpPct = (renewalRate != null && fullFpRate != null)
      ? (renewalRate - fullFpRate) / fullFpRate
      : undefined;
    estimates.estRenewalRateVsOrigAvgRatePct = (renewalRate != null && estimates.origAvgRate != null)
      ? (renewalRate - estimates.origAvgRate) / estimates.origAvgRate
      : undefined;
  }

  private updateUnitTypeCalcs(area: UnitTypeAreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    const estNewRate = estimates.estNewRate || 0;
    const estRenewalLeases = estimates.estRenewalLeases || 0;
    const estNewLeases = estimates.estNewLeases || 0;
    estimates.estTotalLeases = estRenewalLeases + estNewLeases;
    estimates.estOccRenewalPct = estRenewalLeases / estimates.unitCount!;
    estimates.estOccNewPct = estNewLeases / estimates.unitCount!;
    estimates.estOccPct = estimates.estTotalLeases / estimates.unitCount!;
    estimates.estGpr = estNewRate * estimates.unitCount!;

    if (periodIndex >= this.cyJanPeriodIndex) {
      const priorPeriodIndex = periodIndex - 1;
      const priorPeriodNewLeases = area.periods[priorPeriodIndex].calcCyLeases || 0;
      const priorCyRevenue = area.periods[priorPeriodIndex].calcCyRevenue || 0;
      const priorOrigEarlyExpiryCnt = area.periods[priorPeriodIndex].calcOrigEarlyExpiryCnt || 0;

      estimates.estBaseFpRate = estNewRate - (estimates.aggs.avg!.estAmenityNewRate || 0);
      estimates.calcOrigEarlyExpiryCnt = priorOrigEarlyExpiryCnt;
      if (estimates.estTotalLeases < estimates.origLeaseCount! - priorOrigEarlyExpiryCnt + priorPeriodNewLeases) {
        const leaseOverage = estimates.origLeaseCount! + priorPeriodNewLeases - estimates.estTotalLeases;
        estimates.calcOrigEarlyExpiryCnt = Math.max(priorOrigEarlyExpiryCnt, leaseOverage);
      }
      estimates.calcOrigRevisedLeaseCnt = Math.max(0, estimates.origLeaseCount! - estimates.calcOrigEarlyExpiryCnt);
      estimates.calcOrigRevisedRevenue = (estimates.calcOrigRevisedLeaseCnt > 0)
        ? estimates.origTotalRevenue! * estimates.calcOrigRevisedLeaseCnt / estimates.origLeaseCount!
        : 0;
      estimates.calcLeaseCntDelta = estimates.estTotalLeases - estimates.calcOrigRevisedLeaseCnt - priorPeriodNewLeases;
      estimates.calcCyLeases = priorPeriodNewLeases + estimates.calcLeaseCntDelta;
      if (estimates.calcLeaseCntDelta <= 0) {
        // have a lease undercount from prior period
        estimates.calcCyRevenue = estimates.calcCyLeases > 0
          ? (estimates.calcCyLeases / priorPeriodNewLeases) * priorCyRevenue
          : 0;
      }
      else if (estimates.estTotalLeases > 0) {
        const newRenewalRatio = estimates.estNewLeases! / estimates.estTotalLeases;
        // use new rate if there is no estRenewalRate.
        const estRenewalRate = area.periods[this.cyJanPeriodIndex].aggs.avg!.estRenewalRate || estNewRate;
        estimates.calcCyRevenue = priorCyRevenue
          + (estimates.calcLeaseCntDelta * newRenewalRatio * estNewRate)
          + (estimates.calcLeaseCntDelta * (1 - newRenewalRatio) * estRenewalRate);
      }
      else {
        estimates.calcCyRevenue = 0;
      }
      estimates.estVacancyLoss = estNewRate * (estimates.estTotalLeases - estimates.unitCount!);
      estimates.calcTotalRevenue = estimates.calcOrigRevisedRevenue + estimates.calcCyRevenue;
      estimates.calcAvgRate = estimates.calcTotalRevenue / estimates.estTotalLeases;
      estimates.estGainLoss = estimates.calcTotalRevenue - estimates.estGpr - estimates.estVacancyLoss;
      estimates.calcGrowthRateRevenue = area.periods[this.cyJanPeriodIndex - 1].origLeaseCount! * estNewRate;
      const comparedRevenue = area.periods[this.cyJanPeriodIndex - 1].calcGrowthRateRevenue;
      estimates.estRateGrowth = (estimates.calcGrowthRateRevenue && comparedRevenue)
        ? (estimates.calcGrowthRateRevenue - comparedRevenue) / comparedRevenue
        : 0;
      // if (baseRev && estNewRate) {
      //   estimates.estRateGrowth = ((estNewRate * area.periods[this.cyJanPeriodIndex - 1].origLeaseCount) - baseRev) / baseRev;
      // }
    }
  }


  private updateUnitTypeCalcsInitial(area: UnitTypeAreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    estimates.origLeaseCount = estimates.origLeaseCount || 0;
    estimates.origTotalRevenue = (estimates.origAvgRate || 0) * estimates.origLeaseCount;
    if (periodIndex < this.cyJanPeriodIndex) {
      estimates.estBaseFpRate = estimates.origFpRate;
      estimates.estNewRate = estimates.origFpRate! + estimates.aggs.avg!.origAmenityFpRate!;
      estimates.estNewLeases = estimates.origLeaseCount || 0;
      estimates.estOccNewPct = (estimates.unitCount! > 0) ? estimates.origLeaseCount / estimates.unitCount! : 0;
      estimates.calcAvgRate = estimates.origAvgRate
        || (estimates.origLeaseCount > 0 ? estimates.calcTotalRevenue! / estimates.origLeaseCount : 0);
      estimates.calcTotalRevenue = estimates.origTotalRevenue;
      estimates.calcOrigRevisedRevenue = estimates.origTotalRevenue;
      estimates.calcGrowthRateRevenue = estimates.origTotalRevenue;
    }
  }

  private updateUtConsolidatedAmenities(area: AreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    if (periodIndex >= this.cyJanPeriodIndex) {
      estimates.estNewRate = AggregateUtil.avg(area.children!.map(x => x.periods[periodIndex].estNewRate!));
    }
  }

  private updateUtConsolidatedAmenitiesInitial(area: AreaEstimates, periodIndex: number) {
    const estimates = area.periods[periodIndex];
    if (periodIndex < this.cyJanPeriodIndex) {
      estimates.estNewRate = AggregateUtil.avg(area.children!.map(x => x.periods[periodIndex].origFpRate!));
    }
    estimates.unitCount = area.children!.length;
  }
}
