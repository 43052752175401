import { Directive, HostListener } from '@angular/core';

/**
 * Listens to click on host, calls select on event target
 */
@Directive({
    exportAs: 'tccSelectOnFocus',
    selector: '[tccSelectOnFocus]'
})
export class SelectOnFocusDirective {
    constructor() {

    }
    @HostListener('focus', ['$event'])
    focus(event: FocusEvent) {
        const tgt = <SelectableElement>event.target;

        if (tgt.select) {

            tgt.select();
        }
    }
}

interface SelectableElement {
    select?: () => void;
}
