import { Directive, ElementRef, ExistingProvider, forwardRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TCC_MODEL_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TccModelAccessorDirective),
  multi: true,
};

/**
 * Place on any element to interact with NgModel.  Basically allows the creation of a custom input.
 */
@Directive({
  selector: '[tccModelAccessor]',
  exportAs: 'tccModelAccessor',
  providers: [TCC_MODEL_ACCESSOR]
})
export class TccModelAccessorDirective implements ControlValueAccessor {

  @Output()
  tccModelAccessorWrite = new EventEmitter();

  // tslint:disable: member-ordering
  /** Call using template variable ref # to update  model value. */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changed = (_: unknown) => { };

  /** Call using template variable ref # to set touched on the model. */
  touched = () => { };

  // tslint:enable: member-ordering
  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  /**  emits write event */
  writeValue(value: unknown): void {
    this.tccModelAccessorWrite.emit(value);
  }

  /** typically called by FormControlDirective or NgModel to listen to change events. */
  registerOnChange(fn: (_: unknown) => Record<string, unknown>): void { this.changed = fn; }

  /** typically called by FormControlDirective or NgModel to listen to touch events. */
  registerOnTouched(fn: () => Record<string, unknown>): void { this.touched = fn; }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }


}

