
import { DynamicNumberPipe } from '../../shared/dynamic-number.pipe';

declare type HeaderResolverFunc = (comparisonSource: string) => string;

declare type ComparisonFunc = (entryAmount: number, compareToAmount: number) => number;

declare type Formatter = (amt: number, formatArg: string) => string;

export class ComparisonType {

  private static dynNumPipe = new DynamicNumberPipe();

  static formatFlex(n: number, formatArg: string) {
    return ComparisonType.dynNumPipe.transform(n, formatArg, 2);
  }

  static formatPercent(n: number) {
    return ComparisonType.dynNumPipe.transform(n, 'percent', 2);
  }

  static nameToHeader(name: string) {
    if (name) {
      name = name.replace(/^[\W\d]+/, '');
    }
    return name && name.length < 12 ? name : 'Other';
  }


  /**
   * initializes public fields
   * @param id uniqueId for comparison
   * @param displayName name shown in menus and notifications
   * @param headerFunc ledger header text
   * @param comparisonFunc function to run to compare against an entry
   * @param formatter formated comparison Result
   */
  constructor(
    public id: string, public displayName: string, public headerFunc: HeaderResolverFunc, public comparisonFunc: ComparisonFunc,
    public formatter: Formatter) {
  }
}
