class NumberParserSettings {
  /** finds all the text inbetween the first and last digit */
  public digitBoundaryRe = /-?\d+.*?\d*(?=\D*$)/;
  public decimalPoint = '';
  public percentSymbol = '%';
  /** detects a percent symbol */
  public percentDetectorRe: RegExp;
  public thousandsSeperator = '';
  public thousandsSeperatorRe: RegExp;

  constructor() {
    this.detectFormat();
    this.percentDetectorRe = new RegExp(`\\d\\s*${this.percentSymbol}`);
    this.thousandsSeperatorRe = new RegExp(this.thousandsSeperator, 'g');
  }

  /** this is used to set the formats for thousandsSeperator and decimalPoint */
  private detectFormat() {
    const nmbrFormat = Intl.NumberFormat();
    const formattedResult = nmbrFormat.format(1234.5);
    const match = /^1(.)234(.)5$/.exec(formattedResult);
    this.thousandsSeperator = match![1];
    this.decimalPoint = match![2];
  }
}

export interface NumberParsingOptions {
  /** if true, will cause the resulting number to be divided by 100 if a percent symbol is present after the digits. */
  normalizePercent?: boolean;
}
export class NumberParsingUtil {
  private static settings = new NumberParserSettings();

  /**
   * parases text and tries to return a numeric result
   */
  static parseFloat(text: string, opts?: NumberParsingOptions) {
    const match = NumberParsingUtil.settings.digitBoundaryRe.exec(text);
    if (!match) {
      return undefined;
    }
    const trimmedText = match[0];
    const cleanedText = trimmedText.replace(NumberParsingUtil.settings.thousandsSeperatorRe, '');
    const result = parseFloat(cleanedText);
    return (!isNaN(result) && opts && opts.normalizePercent && NumberParsingUtil.settings.percentDetectorRe.test(text))
      ? result / 100 // if percent reduce
      : result;
  }
}


