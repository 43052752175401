import { Component, OnInit } from '@angular/core';
import { ApplicationViewsService } from './Services/application-views.services';
import { MaintenanceService } from '../services/maintenance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationViewsResource } from 'src/app/client-api.service';
import { TableButton, TableColumn } from '@tcc/ui';
import { SortUtil } from 'src/app/shared/sort-util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-application-views',
  templateUrl: './application-views.component.html',
  styleUrls: ['./application-views.component.scss']
})
export class ApplicationViewsComponent implements OnInit {
  
  applicationViews: ApplicationViewsResource[] | undefined;
  filteredApplicationViews: ApplicationViewsResource[] | undefined;
  buttons: TableButton<any>[] | undefined;
  columns: TableColumn<ApplicationViewsResource>[] | undefined;
  state: 'initializing' | 'loading' | 'ready' | 'saving';
  subscription: Subscription | undefined;

  constructor(private appViewsServices: ApplicationViewsService,
              private maintenanceService: MaintenanceService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.state = 'initializing';  
    this.columns = [
      { title: 'Id', name: 'id', hidden: true },
      { title: 'Key', name: 'key' },
      { title: 'View', name: 'view', sort: true, sorter: SortUtil.caseInsensitiveStringSortFactory<ApplicationViewsResource>(a => a.view!) },
      { title: 'Account Code', name: 'accountCode' },
      { title: 'Parent Account Code', name: 'parentAccountCode' },
      { title: 'Name', name: 'name' },
      { title: 'Calculation Expression', name: 'calculationExpression' },
      { title: 'Display Account Code ', name: 'displayAccountCode' },
      { title: 'Is Negated', name: 'isNegated' },
      { title: 'Sort Index', name: 'sortIndex' },
      { title: 'Is Read Only', name: 'isReadOnly' },
      { title: 'Is Ignored In Totals', name: 'isIgnoredInTotals' },
      { title: 'Custom Validation', name: 'customValidation' },
      { title: 'Is Hidden', name: 'isHidden' }
    ];

    this.buttons = [
      {
        data: 'editApplicationViewRecord',
        iconClass: 'fas fa-pencil-alt',
        hidden: false,
        buttonClass: 'btn btn-sm px-1 py-0 btn-outline-secondary border-0',
        tooltip: 'Edit Application View Record'
      },
      {
        data: 'deleteApplicationViewRecord',
        iconClass: 'fas fa-trash-alt', 
        hidden: false,
        buttonClass: 'btn btn-sm px-1 py-0 btn-outline-secondary border-0',
        tooltip: 'Delete Application View Record'
      }
    ];
  }

  ngOnInit(): void {    
    this.maintenanceService.setMaintenanceComponenetPanelSize('full');    
    this.state = 'loading';
    this.appViewsServices.setApplicationViews();
    this.subscription = this.appViewsServices.applicationViews$.subscribe(res => {
      this.applicationViews = res;
      this.filteredApplicationViews = res;
      console.log(res);
      this.state = 'ready';
    });
  }

  deleteApplicationView(id: number) {
    if(confirm('Are you sure you want to delete this Application View?')) {
      this.state = 'loading';
      this.appViewsServices.deleteApplicationView(id)
          .subscribe(
            res => {
              this.appViewsServices.setApplicationViews();
              this.maintenanceService.setMaintenanceComponenetPanelSize('full');    
              this.state = 'ready';
            },
            err => {
              this.state = 'ready';
              alert('Error deleting Application View');
              this.maintenanceService.setMaintenanceComponenetPanelSize('full');    
            }
          );
    }        
  }

  filterApplicationViews(event: any) {
    const value = event.target.value;
    console.log(value);
    this.state = 'loading';
    this.filteredApplicationViews = this.applicationViews?.filter(a => JSON.stringify(a).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    this.state = 'ready';
  }

  openApplicationViewsUpsertPanel(id: number) {
    this.maintenanceService.setMaintenanceComponenetPanelSize('large');      
    this.router.navigate(['upsert-application-view/', id], { relativeTo: this.activatedRoute });
  }

  rowClicked(event: any) {    
    if (event.button) {
            if (event.button.data === 'editApplicationViewRecord') {
        this.maintenanceService.setMaintenanceComponenetPanelSize('large');
        this.openApplicationViewsUpsertPanel(event.row?.id);
        // this.router.navigate(['upsert-application-view/', event.row?.id], { relativeTo: this.activatedRoute });
      }
      else if (event.button.data === 'deleteApplicationViewRecord') {
        this.deleteApplicationView(event.row?.id);
        // this.router.navigate(['upsert-application-view/', event.row?.id], { relativeTo: this.activatedRoute });
      }
    }
  }
  
}
