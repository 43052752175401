
<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>Access Matrix Admin</div>
  <div panel-footer>
    <div style="float: left">
      <button type="button" [disabled]="isDisabledButtons" class="btn btn-primary mr-1" (click)="saveChanges()">
        Save Changes
      </button>
      <button type="button" [disabled]="isDisabledButtons" class="btn btn-danger" (click)="revertToOriginalMatrix()">
        Cancel Changes
      </button>
    </div>
    <div style="float: right">
      <button type="button" class="btn btn-primary" routerLink="./useradmin">
        Add User
      </button>
    </div>
  </div>
  <div panel-body>
    <div class=" mb-2 d-flex flex-row">
      <div class="form-group mr-2">
        <label tcc-labelForNext >User Name</label>
        <select [(ngModel)]="user" class="form-control" [disabled]="isDisabledChangeUser">
          <option *ngFor="let x of users$ | async" [ngValue]="x">{{x.displayName}}</option>
        </select>
      </div>
      <div class="form-group">
        <label tcc-labelForNext>Role</label>
        <select [(ngModel)]="role" class="form-control" [disabled]="isDisabledChangeUser">
          <option *ngFor="let x of roles$ | async" [ngValue]="x">{{x.name}}</option>
        </select>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th *ngFor="let o of orgs$ | async" class="p-0">
            <div >
              <div [ngClass]="{ highlighted: highlightedIntersection.org == o }">
                {{o.orgCode}}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of views$ | async">
          <td (click)="!isDisabledMatrix && toggleViewItems(v.viewId)" style="cursor:pointer; white-space: nowrap"
              [ngClass]="{ highlighted: highlightedIntersection.view == v }">
            {{v.name}}
          </td>
          <td *ngFor="let o of orgs$ | async" style="text-align: center; vertical-align:middle"
              (mouseover)="intersectionHighlight(o, v)" (mouseout)="intersectionDim(o, v)"
              [title]="o.name + ' ' + v.name">
            <input type="checkbox" title="{{o.name}} {{v.name}}" [disabled]="isDisabledMatrix"
                   [(ngModel)]="orgViewMatrix[getMatrixKey(o.orgId, v.viewId)]"
                   (ngModelChange)="makeModified()"
                   [tccFaCheckStyle]="checkStyle" class="fa-stack-sm" />
          </td>
        </tr>
      </tbody>
    </table>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
