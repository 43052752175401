import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClientApi, QueueItemStatus } from '../client-api.service';
import { LedgerService } from '../ledgers/ledger.service';
import { OrganizationService } from '../core-services/organization.service';
import { tapError } from '../shared/tap-error-operator';


@Component({
  selector: 'export-queue',
  styles: [`
        .table td, .table th { padding: 2px 2px; }
        .no-wrap { white-space: nowrap; }
    `],
  templateUrl: './export-queue.component.html'
})
export class ExportQueueComponent {
  private readonly daysBack = 30;

  queueRecords$ = combineLatest([
    this.clientApi.getExportQueue(QueueItemStatus.All, new Date(new Date().getDate() - this.daysBack), undefined),
    this.orgSvc.orgIdMap$,
    this.ledgerSvc.glViewsIdMap$
  ]).pipe(
    map(([queues, orgIdMap, viewIdMap]) =>
      queues.map(x => ({
        createdOn: x.createdOn,
        completedOn: x.completedOn,
        lastMessage: x.lastMessage,
        orgName: (orgIdMap.get(x.payload.orgId) || { name: 'unknown' }).name,
        viewName: (viewIdMap.get(x.payload.viewId) || { name: 'unknown' }).name,
        status: (x.startedOn)
          ? (x.completedOn ? 'Completed' : 'In Progress')
          : 'Queued'
      })).sort((a, b) => a.createdOn.valueOf() - b.createdOn.valueOf())
    ),
    tap(() => this.state = 'ready'),
    tapError(err => this.notifSvc.addError(err))
  );

  state: 'loading' | 'ready' = 'loading';


  constructor(
    private clientApi: ClientApi,
    private orgSvc: OrganizationService,
    private ledgerSvc: LedgerService,
    private notifSvc: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }


  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
