
import { Component, OnDestroy } from '@angular/core';
import { FileDownloadService, SubsManager } from '@tcc/ui';
import { finalize, map } from 'rxjs/operators';
import { ReportCube } from './report-cube';
import { ReportDefinition, ReportParameter, ReportsService } from './reports.service';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnDestroy {
  private subsMgr = new SubsManager();

  cube: ReportCube<unknown> | undefined;
  displayedReport: ReportDefinition | undefined;
  selectedReport: ReportDefinition | undefined;
  state: 'loading' | 'ready' = 'loading';

  constructor(public reportsSvc: ReportsService, private fileDownloadSvc: FileDownloadService) {

  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  canRunSelectedReport() {
    return this.selectedReport
      && (!this.selectedReport.parameters
        || this.selectedReport.parameters.every(x => !x.isRequired || x.value != null));

  }
  getParamOptions(param: ReportParameter) {
    if (!param.options && param.dynamicOptions) {
      param.options = [];
      param.dynamicOptions().subscribe(opts => param.options = opts);
    }
    return param.options;
  }

  runSelectedReport() {
    this.state = 'loading';
    this.cube = undefined;
    this.displayedReport = undefined;
    this.selectedReport?.retrieveData((this.selectedReport.parameters || []).map(x => x.value))
      .pipe(
        map(cube => {
          this.cube = cube;
          this.displayedReport = this.selectedReport;
        }),
        finalize(() => this.state = 'ready')
      )
      .subscribe();
  }

  exportReport() {
    const csvData = this.cube?.renderCsv();
    const fileName = this.displayedReport?.name.replace(/[^a-zA-Z0-9]/g, '_') + '.csv';
    this.fileDownloadSvc.startRawDownload(fileName, csvData, 'text/csv;charset=utf-8;');
  }
}
