import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { ReportDefinition } from '../reports.service';
import { GLOBAL, IGlobalSettings } from '../../shared/global-settings';
import { ClientApi, LedgerComment, UserSummary, Snapshot, LedgerAccountNode, LedgerDetail } from '../../client-api.service';
import { UserService } from '../../core-services/user.service';
import { OrganizationService } from '../../core-services/organization.service';
import { LedgerService } from '../../ledgers/ledger.service';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ReportCubeMeasure, ReportCube } from '../report-cube';
import { formatDate } from '@angular/common';

type SnapComment = LedgerComment & { snapshotName?: string, accountName?: string };

@Injectable({
  providedIn: 'root'
})
export class CommentsReportService implements ReportDefinition<SnapComment> {
  readonly name = 'Comments Report';
  readonly parameters = [
    {
      name: 'Organization',
      dynamicOptions: () => this.orgSvc.orgs$
        .pipe(map(orgs => orgs.map((o: { orgId: number; name: string; }) => ({ value: o.orgId!, label: o.name! })))),
      isRequired: true
    },
    {
      name: 'View',
      dynamicOptions: () => this.ledgerSvc.glViews$
        .pipe(take(1), map(views => [{ value: -1, label: 'All' }].concat(views.map(v => ({ value: v.viewId, label: v.name }))))),
      isRequired: true,
      value: -1
    }
  ];


  constructor(@Inject(GLOBAL) private globalSettings: IGlobalSettings, private clientApi: ClientApi,
    @Inject(LOCALE_ID) private locale: string, private ledgerSvc: LedgerService, private orgSvc: OrganizationService,
    private userSvc: UserService) { }

  retrieveData([orgId, viewId]: [number, number]) {
    const accountMap = new Map<string, LedgerAccountNode>();
    let users: Map<number, UserSummary>;
    let ledger: LedgerDetail;
    let comments: SnapComment[];
    let snapshots: Snapshot[];

    return forkJoin([
      this.userSvc.userIdMap$.pipe(map(x => users = x || new Map()), take(1)),
      this.ledgerSvc.getLedgerByName(this.globalSettings.fpLedgerName).pipe(map(x => {
        ledger = x;
        const nodeParents: LedgerAccountNode[] = [x.accountTree!];
        while (nodeParents.length !== 0) {
          const parentNode = nodeParents.splice(0, 1)[0];
          for (const childNode of parentNode.children!) {
            accountMap.set(childNode.accountCode!, childNode);
            if (childNode.children && childNode.children.length !== 0) {
              nodeParents.push(childNode);
            }
          }
        }
      })),
    ])
      .pipe(
        mergeMap(() => {
          const processes: Observable<unknown>[] = [
            this.clientApi.getComments(orgId, ledger.ledgerId!)
              .pipe(tap(x => comments = x.map(y =>
                <SnapComment>{
                  ...y,
                  accountName: accountMap.get(y.accountCode!)!.name
                })))
          ];
          if (viewId && viewId > 0) {
            processes.push(this.orgSvc.getSnapshots(orgId, ledger.ledgerId!, viewId)
              .pipe(tap(x => snapshots = x.sort((a, b) => a.createdOn!.valueOf() - b.createdOn!.valueOf()))));
          }
          return forkJoin(processes);
        }),
        map(() => {

          let commentsWithSnapshots: SnapComment[];
          const measures = [
            new ReportCubeMeasure<SnapComment>('Date', c => c[0].createdOn!, (x) => formatDate(x, 'MMMM d, h:mm a', this.locale)),
            new ReportCubeMeasure<SnapComment>('Account Code', c => c[0].accountCode!),
            new ReportCubeMeasure<SnapComment>('Account Name', c => c[0].accountName!),
            new ReportCubeMeasure<SnapComment>('Creator', c => (users.get(c[0].creatorId!) || { displayName: '' }).displayName!),
            new ReportCubeMeasure<SnapComment>('Comment', c => c[0].comment!)
          ];
          if (snapshots) {
            commentsWithSnapshots = [];
            measures.push(new ReportCubeMeasure<SnapComment>('Snapshot', c => c[0].snapshotName!));
            for (const comment of comments) {
              const snapComment: SnapComment = { ...comment, snapshotName: 'Current Phase' };
              for (const snap of snapshots) {
                if (snap.createdOn! > comment.createdOn!) {
                  snapComment.snapshotName = snap.description;
                  break;
                }
              }
              commentsWithSnapshots.push(snapComment);
            }
          } else {
            commentsWithSnapshots = comments;
          }
          return ReportCube.createSimpleReportCube(commentsWithSnapshots, measures);
        })
      );
  }
}
