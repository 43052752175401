import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { combineLatest, from } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Organization, UserStateInfo, ViewSummary, WorkflowAction, WorkflowActionType } from '../client-api.service';
import { LedgerService } from './ledger.service';
import { OrganizationService } from '../core-services/organization.service';
import { ArrayUtil } from '../shared/array-util';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { tapError } from '../shared/tap-error-operator';
import { UserService } from '../core-services/user.service';
import { uniq } from 'ramda';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnDestroy, OnInit {
  state: 'loading' | 'ready' = 'loading';
  organizations: Organization[] = [];
  visibleOrgs: Organization[] = [];
  views: ViewSummary[] = [];
  visibleViews: ViewSummary[] = [];

  get org() {
    return this._org!;
  }
  set org(value: Organization) {
    if (this._org !== value) {
      this._org = value;
      this.updateVisibleViews();
      this.updateActions();
    }
  }

  get view() {
    return this._view!;
  }
  set view(value: ViewSummary | undefined) {
    if (this._view !== value) {
      this._view = value;
      this.updateActions();
    }
  }

  get user() {
    return this._user!;
  }
  set user(value: UserStateInfo) {
    if (this._user !== value) {
      this._user = value;
      this.updateVisibleViews();
    }
  }
  actions: WorkflowAction[] = [];
  action?: WorkflowAction;

  private _org?: Organization;
  private _view?: ViewSummary;
  private _user?: UserStateInfo;
  private subsManager = new SubsManager();

  constructor(@Inject(GLOBAL) private globalSettings: IGlobalSettings, private ledgerSvc: LedgerService,
    private orgSvc: OrganizationService, private router: Router, private notifSvc: NotificationsService, private userSvc: UserService) {

  }

  ngOnInit() {
    this.state = 'loading';
    this.subsManager.addSub = combineLatest([
      this.ledgerSvc.glViews$.pipe(tap(views => this.views = views)),
      this.orgSvc.orgs$.pipe(tap(orgs => this.organizations = orgs)),
      this.userSvc.currentUser$.pipe(tap(user => this.user = user))
    ])
      .pipe(
        tap(() => this.visibleOrgs = this.organizations
          .filter(o => this.user.accessMatrix.items.some(i => i.orgId === o.orgId))),
        tapError((err) => this.notifSvc.addError(err))
      )
      .subscribe(() => this.state = 'ready');
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  /** Constructs a label for an organiztion by placing it's orgCode in parenthesis after its name. */
  public getOrgLabel(org: Organization) {
    return `${org.name} (${org.orgCode})`;
  }

  /** Constructs a label for an action. */
  public getActionLabel(action: WorkflowAction) {
    return (WorkflowActionType[action.actionType] as string) + ((action.subTitle) ? ` ${action.subTitle}` : '');
  }

  public navigate() {
    if (this.action!.actionType === WorkflowActionType.Read || this.action!.actionType === WorkflowActionType.Edit) {
      this.goToLedger(this.action!.actionType).subscribe();
    }
    else if (this.action!.actionType === WorkflowActionType.Reopen) {
      this.state = 'loading';
      this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.fpLedgerName)
        .pipe(
          mergeMap(ledger => this.orgSvc
            .reopenWorkflow(this.org.orgId, ledger!.ledgerId, this.view!.viewId, this.action!.workflowId!, this.action!.stepId!)),
          mergeMap(() => this.goToLedger(WorkflowActionType.Edit)),
          tapError((err) => this.notifSvc.addError(err)),
          finalize(() => this.state = 'ready')
        )
        .subscribe();
    }
  }

  private updateVisibleViews() {
    if (!this.org || !this.user) {
      this.visibleViews = [];
      this.view = undefined;
      return;
    }

    const availableViewIds = this.user.accessMatrix.items
      .filter(i => i.orgId === this.org.orgId)
      .map(i => i.viewId);

    this.visibleViews = uniq(availableViewIds)
      .map(avId => this.views.find(x => x.viewId === avId)!)
      .sort((a, b) => (a!.sortIndex === b!.sortIndex) ? ArrayUtil.comparerFuncGeneric(a!.name, b!.name) : a!.sortIndex - b!.sortIndex);

    if (this.view) {
      this.view = this.visibleViews.find(x => x.viewId === this.view!.viewId);
    }
  }

  private updateActions() {
    this.subsManager.cancel('updateActions');

    if (!this.view || !this.org) {
      this.actions = [];
      this.action = undefined;
      return;
    }

    this.state = 'loading';
    this.subsManager.subs['updateActions'] =
      this.orgSvc.getUserActions(this.org.orgId, this.view.viewId)
        .pipe(
          map(actions => {
            this.actions = actions;
            if (this.action) {
              this.action = this.actions.find(x => x.actionType === this.action!.actionType);
            }
          }),
          finalize(() => this.state = 'ready')
        )
        .subscribe();

  }

  private goToLedger(action: WorkflowActionType) {
    const queryParams = { orgId: this.org.orgId, viewId: this.view!.viewId, action: action };
    return from(this.router.navigate(['/ledger'], { queryParams: queryParams }));
  }

}
