import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { BehaviorSubject, combineLatest, defer, of } from 'rxjs';
import { catchError, debounceTime, finalize, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ApiException, Organization, RequestAccessForm, Role, UserStateInfo } from 'src/app/client-api.service';
import { OrganizationService } from 'src/app/core-services/organization.service';
import { UserService } from 'src/app/core-services/user.service';
import { ArrayUtil } from 'src/app/shared/array-util';

interface ComponentVm {
  state: 'loading' | 'ready';
  orgs: Organization[];
  roles: Role[];
  user: UserStateInfo;
}

type RequestFormGroupControls = { 
  [key in keyof RequestAccessForm]: AbstractControl; 
};
interface RequestFormGroup extends FormGroup {
  controls: RequestFormGroupControls & { [key: string]: AbstractControl };
  value: RequestAccessForm;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-request-access-form',
  templateUrl: './request-access-form.component.html'
})
export class RequestAccessFormComponent {
  
  readonly requestAccessForm = new FormGroup({
    rolesRequestedIds: new FormControl([]),
    organizationsRequestedIds: new FormControl([]),
    comment: new FormControl(undefined)
  } as RequestFormGroupControls & { [key: string]: AbstractControl }) as RequestFormGroup;

  readonly vm$ = defer(() => combineLatest([
    this.isSubmittingSubject,
    this.userSvc.currentUser$,
    this.orgSvc.orgs$,
    this.userSvc.roles$,
  ])).pipe(
    debounceTime(0),
    map(([isSubmitting, user, orgs, roles]) => ({
      orgs: orgs.sort((a, b) => ArrayUtil.comparerFuncGeneric(a.orgCode, b.orgCode)),
      roles,
      state: orgs && roles && user && !isSubmitting ? 'ready' : 'loading',
      user
    }) as ComponentVm),
    startWith({ orgs: [], roles: [], state: 'loading', user: { userId: -1, displayName: '' } }),
    shareReplay(1)
  );

  private readonly isSubmittingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private notifySvc: NotificationsService,
    private orgSvc: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private userSvc: UserService
  ) { }

  onSubmit() {
    if (this.requestAccessForm.valid) {
      const requestAccessForm = this.requestAccessForm.value;
      this.isSubmittingSubject.next(true);
      this.userSvc.currentUser$.pipe(
        take(1),
        switchMap(x => this.userSvc.requestRoleAccess(x.userId!, requestAccessForm)),
        tap(() => {
          this.notifySvc.addSuccess(`Request for access roles sent.`);
          this.requestAccessForm.reset();
          this.router.navigate(['../'], { relativeTo: this.route });
        }),
        catchError((err) => {
          console.log(err);
          const isAuthError = err instanceof ApiException && err.status === 401;
          if (!isAuthError) {
            this.notifySvc.addError('There was an error loading the application.  Please refresh your browser.');
          }
          return of (undefined);
        }),
        finalize(() => this.isSubmittingSubject.next(false))
      ).subscribe();
    }
    else {
      this.notifySvc.addError(this.requestAccessForm.errors!.toString());
    }
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
