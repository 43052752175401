import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericCanDeactivateGuard } from '@tcc/ui';
import { AccessMatrixComponent } from './admin/access-matrix.component';
import { BatchExportComponent } from './admin/batch-export.component';
import { ExportQueueComponent } from './admin/export-queue.component';
import { TargetedEditComponent } from './admin/targeted-edit.component';
import { UserAdminComponent } from './admin/user-admin.component';
import { WorkflowAddComponent } from './admin/workflow-add/workflow-add.component';
import { WorkflowAdminComponent } from './admin/workflow-admin.component';
import { AppErrorComponent } from './app-error.component';
import { MasterCalcComponent } from './calculations/master-calc.component';
import { HomeComponent } from './ledgers/home.component';
import { LedgerComponent } from './ledgers/ledger.component';
import { LedgerGuard } from './ledgers/ledger.guard';
import { RequestAccessFormComponent } from './ledgers/request-access-form/request-access-form.component';
import { ReportsComponent } from './reports/reports.component';
import { MsalGuard } from '@azure/msal-angular';
import { FinancialPlanningMaintenanceMenuComponent } from './admin/maintenance/financial-planning-maintenance-menu.component';
import { ApplicationViewsUpsertComponent } from './admin/maintenance/ApplicationViews/application-views-upsert.component';


const routes: Routes = [
  { path: 'error', component: AppErrorComponent, canActivate: [MsalGuard] },
  { path: 'home', 
    component: HomeComponent, 
    canActivate: [MsalGuard],
    children: [
      {
        path: 'requestaccessroles',
        component: RequestAccessFormComponent,
        canActivate: [MsalGuard]
      },
    ],
  },
  {
    path: 'ledger',
    component: LedgerComponent,
    canActivate: [MsalGuard, LedgerGuard],
    canDeactivate: [GenericCanDeactivateGuard],
    data: { 'hideNavOptions': true }
  },
  { path: 'masterCalc', component: MasterCalcComponent, canActivate: [MsalGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [MsalGuard] },
  {
    path: 'revenue',
    loadChildren: () => import('./revenue/revenue.module').then(m => m.RevenueModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'accessMatrix',
    component: AccessMatrixComponent,
    children: [
      {
        path: 'useradmin',
        component: UserAdminComponent
      }
    ],
    canActivate: [MsalGuard]
  },
  {
    path: 'batchExport',
    component: BatchExportComponent,
    children: [
      {
        path: 'exportQueue',
        component: ExportQueueComponent
      }
    ],
    canActivate: [MsalGuard]
  },
  {
    path: 'workflowAdmin',
    component: WorkflowAdminComponent,
    canActivate: [MsalGuard],
    children: [
      { path: 'workflowAdd', component: WorkflowAddComponent, canActivate: [MsalGuard] }
    ]
  },
  {
    path: 'maintenance',
    component: FinancialPlanningMaintenanceMenuComponent,
    canActivate: [MsalGuard],
    children: [     
      {
        path: 'upsert-application-view/:id',
        component: ApplicationViewsUpsertComponent,
        canActivate: [MsalGuard],
      }      
    ]
  },
  {
    path: 'targetedEdit',
    component: TargetedEditComponent,
    canActivate: [MsalGuard]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: ' /home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    enableTracing: false,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

