import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GenericCanDeactivateComponent, GenericCanDeactiveHandler, NotificationsService, SubsManager } from '@tcc/ui';
import { combineLatest, defer, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { WorkflowActionType } from '../client-api.service';
import { CommentsStateService } from '../comments/comments-state.service';
import { MenuTrayStateService } from '../menu-tray/menu-tray-state.service';
import { PermissionError } from '../shared/permission-error';
import { tapError } from '../shared/tap-error-operator';
import { AccountVisibilityService } from './account-visibility.service';
import { ComparisonStateService } from './comparisons/comparison-state.service';
import { LedgerStateService } from './ledger-state.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ledger',
  templateUrl: './ledger.component.html'
})
export class LedgerComponent implements OnDestroy, OnInit, GenericCanDeactivateComponent {

  private readonly subsManager = new SubsManager();

  /** current date */
  readonly currentDate = new Date();

  /** this is the handler used by GenericCanDecativateGuard. */
  readonly deactivationHandler = new GenericCanDeactiveHandler();

  /** is this loading or ready? */
  readonly state$ = defer(() => combineLatest([this.ledgerState.ledger$, this.comparisonState.currentSource$])).pipe(
    map(([ledger, source]) => ledger && source ? 'ready' : 'loading'),
    startWith('loading'),
    distinctUntilChanged(),
    shareReplay(1)
  );

  /** current trayId */
  readonly trayRequest$ = this.menuTrayStateSvc.trayChanges$.pipe(map(x => x.trayId), distinctUntilChanged(), shareReplay(1));

  readonly visibleAccountCodesChange$ = this.accountVizSvc.visibleAccountCodesChange$.pipe(shareReplay(1));

  constructor(
    public accountVizSvc: AccountVisibilityService,
    private cd: ChangeDetectorRef,
    public comparisonState: ComparisonStateService,
    private commentState: CommentsStateService,
    public ledgerState: LedgerStateService,
    private menuTrayStateSvc: MenuTrayStateService,
    private notifSvc: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.cd.detectChanges();

    this.subsManager.addSub = this.router.events
      .pipe(
        filter(x => x instanceof NavigationEnd),
        startWith(undefined as unknown as Event),
        switchMap(() => this.loadLedgerFromRoute())
      ).subscribe();

    this.subsManager.addSub = merge(
      this.ledgerState.ledger$,
      this.comparisonState.anyComparisonUpdate$.pipe(debounceTime(250)),
    ).pipe(
      tap(() => this.cd.detectChanges())
    ).subscribe();

    this.subsManager.addSub = this.deactivationHandler.confirmation$.pipe(tap(() => {
      if (this.ledgerState.amountCommands.queueSize !== 0) {
        return confirm('There are still entries being saved.  Are you sure you want to close?')
          ? this.deactivationHandler.completeDeactivation()
          : this.deactivationHandler.cancelDeactivation();
      }
      this.deactivationHandler.completeDeactivation();
    })).subscribe();

  }

  ngOnDestroy() {
    this.menuTrayStateSvc.closeTray(true);
    this.subsManager.onDestroy();
    this.ledgerState.initState();
    this.commentState.clearCommentState();
  }


  /** returns an observable that when subscribed to will load ledger from route query params */
  loadLedgerFromRoute() {
    const route = this.route.snapshot;
    // LedgerRouteGuard should've caught invalid parameters
    const orgId = parseInt(route.queryParamMap.get('orgId')!, 10);
    const viewId = parseInt(route.queryParamMap.get('viewId')!, 10);
    const action = WorkflowActionType[route.queryParamMap.get('action') as WorkflowActionType];

    return this.loadLedger(orgId, viewId, action);
  }

  getComparisonHeader() {
    return this.comparisonState.currentHeader;
  }

  /** toggles higlighComparisonDifferences */
  toggleHighlightComparisonDifferences() {
    return this.comparisonState.isHighlightingDifferences = !this.comparisonState.isHighlightingDifferences;
  }

  private loadLedger(orgId: number, viewId: number, action: WorkflowActionType) {

    return this.ledgerState.loadLedger(orgId, viewId, action).pipe(
      tap(() => this.commentState.loadCommentState(orgId, this.ledgerState.ledgerSummary!.ledgerId)),
      tapError((err) => {
        const msg = (err instanceof PermissionError) ? err.message : 'There was an error getting ledger data.  Please try again later';
        this.notifSvc.addError(msg);
      }),
    );
  }

}
