import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LedgerEntry, UserLedgerDetail } from '../../client-api.service';
import { OrganizationService } from '../../core-services/organization.service';
import { GLOBAL, IGlobalSettings } from '../../shared/global-settings';
import { LedgerTree } from '../ledger-tree';
import { LedgerService } from '../ledger.service';
import { ComparisonAccountEntriesMap, ComparisonSource, ComparisonSourceDataSettings, SnapshotComparisonSource } from './comparison-source';

@Injectable({ providedIn: 'root'})
export class ComparisonsService {

  constructor(
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerSvc: LedgerService,
    private orgSvc: OrganizationService
  ) { }

  /**
   * gets sorted comparisons information for the current org/view
   */
  getLedgerComparisons(orgId: number, ledgerId: number, viewId: number, addNoneSource: boolean) {
    const comparisonSources: ComparisonSource[] = [];
    if (addNoneSource) {
      comparisonSources.push({ id: -99999, name: 'None', sourceType: 'none', data: [] });
    }

    // create the default actbud comparison with no data.
    return this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.actBudLedgerName)
      .pipe(
        map(l => comparisonSources.push({ id: -99998, ledgerId: l!.ledgerId, name: l!.name, sourceType: 'ledger' })),
        switchMap(() => this.orgSvc.getSnapshots(orgId, ledgerId, viewId)),
        map(snaps => {
          snaps.sort((a, b) => a.createdOn!.valueOf() - b.createdOn!.valueOf());
          let minDate = new Date(0);
          for (const snap of snaps) {

            comparisonSources.push(<SnapshotComparisonSource>{
              id: snap.snapshotId,
              ledgerId: ledgerId,
              minCreatedOn: minDate,
              maxCreatedOn: snap.createdOn,
              name: snap.description,
              sourceType: 'snapshot'
            });

            minDate = snap.createdOn!;
          }
          return comparisonSources;
        })
      );
  }

  /**
   * sets comparison data on comparison source and returns the entries.
   * @param comparison the comparison to set the data on
   * @param orgId the orgId to get the comparison for
   * @param viewLedgerId the view ledgerId to get the comparison for
   * @param force if true, will load data even if there is some already set
   */
  populateComparisonSourceData(comparison: ComparisonSource, orgId: number, viewLedgerId: number, force?: boolean) {

    if (comparison.data == null || force) {
      const settingsOptions: ComparisonSourceDataSettings[] = [{ isDefaultEntriesOnly: true }, { isDefaultEntriesOnly: false }];
      let getLedger$: Observable<UserLedgerDetail>;
      let differenceCalc: (x: LedgerTree) => void;

      if (this.isLedgerComparison(comparison)) {
        getLedger$ = this.orgSvc.getLedger(orgId, comparison.ledgerId!, { viewLedgerId: viewLedgerId });
        differenceCalc = (x) => this.calculateDifferencesForLedgerComparison(x.accountMap, x.accountHistoryMap);
      } else if (this.isSnapshotComparison(comparison)) {
        getLedger$ = this.orgSvc.getLedger(orgId, comparison.ledgerId!, { viewLedgerId: viewLedgerId, snapshotId: comparison.id });
        differenceCalc = (x) => this.calculateDifferencesForSnapshotComparison(x.accountMap, x.accountHistoryMap,
          comparison.minCreatedOn, comparison.maxCreatedOn);
      } else {
        getLedger$ = throwError(new Error('Invalid comparison type: ' + comparison.sourceType));
        differenceCalc = () => { };
      }

      return getLedger$.pipe(map(cl => {
        // here we assume that what ever the first entry's year is the year we want to compare to.
        const year = cl.ledgerEntries.length > 0 ? cl.ledgerEntries[0].appliedOn.getFullYear() : this.globalSettings.budgetYear;
        comparison.data = [];
        for (const settings of settingsOptions) {
          const comparisonLedger = new LedgerTree(cl, year, settings.isDefaultEntriesOnly, true);
          // save comparison data so it can be reused later.
          comparison.data.push({ entriesMap: comparisonLedger.accountMap, settings });
          differenceCalc(comparisonLedger);
        }
        return comparison.data;
      }));
    }
    else {
      return of(comparison.data);
    }
  }

  /**
   * sets isDifferentFromPreviousComparison on account entry meta on the accountMap.
   * In this case it checks if more than one value has been set.
   * @param comparisonLedger
   */
  private calculateDifferencesForLedgerComparison(accountMap: ComparisonAccountEntriesMap, historyMap: Map<string, LedgerEntry[]>) {
    let acctHistory: LedgerEntry[];
    let appliedOnCounts: number[];

    for (const accountCode of Object.keys(accountMap)) {
      const entry = accountMap[accountCode];
      acctHistory = historyMap.get(accountCode)!;
      appliedOnCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      entry.amountChangedSincePreviousComparison = [];
      for (const historyEntry of acctHistory) {
        appliedOnCounts[historyEntry.appliedOn.getMonth()] += 1;
      }
      for (let i = 0; i < 12; i++) {
        entry.amountChangedSincePreviousComparison.push(appliedOnCounts[i] > 1);
      }
    }
  }

  /**
   * sets isDifferentFromPreviousComparison on account entry meta on the accountMap.
   * In this case it checks if more than one value has been set.
   */
  private calculateDifferencesForSnapshotComparison(accountMap: ComparisonAccountEntriesMap, historyMap: Map<string, LedgerEntry[]>,
    minCreatedOn: Date, maxCreatedOn: Date) {

    let acctHistory: LedgerEntry[];

    for (const accountCode of Object.keys(accountMap)) {
      const entry = accountMap[accountCode];
      acctHistory = historyMap.get(accountCode)!;
      entry.amountChangedSincePreviousComparison = acctHistory.map(x => x.createdOn! >= minCreatedOn && x.createdOn! < maxCreatedOn);
    }
  }

  private isLedgerComparison(comparisonSource: ComparisonSource) {
    return comparisonSource.sourceType === 'ledger';
  }

  private isSnapshotComparison(comparisonSource: ComparisonSource): comparisonSource is SnapshotComparisonSource {
    return comparisonSource.sourceType === 'snapshot';
  }
}
