import { NgModule } from '@angular/core';
import { CommentManagementComponent } from './comment-management.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { MenuTrayModule } from '../menu-tray/menu-tray.module';

@NgModule({
  declarations: [
    CommentManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MenuTrayModule,
    TccUiModule,
  ],
  exports: [
    CommentManagementComponent
  ]
})
export class CommentsModule { }
