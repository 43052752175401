<menu-tray-wrapper trayId="WorkflowManagement">
  <div menu-tray-header>
    <strong>Workflow Management</strong>
  </div>
  <ng-container menu-tray-panel>
    <div class="px-3 py-2">
      <div>
        <h4 class="d-inline-block mr-2">
          <ng-container *ngIf="action == 'Reject'">Reject</ng-container>
          <ng-container *ngIf="action == 'Approve'">Approve</ng-container>
          Changes
        </h4>
        <div *ngIf="!isCommentValid" class="text-muted d-inline-block">
          {{comment?.length || 0}} characters ({{(minCommentLength - (comment?.length || 0))}} to go)
        </div>
      </div>
      <div style="clear:both"></div>
      <textarea [(ngModel)]="comment" class="mb-2" style="width:500px;height:100px;" [placeholder]="commentPlaceHolderText">
        </textarea>

      <div>
        <button type="button" class="btn btn-sm btn-primary" name="addCommentBtn" [disabled]="!isCommentValid || accountChangeCommentsMissing"
                (click)="isCommentValid && saveEvent()">
          Finish
        </button>
        <span *ngIf="accountChangeCommentsMissing" class="text-danger">
          There are accounts that have changed entries - all changes require comments.
          Look for <i class="fa fa-exclamation-triangle"></i> to the right of the title.
        </span>
        <span class="instructions" *ngIf="action == 'Reject' && !accountChangeCommentsMissing">Once you click Finish, the changes will be sent back for review.</span>
        <span class="instructions" *ngIf="action == 'Approve' && !accountChangeCommentsMissing">Once you click Finish, the changes will be sent to the next stage.</span>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </ng-container>
</menu-tray-wrapper>
