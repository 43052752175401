<div class="card mt-2">
  <div class="card-header px-2 py-1 d-flex flex-row">
    <div class=" form-inline flex-grow-1">
      <strong class="py-2">
        {{curArea?.displayName}} ({{curArea?.name}}) Summary
      </strong>
    </div>
    <div class="flex-shrink-1">
      <button type="button" class="btn btn-sm" (click)="isDebugRowsVisible = !isDebugRowsVisible"
              [ngClass]="isDebugRowsVisible ? 'btn-info' : 'btn-light'"
              title="Show additional data rows used in calculations.">
        <i class="fas fa-bug"></i>
      </button>
    </div>
  </div>
  <div class="card-body p-2" style="overflow-x:auto">
    <table class="table table-sm table-striped small">
      <thead>
        <tr>
          <th></th>
          <th class="text-right" *ngFor="let p of periodSettings.periods">{{p.label}}</th>
          <th class="text-right">Total</th>
        </tr>
      </thead>
      <tbody *ngIf="curArea">
        <ng-container *ngFor="let r of rowInfo">
          <tr *ngIf="!r.isDebugRow || isDebugRowsVisible" [ngClass]="r.rowCssClass!">
            <td class="text-right text-nowrap">
              <span [ngClass]="{'text-italic': r.area != null}" [title]="r.desc || ''">
                {{r.label}}
              </span>
              <span *ngIf="r.badgeInfo" class="d-inline-block ml-1 small bg-warning text-dark rounded px-1 font-weight-bold">
                {{r.badgeInfo}}
              </span>
              <ng-container *ngIf="(comments$ | async) as comments">
                <a *ngIf="r.commentAcctSrc && comments[r.commentAcctSrc]?.hasComments"
                  (click)="showComment(r.commentAcctSrc)" >
                  <i class="fas fa-comment"></i>
                </a>
              </ng-container>
            </td>
            <ng-container *ngTemplateOutlet="r.template; context: { $implicit: r.area || curArea, selector: r.selector }">
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<ng-template #intRow let-area let-selector="selector">
  <td class="text-right" *ngFor="let p of periodSettings.periods; let index = index">
    <ng-container *ngIf="index < periodSettings.cyJan.index; else noBlanks">
      {{selector(area.periods[index]) | number : '0.0-0'}}
    </ng-container>
    <ng-template #noBlanks>
      {{selector(area.periods[index]) || 0 | number : '0.0-0'}}
    </ng-template>
</td>
  <td class="text-right">{{selector(area.summary) | number : '0.0-0'}}</td>
</ng-template>
<ng-template #numberRow let-area let-selector="selector">
  <td class="text-right" *ngFor="let p of periodSettings.periods; let index = index">
    {{selector(area.periods[index]) || 0 | number : '0.2-2'}}
  </td>
  <td class="text-right">{{selector(area.summary) | number : '0.2-2'}}</td>
</ng-template>
<ng-template #pctRow let-area let-selector="selector">
  <td class="text-right" *ngFor="let p of periodSettings.periods; let index = index">
    {{selector(area.periods[index]) || 0 | percent : '0.1-1'}}
  </td>
  <td class="text-right">{{selector(area.summary) | percent : '0.1-1'}}</td>
</ng-template>
<ng-template #occupancyEditingRow let-area let-selector="selector">
  <td class="text-right" *ngFor="let p of periodSettings.periods; let index = index">
    <ng-container *ngIf="(isReadOnly$ | async) || index < periodSettings.cyJan.index; else editable">
      {{selector(area.periods[index]) || 0| percent : '0.1-1'}}
    </ng-container>
    <ng-template #editable>
      <input type="text" #thisInput="ngModel"
             class="text-right input-subtle" style="width:3.5rem"
             [ngModel]="selector(area.periods[index]) || 0 | percent : '0.1-1'"
             (ngModelChange)="thisInput.valid ? updateOccEstimate(area.revAreaId, index, $event) : undefined"
             (blur)="updateOccEstimateForce(area.revAreaId, index)"
             pattern="^\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*%?\s*$"
             [ngClass]="{'is-invalid': thisInput.invalid, 'bg-light': !thisInput.invalid }"
             tccSelectOnFocus />
    </ng-template>
  </td>
  <td class="text-right">{{selector(area.summary) | percent : '0.1-1'}}</td>
</ng-template>
