import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { debounceTime, tap } from 'rxjs/operators';
import { ComparisonStateService } from '../comparison-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparison-menu-section',
  templateUrl: './comparison-menu-section.component.html',
  styles: []
})
export class ComparisonMenuSectionComponent implements OnInit, OnDestroy {

  private subsManager = new SubsManager();

  constructor(private cd: ChangeDetectorRef, public comparisonState: ComparisonStateService) { }

  ngOnInit() {
    this.subsManager.addSub = this.comparisonState.anyComparisonUpdate$
      .pipe(debounceTime(250), tap(() => this.cd.detectChanges()))
      .subscribe();
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  toggleHighlightDifferences() {
    this.comparisonState.isHighlightingDifferences = !this.comparisonState.isHighlightingDifferences;
  }
  toggleHiglightComparisonPeriodUpdates() {
    this.comparisonState.isHighlightingComparisonPeriodUpdates = !this.comparisonState.isHighlightingComparisonPeriodUpdates;
  }
  toggleShowSuggestions() {
    this.comparisonState.isSuggestionsVisible = !this.comparisonState.isSuggestionsVisible;
  }
}
