import { Estimate, EstimateType } from "../client-api.service";
import { PeriodEstimatesBase } from "./models/period-estimates";

const ESTIMATE_TYPE_PERIOD_EST_KEY_PAIRS: readonly [estimateType: EstimateType, periodEstKey: keyof PeriodEstimatesBase][] = [
  [ EstimateType.EstNewLeases, 'estNewLeases' ],
  [ EstimateType.EstNewRate, 'estNewRate' ],
  [ EstimateType.EstRenewalLeases, 'estRenewalLeases' ],
  [ EstimateType.EstRenewalRate, 'estRenewalRate' ],
  [ EstimateType.OriginalAvgRate, 'origAvgRate' ],
  [ EstimateType.OriginalFpRate, 'origFpRate' ],
  [ EstimateType.OriginalLeaseCount, 'origLeaseCount' ],
  [ EstimateType.TargetOccupancyPct, 'tgtOccPct' ],
  [ EstimateType.TargetRateIncreasePct, 'tgtRateIncreasePct' ],
  [ EstimateType.TargetRenewalPct, 'tgtRenewalPct' ],
  [ EstimateType.UnitCount, 'unitCount' ]
];

const ESTIMATE_TYPE_TO_PERIOD_KEY_MAP = new Map(ESTIMATE_TYPE_PERIOD_EST_KEY_PAIRS);

/** Sets the estimate value on a period estimate based on the source estimates's type */
export function setPeriodEstimateValue(srcEst: Estimate, destEst: PeriodEstimatesBase): void {
  const key = getEstimateTypeKey(srcEst.estimateType);
  destEst[key] = srcEst.value;
}

/** Gets the EstimateType value from a PeriodEstimatesBase */
export function getEstimateValueByEstimateType(estimateType: EstimateType, periodEstimate: PeriodEstimatesBase | null | undefined): number | undefined {
  return periodEstimate ? periodEstimate[getEstimateTypeKey(estimateType)] : undefined;
}

function getEstimateTypeKey(estimateType: EstimateType) {
  const key = ESTIMATE_TYPE_TO_PERIOD_KEY_MAP.get(estimateType);
  if (key == null) {
    console.log('Key not found.');
    throw Error('Estimate type does not have matching key.');
  }
  return key;
}