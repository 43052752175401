
export enum ReportCubeAxis {
  None,
  Row,
  Col
}
/** Basic ReportCubePiece, can be dimension or measure or member */
interface ReportCubeElement {
  label: string;
  elementIndex?: number;
}

/** Member of a dimension */
export interface ReportCubeDimensionMember extends ReportCubeElement {
  key: number;
  elementIndex: number;
  /** The member's parent. */
  dimenesion: ReportCubeDimension;
}

export type FormatterFn = (data: string | number | Date) => string;

export class ReportCubeDimension implements ReportCubeElement {
  /** lookup of keys returning the internal index of the key */
  readonly lookup: { [key: string]: number } = {};
  /** index of this dimension in cube.  */
  elementIndex: number | undefined;
  /** index of dimension members */
  readonly members: ReportCubeDimensionMember[];
  /**
      * Creates new dimension
      * @param label name of dimension
      * @param memberSrc index of dimension members
      * @param axis initial axis of dimension
      */
  constructor(public label: string, memberSrc: Pick<ReportCubeDimensionMember, 'key' | 'label'>[], public axis: ReportCubeAxis = ReportCubeAxis.None) {
    this.members = memberSrc.map((m, i) => ({ ...m, dimenesion: this, elementIndex: i }));
    this.members.forEach((m, i) => this.lookup[m.key] = i);
  }
}

/**
    * A measure is a function run on a dataPoint for a given set of dimensions
    */
export class ReportCubeMeasure<T> implements ReportCubeElement {
  /** set when added to cube */
  elementIndex: number | undefined;

  constructor(public label: string, public aggregateFunc: (data: T[]) => string | number | Date, public formatter?: FormatterFn,
    public displayOrder: number = 0) {

  }
}


class HeaderRepetitionInfo {
  /* Number of times the sequence of elements should repeate in a header */
  sequenceTimes: number;
  /* Number of times an element should repeat in a sequence */
  elementTimes: number;

  constructor(headerIndex: number, headers: ReportCubeElement[][]) {
    this.sequenceTimes = headers.slice(headerIndex + 1).reduce((p, c) => p * Math.max(c.length, 1), 1) || 1;
    this.elementTimes = headers.slice(0, headerIndex).reduce((p, c) => p * Math.max(c.length, 1), 1) || 1;
  }

  /**
      * Gets the index of the header element from the current index
      * @param cellIndex
      */
  public getElementIndex(cellIndex: number) {
    return Math.floor((cellIndex / this.sequenceTimes) / this.elementTimes);
  }
}
class DimensionRenderingInfo {
  /* are measures part of header */
  displaysMeasureHeaders: boolean;
  /* header elements to display */
  headers: ReportCubeElement[][];
  /* The number of times a header should repeat.  Element(3) = AAABBBCCC, Sequence(2) = AABBCCAABBCC */
  headerRepetitions: HeaderRepetitionInfo[];
  /* Count of headers, and also How many cells should the other axis be offset by the headers */
  headerCount: number;
  /* total cells per row/col */
  totalCells: number;

  constructor(displaysMeasureHeaders: boolean, headers: ReportCubeElement[][]) {

    this.displaysMeasureHeaders = displaysMeasureHeaders;
    this.headers = headers;
    this.headerRepetitions = this.headers.map((rh, i) => new HeaderRepetitionInfo(i, this.headers));
    this.totalCells = this.headers.reduce((prev: number, cur: ReportCubeElement[]) => prev * cur.length, 1);
    this.headerCount = this.headers.length;
  }
}

export class ReportCube<TData> {

  /**
   * Creates a cube from flat data by adding a row index dimension
   * @param data
   * @param measures
   */
  public static createSimpleReportCube<T>(data: T[], measures: ReportCubeMeasure<T>[]) {
    // create a row dimension of just indexes
    const cube = new ReportCube<T>();
    cube.addDimension(new ReportCubeDimension('Row',
      data.map((c, i) => <ReportCubeDimensionMember>{ key: i, label: i.toString() }), ReportCubeAxis.Row));
    for (const measure of measures) {
      cube.addMeasure(measure);
    }
    for (let i = 0, il = data.length; i < il; i++) {
      cube.addData(data[i], [i.toString()]);
    }
    return cube;
  }

  /** don't modify directly, use addDimension */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private dimensions: ReportCubeDimension[] = [];
  /** don't modify directly, use addMeasure */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private measures: ReportCubeMeasure<TData>[] = [];
  /** nested in dimension order, keyed by dimensionMemberIndex data index */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private dataIndex: TData[] | undefined;
  /** axis where measures are displayed on */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public measureAxis: ReportCubeAxis = ReportCubeAxis.Col;
  /** various options */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public options = {
    hideSoloMeasureHeader: false
  };

  /** Count of columns dimensions plus measure if displayed on column axis */
  get headerColCount() {
    const displaysMeasures = this.measureHeaderIsOnAxis(ReportCubeAxis.Col, this.measures.filter(m => m.displayOrder >= 0).length);

    return this.dimensions.filter(d => d.axis === ReportCubeAxis.Col).length + (displaysMeasures ? 1 : 0);
  }

  /** Count of row dimensions plus measure if displayed on row axis */
  get headerRowCount() {
    const displaysMeasures = this.measureHeaderIsOnAxis(ReportCubeAxis.Row, this.measures.filter(m => m.displayOrder >= 0).length);

    return this.dimensions.filter(d => d.axis === ReportCubeAxis.Row).length + (displaysMeasures ? 1 : 0);
  }
  /** adds a dimension, setting its dimIndex value, and making sure dataIndex is synced */
  public addDimension(dim: ReportCubeDimension) {
    const index = this.dimensions.push(dim) - 1;
    dim.elementIndex = index;
    this.initializeDataIndex();
  }

  public addMeasure(measure: ReportCubeMeasure<TData>) {
    const index = this.measures.push(measure) - 1;
    measure.elementIndex = index;
  }

  /** sets the dataIndex */
  public initializeDataIndex() {
    const itemsLength = this.dimensions.reduce((p: number, cur: ReportCubeDimension) => p * cur.members.length, 1);
    this.dataIndex = [];
    this.dataIndex.length = itemsLength; // hopefully this creates a sparse map if really big number
  }

  /** adds a data point.  Should be done after all dimensions are set so that keys are resolved */
  public addData(data: TData, keys: string[]) {
    this.dataIndex![this.getDataIndexFromKeys(keys)] = data;
  }

  /**
      * adds a range of data, using key retriver to pull keys.  Should be done after all dimiensions are set.
      */
  public addDataRange(dataRange: TData[], keyRetriever: (d: TData) => string[]) {
    for (const data of dataRange) {
      this.addData(data, keyRetriever(data));
    }
  }
  /** gets the index in dataIndex from any array of keys */
  private getDataIndexFromKeys(keys: string[]) {
    let dataIndex = 0;
    let cumulativeDimFactor = 1;
    let dimension: ReportCubeDimension;
    for (let i = 0, il = keys.length; i < il; i++) {
      dimension = this.dimensions[i];
      dataIndex += dimension.lookup[keys[i]] * cumulativeDimFactor;
      cumulativeDimFactor *= dimension.members.length;
    }
    return dataIndex;
  }

  private getMeasureValue(measure: ReportCubeMeasure<TData>, dimMembers: ReportCubeDimensionMember[]) {
    const dimMemberIndexes = new Array<number>(this.dimensions.length);
    for (const dimMember of dimMembers) {
      dimMemberIndexes[dimMember.dimenesion.elementIndex!] = dimMember.elementIndex;
    }
    const dataIndexes = this.getDataIndixesFromDimensionMemberIndexes(dimMemberIndexes);
    const validData: TData[] = [];
    for (const dataIndex of dataIndexes) {
      const dataCur = this.dataIndex![dataIndex];
      if (dataCur != null) {
        validData.push(dataCur);
      }
    }

    return measure.aggregateFunc(validData);
  }

  /** get the indixes in dataIndex from an array of dimIncdices.  Any empty index will include all members in dimension */
  private getDataIndixesFromDimensionMemberIndexes(dimMemberIndexes: number[]) {
    // initialize to an index with a zero element.  This only works if at least one result is always returned
    let dataIndexes = [0];
    let cumulativeDimFactor = 1;
    let dimension: ReportCubeDimension;
    let dimMemberIndex: number;
    for (let i = 0, il = dimMemberIndexes.length; i < il; i++) {
      dimension = this.dimensions[i];
      dimMemberIndex = dimMemberIndexes[i];
      if (dimMemberIndex != null) {
        for (let j = 0, jl = dataIndexes.length; j < jl; j++) {
          dataIndexes[j] += dimMemberIndex * cumulativeDimFactor;
        }
      }
      else {
        // get copy of current array and reinitialize because we will be creating a product of both arrays
        const dataIndexesCurrent = dataIndexes;
        dataIndexes = [];
        for (let j = 0, jl = dimension.members.length; j < jl; j++) {
          for (let k = 0, kl = dataIndexesCurrent.length; k < kl; k++) {
            dataIndexes.push(dataIndexesCurrent[k] + j * cumulativeDimFactor);
          }
        }
      }
      cumulativeDimFactor *= dimension.members.length;
    }
    return dataIndexes;
  }


  /**
      * Creates a 2d array of raw values from the cube.
      */
  renderReport() {
    const colDims = this.dimensions.filter(d => d.axis === ReportCubeAxis.Col);
    const rowDims = this.dimensions.filter(d => d.axis === ReportCubeAxis.Row);
    const displayedMeasures = this.measures.filter(m => m.displayOrder >= 0).sort((a, b) => a.displayOrder - b.displayOrder);

    return this.renderPivotTable(colDims, rowDims, displayedMeasures);
  }
  /**
   * renders report output as a Comma Seperated Values string
   * @param renderedReport optional prerendered report
   */
  renderCsv(renderedReport?: unknown[][]) {
    if (renderedReport == null) {
      renderedReport = this.renderReport();
    }
    let output = '';
    for (const row of renderedReport) {
      const values = row.map(x => this.getCsvValue(x));
      output += `${values.join(',')}\n`;
    }
    return output;
  }
  /**
      * get formatters for each cell in a row
      */
  getMeasureFormatters(): (FormatterFn | undefined)[] {
    return this.measures
      .filter((m) => m.displayOrder >= 0).sort((a, b) => a.displayOrder - b.displayOrder)
      .map(m => m.formatter);
  }

  private getCsvValue(col: unknown) {
    let text: string;
    if (col == null) {
      text = '';
    }
    else if (typeof col === 'string') {
      text = col;
    }
    else if (col instanceof Date) {
      text = col.toLocaleString();
    }
    else if ((col as { toString?(): string }).toString) {
      text = (col as { toString(): string }).toString();
    }
    else {
      text = `${col}`;
    }
    return `"${text.replace('"', '""')}"`;
  }
  private renderPivotTable(colDims: ReportCubeDimension[], rowDims: ReportCubeDimension[],
    displayedMeasures: ReportCubeMeasure<TData>[]) {

    const output: (string | number | Date)[][] = [];

    const colInfo = this.createDimensionRenderingInfo(colDims, ReportCubeAxis.Col, displayedMeasures);
    const rowInfo = this.createDimensionRenderingInfo(rowDims, ReportCubeAxis.Row, displayedMeasures);

    for (let i = 0; i < colInfo.headerCount; i++) {
      output[i] = new Array<number>(rowInfo.headerCount + colInfo.totalCells);
      const curCol = colInfo.headers[i];
      const repeatInfo = colInfo.headerRepetitions[i];
      for (let j = 0; j < colInfo.totalCells; j++) {
        output[i][rowInfo.headerCount + j] = curCol[repeatInfo.getElementIndex(j)].label;
      }
    }

    let outputRow: unknown[];
    let measure: ReportCubeMeasure<TData> = displayedMeasures[0]; // default to measure[0] in case there are none on cols or rows
    const rowKeys = new Array<ReportCubeDimensionMember>(rowDims.length);
    let dataKeys: ReportCubeDimensionMember[];
    for (let i = 0; i < rowInfo.totalCells; i++) {

      output[i + colInfo.headerCount] = outputRow = new Array<number>(rowInfo.headerCount + colInfo.totalCells);
      for (let j = 0; j < rowInfo.headerCount; j++) {
        const rowHeader = rowInfo.headers[j][rowInfo.headerRepetitions[j].getElementIndex(i)];
        outputRow[j] = rowHeader.label;
        if (rowInfo.displaysMeasureHeaders && (j === rowInfo.headerCount - 1)) {
          measure = <ReportCubeMeasure<TData>>rowHeader;
        } else {
          rowKeys[j] = <ReportCubeDimensionMember>rowHeader;
        }
      }
      for (let j = 0; j < colInfo.totalCells; j++) {
        dataKeys = rowKeys.slice(0);
        for (let k = 0; k < colInfo.headerCount; k++) {
          const colHeader = colInfo.headers[k][colInfo.headerRepetitions[k].getElementIndex(j)];
          if (colInfo.displaysMeasureHeaders && (k === colInfo.headerCount - 1)) {
            measure = <ReportCubeMeasure<TData>>colHeader;
          } else {
            dataKeys.push(<ReportCubeDimensionMember>colHeader);
          }
        }

        outputRow[j + rowInfo.headerCount] = this.getMeasureValue(measure, dataKeys);
      }
    }

    return output;
  }

  /**
      * Calculates the headers for axis and creates a new DimensionRenderingInfo
      * @param dims The dimensions on the axis
      * @param dimAxis The axis to create info for
      * @param displayedMeasures the measures that will be displayed in the report
      */
  private createDimensionRenderingInfo(dims: ReportCubeDimension[], dimAxis: ReportCubeAxis,
    displayedMeasures: ReportCubeMeasure<TData>[]) {

    const displaysMeasureHeaders = this.measureHeaderIsOnAxis(dimAxis, displayedMeasures.length);

    const headers = dims.map(d => <ReportCubeElement[]>d.members).concat(displaysMeasureHeaders ? [displayedMeasures] : []);

    return new DimensionRenderingInfo(displaysMeasureHeaders, headers);

  }

  /**
   * determines if the measure header is displayed on axis
   * @param axis the axis to check
   * @param displayedMeasuresCount the number of measures displayed (a single measure may be supressed)
   */
  private measureHeaderIsOnAxis(axis: ReportCubeAxis, displayedMeasuresCount: number) {
    return ((this.measureAxis === axis) && (displayedMeasuresCount > 1 || !this.options.hideSoloMeasureHeader));
  }

}
