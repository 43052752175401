<tcc-simplePanel size="small">
    <div panel-header>Home</div>

    <div panel-body>
      <div class="form-group">
        <label tcc-labelForNext>Organization</label>
        <div>
          <span [hidden]="visibleOrgs?.length == 0">
            <select class="form-control" [(ngModel)]="org">
              <option [ngValue]="undefined">Select One</option>
              <option *ngFor="let o of visibleOrgs" [ngValue]="o">{{getOrgLabel(o)}}</option>
            </select>
          </span>
          <span class="text-muted" [hidden]="visibleOrgs.length">
            You do not have access to view any organizations
          </span>
        </div>
      </div>
      <div class="form-group">
        <label tcc-labelForNext>View</label>
        <div>
          <span [hidden]="visibleViews?.length == 0">
            <select class="form-control" [(ngModel)]="view">
              <option [ngValue]="undefined">Select One</option>
              <option *ngFor="let v of visibleViews" [ngValue]="v">{{v.name}}</option>
            </select>
          </span>
          <span class="text-muted" [hidden]="org">Select Organization to get available Views</span>
          <span class="text-muted" [hidden]="!org || visibleViews.length">
            You do not have access to any views for this organization
          </span>
        </div>
      </div>
      <div class="form-group">
        <label tcc-labelForNext>Action</label>
        <div>
          <span [hidden]="actions?.length == 0">
            <select class="form-control" [(ngModel)]="action">
              <option [ngValue]="undefined">Select One</option>
              <option *ngFor="let a of actions" [ngValue]="a">{{getActionLabel(a)}}</option>
            </select>
          </span>
          <span class="text-muted" [hidden]="(org && view) || !actions">
            Select Organization and View to see available actions
          </span>
          <span class="text-muted" [hidden]="!org || !view || (actions && actions.length > 0)">
            There are no actions available.
          </span>
        </div>
      </div>
      <div class="pt-2">
        <button type="button" class="btn btn-primary" [disabled]="!org || !view || !action"
                (click)="org && view && action && navigate()">
          Financial Plan
        </button>
        <button type="button" class="btn btn-secondary ml-2" [routerLink]="['requestaccessroles']">
          Request Access Roles
        </button>
      </div>
      <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
    </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
