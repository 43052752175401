import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { finalize, tap } from 'rxjs/operators';
import { tapError } from '../shared/tap-error-operator';
import { UserService } from '../core-services/user.service';


@Component({
  selector: 'user-admin',
  styles: [`
  .table td, .table th { padding: 2px 2px; }

  `],
  templateUrl: './user-admin.component.html'
})
export class UserAdminComponent implements OnDestroy, OnInit {
  @ViewChild('myForm', { static: true }) myForm?: NgForm;

  state: 'loading' | 'ready' = 'loading';

  orgViewMatrix: { [key: string]: boolean } = {};

  user: { displayName: string, email: string } = { displayName: '', email: '' };

  private subsMgr = new SubsManager();


  constructor(
    private userSvc: UserService,
    private notifSvc: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.state = 'ready';
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  saveChanges() {
    if (this.myForm?.invalid) {
      return;
    }

    this.state = 'loading';
    this.userSvc.addUser(this.user)
      .pipe(
        tap(() => {
          this.notifSvc.addSuccess(`User ${this.user.displayName} added.`);
          this.myForm?.resetForm();
        }),
        tapError(() => this.notifSvc.addError('Unable to save user')),
        finalize(() => this.state = 'ready')
      ).subscribe();
  }


}
