<h1 class="h4 d-none d-print-block">
  {{ledgerState.organization?.name}}:
  {{ledgerState.view?.name}}-{{ledgerState.currentStep?.name}}
  <span *ngIf="comparisonState.isComparing">
    compared to {{comparisonState.currentSource?.name}}
    <span *ngIf="comparisonState.currentType?.id != 'direct'">{{getComparisonHeader()}}</span>
  </span>
  on {{currentDate | date:'mediumDate'}} by {{ledgerState.user?.displayName}}
</h1>
<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    <ng-container *ngIf="!ledgerState.organization; else isReady">Loading</ng-container>
    <ng-template #isReady>
      {{ledgerState.organization?.name}}
      {{ledgerState.view?.name}}
      <ng-container *ngIf="ledgerState.currentStep">
        - {{ledgerState.currentStep?.name}}
      </ng-container>
    </ng-template>
  </div>
  <div panel-subheader>
    <ledger-menu></ledger-menu>
    <div style="margin: 6px -6px; margin-bottom: -14px" [hidden]="(trayRequest$ | async)  == 'none'">
      <payroll *ngIf="(trayRequest$ | async) == 'PayrollEditor'"></payroll>
      <manual-export *ngIf="(trayRequest$ | async) == 'ManualExport'"></manual-export>
      <workflow-management *ngIf="(trayRequest$ | async) == 'WorkflowManagement'"></workflow-management>
      <comment-management *ngIf="(trayRequest$ | async) == 'CommentManagement'"></comment-management>
      <account-history *ngIf="(trayRequest$ | async) == 'AccountHistory'"></account-history>
    </div>
  </div>
  <div panel-body class="LedgerPanelBody" >
    <div *ngIf="ledgerState.ledger" class="LedgerArea" [class.x2]="comparisonState.isComparing">
      <div class="LedgerHeaderContainer">
        <div class="LedgerHeaderDragHandle" #LeaderHeaderDragHandle>
          <div class="horizontalDragHandle"
               [tccResizeHandle]="[{ el: LedgerTitles, style: 'width'},
                { el: LeaderHeaderDragHandle, style: 'paddingLeft' }]">
          </div>
        </div>
        <div class="LedgerHeader">
          <div class="LedgerHeaderItems shadow-sm" #LedgerHeaderItems>
            <div class="led-row LedgerHeaderTitlesRow">
              <div class="led-amounts">
                <div *ngFor="let mon of ledgerState.ledger.months" class="led-amount">
                  <div class="led-amount-text">{{mon | date:'MMMM'}}</div>
                </div>
                <div class="led-amount">
                  <div class="led-amount-text">Total</div>
                </div>
              </div>
            </div>
            <div class="led-row" *ngIf="comparisonState.isComparing">
              <div class="led-amounts">
                <div *ngFor="let mon of ledgerState.ledger.months" class="led-amount">
                  <div class="led-amount-text">FP</div>
                  <div class="led-amount-text">{{comparisonState.currentHeader}}</div>
                </div>
                <div class="led-amount">
                  <div class="led-amount-text">FP</div>
                  <div class="led-amount-text">{{comparisonState.currentHeader}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="LedgerContent">
        <div class="LedgerTitles" #LedgerTitles>
          <ledger-account-title *ngFor="let a of ledgerState.orderedAccounts"
            [hidden]="!(visibleAccountCodesChange$ | async)?.includes(a.accountCode!)" [account]="a"></ledger-account-title>
        </div>
        <div class="LedgerBody" #LedgerBody>
          <ledger-account *ngFor="let a of ledgerState.orderedAccounts"
            [hidden]="!(visibleAccountCodesChange$ | async)?.includes(a.accountCode!)" [account]="a"></ledger-account>
        </div>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="(state$ | async) != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
