import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { concatMap, filter, last, map, tap, toArray } from 'rxjs/operators';
import { LedgerEntry, LedgerSummary, Organization } from '../client-api.service';
import { SimpleLog } from '../controls/simple-log/simple-log-models';
import { OrganizationService } from '../core-services/organization.service';
import { LedgerService } from '../ledgers/ledger.service';
import { ArrayUtil } from '../shared/array-util';
import { CalcExpression } from '../shared/calculation-expression';
import { defaultCheckStyle } from '../shared/check-styles';
import { AccountEntriesMap, EntryAmounts } from '../shared/entry-amounts';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { NormalizedError } from '../shared/normalized-error';
import { tapError } from '../shared/tap-error-operator';
import { CashFlowZeroOutService } from './cash-flow-zero-out.service';
import { ManagementFeesService } from './management-fees.service';
import { IOrgCalculationRule, OrgCalculationTransform } from './org-calculation-models';
import { PayrollSplitsService } from './payroll-splits.service';
import { TaxCalculationsService } from './tax-calculations.service';
import { range, uniq } from 'ramda';
import { Console } from 'console';

interface CalculationConfig {
  name: string;
  icon: string;
  config: OrgCalculationTransform[];
  destOrgs: { isSelected: boolean, orgCode: string }[];
}


interface OrgResultsTree {
  [orgCode: string]: {
    [acctCode: string]: number[]
  };
}

interface CalculationState {
  ledger: LedgerSummary;
  org: Organization;
  prevLedger: LedgerSummary;
  resolvedVars: AccountEntriesMap;
}

@Component({

  template: `
<tcc-simplePanel size="full" [options]="{ respondsToMainMenu: true }">
  <div panel-header>Master Calculations</div>
  <div panel-body class="d-flex flex-column h-100">
    <div class="pb-3">
      <p>The following will perform calculations on all organizations.  It will not submit workflows or import anything.</p>
      <button *ngFor="let c of configurations" class="btn btn-link" (click)="currentConfig = c">
        <i [ngClass]="c.icon"></i> {{c.name}}
      </button>
    </div>
    <div class="pb-3" *ngIf="currentConfig">
      <div class="d-flex flex-row">
        <h3 class="h4">{{currentConfig.name}}</h3>
        <button class="btn btn-link" [disabled]="state == 'running' || orgSelectedCount == 0"  (click)="deselectAllOrgs()">
          <i class="fa fa-minus-circle"></i> Deselect All
        </button>
        <button class="btn btn-link" [disabled]="state == 'running' || orgSelectedCount === currentConfig?.destOrgs!.length"
            (click)="selectAllOrgs()">
          <i class="fa fa-check-circle"></i> Select All
        </button>
        <button class="btn btn-link" [disabled]="state == 'running' || orgSelectedCount == 0"  (click)="startTransforms()">
          <i class="fas fa-bolt"></i> Run Process
        </button>
      </div>
      <div style="display:inline-block; vertical-align:baseline; width:90px;" *ngFor="let o of currentConfig?.destOrgs">
        <label [title]="orgIndex?.get(o.orgCode)?.name">
          <input type="checkbox" [tccFaCheckStyle]="checkStyle" class="fa-stack-sm" [(ngModel)]="o.isSelected" [disabled]="state == 'running'" />
          {{o.orgCode}}
        </label>
      </div>
      <div *ngIf="orgSelectedCount != currentConfig?.destOrgs!.length" class="text-muted" >
        All variables are processed regardless if an organization is checked above, but accounts are only updated for checked organizations.
      </div>
    </div>
    <div>
        <h3 class="h4">Process Log</h3>
    </div>
    <div style="flex-grow:1; overflow:auto">
        <app-simpleLog [log]="log" tableClass="table table-sm table-striped table-bordered"></app-simpleLog>
    </div>
  </div>
</tcc-simplePanel>
`
})
export class MasterCalcComponent implements OnInit, OnDestroy {
  readonly checkStyle = defaultCheckStyle;
  configurations: CalculationConfig[] = [];
  currentConfig: CalculationConfig | undefined;

  readonly log = new SimpleLog();
  orgIndex: Map<string, Organization> | undefined;

  /** returns the count of the currently selected organizations */
  get orgSelectedCount() {
    return (!this.currentConfig)
      ? 0
      : this.currentConfig.destOrgs.reduce((p: number, c) => p + (c.isSelected ? 1 : 0), 0);
  }

  state: 'none' | 'running' = 'none';

  private subsMgr = new SubsManager();

  constructor(
    private orgSvc: OrganizationService,
    private ledgerSvc: LedgerService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private notifySvc: NotificationsService,
    private mgmtFeesSvc: ManagementFeesService,
    private taxCalcsSvc: TaxCalculationsService,
    private cashFlowZeroSvc: CashFlowZeroOutService,
    private payrollSplitSvc: PayrollSplitsService
  ) { }

  ngOnInit() {
    this.addConfig('Management Fees', 'far fa-building', this.mgmtFeesSvc.config!);
    this.addConfig('Cash Flow Zero Out', 'fas fa-balance-scale', this.cashFlowZeroSvc.config!);

    const initObservables: Observable<unknown>[] = [
      this.orgSvc.orgCodeMap$.pipe(map(x => this.orgIndex = x)),
      this.payrollSplitSvc.getConfig().pipe(map(x => this.addConfig('Payroll Splits', 'fas fa-star-half-alt', x))),
      this.taxCalcsSvc.getConfig().pipe(map(x => this.addConfig('Payroll Taxes', 'fa fa-briefcase', x))),
    ];

    this.subsMgr.addSub = forkJoin(initObservables).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  deselectAllOrgs() {
    this.currentConfig?.destOrgs?.forEach(x => x.isSelected = false);
  }

  selectAllOrgs() {
    this.currentConfig?.destOrgs?.forEach(x => x.isSelected = true);
  }

  startTransforms() {
    this.subsMgr.cancel('doTransforms');
    this.state = 'running';
    const destOrgs = this.filterSelectedOrgs(this.currentConfig!);
    console.log('destOrgs');
    console.log(destOrgs);

    this.subsMgr.subs['doTransforms'] = this.doTransforms(this.currentConfig!.name, this.currentConfig!.config, destOrgs)
      .pipe(
        tapError(err => this.notifySvc.addError(err)))
      .subscribe(() => this.state = 'none');
  }

  private addConfig(name: string, icon: string, config: OrgCalculationTransform[]) {
    // flatten orgCodes
    const orgCodes = uniq(config.map(x => x.assignmentCalculations.map(y => y.orgCode)).flat());

    this.configurations.push({
      name: name,
      icon: icon,
      config: config,
      destOrgs: orgCodes.map(x => ({ isSelected: true, orgCode: x! }))
    });
  }

  private doTransforms(name: string, orgCalcsCollection: OrgCalculationTransform[], destOrgs: string[]) {
    const validations = this.validateMultiOrgCalcsCollection(orgCalcsCollection);

    if (validations.length > 0) {
      validations.forEach(err => this.log.append(err, 'error'));
      return throwError(new Error('Valdation errors occured'));
    }
    const assignmentResults: OrgResultsTree = {};
    let ledger: LedgerSummary;
    let prevLedger: LedgerSummary; // necessasry for lagged values
    let resolvedViews: Map<string | undefined, LedgerSummary | undefined>;

    // The first three observables get global data for all orgs. The fourth processes the orgs. After the last, the fifth saves all results.
    return forkJoin([
      this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.fpLedgerName).pipe(map(x => ledger = x!)),
      this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.actBudLedgerName).pipe(map(x => prevLedger = x!)),
      this.loadViews(orgCalcsCollection).pipe(map(x => resolvedViews = x)),
    ]).pipe(
      tap((x) => {
        //console.log('forkJoin');
        //console.log(x);
      }),
      concatMap(() => from(orgCalcsCollection).pipe(
        map(x => ({
          calcs: x,
          state: <CalculationState>{ ledger: ledger, org: this.orgIndex!.get(x.orgCode!), prevLedger: prevLedger, resolvedVars: {} },
          viewId: (x.sourceViewName) ? resolvedViews.get(x.sourceViewName)!.ledgerId : undefined
        })),
        concatMap(orgInfo => concat(
          this.setSourceVars(orgInfo.state, orgInfo.calcs.sourceCalculations, orgInfo.calcs.sourceLag, orgInfo.viewId),
          this.setAssignmentResults(orgInfo.state, orgInfo.calcs.assignmentCalculations, assignmentResults, destOrgs)
        ))
      )),
      last(),
      concatMap(() => this.saveResults(ledger.ledgerId, assignmentResults)),
      last(),
      map(() => {
        this.log.append(`${name} Complete`, 'success');
        this.notifySvc.addSuccess(`Processing of ${name} has completed.`);
      }),
      tapError(err => {
        const normalError = new NormalizedError(err);
        this.log.append(normalError.message, 'error');
      })
    );

  }

  private filterSelectedOrgs(config: CalculationConfig) {
    const destOrgs: string[] = [];
    const removedOrgs: string[] = [];
    for (const orgCode of config.destOrgs.filter(x => x.isSelected).map(x => x.orgCode)) {
      this.orgIndex?.has(orgCode) ? destOrgs.push(orgCode) : removedOrgs.push(orgCode);
    }
    if (removedOrgs.length !== 0) {
      this.log.append(`Invalid orgs will not be processed: ${removedOrgs.join(', ')}`, 'warning');
    }
    return destOrgs;
  }

  /** loads all views needed for source calculations */
  private loadViews(orgCalcs: OrgCalculationTransform[]) {

    const viewNames = uniq(orgCalcs.map(oc => oc.sourceViewName)).filter(x => x != null);
    return this.ledgerSvc.ledgerSummariesNameMap$.pipe(map(ledgerMap => new Map(viewNames.map(x => [x, ledgerMap.get(x!)]))));
  }

  /** Intended to recieve precalculations to created resolved vars by executing them and adding them to calcState.resolvedVars */
  private setSourceVars(calcState: CalculationState, calcs: IOrgCalculationRule[], lag: number, sourceViewId?: number) {
    if (!calcs || calcs.length === 0) {
      return of(calcState);
    }

    return from(calcs).pipe(
      concatMap(calcInfo => this.ensureRequiredVariables(calcState, calcInfo.calcExpr, lag, sourceViewId).pipe(map(() => calcInfo))),
      map(calcInfo => {
        const calcFunc = calcInfo.calcExpr.compile();
        const rawAmounts = range(0, 12).map(i => calcFunc(calcState.resolvedVars, i));

        if (!calcState.resolvedVars[calcInfo.varName]) {
          calcState.resolvedVars[calcInfo.varName] = { amounts: rawAmounts, total: this.sum(rawAmounts) };
        }
        else {
          const varEntries = <EntryAmounts>calcState.resolvedVars[calcInfo.varName];
          for (let i = 0; i < 12; i++) {
            varEntries.amounts[i] = (varEntries.amounts[i] || 0) + (rawAmounts[i] || 0);
          }
          varEntries.total = this.sum(varEntries.amounts);
        }

        const amountsText = rawAmounts.map(a => a.toFixed(2)).join(', ');
        this.log.append(`Calculations for ${calcState.org.orgCode}, ${calcInfo.varName}: [${amountsText}] - Current Total:
                        ${calcState.resolvedVars[calcInfo.varName].total.toFixed(2)}.`);
      }),
      toArray(),
      map(() => calcState)
    );
  }
  /** Assigns the results of a multi org calcs to the results tree */
  private setAssignmentResults(calcState: CalculationState, calcs: IOrgCalculationRule[], resultTree: OrgResultsTree,
    allowedOrgs: string[]) {

    if (!calcs || calcs.length === 0) {
      return of(resultTree);
    }

    return from(calcs).pipe(
      filter(calcInfo => allowedOrgs.indexOf(calcInfo.orgCode!) !== -1),
      concatMap(calcInfo => this.ensureRequiredVariables(calcState, calcInfo.calcExpr).pipe(map(() => calcInfo))),
      map(calcInfo => {
        const destOrgResults = (resultTree[calcInfo.orgCode!] || (resultTree[calcInfo.orgCode!] = {}));

        const calcFunc = calcInfo.calcExpr.compile();
        const rawAmounts = range(0, 12).map(i => calcFunc(calcState.resolvedVars, i));

        // perform assignment calculations
        // since it is possible that the same account will be assigned a value from multiple calculations
        // we'll save the values and then set them after everything has been calculated.
        if (!destOrgResults[calcInfo.varName]) {
          destOrgResults[calcInfo.varName] = rawAmounts;
        } else {
          // add to existing results
          for (let i = 0; i < 12; i++) {
            destOrgResults[calcInfo.varName][i] += rawAmounts[i];
          }
        }
        return 1;
      }),
      toArray(), // combine all emissions.
      map(() => resultTree)
    );
  }
  /** returns an array of results to save */
  private saveResults(ledgerId: number, resultTree: OrgResultsTree) {
    const orgCodes = Object.keys(resultTree);

    return from(orgCodes)
      .pipe(concatMap(orgCode => {
        const destOrgId = this.orgIndex!.get(orgCode)!.orgId;
        const orgAccts = resultTree[orgCode];

        const orgAcctVals = Object.keys(orgAccts).map(acctCode =>
          orgAccts[acctCode].map((value, index) => ({ acctCode, value, index })));
        const entries: LedgerEntry[] = orgAcctVals.flat().map(av => ({
          accountCode: av.acctCode,
          amount: av.value,
          appliedOn: new Date(this.globalSettings.budgetYear, av.index, 1)
        } as LedgerEntry));
        return this.orgSvc.saveLedgerEntiresSuperUser(destOrgId, ledgerId, entries)
          .pipe(map(() => {
            const accountGrouped = ArrayUtil.group(entries, e => e.accountCode!);
            accountGrouped.forEach((acctEntries, acctCode) => {
              const entriesObj = this.singleAccountEntriesToEntryAmounts(acctEntries, this.globalSettings.budgetYear);
              this.log.append(`Total saved to ${orgCode} ${acctCode}: ${entriesObj.total.toFixed(2)}`);
            });
            return entries;
          }));
      }));
  }
  /** retrieves an required variables for the calculation expression and adds them to the calc state  */
  private ensureRequiredVariables(calcState: CalculationState, calcExpr: CalcExpression, lag: number = 0, sourceViewId?: number) {

    const unresolvedVars: string[] = calcExpr.requiredVariables.filter(v => !calcState.resolvedVars[v]);

    if (unresolvedVars.length === 0) {
      return of(calcState);
    }

    return from(unresolvedVars).pipe(
      concatMap((varName) => this.orgSvc.getCalculatedAccountEntries(calcState.org.orgId, calcState.ledger.ledgerId, varName,
          this.globalSettings.budgetYear, sourceViewId).pipe(map(entries => ({ entries, varName})))),
      concatMap(({ entries, varName }) => {
        if (lag === 0) {
          return of({ entries, varName });
        }
        // prepend budget entries to entries.
        return this.orgSvc.getCalculatedAccountEntries(calcState.org.orgId, calcState.prevLedger.ledgerId,
          varName, this.globalSettings.budgetYear - 1, sourceViewId)
          .pipe(map(resEntries => {
            entries = resEntries.concat(entries);
            entries.forEach(x => x.appliedOn.setMonth(x.appliedOn.getMonth() + lag));
            return { entries, varName };
          }));
      }),
      tap(({ entries, varName }) => {
        // this method will keep all accounts on the current year
        calcState.resolvedVars[varName] = this.singleAccountEntriesToEntryAmounts(entries, this.globalSettings.budgetYear);
      }),
      toArray(),
      map(() => calcState)
    );
  }


  /** Converts an array of entries to an EntryAmounts ensuring the amounts are in the matching year */
  private singleAccountEntriesToEntryAmounts(ledgerEntries: LedgerEntry[], year: number) {
    const amounts = ledgerEntries.filter(x => x.appliedOn.getFullYear() === year)
      .reduce((acc, cur) => { acc[cur.appliedOn.getMonth()] = cur.amount; return acc; }, [] as number[]);
    const entryAmounts: EntryAmounts = { amounts, total: this.sum(amounts) };
    return entryAmounts;
  }

  /** sums an array of numbers. */
  private sum(values: number[]) {
    return (values || []).reduce((acc, cur) => acc + cur, 0);
  }

  /** Validates an array of OrgCalcTransform, returning an array of validation errors. */
  private validateMultiOrgCalcsCollection(orgCalcsCollection: OrgCalculationTransform[]) {
    console.log('MasterCalcComponent::validateMultiOrgCalcsCollection::orgCalcsCollection');
    console.log(orgCalcsCollection);
    return orgCalcsCollection.map(x => this.validateOrgCalcTransform(x)).flat();
  }

  /**
   * finds if there are any missing orgCodes in the calculations
   * @param orgCalcTransform: The transform to validate orgCodes on.
   * @returns an array of errors that is empty if none occur
   */
  public validateOrgCalcTransform(orgCalcTransform: OrgCalculationTransform) {
    //console.log('orgCalcTransform');
    //console.log(orgCalcTransform);

    // create an array of orgs to check, identifying if they are a source a destination org.
    const orgsToCheck = [ { type: 'Source', orgCode: orgCalcTransform.orgCode },
      ... orgCalcTransform.assignmentCalculations.map(x => ({ type: 'Destination', orgCode: x.orgCode }))
    ];

    // check that orgCode is set and that it is in the orgIndex.
    return orgsToCheck
      .filter(x => !x.orgCode || !this.orgIndex?.has(x.orgCode))
      .map(x => `${x.type} organization ${x.orgCode} does not exist.`);

  }
}



