import { InputDebounceManager } from '../shared/input-debounce-manager';
import { EstimateType } from '../client-api.service';
import { NumberParsingUtil } from '../shared/number-parsing-util';
import { PeriodSettings } from './models/period-settings';

export interface EstimateKeys {
  appliedOn?: Date;
  estimateType?: EstimateType;
  orgId?: number;
  revAreaId?: number;
}

export interface EstimateInputDebounceManagerUpdateOptions {
  forceUpdate?: boolean;
  forCy?: boolean;
}

/** common methods for handling ui inputs interactions */
export class EstimateInputDebounceManager {
  private readonly inputDebounceMgr = new InputDebounceManager<string, EstimateKeys & { value: number }>();
  /** optional default applies on type */
  defaultAppliedOn?: Date;
  /** optional default estimate type */
  defaultEstimateType?: EstimateType;
  /** optional orgId */
  defaultOrgId?: number;
  /** optional default rev area id */
  defaultRevAreaId?: number;

  readonly estimateChange$ = this.inputDebounceMgr.change$;

  /**
   * Creates a new instance, optionally settings periodsSettings that will be used when calling update with forCy option.
   * @param periodSettings set this if calling updating CY estimates
   */
  constructor(private periodSettings?: PeriodSettings) { }

  /** call this when subscriptions need to be unsubscribed */
  clear() {
    this.inputDebounceMgr.clear();
  }

  /** forces change notifications for all outstanding changes */
  forceAll() {
    this.inputDebounceMgr.forceAll();
  }

  updateEstimate(value: number, keys: EstimateKeys, opts: EstimateInputDebounceManagerUpdateOptions = {}) {
    const keysWithDefaults = this.getKeysWithDefaultsForMissing(keys);
    if (opts.forCy) {
      // create estimates for the entire current year
      for (let i = this.periodSettings!.cyJan.index; i < this.periodSettings!.periods.length; i++) {
        keysWithDefaults.appliedOn = this.periodSettings!.periods[i].value;
        const key = this.createCompositeKey(keysWithDefaults);
        this.inputDebounceMgr.onChange(key, { ...keysWithDefaults, value }, opts.forceUpdate);
      }
    }
    else {
      const key = this.createCompositeKey(keysWithDefaults);
      this.inputDebounceMgr.onChange(key, { ...keysWithDefaults, value }, opts.forceUpdate);
    }
  }

  updateRawEstimate(valueRaw: string, keys: EstimateKeys, opts?: EstimateInputDebounceManagerUpdateOptions) {
    this.updateEstimate(this.parseValue(valueRaw)!, keys, opts);
  }

  /**
   * Updates an estimate pct.  Assumes if there number is greater than 1 that the intention is that the number is a fraction of percent
   */
  updateRawEstimatePct(valueRaw: string, keys: EstimateKeys, opts?: EstimateInputDebounceManagerUpdateOptions) {
    this.updateEstimate(this.parseValue(valueRaw, true)!, keys, opts);
  }

  updateForce(keys: EstimateKeys, isForCy?: boolean) {
    const keysWithDefaults = this.getKeysWithDefaultsForMissing(keys);
    if (isForCy) {
      for (let i = this.periodSettings!.cyJan.index; i < this.periodSettings!.periods.length; i++) {
        keysWithDefaults.appliedOn = this.periodSettings!.periods[i].value;
        const key = this.createCompositeKey(keysWithDefaults);
        this.inputDebounceMgr.forceNotification(key);
      }
    }
    else {
      const key = this.createCompositeKey(keysWithDefaults);
      this.inputDebounceMgr.forceNotification(key);
    }
  }

  private createCompositeKey(keys: EstimateKeys) {
    return `${keys.orgId}:${keys.revAreaId}:${keys.estimateType}:${keys.appliedOn?.valueOf()}`;
  }

  private getKeysWithDefaultsForMissing(keys: EstimateKeys) {
    return {
      appliedOn: keys.appliedOn || this.defaultAppliedOn,
      estimateType: keys.estimateType || this.defaultEstimateType,
      orgId: (keys.orgId != null ? keys.orgId : this.defaultOrgId) || 0,
      revAreaId: keys.revAreaId != null ? keys.revAreaId : this.defaultRevAreaId
    };
  }

  /**
   * Tries to parse a text value as a float.
   * @param valueRaw The string to parse
   * @param handlePct if true will using parseFloat's pct handling, plus additional logic to handle percents
   */
  private parseValue(valueRaw: string, handlePct?: boolean) {
    if (valueRaw == null || valueRaw.trim() === '') {
      return undefined;
    }

    let value = NumberParsingUtil.parseFloat(valueRaw, { normalizePercent: handlePct });
    if (!isNaN(value!) && handlePct && value! > 1 && valueRaw.indexOf('%') === -1) {
      value = value! / 100;
    }
    return value;
  }

}
