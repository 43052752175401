
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentsReportService } from './definitions/comments-report.service';
import { WorkflowStatusReportService } from './definitions/workflow-status-report.service';
import { ReportCube } from './report-cube';

export interface ReportParameter<TVal extends string | Date | number = string | Date | number> {
  name: string;
  dynamicOptions?: () => Observable<{ value: TVal, label: string }[]>;
  options?: { value: TVal, label: string }[];
  isRequired?: boolean;
  isHidden?: boolean;
  value?: TVal;
}

export interface ReportDefinition<T = unknown> {
  readonly name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retrieveData: (...rest: any[]) => Observable<ReportCube<T>>;
  parameters?: ReportParameter[];
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly reportDefinitions: ReportDefinition<any>[];

  constructor(commentsReport: CommentsReportService, workflowStatusReport: WorkflowStatusReportService) {
    this.reportDefinitions = [
      commentsReport,
      workflowStatusReport
    ];
  }

}
