import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '@tcc/ui';
import { BehaviorSubject, concat, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, mapTo, shareReplay, switchMap, take, tap, toArray } from 'rxjs/operators';
import { AccountingMethodType, Employee, HourAllocation, Organization, PayItem, PayPeriodType, PayType, PositionType } from '../client-api.service';
import { CommandConstants } from '../commands/command-constants';
import { CommandsService } from '../commands/commands.service';
import { OrganizationService } from '../core-services/organization.service';
import { UserService } from '../core-services/user.service';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { tapError } from '../shared/tap-error-operator';
import { DateHelper } from './date-helper';
import { PayBreakdown } from './pay-breakdown';
import { PayItemAmounts, PayrollEmployeeModel, PayrollEmployeeModelUtil } from './payroll-employee';
import { PayrollService } from './payroll.service';

interface HourlyPagConfig {
  /** returns true if the month index should be highlighted, probably because it has extra pay periods. */
  highlightMonth: (monthIndex: number) => boolean;
  /** Number of periods in a month for each month. */
  monthPeriods: number[];
  /** What should appear after the numerical periods in a month. */
  monthPeriodsSuffix: string;
  /** Average number of month periods per week. */
  periodsPerWeek: number;
}
@Injectable({
  providedIn: 'root',
})
export class PayrollStateService {
  private _selectedEmployee: PayrollEmployeeModel | null | undefined;
  /** stores up a bunch of changes that need to be performed on an employee and return changed employee. */
  private employeeChangesQueue = new Subject<Observable<PayrollEmployeeModel>>();
  /** notifies wehn an employee has been changed. */
  private employeeChangesSubject = new Subject<PayrollEmployeeModel>();
  private orgChangeSubject = new Subject<Organization | undefined>();
  private selectedEmployeeeChangedSubject = new Subject<PayrollEmployeeModel>();

  readonly overtimeThreshold = 40;
  readonly overtimePayrate = 1.5;
  /** because sometimes there are 53 */
  readonly payWeeksInaYear = 52;

  basePayType?: PayType;

  // implement me
  canModifyEmployees$ = this.orgChangeSubject.pipe(
    switchMap((org) => this.userSvc.isUserInRoles([UserService.roles.executiveApprover, UserService.roles.superApprover], org!.orgId!)),
    shareReplay(1)
  );

  readonly employeeChanges$ = this.employeeChangesSubject.asObservable();

  employees: PayrollEmployeeModel[] = [];

  hourlyPayConfig: HourlyPagConfig = {
    monthPeriods: [],
    periodsPerWeek: 1,
    monthPeriodsSuffix: '',
    highlightMonth: () => false
  };

  /** month labels */
  months: string[] | undefined;

  /** current organization id */
  orgId?: number;

  /** non-basePay pay types */
  otherPayTypes: PayType[] | undefined;

  payTypes: PayType[] | undefined;
  payTypeMap: Map<number, PayType> | undefined;
  positions: PositionType[] | undefined;
  positionMap: Map<string, PositionType> | undefined;
  positionNames: string[] | undefined;

  /** is the state ready. */
  readonly readyChangeSubject = new BehaviorSubject(false);

  get selectedEmployee() {
    return this._selectedEmployee!;
  }
  set selectedEmployee(value: PayrollEmployeeModel | undefined) {
    if (value !== this._selectedEmployee) {
      this._selectedEmployee = value;
      this.selectedEmployeeeChangedSubject.next(this._selectedEmployee);
    }
  }
  get selectedEmployeeChanged$() {
    return this.selectedEmployeeeChangedSubject.asObservable();
  }

  constructor(
    private commandsSvc: CommandsService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerState: LedgerStateService,
    private notifSvc: NotificationsService,
    private orgSvc: OrganizationService,
    private payrollSvc: PayrollService,
    private userSvc: UserService) {

    this.init();
  }


  getEmployeeTotalBasePay(employee: PayrollEmployeeModel) {
    let total = 0;
    for (let i = 0; i < 12; i++) {
      total += this.getWeeklyBasePay(employee, i).totalPay * this.getPeriodWeekFactor(i);
    }
    return total;

  }

  getEmployeeOtherPayItemsTotal(employee: PayrollEmployeeModel, isBudget: boolean) {
    let total = 0;
    for (let i = 0; i < 12; i++) {
      total += this.getPayItemsForMonth(employee, i, isBudget, this.otherPayTypes!.map(x => x.payTypeId!));
    }

    return total;
  }

  getProposedVarianceFromCurrentPercent(amounts: PayItemAmounts) {
    if (!amounts || !amounts.proposedAmount) {
      return 0;
    }
    else if (!amounts.currentAmount) {
      return Number.POSITIVE_INFINITY;
    }
    return (amounts.proposedAmount - amounts.currentAmount) / amounts.currentAmount;
  }

  getEmployeeTotalBasePayForMonth(employee: PayrollEmployeeModel, monthIndex: number) {
    return this.getWeeklyBasePay(employee, monthIndex).totalPay * this.getPeriodWeekFactor(monthIndex);
  }

  getPayItemsForMonth(employee: PayrollEmployeeModel, monthIndex: number, isBudget: boolean, forPayTypeIds: number[]) {

    let total = 0;
    const periodWeekFator = this.getPeriodWeekFactor(monthIndex);

    for (const payTypeId of forPayTypeIds) {
      const payAmounts = employee.payItemAmounts[payTypeId];

      if (!payAmounts) {
        continue;
      }
      const amount = (isBudget) ? payAmounts.proposedAmount : payAmounts.currentAmount;
      switch (payAmounts.payPeriodType) {
        case PayPeriodType.Biweekly: total += amount * periodWeekFator / 2; break;
        case PayPeriodType.Monthly: total += amount; break;
        case PayPeriodType.Weekly: total += amount * periodWeekFator; break;
        case PayPeriodType.Hourly: total += amount * periodWeekFator * employee.monthlyHourAllocations[monthIndex].hours; break;
        case PayPeriodType.Annual: total += amount * periodWeekFator / 52; break;
      }
    }
    return total;
  }

  getEmployeeTotalCompensation(employee: PayrollEmployeeModel) {
    return this.getEmployeeOtherPayItemsTotal(employee, true) + this.getEmployeeTotalBasePay(employee);
  }

  /** Gets number of paid weeks in a month */
  getPeriodWeekFactor(monthIndex: number) {
    return this.hourlyPayConfig.monthPeriods[monthIndex] / this.hourlyPayConfig.periodsPerWeek;
  }


  getWeeklyBasePay(employee: PayrollEmployeeModel, monthIndex: number) {
    if (!employee || !employee.payItemAmounts[this.basePayType!.payTypeId!]) {
      return new PayBreakdown(0);
    }
    const ha = employee.monthlyHourAllocations[monthIndex] || { hours: 0 };
    const payItem = employee.payItemAmounts[this.basePayType!.payTypeId!];

    return this.getWeeklyRate(payItem.proposedAmount, payItem.payPeriodType === PayPeriodType.Annual, ha.hours);
  }


  /**
   * Calculates the number of overtimehours
   * @param totalHours The total hours that was worked in a week
   */
  getOvertimeHours(totalHours: number) {
    return Math.max(0, totalHours - this.overtimeThreshold);
  }
  /**
   * Calculates the number of regular hours per week upto the threadshold
   * @param totalHours The total hours that was worked in a week
   */
  getRegularHours(totalHours: number) {
    return Math.min(this.overtimeThreshold, totalHours);
  }
  /**
   * Calculate the weekly wage for an employee using an hourlyWage
   * @param hourlyRate how much they are paid per hour
   * @param totalHours The total hours that was worked in a week
   */
  getHourlyWeeklyWage(hourlyRate: number, totalHours: number) {
    return new PayBreakdown(this.getRegularHours(totalHours) * hourlyRate,
      this.getOvertimeHours(totalHours) * hourlyRate * this.overtimePayrate);

  }
  /**
   * Calculate the weekly wage for an employee using an salary and expected work week equal to overtimeHoursThreshold
   * @param annualSalary how much they are paid per year
   * @param totalHours the Total hours worked in a  week
   */
  getSalaryWeeklyWage(annualSalary: number, totalHours: number) {
    const weeklySalary = annualSalary / this.payWeeksInaYear;

    if (annualSalary >= this.globalSettings.payrollExemptSalaryThreshold) {
      return new PayBreakdown(weeklySalary, 0);
    }
    const hourlyRate = weeklySalary / this.overtimeThreshold;

    return new PayBreakdown(weeklySalary, this.getOvertimeHours(totalHours) * hourlyRate * this.overtimePayrate);
  }

  /**
   * Calculate the weekly wage for an employee using appropriate rules depending on if they are salary or hourly
   * @param salaryOrHourlyRate salary or hourly rate
   * @param isAnnualSalary is salaryOrHourlyRate salary (true) or hourly rate)
   * @param totalHours the Total hours worked in a  week
   */
  getWeeklyRate(salaryOrHourlyRate: number, isAnnualSalary: boolean, totalHours: number) {

    return (isAnnualSalary)
      ? this.getSalaryWeeklyWage(salaryOrHourlyRate, totalHours)
      : this.getHourlyWeeklyWage(salaryOrHourlyRate, totalHours);
  }

  /**
   * Returns true if the employee is not hourly and exceeds the threshold for to be overtime exempt.
   * @param employee
   */
  isOvertimeExempt(employee: PayrollEmployeeModel) {
    const basePayAmounts = employee && employee.payItemAmounts[this.basePayType!.payTypeId!];
    return basePayAmounts &&
      (basePayAmounts.payPeriodType !== PayPeriodType.Hourly) &&
      (basePayAmounts.proposedAmount >= this.globalSettings.payrollExemptSalaryThreshold);
  }

  /**
   * Adds a new employee to the employees array
   */
  employeeAdd() {
    const newEmpModel = PayrollEmployeeModelUtil.create({
      externalId: undefined,
      position: '',
      isCurrentValuesLocked: false,
      isFullTime: false,
      empId: 0,
      name: 'New Employee',
      budgetYear: this.globalSettings.budgetYear,
      payItems: [],
      hourAllocations: []
    }, this.payTypes!, this.basePayType!);

    this.employees.push(newEmpModel);
  }


  /** Deletes an employee. */
  employeeDelete(emp: PayrollEmployeeModel) {
    const employeeIndex = this.employees.findIndex(x => x.trackingId === x.trackingId);
    if (employeeIndex !== -1) {
      this.employees.splice(employeeIndex, 1);
      if (this.selectedEmployee && this.selectedEmployee.trackingId === emp.trackingId) {
        this.selectedEmployee = null!;
      }
    }
    if (emp.empId) {
      return this.payrollSvc.deleteEmployee(this.orgId!, emp.empId).pipe(take(1));
    }
    return of(undefined);
  }

  /**
   * determines differences from original model and returns an array of all server side changes to be made
   * @param updatedEmp: a model that has all updates
   */
  employeeUpdate(updatedEmp: PayrollEmployeeModel) {
    const empIndex = this.employees.findIndex(x => x.trackingId === updatedEmp.trackingId);
    const emp = this.employees[empIndex];
    const updates: Observable<unknown>[] = [];

    if (emp.name !== updatedEmp.name || emp.isFullTime !== updatedEmp.isFullTime || emp.position !== updatedEmp.position) {
      updates.push(this.saveEmployeeInfo(updatedEmp));
    }

    for (const pt of this.payTypes!) {
      const empPayAmounts = emp.payItemAmounts[pt.payTypeId!];
      const updPayAmounts = updatedEmp.payItemAmounts[pt.payTypeId!];
      const updateCurrent = (empPayAmounts.payPeriodType !== updPayAmounts.payPeriodType) ||
        (empPayAmounts.currentAmount !== updPayAmounts.currentAmount);
      const updateProposed = (empPayAmounts.payPeriodType !== updPayAmounts.payPeriodType) ||
        (empPayAmounts.proposedAmount !== updPayAmounts.proposedAmount);

      if (updateCurrent) {
        const pi: PayItem = {
          amount: updPayAmounts.currentAmount,
          payItemId: updPayAmounts.currentPayItemId!,
          payTypeId: pt.payTypeId,
          isBudget: false,
          payPeriodType: updPayAmounts.payPeriodType
        };
        updates.push(this.savePayItem(updatedEmp.empId, pi)
          .pipe(map(res => {
            if (res != null) {
              updPayAmounts.currentPayItemId = res.payItemId;
            }
          })));
      }
      if (updateProposed) {

        const pi: PayItem = {
          amount: updPayAmounts.proposedAmount,
          payItemId: updPayAmounts.proposedPayItemId!,
          payTypeId: pt.payTypeId,
          isBudget: true,
          payPeriodType: updPayAmounts.payPeriodType
        };
        updates.push(this.savePayItem(updatedEmp.empId, pi)
          .pipe(map(res => {
            if (res != null) {
              updPayAmounts.proposedPayItemId = res.payItemId;
            }
          })));
      }
    }

    for (let i = 0, il = this.months?.length; i < il!; i++) {
      const empHourAllocation = emp.monthlyHourAllocations[i];
      const updHourAllocation = updatedEmp.monthlyHourAllocations[i];
      if (updHourAllocation.hours !== empHourAllocation.hours) {
        const ha = { hourAllocationId: updHourAllocation.hourAllocationId, month: i + 1, hours: updHourAllocation.hours } as HourAllocation;
        updates.push(this.saveHourAllocation(updatedEmp.empId, ha)
          .pipe(map(res => {
            if (res != null) {
              updHourAllocation.hourAllocationId = res.hourAllocationId;
            }
          })));
      }
    }

    if (updates.length > 0) {
      this.employees[empIndex] = updatedEmp;
      this.employeeChangesSubject.next(updatedEmp);
      if (this.selectedEmployee && this.selectedEmployee.trackingId === updatedEmp.trackingId) {
        this.selectedEmployee = updatedEmp;
      }
      const changesObservable = concat(...updates).pipe(
        toArray(), // rollup changes, and notify that the employee changed, again in case of side effects.
        mapTo(updatedEmp)
      );
      this.employeeChangesQueue.next(changesObservable);
    }
  }

  setOrganization(orgId: number | undefined) {
    this.hourlyPayConfig = {
      monthPeriods: <number[]>[],
      periodsPerWeek: 1,
      monthPeriodsSuffix: '',
      highlightMonth: () => false
    };
    this.employees = [];
    this.orgId = orgId;
    if (!this.orgId) {
      this.orgChangeSubject.next(undefined as unknown as Organization);
      return of(undefined);
    }
    return forkJoin([
      this.orgSvc.getOrganization(orgId!).pipe(map(org => {
        if (org?.accountingMethod === AccountingMethodType.Cash) {
          this.hourlyPayConfig.monthPeriods = this.globalSettings.payrollCashPayDaysPerMonth
            || DateHelper.biWeeklyPayDaysPerMonth(this.globalSettings.payrollFirstPayPeriod!);
          this.hourlyPayConfig.periodsPerWeek = .5; // period = 2wks
          this.hourlyPayConfig.monthPeriodsSuffix = 'Paychecks';
          this.hourlyPayConfig.highlightMonth = (monthIndex: number) => this.hourlyPayConfig.monthPeriods[monthIndex] > 2;
        } else {
          this.hourlyPayConfig.monthPeriods = DateHelper.weekDaysPerMonth(this.globalSettings.budgetYear);
          this.hourlyPayConfig.periodsPerWeek = 5.0; // period = 8hrs assuming 40 hours work week
          this.hourlyPayConfig.monthPeriodsSuffix = 'Work Days';
          this.hourlyPayConfig.highlightMonth = () => false;
        }
        return org;
      })),
      this.payrollSvc.getEmployees(orgId!).pipe(tap(emps =>
        this.employees = emps.map(e => PayrollEmployeeModelUtil.create(e, this.payTypes!, this.basePayType!))
          .sort((a, b) => (a.payItemAmounts[this.basePayType!.payTypeId!] || { currentAmount: 0 }).currentAmount
            - (b.payItemAmounts[this.basePayType!.payTypeId!] || { currentAmount: 0 }).currentAmount)
      ))
    ]).pipe(
      tap(([org]) => this.orgChangeSubject.next(org)),
      map(([org]) => org)
    );
  }

  /** This updates the fp ledger with payroll values. */
  updateFp() {
    // this command is only for UI purposes to show that something is occuring even if a save doesn't actually.
    const command = this.commandsSvc.getCommand(CommandConstants.PayrollFpUpdate);
    const batch = command.enqueue({});
    const accountValues = this.calculateAccountValues();
    accountValues.forEach((amounts, accountCode) =>
      amounts.forEach((val, i) => this.ledgerState.executeAmountChange(accountCode, i, val))
    );
    command.executed(batch!);
  }

  /** Returns a map of accounts, with month by month values for payroll */
  private calculateAccountValues() {
    const results = new Map<string, number[]>();
    let breakdown: PayBreakdown;
    let isService: boolean | undefined;

    // salary types mapped to account codes
    const salaryAccountMap = {
      mgtSalary: '548000',
      mgtOt: '548050',
      svcSalary: '548100',
      svcOt: '548150',
    } as const;
    // Non additional pay types mapped to accounts with regex to find payTypeIds
    const payTypeAccountMap = [
      { account: '511000.1', payTypeRe: /^Cell Allowance$/i, payTypeIds: <number[]><unknown>undefined },
      { account: '548191', payTypeRe: /^Auto Allowance$/i, payTypeIds: <number[]><unknown>undefined },
      { account: '548192', payTypeRe: /^Internet Allowance$/i, payTypeIds: <number[]><unknown>undefined },
      { account: '548193', payTypeRe: /^Other Allowance$/i, payTypeIds: <number[]><unknown>undefined }
    ];
    Object.values(salaryAccountMap).forEach((accountCode) => results.set(accountCode, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]));
 
    for (const ptaMap of payTypeAccountMap) {
      ptaMap.payTypeIds = this.payTypes!.filter(pt => ptaMap.payTypeRe.test(pt.name!)).map(pt => pt.payTypeId!);
      console.log('ptaMAP:');
      console.log(ptaMap);
      results.set(ptaMap.account, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      console.log(results);
    }
    for (const emp of this.employees) {
      isService = (this.positionMap?.get(emp.position) || { isService: false }).isService;

      for (let i = 0; i < 12; i++) {
        breakdown = this.getWeeklyBasePay(emp, i);
        if (isService) {
          results.get(salaryAccountMap.svcSalary)![i] += breakdown.regularPay * this.getPeriodWeekFactor(i);
          results.get(salaryAccountMap.svcOt)![i] += breakdown.otPay * this.getPeriodWeekFactor(i);
        } else {
          results.get(salaryAccountMap.mgtSalary)![i] += breakdown.regularPay * this.getPeriodWeekFactor(i);
          results.get(salaryAccountMap.mgtOt)![i] += breakdown.otPay * this.getPeriodWeekFactor(i);
        }
        for (const ptaMap of payTypeAccountMap) {
          results.get(ptaMap.account)![i] += this.getPayItemsForMonth(emp, i, true, ptaMap.payTypeIds);
        }
      }
    }

    return results;
  }

  /** Saves just the demographic information about employee, updates the employeeId if the save is succesful. */
  private saveEmployeeInfo(emp: PayrollEmployeeModel) {

    const svcEmp: Employee = {
      ...emp,
      hourAllocations: [],
      payItems: []
    };

    const cmd = this.commandsSvc.getCommand<Employee>(CommandConstants.PayrollEmployeeSave);
    const batch = cmd.enqueue(svcEmp);

    return this.payrollSvc.saveEmployee(this.orgId!, svcEmp)
      .pipe(
        map(savedEmp => {
          emp.empId = savedEmp.empId!;
          cmd.executed(batch!);
          return emp;
        }),
        tapError(() => {
          cmd.failed(batch!);
          this.notifSvc.addError('Application failed saving new Employee.  Please stop your work and refresh the browser');
        })
      );
  }

  private savePayItem(empId: number, payItem: PayItem) {
    const cmd = this.commandsSvc.getCommand<PayItem>(CommandConstants.PayrollItemSave);
    const batch = cmd.enqueue(payItem);


    return this.payrollSvc.savePayItem(this.orgId!, empId, payItem)
      .pipe(
        map(savedItem => {
          payItem.payItemId = savedItem.payItemId;
          return payItem;
        }),
        tap(() => cmd.executed(batch!)),
        tapError(() => {
          cmd.failed(batch!);
          this.notifSvc.addError('Application failed saving Payroll Change.  Please stop your work and refresh the browser');
        })
      );
  }

  private saveHourAllocation(empId: number, hourAllocation: HourAllocation) {
    const cmd = this.commandsSvc.getCommand<HourAllocation>(CommandConstants.PayrollHourAllocationSave);
    const batch = cmd.enqueue(hourAllocation);

    return this.payrollSvc.saveHourAllocation(this.orgId!, empId, hourAllocation)
      .pipe(
        map(savedItem => {
          hourAllocation.hourAllocationId = savedItem.hourAllocationId;
          cmd.executed(batch!);
          return hourAllocation;
        }),
        tapError(() => {
          cmd.failed(batch!);
          this.notifSvc.addError('Application failed saving Hour Allocation.  Please stop your work and refresh the browser');
        })
      );
  }

  private init() {
    this.months = this.globalSettings.monthLabels;

    this.readyChangeSubject.next(false);
    forkJoin([
      this.payrollSvc.payTypes$.pipe(map(pts => {
        this.payTypes = pts;
        this.payTypeMap = new Map(pts.map(x => [x.payTypeId!, x]));
        this.basePayType = pts.find(x => x.name === 'Base Pay');
        this.otherPayTypes = pts.filter(x => x !== this.basePayType);
      })),
      this.payrollSvc.nonBasePayTypes$.pipe(map(pts => this.otherPayTypes = pts)),
      this.payrollSvc.positions$.pipe(map(pos => {
        this.positions = pos;
        this.positionMap = new Map(pos.map(x => [x.name!, x]));
        this.positionNames = pos.map(p => p.name!);
      }))
    ]).pipe(
      take(1),
      tap(() => this.readyChangeSubject.next(!!this.orgId))
    ).subscribe();

    this.ledgerState.ledger$.pipe(
      tap(() => this.readyChangeSubject.next(false)),
      map(() => this.ledgerState.organization?.orgId),
      switchMap(orgId => this.setOrganization(orgId)),
      tap((org) => this.readyChangeSubject.next(!!org && !!this.positions && !!this.otherPayTypes && !!this.payTypes))
    ).subscribe();

    this.employeeChangesQueue.pipe(
      concatMap((x$) => x$),
      tap(x => this.employeeChangesSubject.next(x))
    ).subscribe();
  }
}
