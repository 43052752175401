<div class="card h-100">
  <div class="card-header px-2 py-1 form-inline">
    <strong class="py-2">Unit Types</strong>
    <div class="input-group ml-2">
      <input placeholder="Filter Unit Types" class="form-control form-control-sm py-0" style="width: 10rem"
             [(ngModel)]="filter" />
    </div>
  </div>
  <div class="card-body p-2" style="overflow-x:auto">
    <div class="d-flex flex-row flex-nowrap" >
      <div *ngFor="let ut of unitTypes" class="px-1 mr-2" (click)="toggleSelected(ut.revAreaId)"
           [ngClass]="{ 'table-dark': isSelected(ut) }">
        <ng-container *ngTemplateOutlet="unitTypeTemplate; context: { $implicit: ut }">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #unitTypeTemplate let-ut>
  <table class="table table-striped table-sm mb-1 border-bottom small" [ngClass]="{ 'table-dark': isSelected(ut) }">
    <thead>
      <tr>
        <th class="text-nowrap" colspan="2">
          <span class="d-flex"  [title]="ut.displayName">
            <span class="flex-grow-1">
              {{ut.name}}
              <i *ngIf="hasRateWarning(ut)" class="text-warning fas fa-exclamation-triangle"
                 title="There is something wrong with the new rate for this unit type."></i>
              <i *ngIf="harRenewalWarning(ut)" class="text-warning fas fa-exclamation-circle"
                 title="There is a renewal pct but no renewal rate.  New Rate will be used for renewal leases."></i>
            </span>
            <span class="small">{{ut.periods[tgtPeriodIndex].unitCount}} spaces</span>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-nowrap">Base Fp Rate</td>
        <td class="text-right">{{ut.summary.estBaseFpRate | number:'0.0-0' }}</td>
      </tr>
      <ng-container *ngIf="utAmenityMap?.has(ut.revAreaId); else noUtAmenities">
        <tr *ngFor="let a of utAmenityMap?.get(ut.revAreaId)" [ngClass]="{'table-warning': !a.summary?.estNewRate }">
          <td class="text-nowrap">
            <span class="text-italic">{{a.displayName}}</span>
            <span *ngIf="ut.summary.unitCount != a.summary?.unitCount"
                  class="d-inline-block ml-1 small bg-warning text-dark rounded px-1 font-weight-bold"
                  myTooltip [myTooltip]="generateAmenityTooltip(a.displayName, ut)">
              {{a.summary?.unitCount}}
            </span>
          </td>
          <td class="text-right pr-1">
            <input type="text" class="text-right input-subtle" [disabled]="!!(isReadOnly$ | async)"
                   [ngModel]="a.summary?.estNewRate | number:'0.0-0'"
                   (ngModelChange)="updateAggAmenityEstimates(ut.revAreaId, a.revAreaId, estimateTypeEnumRef.EstNewRate, $event)"
                   (blur)="updateAggAmenityForce(ut.revAreaId, a.revAreaId,  estimateTypeEnumRef.EstNewRate)"
                   tccSelectOnFocus tccStopPropagation="click" />
          </td>
        </tr>
        <ng-container *ngIf="maxUtAmenities! - utAmenityMap!.get(ut.revAreaId)!.length > 0">
          <tr *ngFor="let x of [].constructor(maxUtAmenities! - utAmenityMap?.get(ut.revAreaId)!.length)">
            <td colspan="2" class="text-right">&ndash;</td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noUtAmenities>
        <tr>
          <td colspan="2" class="text-nowrap text-muted">No Amenities</td>
        </tr>
        <ng-container *ngIf="maxUtAmenities! > 0">
          <tr *ngFor="let x of [].constructor(maxUtAmenities! - 1)">
            <td colspan="2" class="text-right">&ndash;</td>
          </tr>
        </ng-container>
      </ng-template>
      <tr>
        <td class="text-nowrap">New Rate</td>
        <td class="text-right pr-1">
          <input type="text" class="text-right input-subtle" [disabled]="!!(isReadOnly$ | async)"
                 [ngModel]="ut.summary.estNewRate | number:'0.0-0'"
                 (ngModelChange)="updateEstimate(ut.revAreaId, estimateTypeEnumRef.EstNewRate, $event)"
                 (blur)="updateEstimateForce(ut.revAreaId, estimateTypeEnumRef.EstNewRate)"
                 tccSelectOnFocus tccStopPropagation="click" />
        </td>
      </tr>

      <tr>
        <td class="text-nowrap">Renewal Rate</td>
        <td class="text-right">
          <a [routerLink]="['../renewals']" [fragment]="ut.revAreaId.toString()"
             [title]="'View ' + ut.displayName + ' renewal plan'">
            <ng-container *ngIf="ut.periods[tgtPeriodIndex].aggs.avg.estRenewalRate != null; else isNaN">
              {{ut.periods[tgtPeriodIndex].aggs.avg.estRenewalRate | number:'0.0-0'}}
            </ng-container>
            <ng-template #isNaN>&ndash;</ng-template>
          </a>
        </td>
      </tr>
      <tr>
        <td class="text-nowrap">Renewal %</td>
        <td class="text-right pr-1">
          <input type="text" class="text-right input-subtle" [disabled]="!!(isReadOnly$ | async)"
                 [ngModel]="ut.summary.estOccRenewalPct | percent:'0.0-2'"
                 (ngModelChange)="updateUnitCountPctEstimate(ut.revAreaId, estimateTypeEnumRef.EstRenewalLeases, $event)"
                 (blur)="updateEstimateForce(ut.revAreaId, estimateTypeEnumRef.EstRenewalLeases)"
                 tccSelectOnFocus tccStopPropagation="click" />
        </td>
      </tr>
      <tr>
        <td class="text-nowrap">New %</td>
        <td class="text-right pr-1">
          <input type="text" class="text-right input-subtle" [disabled]="!!(isReadOnly$ | async)"
                 [ngModel]="ut.summary.estOccNewPct | percent:'0.0-2'"
                 (ngModelChange)="updateUnitCountPctEstimate(ut.revAreaId, estimateTypeEnumRef.EstNewLeases, $event)"
                 (blur)="updateEstimateForce(ut.revAreaId, estimateTypeEnumRef.EstNewLeases)"
                 tccSelectOnFocus tccStopPropagation="click" />
        </td>
      </tr>
      <tr [ngClass]="{'table-danger': ut.summary.estOccPct < 0 || ut.summary.estOccPct > 1 }">
        <td class="text-nowrap" colspan="2">
          <span class="d-flex flex-row">
            <span class="flex-grow-1 pr-3">Occupancy</span>
            <span class="flex-shrink-1">
              {{ut.summary.estOccPct | percent:'0.0-2'}} /
              {{ut.summary.estRenewalLeases + ut.summary.estNewLeases | number:'0.0-1'}}
            </span>
          </span>
        </td>
      </tr>

    </tbody>
  </table>
</ng-template>
