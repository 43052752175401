import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaCheckStyleDirective } from './fa-check-style.directive';
import { FileValidatorDirective } from './file-validator.directive';
import { KeyboardDirectionalEventsDirective } from './keyboard-directional-events.directive';
import { LinkedScrollDirective } from './linked-scroll.directive';
import { TccModelAccessorDirective } from './model-accessor.directive';
import { ResizeHandleDirective } from './resize-handle.directive';
import { SelectOnFocusDirective } from './select-on-focus.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { ValidityClassesDirective } from './validity-classes.directive';
import { DropDownTimerCloseDirective } from './drop-down-timer-close.directive';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingtooltipDirective } from './scrollingtooltip.directive';


@NgModule({
  exports: [
    DropDownTimerCloseDirective,
    FaCheckStyleDirective,
    FileValidatorDirective,
    LinkedScrollDirective,
    KeyboardDirectionalEventsDirective,
    ResizeHandleDirective,
    SelectOnFocusDirective,
    StopPropagationDirective,
    TccModelAccessorDirective,
    ValidityClassesDirective,
    ScrollingtooltipDirective
  ],
  imports: [CommonModule, NgbDropdownModule],
  declarations: [
    DropDownTimerCloseDirective,
    FaCheckStyleDirective,
    FileValidatorDirective,
    LinkedScrollDirective,
    KeyboardDirectionalEventsDirective,
    ResizeHandleDirective,
    SelectOnFocusDirective,
    StopPropagationDirective,
    TccModelAccessorDirective,
    ValidityClassesDirective,
    ScrollingtooltipDirective
  ],
})
export class DirectivesModule {

}
