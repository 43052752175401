
import { WorkflowStep, WorkflowState, Workflow, WorkflowStateCode } from '../../client-api.service';

export class WorkflowStatusDetail {
  workflowName: string | undefined;
  currentStep?: WorkflowStep;
  isAtLastStep = false;
  isWorkflowComplete = false;
  isStarted = false;
  stateName?: string;
  stateComments?: string;
  stateState?: WorkflowStateCode;

  /** Populates important information about current step */
  constructor(workflow: Workflow, state?: WorkflowState) {
    this.workflowName = workflow.name;
    if (state) {
      this.stateName = workflow.name + ' ' + WorkflowStateCode[state.stateCode!];
      this.stateComments = state.comments;
      this.stateState = state.stateCode;
      this.currentStep = workflow.relatedSteps?.find(x => x.stepId === state.stepId);
      if (this.currentStep) {
        this.isAtLastStep = (workflow.relatedSteps![workflow.relatedSteps!.length - 1] === this.currentStep);
        this.isWorkflowComplete = (this.isAtLastStep) && (state.completedOn != null);
      }
    }
  }
}
