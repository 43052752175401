/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Component, OnInit } from '@angular/core';
import { FinancialPlanningService } from './services/financial-planning.service';
import { PanelSize } from '@tcc/ui/lib/simple-panel/models';
import { InstallerTrackingResource } from 'src/app/client-api.service';
import { TableColumn } from '@tcc/ui';
import { DatePipe } from '@angular/common';
import { MaintenanceService } from '../services/maintenance.service';

@Component({
  selector: 'app-financial-planning-installer-tracking',
  templateUrl: './financial-planning-installer-tracking.component.html',
  styleUrls: ['./financial-planning-installer-tracking.component.scss']
})
export class FinancialPlanningInstallerTrackingComponent implements OnInit {

  readonly longDateFormatter = ((dp) => (dt: Date) => dp.transform(dt, 'M/d/yyyy h:mm a'))(new DatePipe('en-US'));

  state: 'initializing' | 'loading' | 'ready' | 'saving';
  tabs: string[] = ['Installer', 'Tracking'];
  panalSize: PanelSize = 'large';
  activeTab = 0;
  rows: InstallerTrackingResource[] | undefined;
  columns: TableColumn<InstallerTrackingResource>[] | undefined;

  hasNoChildBladesOpen = false;


  constructor(private fpService: FinancialPlanningService,
              private maintenanceService: MaintenanceService ) {
    this.state = 'initializing';
    this.columns = [
      { title: 'Id', name: 'id', hidden: true },      
      { title: 'Start Date Time (UTC)', name: 'startDateTimeUtc', contentResolver: (_, row) => this.longDateFormatter(row?.startDateTimeUtc!)?.toString()! },      
      { title: 'End Date Time (UTC)', name: 'endDateTime', contentResolver: (_, row) => this.longDateFormatter(row?.endDateTime!)?.toString()! },
      { title: 'Command', name: 'command' },
      { title: 'User Name', name: 'userName' },
      { title: 'Results', name: 'results' }
    ];
  }

  ngOnInit(): void {    
    this.maintenanceService.setMaintenanceComponenetPanelSize('full');
    this.getInstallerTrackingResources();
  }

  getInstallerTrackingResources() {
    this.state = 'loading';
    this.fpService.getInstallerTracking()
        .subscribe(
          res => {
            this.rows = res;
            for( const r of this.rows) {
              r.results = r.results?.replace(/\r\n/g, '<br />');
            }
            this.state = 'ready';
          },
          err => {
            this.state = 'ready';
            alert('Error getting installer tracking records!');
          }
        );
  }

  // Tracking selector
  setActiveTab(index: number): void {    
    this.activeTab = index;
  }

  rowClicked(event: any) {

  }

}
