<div class="card h-100">
  <div class="card-header px-2 py-1 form-inline">
    <strong class="py-2">Amenities</strong>
  </div>
  <div class="card-body p-2">
    <table class="table table-striped table-sm small" *ngIf="amenityAverages?.length; else noAmenities">
      <thead thead-dark>
        <tr>
          <th>
            Name
          </th>
          <th class="text-right">
            {{priorYear}}
          </th>
          <th class="text-right">
            {{currentYear}}
          </th>
          <th class="text-right">
            Change
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of amenityAverages">
          <td><div myTooltip [myTooltip]="a.toolTip">{{a.displayName}}</div></td>
          <td class="text-right">{{a.pyAvg | number:'0.2-2'}}</td>
          <td class="text-right">
            <input #thisInput="ngModel" *ngIf="!(isReadOnly$ | async); else readOnly" type="text"
                   class="text-right input-subtle"
                   [ngModel]="a.cyAvg | number: '0.2-2'"
                   (ngModelChange)="thisInput.valid ? updateEstimate(a.id, $event) : undefined"
                   (blur)="updateEstimateForce(a.id)"
                   pattern="^\s*\$?\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*$"
                   [ngClass]="{'is-invalid': thisInput.invalid, 'bg-light': !thisInput.invalid }"
                   tccSelectOnFocus />
            <ng-template #readOnly>
              {{a.cyAvg | number:'0.2-2'}}
            </ng-template>
          </td>
          <td class="text-right">{{(a.cyAvg - a.pyAvg) / a.pyAvg | percent:'0.2-2'}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="thead-light">
          <th>Total</th>
          <th class="text-right">{{totalRow!.pyAvg | number:'0.2-2'}}</th>
          <th class="text-right">{{totalRow!.cyAvg | number:'0.2-2'}}</th>
          <th class="text-right">{{(totalRow!.cyAvg - totalRow!.pyAvg) / totalRow!.pyAvg | percent:'0.2-2'}}</th>
        </tr>
      </tfoot>
    </table>
    <ng-template #noAmenities>
      <div class="alert alert-info text-center">
        There are no amenities<br />for this community.
      </div>
    </ng-template>
  </div>
</div>
