import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowSummary } from '../../client-api.service';
import { NgForm } from '@angular/forms';
import { tap, finalize } from 'rxjs/operators';
import { tapError } from '../../shared/tap-error-operator';
import { NotificationsService } from '@tcc/ui';
import { WorkflowsService } from 'src/app/core-services/workflows.service';

@Component({
  selector: 'app-workflow-add',
  templateUrl: './workflow-add.component.html',
  styles: []
})
export class WorkflowAddComponent implements OnInit {
  @ViewChild('myForm', { static: true }) myForm?: NgForm;

  state: 'loading' | 'ready' = 'loading';
  workflow: WorkflowSummary = { workflowId: 0, name: ''};

  constructor(
    private notifSvc: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private wfsSvc: WorkflowsService) { }

  ngOnInit() {
    this.state = 'ready';
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  saveChanges() {
    if (this.myForm!.invalid) {
      return;
    }

    this.state = 'loading';
    this.wfsSvc.upsertWorkflow(this.workflow)
      .pipe(
        tap(wf => {
          this.notifSvc.addSuccess(`Workflow ${this.workflow.name} added.`);
          this.myForm!.resetForm();
          this.router.navigate(['../'], { relativeTo: this.route, queryParams: { workflowId: wf.workflowId } });
        }),
        tapError(() => this.notifSvc.addError('Unable to save workflow.')),
        finalize(() => this.state = 'ready')
      ).subscribe();
  }
}
