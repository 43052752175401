<tcc-simplePanel size="large" [options]="{isFloating: true}" [buttons]="['close']" (buttonClick)="close()">
  <div panel-header>Export Queue</div>
  <div panel-body>
    <table class="table table-striped small">
      <thead>
        <tr>
          <th>Created</th>
          <th>Org</th>
          <th>View</th>
          <th>Completed</th>
        </tr>
        <tr style="font-style:italic">
          <th colspan="4">Message</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-r [ngForOf]="queueRecords$ | async">
          <tr>
            <td class="no-wrap">{{r.createdOn | date:'MM/dd/yyyy'}}</td>
            <td>{{r.orgName}}</td>
            <td>{{r.viewName}}</td>
            <td class="no-wrap">
              <ng-container *ngIf="r.status == 'Completed'; else NotComplete">{{r.completedOn | date:'shortTime'}}</ng-container>
              <ng-template #NotComplete>{{r.status}}</ng-template>
            </td>
          </tr>
          <tr *ngIf="r.lastMessage" style="font-style:italic">
            <td colspan="4">{{r.lastMessage}}</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
