import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService, ScrollService, SubsManager } from '@tcc/ui';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, take, takeWhile, tap } from 'rxjs/operators';
import { Estimate, EstimateType } from '../../client-api.service';
import { AggregationType } from '../../shared/aggregate-util';
import { tapError } from '../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../estimate-input-debounce-manager';
import { AreaEstimates } from '../models/area-estimates';
import { RevenueStateService, RootAreaState } from '../revenue-state.service';
import { RenewalsStateService } from './renewals-state.service';
import { RenewalMathService } from './renewal-math.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-renewals',
  templateUrl: './renewals.component.html',
  styles: [`
  .table .thead-dark th.sticky-top {
    /* this is a fix because stick doesn't keep the border so content shows behind the gap. */
    border-bottom: 0;
    border-top: 0;
    padding-bottom: calc(.3em + 2px);
    padding-top: calc(.3em + 1px);
  }
  .highlighted-text {
    /* #0d6efd is Bootstrap primary */
    color: #0d6efd;
    --bs-table-striped-color: #0d6efd;
  }
`]
})
export class RenewalsComponent implements AfterViewInit, OnDestroy, OnInit {

  private subsMgr = new SubsManager();

  readonly hasSelections$ = this.renewalsState.selectedUnitIds$.pipe(
    map(x => x.length > 0),
    shareReplay(1)
  );

  readonly hasWarnings$ = this.renewalsState.unitTypes$.pipe(
    map(ut => ut.some(x => x.children?.some(y => this.hasUpdateWarning(y)))),
    shareReplay(1)
  );

  /** regulates updates of estimates. */
  readonly inputDebounceMgr = new EstimateInputDebounceManager();

  readonly isReadOnly$ = this.revState.isReadOnly$;

  @ViewChildren('offerElem') offerInputElemRefs: QueryList<ElementRef> | undefined;

  /** The current org. */
  readonly org$ = this.revState.org$;

  /** renewal math actions for the math menu component. */
  readonly renewalMathActions = this.renewalMathSvc.actions;

  /** The ids of the selected unit areas that are visible. */
  readonly selectedUnitIds$ = this.renewalsState.selectedUnitIds$;

  /** The count of the selected unit areas that are visible. */
  readonly selectedUnitCount$ = this.renewalsState.selectedUnitIds$.pipe(
    map(x => x.length),
    shareReplay(1)
  );

  readonly state$ = this.revState.areas$.pipe(
    filter((x): x is RootAreaState => !!x),
    map(({ areas }) => (areas && areas.length) ? 'ready' : 'loading'),
    startWith('loading'));

  /** Gets the estimates from the total area. */
  readonly totalEsts$ = this.renewalsState.total$.pipe(
    map((x) => x?.periods[this.renewalsState.tgtPeriodIndex]),
    shareReplay(1)
  );

  readonly unitTypeAggCategories: { aggType: AggregationType, label: string }[] = [
    { aggType: 'min', label: 'Min' },
    { aggType: 'max', label: 'Max' },
    { aggType: 'avg', label: 'Average' },
    { aggType: 'sum', label: 'Total' },
  ];

  readonly totalAggCategories = this.unitTypeAggCategories.filter(x => ['avg', 'sum'].includes(x.aggType));

  readonly tgtPeriodIndex = this.renewalsState.tgtPeriodIndex;

  readonly unitTypes$ = this.renewalsState.unitTypes$;

  @ViewChildren('utAnchor')
  unitTypeAnchors: QueryList<ElementRef> | undefined;

  readonly warningCount$ = this.renewalsState.unitTypes$.pipe(
    map(ut => ut
      .reduce((acc, cur) => acc + cur.children!.filter(x => this.hasUpdateWarning(x)).length, 0)),
    shareReplay(1)
  );

  constructor(
    private cd: ChangeDetectorRef,
    private notifySvc: NotificationsService,
    private renewalMathSvc: RenewalMathService,
    private renewalsState: RenewalsStateService,
    public revState: RevenueStateService,
    private route: ActivatedRoute,
    private scrollSvc: ScrollService
  ) { }

  ngOnInit() {
    this.inputDebounceMgr.defaultAppliedOn = this.revState.periodSettings.periods[this.tgtPeriodIndex].value;
    this.inputDebounceMgr.defaultEstimateType = EstimateType.EstRenewalRate;

    this.subsMgr.addSub = this.route.params.pipe(
      filter((x): x is { orgId: string} => typeof x.orgId === 'string'),
      tap((x: { orgId: string }) => {
        this.revState.orgId = Number.parseInt(x.orgId, 10);
        this.renewalsState.filter = {};
      })
    ).subscribe();

    // this stream saves changes
    this.subsMgr.addSub = this.inputDebounceMgr.estimateChange$.pipe(
      tap(x => this.revState.enqueueEstimateSave(x.value.revAreaId!, {
        appliedOn: x.value.appliedOn!,
        estimateType: EstimateType.EstRenewalRate,
        estimateId: 0,
        value: x.value.value
      })),
      tapError(() => this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.'))
    ).subscribe();

    this.subsMgr.addSub = this.renewalMathSvc.estimateUpdate$.pipe(
      tap(({ revAreaId, value }) => this.inputDebounceMgr.updateEstimate(value!, { revAreaId }, { forceUpdate: true }))
    ).subscribe();
  }

  ngAfterViewInit() {
    // if a fragment is set in the url before the table loads, no scrolling occurs.
    // this will wait until their are anchors loaded, and if so, will scroll if there is a fragment.
    this.subsMgr.addSub = combineLatest([this.unitTypeAnchors!.changes, this.route.fragment]).pipe(
      tap(([, fragment]) => {
        if (fragment) {
          const elem = this.unitTypeAnchors?.find(x => x.nativeElement.name === fragment);
          if (elem) {
            this.scrollSvc.scrollIntoView(elem);
          }
        }
      }),
      takeWhile(() => this.unitTypeAnchors?.length === 0)
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
    this.inputDebounceMgr.clear();
    this.revState.orgId = undefined;
  }

  /** Use at own risk in templates to force typing as AreaEstimates. */
  asAreaEstimates(x: unknown): x is AreaEstimates {
    return true;
  }
  /** Use at own risk in templates to force typing as AreaEstimates with meta required. */
  asAreaEstimatesWithMeta(x: unknown): x is AreaEstimates & Required<Pick<AreaEstimates, 'meta'>> {
    return true;
  }
  clearSelected() {
    this.revState.selectedIds.clear();
  }

  /** clears update warnings by forcing an update in the renewal estimate. */
  clearUpdateWarnings() {
    this.unitTypes$.pipe(
      take(1),
      tap(ut => {
        const units = ut.flatMap(x => x.children!.filter(y => this.hasUpdateWarning(y)));
        units.forEach(x => {
          const estimate: Estimate = {
            appliedOn: this.revState.periodSettings.cyJan.value,
            estimateType: EstimateType.EstRenewalRate,
            estimateId: 0,
            value: x.periods[this.tgtPeriodIndex].estRenewalRate!
          };
          this.revState.enqueueEstimateSave(x.revAreaId, estimate, undefined, true);
        });
      })
    ).subscribe();
  }

  hasUpdateWarning(area: AreaEstimates) {
    return area.warnings?.includes('updatesAfterEstRenewal') ?? false;
  }

  onDirectionEvent(evt: KeyboardEvent) {
    // for ArrowLeft and Right, treat shift as resizing of selection area.
    const elem = <HTMLInputElement>evt.target;
    const offerInputElems: HTMLInputElement[] = (this.offerInputElemRefs)
      ? this.offerInputElemRefs.toArray().map(x => x.nativeElement)
      : [];
    const tgtIndex = offerInputElems.indexOf(elem);
    switch (evt.key) {
      case 'ArrowUp':
        if (tgtIndex > 0) {
          offerInputElems[tgtIndex - 1].select();
        }
        break;
      case 'ArrowDown':
      case 'Enter':
        if (tgtIndex < offerInputElems.length - 1) {
          offerInputElems[tgtIndex + 1].select();
        }
        break;
    }
  }

  selectAll() {
    this.unitTypes$.pipe(
      take(1),
      tap(ut => this.revState.selectedIds.set(ut.flatMap(x => x.children || []).map(x => x.revAreaId)))
    ).subscribe();
  }

  toggleSelected(toToggle: AreaEstimates[] | AreaEstimates | undefined): void {
    if (toToggle) {
      const toToggleIds = (!Array.isArray(toToggle))
        ? [toToggle.revAreaId]
        : toToggle.map(x => x.revAreaId);
      this.revState.selectedIds.toggle(toToggleIds, false);
    }
  }

  trackByRevAreaId(_: number, area: AreaEstimates): number {
    return area.revAreaId;
  }

  updateFlag(area: AreaEstimates) {
    this.revState.enqueueMetaSave(area.revAreaId, area.meta!, this.revState.orgId);
  }

  updateItemsFlags(flag: string) {
    combineLatest([
      this.unitTypes$,
      this.selectedUnitIds$
    ]).pipe(
      take(1),
      tap(([ut, sui]) => {
        const selectedUnits = ut.flatMap(x => x.children || []).filter(y => sui.includes(y.revAreaId));
        selectedUnits.forEach(x => {
          const utMeta = x.meta;
          if (utMeta) {
            utMeta.flag = flag;
            this.revState.enqueueMetaSave(x.revAreaId, x.meta!, this.revState.orgId);
          }
        });
      })
    ).subscribe();
  }

  updateUnitEstimate(area: AreaEstimates, valueRaw: string) {
    this.inputDebounceMgr.updateRawEstimate(valueRaw, { revAreaId: area.revAreaId });
  }

  updateUnitEstimateForce(revAreaId: number) {
    this.inputDebounceMgr.updateForce({ revAreaId });
  }

}
