import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { EstimateAggregation, EstimateType, Organization, RevenueAreaType } from '../../client-api.service';
import { OrganizationService } from '../../core-services/organization.service';
import { ArrayUtil } from '../../shared/array-util';
import { GLOBAL, IGlobalSettings } from '../../shared/global-settings';
import { tapError } from '../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../estimate-input-debounce-manager';
import { RevenueStateService } from '../revenue-state.service';
import { RevenueService } from '../revenue.service';

interface OrgRollupSummary {
  orgId: number;
  orgCode: string;
  selfRevAreaId: number;
  name: string;
  actAvg: number;
  actTotal: number;
  fpAvg: number;
  fpTotal: number;
  renewalOfferAvg: number;
  renewalOfferTotal: number;
  actVsFpPct: number;
  renewalVsActPct: number;
  renewalVsFpPct: number;
  targetRenewalPct: number;
  targetOccupancyPct: number;
  targetRevenueIncreatePct: number;
  unitCount: number;
}
@Component({
  selector: 'app-rollup',
  templateUrl: './rollup.component.html',
  styles: []
})
export class RollupComponent implements OnInit, OnDestroy {
  state: 'loading' | 'ready' = 'loading';

  summaries: OrgRollupSummary[] | undefined;

  /** reference to the EstimateType enum */
  readonly estimateTypeEnumRef = EstimateType;
  readonly isReadOnly$ = this.revState.isReadOnly$;
  readonly targetInputBounceMgr = new EstimateInputDebounceManager();

  private subsMgr = new SubsManager();

  constructor(private cd: ChangeDetectorRef,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private notifySvc: NotificationsService,
    private orgSvc: OrganizationService,
    private revSvc: RevenueService,
    private revState: RevenueStateService) {
  }

  ngOnInit() {
    this.state = 'loading';
    this.targetInputBounceMgr.defaultAppliedOn = this.revState.periodSettings.cyJan.value;
    const periodSettings = this.revState.periodSettings;

    // this stream saves changes
    this.subsMgr.addSub = this.targetInputBounceMgr.estimateChange$.pipe(
      tap(x => {
        const estimate = { appliedOn: x.value.appliedOn!, estimateType: x.value.estimateType!, value: x.value.value, estimateId: 0 };
        this.revState.enqueueEstimateSave(x.value.revAreaId!, estimate, x.value.orgId);

      }),
      tapError(() => this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.'))
    ).subscribe();

    this.subsMgr.addSub = combineLatest([
      this.orgSvc.orgIdMap$,
      this.revSvc.getRootOrgAreas(this.globalSettings.budgetYear),
      this.revSvc.getRollup(this.globalSettings.budgetYear, periodSettings.cyJan.value)
    ]).pipe(
      tap(([orgMap, orgAreaMap, rollupMap]) => {
        this.summaries = [];
        for (const [orgId, aggs] of rollupMap!) {
          const summary = this.summarizeOrgAggregates(orgMap.get(orgId)!, aggs);
          summary.selfRevAreaId = orgAreaMap.get(orgId)!.revAreaId!;
          this.summaries.push(summary);
        }
        this.summaries = this.summaries.sort(
          ArrayUtil.compareSelectorStringsFuncFactory(x => x.name, { ignoreCase: true }));
        this.state = 'ready';
      }),
      tapError(() => this.notifySvc.addError('Unable to load rollup info.  Please refresh and try again.'))
    ).subscribe();

    this.subsMgr.addSub = this.revState.estimateChange$.pipe(
      filter(x => x.appliedOn?.valueOf() === periodSettings.cyJan.value.valueOf()),
      tap(x => {
        const org = this.summaries!.find(y => y.selfRevAreaId === x.revAreaId);
        if (org) {
          switch (x.estimateType) {
            case EstimateType.TargetOccupancyPct: org.targetOccupancyPct = x.value || 0; break;
            case EstimateType.TargetRenewalPct: org.targetRenewalPct = x.value || 0; break;
            case EstimateType.TargetRateIncreasePct: org.targetRevenueIncreatePct = x.value || 0; break;
          }
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.targetInputBounceMgr.clear();
    this.subsMgr.onDestroy();
  }


  forceTargetChange(orgId: number, estimateType: EstimateType) {
    const summary = this.summaries!.find(x => x.orgId === orgId);
    this.targetInputBounceMgr.updateForce({ orgId, estimateType, revAreaId: summary?.selfRevAreaId });
  }

  queueTargetChange(orgId: number, estimateType: EstimateType, rawValue: string) {
    const summary = this.summaries!.find(x => x.orgId === orgId);
    try {
      this.targetInputBounceMgr.updateRawEstimatePct(rawValue, { orgId, revAreaId: summary?.selfRevAreaId, estimateType });
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${rawValue}.  Nothing was saved.`);
    }
  }


  private summarizeOrgAggregates(org: Organization, aggs: EstimateAggregation[]) {
    const summary: OrgRollupSummary = {
      orgId: org.orgId!,
      name: org.name!,
      orgCode: org.orgCode!,
      selfRevAreaId: 0,
      actAvg: 0,
      actTotal: 0,
      fpAvg: 0,
      fpTotal: 0,
      renewalOfferAvg: 0,
      renewalOfferTotal: 0,
      actVsFpPct: 0,
      renewalVsActPct: 0,
      renewalVsFpPct: 0,
      targetRenewalPct: 0,
      targetOccupancyPct: 0,
      targetRevenueIncreatePct: 0,
      unitCount: 0
    };
    for (const agg of aggs) {
      switch (agg.areaType) {
        case RevenueAreaType.AddOn:
          switch (agg.estimateType) {
            case EstimateType.OriginalFpRate:
              summary.fpTotal += agg.sum!;
              break;
          }
          break;
        case RevenueAreaType.Self:
          switch (agg.estimateType) {
            case EstimateType.TargetOccupancyPct: summary.targetOccupancyPct = agg.sum || 0; break;
            case EstimateType.TargetRenewalPct: summary.targetRenewalPct = agg.sum || 0; break;
            case EstimateType.TargetRateIncreasePct: summary.targetRevenueIncreatePct = agg.sum || 0; break;
          }
          break;
        case RevenueAreaType.Unit:
          switch (agg.estimateType) {
            case EstimateType.OriginalFpRate:
              summary.fpTotal += agg.sum!;
              break;
            case EstimateType.OriginalAvgRate:
              summary.actAvg += agg.sum! / agg.count!;
              summary.actTotal += agg.sum!;
              break;
            case EstimateType.EstRenewalRate:
              summary.renewalOfferAvg += agg.sum! / agg.count!;
              summary.renewalOfferTotal += agg.sum!;
              break;
          }
          break;
        case RevenueAreaType.UnitType:
          switch (agg.estimateType) {
            case EstimateType.UnitCount:
              summary.unitCount += agg.sum!;
              break;
          }
          break;
      }
    }
    summary.fpAvg = summary.fpTotal / summary.unitCount;
    summary.actVsFpPct = (summary.actAvg - summary.fpAvg) / summary.fpAvg;
    summary.renewalVsActPct = (summary.renewalOfferAvg - summary.actAvg) / summary.actAvg;
    summary.renewalVsFpPct = (summary.renewalOfferAvg - summary.fpAvg) / summary.fpAvg;
    return summary;
  }
}
