import { ChangeDetectorRef, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, Directive } from '@angular/core';
import { Subject, BehaviorSubject, interval } from 'rxjs';
import { takeUntil, switchMap, filter, tap } from 'rxjs/operators';

interface LinkedTarget {
  /** direction to resize */
  dir?: 'horiz' | 'vert';
  /** target element, if 'self' is passed then the element the directive is on is used */
  el?: 'self' | ElementRef | HTMLElement;
  /** if true value changes will be negated */
  invert?: boolean;
  /** prop to update */
  prop?: string;
  /** style to update */
  style?: string;
  /** internal value to track the last computed amount */
  _lastVal?: number;
  /** internal value to track the initial value during dragging */
  _initialVal?: number;
}


@Directive({
  selector: '[tccResizeHandle]'
})
export class ResizeHandleDirective implements OnInit, OnDestroy {


  @Input('tccResizeHandle') linkedTargets: LinkedTarget[] = [];

  private curPos: { x: number, y: number } = { x: 0, y: 0 };
  private destroyedSubject = new Subject();
  private intervalTime = 15;
  private initialPos: { x: number, y: number } = { x: 0, y: 0 };
  private isDraggingSubject = new BehaviorSubject<boolean>(false);

  constructor(private cd: ChangeDetectorRef, private el: ElementRef, private renderer: Renderer2) {

  }
  ngOnInit(): void {
    // prevents hostlistener events from bubbling up
    // this.cd.detach();
    this.isDraggingSubject.pipe(
      takeUntil(this.destroyedSubject),
      filter(x => x),
      switchMap(() => interval(this.intervalTime)),
      tap(() => this.update(this.curPos))
    ).subscribe();
  }

  ngOnDestroy() {
    this.stopDragging();
    this.destroyedSubject.next();
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent) {
    if (this.isDraggingSubject.value) {
      return; // we're still dragging.
    }
    this.curPos = this.initialPos = { x: event.pageX, y: event.pageY };

    this.renderer.addClass(this.el.nativeElement, 'dragging');
    for (const tgt of this.linkedTargets) {
      if (tgt.style) {
        const style = tgt.style as keyof CSSStyleDeclaration;
        tgt._initialVal = parseFloat(window.getComputedStyle(this.getTargetElem(tgt))[style] as string) || 0;
      }
      else if (tgt.prop) {
        tgt._initialVal = this.getTargetElem(tgt)[tgt.prop] || 0;
      }
    }
    event.preventDefault();
    this.isDraggingSubject.next(true);
  }

  @HostListener('window:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (this.isDraggingSubject.value) {
      event.preventDefault();
      this.curPos = { x: event.pageX, y: event.pageY };
    }
  }

  @HostListener('window:mouseup')
  onMouseup() {
    this.stopDragging();
  }

  /**
   * Gets the element from the target, retrieve the directive's element if of el is 'self'
   * @param tgt
   */
  private getTargetElem(tgt: LinkedTarget) {
    return (tgt.el === 'self') 
      ? this.el.nativeElement 
      : (tgt.el && 'nativeElement' in tgt.el) ? tgt.el.nativeElement
      : tgt.el;
  }

  private stopDragging() {
    if (this.isDraggingSubject.value) {
      this.renderer.removeClass(this.el.nativeElement, 'dragging');
      this.isDraggingSubject.next(false);
    }
  }


  private update(pos: { x: number, y: number }) {
    const delta = { x: pos.x - this.initialPos.x, y: pos.y - this.initialPos.y };

    for (const tgt of this.linkedTargets) {
      const newVal = (tgt._initialVal || 0) + ((tgt.dir === 'vert') ? delta.y : delta.x) * (tgt.invert ? -1 : 1);
      if (tgt.style) {
        this.renderer.setStyle(this.getTargetElem(tgt), tgt.style, Math.round(newVal) + 'px');
      } else if (tgt.prop) {
        this.renderer.setProperty(this.getTargetElem(tgt), tgt.prop, Math.round(newVal));
      }
    }
  }
}

