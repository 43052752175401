/** Exception info from server (is this really still valid?) */
interface ExceptionErrorInfo { 
  data: { 
    ExceptionMessage: string;
    Message?: string;
    StackTrace?: string;
  }
}

/** Takes some sort of error like object and converts it into a common format. */
export class NormalizedError {
  /** the error message */
  message = '';
  /** the original object passed to the constructor */
  source: unknown;
  /** a stack trace result in an array of strings */
  stackTrace: string[];

  constructor(errorLike: unknown) {
    this.source = errorLike;
    this.stackTrace = [];

    if (!errorLike) {
      this.message = 'An unknown error has occurred.';
    }
    else if (errorLike instanceof Error) {
      this.message = errorLike.message;
      if (errorLike.stack && typeof errorLike.stack === 'string') {
        this.stackTrace = this.parseStackTraceText(errorLike.stack);
      }
    }
    else if (typeof errorLike === 'string') {
      this.message = <string>errorLike;
    }
    else if (isExceptionError(errorLike)) {
      // possible webApi error
      const errData = errorLike.data;
      this.message = (<string>errData.Message || 'An error has occurred.') + ' ' + errData.ExceptionMessage;

      if (errData.StackTrace && typeof errData.StackTrace === 'string') {
        this.stackTrace = this.parseStackTraceText(errData.StackTrace);
      }
    }
    else if (isHttpStatusError(errorLike)) {
      // http error
      this.message = errorLike.statusText;
    }

    function isExceptionError(value: unknown): value is ExceptionErrorInfo {
      return !!(value as Partial<ExceptionErrorInfo>).data?.ExceptionMessage;
    }

    function isHttpStatusError(value: unknown): value is { statusText: string } {
      return !!(value as Partial<{ statusText: string }>).statusText;
    }
  }

  private parseStackTraceText(stackTraceText: string) {
    const stackTrace: string[] = [];
    const traceItems = (<string>stackTraceText).split(/[\r\n]+/g);
    for (const item of traceItems) {
      const trimmedItem = item?.trim();
      if (trimmedItem) {
        stackTrace.push(trimmedItem);
      }
    }
    return stackTrace;
  }
}


