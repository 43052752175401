import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FlagItemComponent } from './flag-item/flag-item.component';
import { MathMenuComponent } from './math-menu/math-menu.component';
import { FormsModule } from '@angular/forms';
import { SimpleLogComponent } from './simple-log/simple-log.component';
import { FlagItemsMenuComponent } from './flag-items-menu/flag-items-menu.component';

/** generic controls go here. */
@NgModule({
  declarations: [
    FlagItemComponent,
    MathMenuComponent,
    SimpleLogComponent,
    FlagItemsMenuComponent
  ],
  exports: [
    FlagItemComponent,
    MathMenuComponent,
    SimpleLogComponent,
    FlagItemsMenuComponent
  ],
  imports: [
    CommonModule, NgbDropdownModule, FormsModule
  ]
})
export class ControlsModule { }
