import { Inject, Injectable } from '@angular/core';
import { ClientApi, Employee, PayItem, HourAllocation } from '../client-api.service';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  /**  gets a list of positions and if they are service or not */
  readonly positions$ = this.clientApi.getPositions().pipe(shareReplay(1));

  /** returns payTypes minus basePay */
  readonly payTypes$ = this.clientApi.getPayTypes().pipe(shareReplay(1));

  readonly nonBasePayTypes$ = this.payTypes$
    .pipe(
      map(pts => pts.filter(x => x.name !== 'Base Pay')),
      shareReplay(1)
    );

  constructor(private clientApi: ClientApi, @Inject(GLOBAL) private globalSettings: IGlobalSettings) {
  }

  public getEmployees(orgId: number) {
    return this.clientApi.getEmployees(orgId, this.globalSettings.budgetYear);
  }

  public saveEmployee(orgId: number, emp: Employee) {
    return this.clientApi.postEmployee(orgId, emp);
  }

  public deleteEmployee(orgId: number, empId: number) {
    return this.clientApi.deleteEmployee(orgId, empId);
  }

  public savePayItem(orgId: number, empId: number, payItem: PayItem) {
    return this.clientApi.postPayItem(orgId, empId, payItem);
  }

  public saveHourAllocation(orgId: number, empId: number, hourAllocation: HourAllocation) {
    return this.clientApi.postHourAllocation(orgId, empId, hourAllocation);
  }

}
