<menu-tray-wrapper trayId="AccountHistory">
  <div menu-tray-header>
    <strong>Account History: {{getSubTitle()}}</strong>
  </div>
  <ng-container menu-tray-header-right>
    <span>
      Filter Month:
      <select [(ngModel)]="filterMonth" style="color:black">
        <option [ngValue]="null">None</option>
        <option *ngFor="let mon of ledgerState.ledger?.months" [ngValue]="mon">{{ mon | date: 'MMM'}}</option>
      </select>
    </span>
  </ng-container>
  <ng-container menu-tray-panel>

    <table class="table table-striped mb-0 table-sm">
      <thead class="thead-light">
        <tr>
          <th class="sticky-top pl-2">Step</th>
          <th class="sticky-top">Creator</th>
          <th class="sticky-top">Created On</th>
          <th class="sticky-top text-center">Month</th>
          <th class="sticky-top text-right pr-2">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of filteredItems">
          <td class="pl-2">{{i.stateName}}</td>
          <td>{{getUserName(i.creatorId!) | async}}</td>
          <td>{{i.createdOn | date: 'short' }}</td>
          <td class="text-center">{{i.appliedOn | date: 'MM-yy' }}</td>
          <td class="text-right pr-2">{{i.amount * selectedAccount!.negationFactor | number:'1.0-0'}}</td>
        </tr>
      </tbody>
    </table>

  </ng-container>
</menu-tray-wrapper>
