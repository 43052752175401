<menu-tray-wrapper traySize="med" class="sub-editor"
                   trayId="PayrollEditor">
  <div menu-tray-header class="form-inline">
    <strong class="mr-2">Payroll</strong>
    <div class="btn-group mr-2 rounded-top">
      <button type="button" [ngClass]="(subView == 'WagesAndAllowances') ? 'btn-light' : 'btn-success'" class="btn btn-sm"
              (click)="subView = 'WagesAndAllowances'">
        <i class="far fa-money-bill-alt"></i> Wages and Allowances
      </button>
      <button type="button" [ngClass]="(subView == 'HourAllocations') ? 'btn-light' : 'btn-success'" class="btn btn-sm"
              (click)="subView = 'HourAllocations'">
        <i class="fa fa-calendar"></i> Weekly Hour Allocations
      </button>
    </div>

    <div class="btn-group mr-2" *ngIf="!ledgerState.readOnlyAmounts && (payrollState.canModifyEmployees$ | async)">
      <button type="button" class="btn btn-success btn-sm" (click)="addEmployee()" title="Add New Employee"
        [disabled]="state !== 'ready'">
        <i class="fa fa-user-plus"></i>
      </button>
      <button type="button" class="btn btn-success btn-sm"
              [disabled]="state !== 'ready' || !payrollState.selectedEmployee"
              [title]="(!payrollState.selectedEmployee) ? 'Select employee to delete' : 'Delete ' + payrollState.selectedEmployee.name"
              (click)="startDelete()">
        <i class="fa fa-trash"></i>
      </button>
    </div>

    <div class="input-group input-group-sm mr-2" *ngIf="!ledgerState.readOnlyAmounts">
      <input type="number" class="form-control text-right" style="width:4rem" [(ngModel)]="hoursToAllocate" />
      <button type="button" class="btn btn-success input-group-append "
              [disabled]="state !== 'ready' || !payrollState.selectedEmployee || payrollState.isOvertimeExempt(payrollState.selectedEmployee)"
              (click)="setSelectedHours()"
              title="Set hours for employee">
        <i class="fa fa-calendar"></i>
      </button>
    </div>
    <app-math-menu [actions]="mathActions" [buttonClass]="'btn-success'" *ngIf="!ledgerState.readOnlyAmounts"></app-math-menu>
  </div>
  <ng-container menu-tray-header-right>
    <button type="button" class="btn btn-success btn-sm" *ngIf="!ledgerState.readOnlyAmounts" (click)="updateFp()">
      <i class="fa fa-bolt"></i> Update Fp
    </button>
  </ng-container>
  <ng-container menu-tray-panel>

    <div class="px-2" *ngIf="state == 'ready'">
      <table class="table table-very-condensed table-borderless table-striped text-right" #PayrollTable>
        <thead>
          <tr class="text-center border border-left-0 border-right-0 border-top-0">
            <th colspan="3" rowspan="2" class="border-right">Employee Information</th>
            <th [hidden]="subView != 'WagesAndAllowances'" colspan="4" rowspan="2" class="border-right">Wages</th>
            <th *ngIf="subView == 'WagesAndAllowances' && payrollState.otherPayTypes"
                [colSpan]="payrollState.otherPayTypes.length * 3" class="border-right">
              Payroll Allowances
            </th>
            <th [hidden]="subView != 'HourAllocations'" colspan="2" rowspan="2" class="border-right">Wages</th>
            <th [hidden]="subView != 'HourAllocations'" colspan="2" class="border-right"
                *ngFor="let m of payrollState.months; let $index = index"
                [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
              {{m}}
            </th>
          </tr>
          <tr class="text-center border border-left-0 border-right-0 border-top-0">
            <th [hidden]="subView != 'WagesAndAllowances'" colspan="3" class="border-right text-center"
                *ngFor="let pt of payrollState.otherPayTypes">
              {{pt.name}}
            </th>
            <th [hidden]="subView != 'HourAllocations'" colspan="2" class="border-right text-muted text-center"
                *ngFor="let m of payrollState.months; let $index = index" [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
              {{payrollState.hourlyPayConfig.monthPeriods[$index]}} {{payrollState.hourlyPayConfig.monthPeriodsSuffix}}
            </th>
            <th class="border-top-0">Total</th>
          </tr>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Position</th>
            <th class="text-left border-right">Status</th>
            <th class="text-left">Type</th>
            <th [hidden]="subView != 'WagesAndAllowances'">Current</th>
            <th [ngClass]="{'border-right': subView != 'WagesAndAllowances' }">Proposed</th>
            <th [hidden]="subView != 'WagesAndAllowances'" class="border-right">Var.</th>
            <ng-template ngFor let-pt [ngForOf]="payrollState.otherPayTypes">
              <th [hidden]="subView != 'WagesAndAllowances'">Current</th>
              <th [hidden]="subView != 'WagesAndAllowances'">Proposed</th>
              <th [hidden]="subView != 'WagesAndAllowances'" class="border-right">Var.</th>
            </ng-template>
            <ng-template ngFor let-m [ngForOf]="payrollState.months" let-$index="index">
              <th [hidden]="subView != 'HourAllocations'"
                  [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
                <sup style="margin: 0 -2px">Hr</sup>/<sub style="margin-left:-2px">Wk</sub>
              </th>
              <th [hidden]="subView != 'HourAllocations'" class="border-right"
                  [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
                $$
              </th>
            </ng-template>
            <th class="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-e [ngForOf]="payrollState.employees" let-$index="index">
            <tr payrollEmployee [readOnly]="ledgerState.readOnlyAmounts"
                [subView]="subView" [employee]="payrollState.employees[$index]"
                [ngClass]="{'selected': payrollState.selectedEmployee == payrollState.employees[$index]}"
                (click)="payrollState.selectedEmployee = payrollState.employees[$index]"
                (employeeChange)="payrollState.employees[$index] = $event"></tr>
          </ng-template>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="3" class="border-right text-nowrap"></td>
            <td class="text-nowrap"></td>
            <td [hidden]="subView != 'WagesAndAllowances'" class="text-nowrap"></td>

            <td class="text-nowrap" [ngClass]="{'border-right':subView != 'WagesAndAllowances'}"></td>
            <td [hidden]="subView != 'WagesAndAllowances'" class="border-right text-nowrap"></td>
            <ng-container *ngIf="subView == 'WagesAndAllowances'; else HourAllocations">
              <ng-template ngFor let-pt [ngForOf]="payrollState.otherPayTypes" let-$index="index">
                <td>
                  {{getTotalPayTypeCurrentAmount(pt.payTypeId!) | number:'1.0-0'}}
                </td>
                <td>
                  {{getTotalPayTypeProposedAmount(pt.payTypeId!) | number:'1.0-0'}}
                </td>
                <td class="border-right">
                  {{getAvgProposedVarianceFromCurrentPercent(pt.payTypeId!) | percent:'1.1-1'}}
                </td>
              </ng-template>
            </ng-container>
            <ng-template #HourAllocations>
              <ng-template ngFor let-m [ngForOf]="payrollState.months" let-$index="index">
                <td [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}" class="text-right" style="width:2em">
                  <span class="pr-1">{{getTotalHours($index) | number:'1.0-0' }}</span>
                </td>
                <td class="border-right"
                    [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
                  {{getTotalBasePayForMonth($index) | number:'1.0-0'}}
                </td>
              </ng-template>
            </ng-template>
            <td>{{getTotalCompensation() | number:'1.2-2'}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'">{{state}}</tcc-loadingOverlay>

  </ng-container>
</menu-tray-wrapper>

<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirm Delete</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Delete Employee? (this action can not be undone)
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('execute')">Yes, Delete</button>
  </div>
</ng-template>
