import { Injectable } from '@angular/core';
import { EntryAmounts } from 'src/app/shared/entry-amounts';
import { ComparisonEntryAmounts } from './comparison-source';
import { FormattedAmounts, ComparisonResult } from './models';

@Injectable({
  providedIn: 'root'
})
export class AccountComparerService {

  /** The size of decimal precision. */
  private precision?: number;
  /** The factor used in calculating getPrecisionAmounts. */
  private precisionFactor?: number;

  constructor() {
    this.roundingPrecision = 0;
  }

  get roundingPrecision() { return this.precision!; }
  set roundingPrecision(value: number) {
    if (value < 0) {
      throw new Error('rounding precision must be greater than or equal to 0.');
    }
    this.precision = value;
    this.precisionFactor = Math.pow(10, value);
  }

  /**
   * Returns the formatted results of the comparion results, with the total as the last index.
   * Ideally this is called by the comparison state service.
   */
  getComparisonResults(srcAmounts: EntryAmounts,
    comparisonAmounts: ComparisonEntryAmounts | undefined,
    comparisonFormattedResults: FormattedAmounts | undefined,
    comparePeriodUpdates: boolean,
    compareDifferences: boolean
  ) {

    if ((!comparePeriodUpdates && !compareDifferences) || !comparisonAmounts || !comparisonFormattedResults) {
      // no comparisons being done.
      const results = comparisonFormattedResults!.amounts.map(x => ({ different: false, updated: false, value: x } as ComparisonResult));
      results.push({ different: false, updated: false, value: comparisonFormattedResults!.total });
      return results;
    }
    else {
      const compFactors = this.getComparisonFactors(srcAmounts, comparisonAmounts, comparisonFormattedResults);
      return compFactors.map(x => ({
        different: compareDifferences && x.acctAmt !== x.compAmt,
        updated: comparePeriodUpdates && x.updated,
        value: x.compFormattedAmt
      })) as ComparisonResult[];
    }
  }

  isComparisonResultCollectionsEqual(a: ComparisonResult[], b: ComparisonResult[]) {
    return a.length === b.length && a.every((aResult, index) => this.isComparisonResultsEqual(aResult, b[index]));
  }

  isComparisonResultsEqual(a: ComparisonResult, b: ComparisonResult) {
    return a.different === b.different && a.updated === b.updated && a.value === b.value;
  }

  /** Creates an array where variables used in a comparison are all put in their repective index, with totals added to the end. */
  private getComparisonFactors(srcAmounts: EntryAmounts,
    comparisonAmounts: ComparisonEntryAmounts,
    comparisonFormattedResults: FormattedAmounts) {

    const maxLength = Math.max(srcAmounts.amounts.length, comparisonAmounts.amounts.length, comparisonFormattedResults.amounts.length);
    const periodAmounts: { acctAmt: number, compAmt: number, compFormattedAmt: string, updated: boolean }[] = [];
    for (let i = 0; i < maxLength; i++) {
      periodAmounts.push({
        acctAmt: this.getPrecisionValue(srcAmounts.amounts[i] ?? 0),
        compAmt: this.getPrecisionValue(comparisonAmounts.amounts[i] ?? 0),
        compFormattedAmt: comparisonFormattedResults.amounts[i] ?? '',
        updated: comparisonAmounts.amountChangedSincePreviousComparison![i] || false
      });
    }
    periodAmounts.push({
      acctAmt: this.getPrecisionValue(srcAmounts.total ?? 0),
      compAmt: this.getPrecisionValue(comparisonAmounts.total ?? 0),
      compFormattedAmt: comparisonFormattedResults.total ?? '',
      updated: comparisonAmounts.amountChangedSincePreviousComparison!.some(x => x)
    });
    return periodAmounts;
  }

  /** Creates a new numeric value based upon the settings for precision. */
  private getPrecisionValue(value: number) {
    return Math.round((value + Number.EPSILON) * this.precisionFactor!) / this.precisionFactor!;
  }
}
