import { Injectable } from '@angular/core';
import { clone } from 'ramda';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { RevenueAreaType } from 'src/app/client-api.service';
import { AreaEstimates } from '../models/area-estimates';
import { RevenueCalcService } from '../revenue-calc-service';
import { RevenueStateService, RootAreaState } from '../revenue-state.service';
import { RenewalsFilter } from './models';

@Injectable({
  providedIn: 'root'
})
export class RenewalsStateService {

  private filterChangeSubj = new BehaviorSubject<RenewalsFilter>({});

  readonly filterChange$ = this.filterChangeSubj.asObservable();

  /** The period index where all estimates are recorded. */
  readonly tgtPeriodIndex = this.revState.periodSettings.cyJan.index;

  /** original selected unit ids  */
  readonly selectedUnitIdsOriginal$ = combineLatest([this.revState.areas$, this.revState.selectedIds.setChange$]).pipe(
    map(([areas, allSelectedIds]) => allSelectedIds.filter(x => areas?.idMap.get(x)!.areaType === RevenueAreaType.Unit)),
    startWith([] as number[]),
    shareReplay(1)
  );
  /** all unit types */
  readonly unitTypesOriginal$ =  this.revState.areas$.pipe(
    filter((x): x is RootAreaState => !!x),
    map(({ typeMap }) => typeMap.get(RevenueAreaType.UnitType) || []),
    startWith([] as AreaEstimates[]),
    shareReplay(1)
  );

  /** unit types after filtering */
  readonly unitTypes$ = combineLatest([this.unitTypesOriginal$, this.filterChange$]).pipe(
    map(([utSrc, filterCur]) => this.applyFilter(filterCur, utSrc)),
    shareReplay(1)
  );

  /** selected unit ids after unitTypes$ are filtered. */
  readonly selectedUnitIds$ = combineLatest([this.unitTypesOriginal$, this.unitTypes$, this.selectedUnitIdsOriginal$]).pipe(
    map(([utsOrig, uts, selectedUnitIdsOriginal]) => {
      if (utsOrig === uts || selectedUnitIdsOriginal.length === 0) {
        return selectedUnitIdsOriginal;
      }
      const unfilteredUnitIds = uts.flatMap(x => x.children ?? []).map(x => x.revAreaId);
      return selectedUnitIdsOriginal.filter(x => unfilteredUnitIds.includes(x));
    }),
    shareReplay(1)
  );

  readonly selectedUnitAreas$ = combineLatest([this.revState.areas$, this.selectedUnitIds$]).pipe(
    map(([areas, unitIds]) => unitIds.map(x => areas?.idMap.get(x))),
    shareReplay(1)
  );

  readonly total$ = combineLatest([
    this.revState.areas$.pipe(
      filter((x): x is RootAreaState => !!x),
      map(({ typeMap }) => typeMap.get(RevenueAreaType.Self)?.[0])),
    this.unitTypesOriginal$,
    this.unitTypes$
  ]).pipe(
    filter(([a, b, c]) => a! && b && c && true),
    debounceTime(1),
    map(([totalAreaOrig, utsOrig, uts]) => {
      // if unitTypes is unchanged then return the original total area, otherwise create a new one and recalulate it based on the filters.
      if (uts === utsOrig ||
        (uts.length === utsOrig.length && uts.flatMap(x => x.children).length === utsOrig.flatMap(x => x.children).length)) {
        return totalAreaOrig;
      }
      const totalArea = this.cloneAreaEstimates(totalAreaOrig!, uts);
      this.revCalcSvc.updatePeriodCalculations(totalArea, this.tgtPeriodIndex, true);
      return totalArea;
    }),
    shareReplay(1)
  );
  constructor(private revCalcSvc: RevenueCalcService, private revState: RevenueStateService) { }

  get filter() { return this.filterChangeSubj.value; }
  set filter(value: RenewalsFilter) { this.filterChangeSubj.next({ ...value }); }

  /**
   * creates an array of unit types estimates with children filtered out.
   * If no children passed the filter then the unit type is omitted as well.
   */
  private applyFilter(filterCur: RenewalsFilter, unitTypesSource: AreaEstimates[]) {
    if (!filterCur ||
      (filterCur.actualRateMax == null && filterCur.actualRateMin == null && filterCur.amenityName == null
        && filterCur.category == null && !filterCur.spaceName && !filterCur.unitTypeName
        && !filterCur.actualRateState && !filterCur.offerRateState)
    ) {
      return unitTypesSource;
    }

    const spaceNameFilterLcase = (filterCur.spaceName || '').toLowerCase();
    const utNameFilterLcase = (filterCur.unitTypeName || '').toLowerCase();
    return unitTypesSource
      .filter(x => filterName(x, utNameFilterLcase, filterCur.isUnitTypeNameExact))
      .map(utOrig => ({
        utOrig,
        children: utOrig.children?.filter(x =>
            (filterCur.actualRateMax == null || filterCur.actualRateMax >= x.periods[this.tgtPeriodIndex].origAvgRate!)
            && (filterCur.actualRateMin == null || filterCur.actualRateMin <= x.periods[this.tgtPeriodIndex].origAvgRate!)
            && (!filterCur.actualRateState
              || (filterCur.actualRateState === 'blank' && x.periods[this.tgtPeriodIndex].origAvgRate == null)
              || (filterCur.actualRateState === 'set' && x.periods[this.tgtPeriodIndex].origAvgRate != null)
            )
            && (filterCur.amenityName == null || x.children?.some(y => y.displayName === filterCur.amenityName))
            && (filterCur.category == null || x.meta?.leaseInfo && filterCur.category === x.meta.leaseInfo.leaseCategory)
            && filterName(x, spaceNameFilterLcase, filterCur.isSpaceNameExact)
            && (!filterCur.offerRateState
              || (filterCur.offerRateState === 'blank' && x.periods[this.tgtPeriodIndex].estRenewalRate == null)
              || (filterCur.offerRateState === 'set' && x.periods[this.tgtPeriodIndex].estRenewalRate != null)
            )
          )
        })
      )
      .filter(({children}) => children!.length > 0)
      .map(({ utOrig, children }) => {
        if (utOrig.children?.length === children!.length) {
          return utOrig;
        }
        const utArea = this.cloneAreaEstimates(utOrig, children!);
        this.revCalcSvc.updatePeriodCalculations(utArea, this.tgtPeriodIndex, true);
        return utArea;
      });

    function filterName(area: AreaEstimates, lowerCaseFilter: string | undefined, isExact: boolean | undefined) {
      const lowerCaseAreaName = (area.displayName || area.name || '').toLowerCase();
      return (!lowerCaseFilter)
        || (isExact && area.displayName && area.displayName.toLowerCase() === lowerCaseFilter)
        || (!isExact && lowerCaseAreaName.indexOf(lowerCaseFilter) !== -1);

    }
  }

  /** Make sure anything that is an object is recreated so that changes do affect the source. */
  private cloneAreaEstimates(src: AreaEstimates, children: AreaEstimates[]) {
    const clonedArea: AreaEstimates = clone({ ...src, children: undefined });
    clonedArea.children = children;
    return clonedArea;
  }
}
