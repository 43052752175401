<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>Batch Export</div>
  <div panel-footer>
    <button type="button" [disabled]="selectedOrgCount == orgs.length" class="btn btn-primary mr-1" (click)="setAllIsSelected(true)">
      Select All
    </button>
    <button type="button" [disabled]="!selectedOrgCount" class="btn btn-primary mr-1" (click)="setAllIsSelected(false)">
      Deselect All
    </button>
    <button type="button" class="btn btn-warning" [disabled]="!selectedView || !selectedOrgCount" (click)="runExports()">
      Run Exports
    </button>
    <div style="float: right">
      <button type="button" class="btn btn-primary" routerLink="./exportQueue">
        View Export Queue
      </button>
    </div>
  </div>
  <div panel-body class="px-1">

    <div class="form-group form-inline">
      <label tcc-labelForNext class="control-label mr-1">View</label>
      <select [(ngModel)]="selectedView" class="form-control">
        <option *ngFor="let v of views" [ngValue]="v">{{v.name}}</option>
      </select>
    </div>

    <div>
      <h4>Organizations</h4>
    </div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let o of orgs">
        <label>
          <input type="checkbox" [(ngModel)]="o.isSelected"
                 [tccFaCheckStyle]="checkStyle" class="fa-stack-sm" />
          <span class="small"> {{o.name}}</span>
        </label>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
