import { Injectable } from '@angular/core';
import { BehaviorSubject, of, concat, combineLatest, Observable } from 'rxjs';
import { switchMap, tap, distinctUntilChanged, finalize, map, toArray, filter, shareReplay, take, startWith } from 'rxjs/operators';
import { ClientApi, LedgerComment } from '../client-api.service';
import { CommandConstants } from '../commands/command-constants';
import { CommandsService } from '../commands/commands.service';
import { tapError } from '../shared/tap-error-operator';

interface CommentSaveCommand {
  comment: LedgerComment;
  ledgerId: number;
  orgId: number;
}
/** This is a sub state of either ledger or revenue. */
@Injectable({
  providedIn: 'root'
})
export class CommentsStateService {
  private readonly commentsSubject = new BehaviorSubject<LedgerComment[] | undefined>(undefined);
  private readonly commentSaveCmd = this.commandsSvc.getCommand<CommentSaveCommand>(CommandConstants.AccountCommentSave);
  private readonly ledgerInfoSubject = new BehaviorSubject<{ orgId: number, ledgerId: number } | undefined>(undefined);
  private readonly selectedAccountCodeSubj = new BehaviorSubject<string | undefined>(undefined);

  /** current value of accountMap$ observable. */
  accountMap = new Map<string, LedgerComment[]>();

  /** comments mapped by account code.  never undefined. */
  readonly accountMap$ = this.commentsSubject.pipe(
    map(comments =>
      (comments || []).reduce((acc, cur) => {
        (acc.get(cur.accountCode) ?? acc.set(cur.accountCode, []).get(cur.accountCode))!.push(cur);
        return acc;
      }, new Map<string, LedgerComment[]>())
    ),
    shareReplay(1)
  );

  /** fired every time source comments change. */
  readonly comments$ = this.commentsSubject.asObservable();

  /** fires every time selected account changes */
  readonly selectedAccountCode$ = this.selectedAccountCodeSubj.pipe(distinctUntilChanged());

  /** General state of comments.  Loading = loading comments, saving = saving comments, ready = no activity. */
  readonly state$: Observable<'loading' | 'saving' | 'ready'> = combineLatest([
    this.commentsSubject,
    this.commentSaveCmd.batchStatusChange$.pipe(startWith(undefined))
  ]).pipe(
    map(([comments]) => (
      (!comments) ? 'loading' : this.commentSaveCmd.queueSize > 0 ? 'saving' : 'ready'
    ) as ('loading' | 'saving' | 'ready')),
    shareReplay(1)
  );

  isReadOnly = false;
  minCommentLength = 10;

  /** keeps track of the currently selectedAccount */
  get selectedAccountCode() { return this.selectedAccountCodeSubj.value!; }
  set selectedAccountCode(value: string) { this.selectedAccountCodeSubj.next(value); }


  constructor(
    private clientApi: ClientApi,
    private commandsSvc: CommandsService
  ) {

    this.ledgerInfoSubject.pipe(
      switchMap(x => (x !== undefined) ? this.clientApi.getComments(x.orgId, x.ledgerId) : of(undefined)),
      tap(x => this.commentsSubject.next(x))
    ).subscribe();

    this.commentSaveCmd.enqueued$.pipe(
      switchMap(batch => 
        concat(...batch.commands.map(x => this.clientApi.postComment(x.orgId, x.ledgerId, x.comment))).pipe(
          toArray(),
          tap((results) => {
            const updatedComments = [... this.commentsSubject.value!];
            results.forEach(res => {
              const existingCommentIndex = updatedComments.findIndex(x => x.commentId === res.commentId);
              const replacementCommentIndex = (existingCommentIndex === -1) ? updatedComments.length : existingCommentIndex;
              updatedComments[replacementCommentIndex] = res;
            });
            this.commentsSubject.next(updatedComments);
            this.commentSaveCmd.executed(batch);
          }),
          tapError(() => this.commentSaveCmd.failed(batch)),
          finalize(() => this.commentSaveCmd.ensureDequeued(batch))
        )
      )
    ).subscribe();

    this.accountMap$.subscribe(x => this.accountMap = x);
  }

  /** returns true if account has non-hidden comments */
  accountHasComments(accountCode: string) {
    return (this.accountMap.get(accountCode) || []).filter(x => !x.isHidden).length > 0;
  }

  clearCommentState() {
    this.ledgerInfoSubject.next(undefined);
  }

  loadCommentState(orgId: number, ledgerId: number) {
    this.ledgerInfoSubject.next({ orgId, ledgerId });
  }

  /** Enqueues comment for saving returning observable that reports when batch was saved. */
  saveComment(comment: LedgerComment) {
    this.validatePreOpState();
    const { orgId, ledgerId } = this.ledgerInfoSubject.value!;
    const batch = this.commentSaveCmd.enqueue({ orgId, ledgerId, comment });
    return this.commentSaveCmd.batchStatusChange$.pipe(
      filter(x => x.batchId === batch.batchId && ['cancelled', 'executed', 'failed'].includes(x.status)),
      take(1),
      tap(x => {
        if (x.status === 'failed') {
          throw new Error('Failed saving comment.');
        }
      })
    );
  }

  private validatePreOpState() {
    if (this.ledgerInfoSubject.value === undefined || !this.ledgerInfoSubject.value.ledgerId || !this.ledgerInfoSubject.value.orgId) {
      throw new Error('Cannot save comment - the state org or ledger is not set');
    }
  }
}
