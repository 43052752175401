import { Injectable } from '@angular/core';
import { PanelSize } from '@tcc/ui/lib/simple-panel/models';
import { BehaviorSubject } from 'rxjs';
import { ClientApi, FileParameter } from 'src/app/client-api.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialPlanningService {
  
  constructor(private apiClient: ClientApi) { }
  
  getFPOrganizations() {
    return this.apiClient.getFPOrganizations();
  }

  fpInstaller(args: string[]) {
    return this.apiClient.fpInstaller(args);
  }

  fpInstallerStatus() {
    return this.apiClient.fpInstallerStatus();
  }

  getPayrollData() {
    return this.apiClient.getPayrollData();
  }

  getInstallerTracking() {
    return this.apiClient.getInstallerTracking();
  }

  postPayrollAllowanceFile(fileParameter: FileParameter) {
    return this.apiClient.installpayrollallowances(fileParameter);
  
  }

  postPayrollWagesFile(fileParameter: FileParameter) {
    return this.apiClient.installpayrollwages(fileParameter);
  
  }
}
