import { Component, OnInit } from '@angular/core';
import { ApplicationViewsService } from './Services/application-views.services';
import { MaintenanceService } from '../services/maintenance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelSize } from '@tcc/ui/lib/simple-panel/models';
import { ApplicationViewsResource } from 'src/app/client-api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { add } from 'ramda';

@Component({
  selector: 'app-application-views-upsert',
  templateUrl: './application-views-upsert.component.html',
  styleUrls: ['./application-views-upsert.component.scss']
})
export class ApplicationViewsUpsertComponent implements OnInit {
  
  allApplicationViews: ApplicationViewsResource[] | undefined;
  applicationViewsCategories: string[] | undefined;  
  applicationViewFormGroup = new FormGroup({
    id: new FormControl(''),
    key: new FormControl('', Validators.required),
    view: new FormControl('', Validators.required),
    accountCode: new FormControl('', Validators.required),
    parentAccountCode: new FormControl(0, Validators.required),
    name: new FormControl(0, Validators.required),
    calculationExpression: new FormControl(''),
    displayAccountCode: new FormControl(''),
    isNegated: new FormControl(0, Validators.required),
    sortIndex: new FormControl(0, Validators.required),
    isReadOnly: new FormControl(0, Validators.required),
    isIgnoredInTotals: new FormControl(0, Validators.required),
    customValidation: new FormControl(''),
    isHidden: new FormControl(0, Validators.required)
  });
  
  datasetDirty = false;
  duplicateKey = false;
  addOrEdit: 'add' | 'edit';
  currentId = 0;
  currentApplicationView: ApplicationViewsResource | undefined;
  generatedKey = '';
  panalSize: PanelSize = 'large';
  state: 'initializing' | 'loading' | 'ready' | 'saving';  
  subscription: Subscription | undefined;

  constructor(private appViewsServices: ApplicationViewsService,
              private maintenanceService: MaintenanceService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.state = 'initializing';  
    this.addOrEdit = 'edit';
  }

  ngOnInit(): void {    
    this.appViewsServices.setApplicationViews();
    this.subscription = this.appViewsServices.applicationViews$.subscribe(res => {
      this.allApplicationViews = res;      
    });

    this.getApplicationViewCategories();
    this.activatedRoute.params.subscribe(routeParam => {
      this.currentId =  routeParam['id'];      
      if(routeParam['id'] !== '0') {
        this.addOrEdit = 'edit';
        this.getApplicationView(this.currentId);
        this.applicationViewFormGroup.controls['view'].disable();
        this.applicationViewFormGroup.controls['accountCode'].disable();
      }
      else {
        this.addOrEdit = 'add';
        this.state = 'ready';        
        this.applicationViewFormGroup.reset();
        this.applicationViewFormGroup.controls['view'].enable();
        this.applicationViewFormGroup.controls['accountCode'].enable();
      }
    });    
  }   

  getApplicationView(id: any) {
    this.state = 'loading';
    this.appViewsServices.getApplicationView(id)
        .subscribe(
          res => {
            this.state = 'ready';
            this.currentApplicationView = res;
            this.applicationViewFormGroup.patchValue({
              accountCode: res.accountCode,
              calculationExpression: res.calculationExpression,
              customValidation: res.customValidation,
              displayAccountCode: res.displayAccountCode,
              id: res.id,
              isHidden: res.isHidden,
              isNegated: res.isNegated,
              isReadOnly: res.isReadOnly,
              isIgnoredInTotals: res.isIgnoredInTotals,
              key: res.key,              
              name: res.name,
              parentAccountCode: res.parentAccountCode,
              sortIndex: res.sortIndex,
              view: res.view
            });
            this.generateKey();
          },
          err => {
            this.state = 'ready';
            alert('Error getting Application View from database!');
          });
  }
  
  getApplicationViewCategories() {
    this.appViewsServices.getApplicationViewsCategories()
        .subscribe(
          res => {
            this.applicationViewsCategories = res;
          },
          err => {            
            alert('Error getting Avvount Vuew Categories!');
          }
        )
  }

  close() {
    this.maintenanceService.setMaintenanceComponenetPanelSize('full');
    this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
  }

  fieldChanged(elem: HTMLInputElement) {    
    this.generateKey();    
    return this.applicationViewFormGroup.get(elem.name)?.dirty;
  }

  generateKey() {
    const accountCode = this.applicationViewFormGroup.get('accountCode')?.value;
    const view = this.applicationViewFormGroup.get('view')?.value;      
    this.generatedKey = view + ':' + accountCode;    

    this.checkForDuplicatedKey();
  }

  checkForDuplicatedKey() {
    const duped = this.allApplicationViews?.find(a => a.key === this.generatedKey);
    if(duped){
      this.duplicateKey = true;
    }
    else {
      this.duplicateKey = false;
    }
  }

  isChanged(field: string) {
    return this.applicationViewFormGroup.get(field)?.dirty;
  }

  isEditMode(){
    return this.addOrEdit === 'edit';
  }
  
  onSubmit() {

    console.log(this.applicationViewFormGroup.value);

    if(this.addOrEdit === 'add') {
      this.applicationViewFormGroup.controls['id'].setValue(0); 
    }

    this.applicationViewFormGroup.controls['view'].enable();
    this.applicationViewFormGroup.controls['accountCode'].enable();

      this.appViewsServices.putApplicationView(this.applicationViewFormGroup.value).subscribe(
        res => {
          this.appViewsServices.setApplicationViews();
          this.close();
        },
        err => {
          alert('Error adding Application View!');
        });
  }
  
  updateViewName() {
    this.generateKey();
  }
}
