import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { CommandBatchStatusChange } from '../commands/command-manager';
import { CommentsStateService } from '../comments/comments-state.service';
import { MenuTrayStateService } from '../menu-tray/menu-tray-state.service';
import { AccountEntriesNode } from './account-entries-node';
import { LedgerAccountComponentUtil } from './ledger-account-component-util';
import { LedgerStateService } from './ledger-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ledger-account-title',
  template: `
<div class="led-row" [ngClass]="(cssClass$ | async)!">
  <div class="led-title" (click)="selectAccount()">{{(account$ | async)?.displayName}}</div>
  <div class="led-row-notification-right d-print-none" >
    <span (click)="showComments()" [hidden]="!(hasComments$ | async)" title="There are comments for this account">
      <i class="far fa-comment"> </i>
    </span>
    <span (click)="showComments()" class="text-danger" [hidden]="!(requiresComment$ | async)"
      title="Comments are required for this account">
      <i class="fa fa-exclamation-triangle"> </i>
    </span>
  </div>
</div>
`
})
export class LedgerAccountTitleComponent {
  private readonly accountSubject = new BehaviorSubject<AccountEntriesNode | undefined>(undefined);
  private readonly isSelected$ = combineLatest([
    this.accountSubject,
    this.ledgerState.selectedAccountChange$.pipe(map(x => x.acct), startWith(this.ledgerState.selectedAccount))
  ]).pipe(
    map(([account, selectedAccount]) => account === selectedAccount),
    startWith(false),
    distinctUntilChanged()
  );

  readonly account$ = this.accountSubject.asObservable();

  readonly cssClass$ = combineLatest([
    this.accountSubject,
    this.isSelected$
  ]).pipe(map(([account, isSelected]) => LedgerAccountComponentUtil.getCssClasses(account!, isSelected)));

  readonly hasComments$ = combineLatest([
    this.accountSubject,
    this.commentsState.comments$.pipe(startWith([]))
  ]).pipe(
    map(([account]) => account && this.commentsState.accountHasComments(account.accountCode!)),
    startWith(false),
    distinctUntilChanged()
  );

  readonly requiresComment$ = combineLatest([
    this.accountSubject,
    merge(
      this.commentsState.comments$.pipe(filter(x => x != null)),
      this.ledgerState.amountCommands.executed$.pipe(startWith(undefined as unknown as CommandBatchStatusChange<unknown>))
    )
  ]).pipe(
    map(([account]) => account && !!this.ledgerState.accountCodesRequiringComment.get(account.accountCode!)),
    startWith(false),
    distinctUntilChanged(),
  );

  constructor(private commentsState: CommentsStateService, private ledgerState: LedgerStateService,
    private menuTrayState: MenuTrayStateService) {
  }

  /** Sets the component's AccountEntriesNode */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('account')
  set account(value: AccountEntriesNode | undefined) {
    if (value !== this.accountSubject.value) {
      this.accountSubject.next(value);
    }
  }

  /** Changes the selected account in the ledger state to this component's account. */
  selectAccount() {
    this.ledgerState.selectAccount(this.accountSubject.value);
  }

  /** Changes the selected account in the ledger state and shows the comment management tray. */
  showComments() {
    this.ledgerState.selectAccount(this.accountSubject.value);
    this.menuTrayState.openTray('CommentManagement');
  }


}
