import { BehaviorSubject } from 'rxjs';

/** helps keep track of selected items */
export class ObservableSet<T> {
  private setChangeSubj = new BehaviorSubject<T[]>([]);

  readonly setChange$ = this.setChangeSubj.asObservable();

  /** readonly only property.  Don't modify collection items. */
  get items() {
    return this.setChangeSubj.value;
  }

  /** adds selections */
  add(itemOrItems: T | T[]) {
    const items = this.normalizeItemOrItemsParam(itemOrItems);
    const current = this.setChangeSubj.value;
    const filteredItems = (items || []).filter(x => !current.includes(x));
    if (filteredItems.length > 0) {
      this.setChangeSubj.next(current.concat(filteredItems));
    }
  }

  /** clears all selections */
  clear() {
    this.set([]);
  }


  /** removes all matched items */
  delete(itemOrItems: T | T[]) {
    const items = this.normalizeItemOrItemsParam(itemOrItems);
    const current = this.setChangeSubj.value;
    const updated = current.filter(x => !items.includes(x));
    if (updated.length !== current.length) {
      this.setChangeSubj.next(updated);
    }
  }

  /**
   * returns true if an item is contained within
   */
  has(item: T) {
    return this.setChangeSubj.value.includes(item);
  }

  /** sets items */
  set(itemOrItems: T | T[]) {
    const current = this.setChangeSubj.value;
    const items = this.normalizeItemOrItemsParam(itemOrItems);

    if (current.length !== items.length || current.some(x => !items.includes(x))) {
      // update subject if there is some sort of change.
      this.setChangeSubj.next([... items]);
    }
  }

  /**
   * Toggles items. When all items are missing or exist in selections, they are added or removed.
   * In case of partial match the behavior depends on removeAllIfPartialMatch
   */
  toggle(item: T): void;
  toggle(items: T[], removeAllIfPartialMatch?: boolean): void;
  toggle(itemOrItems: T | T[], removeAllIfPartialMatch?: boolean): void {
    const items = this.normalizeItemOrItemsParam(itemOrItems);
    const current = this.setChangeSubj.value;
    const matches: T[] = [];
    const misses: T[] = [];
    for (const item of items || []) {
      ((current.includes(item)) ? matches : misses).push(item);
    }

    if (matches.length > 0 && (misses.length === 0 || removeAllIfPartialMatch)) {
      // matches are removed if there are matches and no misses, or removeAllIfPartialMatch is true
      this.delete(matches);
    }
    else if (misses.length > 0) {
      this.setChangeSubj.next(current.concat(misses));
    }
  }

  private normalizeItemOrItemsParam(itemOrItems: T | T[]) {
    if (itemOrItems == null) {
      return [];
    }
    return (Array.isArray(itemOrItems)) ? itemOrItems : [itemOrItems];
  }
}
