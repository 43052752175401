import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { uniq } from 'ramda';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { RevenueAreaType } from 'src/app/client-api.service';
import { RevenueStateService, RootAreaState } from '../../revenue-state.service';
import { RenewalsFilter, RenewalStatesFilter } from '../models';
import { RenewalsStateService } from '../renewals-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-renewal-filter',
  templateUrl: './renewal-filter.component.html',
  styles: [
  ]
})
export class RenewalFilterComponent implements OnInit, OnDestroy {
  private destroyedSubject = new Subject();

  readonly amenityNames$ = this.revState.areas$.pipe(
    filter((x): x is RootAreaState => !!x),
    map(({ typeMap }) => uniq(typeMap.get(RevenueAreaType.AddOn)!.map((x: { displayName: string; }) => x.displayName)))
  );

  @ViewChild(NgbDropdown, { static: true })
  dropDown: NgbDropdown | undefined;

  filter: RenewalsFilter = {};

  onFilterChange() {
    this.renewalsState.filter = this.filter;
  }

  clearFilter() {
    this.filter = {};
    this.renewalsState.filter = this.filter = this.filter;
  }

  constructor(private cd: ChangeDetectorRef, private renewalsState: RenewalsStateService, private revState: RevenueStateService) { }

  ngOnInit(): void {
    this.renewalsState.filterChange$.pipe(
      takeUntil(this.destroyedSubject),
      tap(x => {
        this.filter = { ...x };
        this.cd.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroyedSubject.next();
  }

  /** Toggles state filter and notfies change. */
  toggleStateFilter(propertyPath: keyof RenewalStatesFilter, onState: 'blank' | 'set') {
    this.filter[propertyPath] = (this.filter[propertyPath] === onState ? undefined : onState);
    this.onFilterChange();
  }

}
