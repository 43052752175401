/** collection of accounts with entries */
import { Subject } from 'rxjs';
import { LedgerAccountNode } from '../client-api.service';
import { CalcExpression } from '../shared/calculation-expression';
import { AccountEntriesMap, EntryAmounts } from '../shared/entry-amounts';
import { LedgerTree } from './ledger-tree';


export interface IAccountEntriesNodeMap extends AccountEntriesMap {
  [accountCode: string]: AccountEntriesNode;
}

export interface AmountChangedArgs {
  amountIndex: number;
  newValue: number;
  oldValue: number;
}

export class AccountEntriesNode implements EntryAmounts {
  accountCode?: string;
  root?: LedgerTree;
  parent?: AccountEntriesNode;

  /** subject that is called everytime and amount value is changed */
  readonly amountChanges = new Subject<AmountChangedArgs>();

  /** amount values for each amount.  If a value is changed call onAmountChanged after */
  amounts: number[] = [];

  /** optional meta for each amount */
  //amountsMeta?: any[];

  /** don't modify directly - use addChild and removeChild  */
  children: AccountEntriesNode[] = [];

  /** if set, the amounts for this node are set by this expression */
  calcExpr?: CalcExpression;

  displayName?: string;

  /** what the account numbers should look like when displayed */
  get displayFormat() {
    return <{ type: string, decimals?: number }>{
      type: (this.calcExpr != null) ? this.calcExpr.format || 'whole' : 'whole',
      decimals: (this.calcExpr != null && this.calcExpr.format === 'percent') ? 1 : 2
    };
  }

  /** if true, don't include this account's amounts in ancestor totals */
  isIgnoredInTotals?: boolean;
  isNegated?: boolean;
  isHidden?: boolean;
  isReadOnly?: boolean;

  /** if set, returns true or false if the number is valid */
  validator?: (amount: number) => boolean;

  /** gets how deep this is in the tree */
  levelIndex?: number;


  /** backing value for total property.  Set to undefined to recalculate total at next access */
  private _total?: number;
  /** calcualted total of amounts */
  get total(): number {
    // calculate _total if not set;
    if (this._total == null) {
      if (!this.hasCalculation) {
        this._total = this.amounts.reduce((p, c) => p + c, 0);
      }
      else {
        this._total = this.calcExpr!.compile()(this.root!.accountMap, -1);
      }
    }
    return this._total;
  }


  /** get the total with negation factor applied */
  get totalWithNegationFactored() {
    return this.total * (this.isNegated ? -1 : 1);
  }

  /** if isNegated is true returns -1, otherwise 1.  Used to apply negation when saving to, or updating source */
  get negationFactor(): number {
    return this.isNegated ? -1 : 1;
  }

  /** returns true if this has any children */
  get hasChildren(): boolean {
    return (this.children.length > 0);
  }

  /** gets how many levels deep is the deepest Descendant */
  get maxDescendantDepth(): number {
    if (this.children.length === 0) {
      return 0;
    }

    return 1 + this.children.map(c => c.maxDescendantDepth).reduce((pv, cv) => Math.max(pv, cv), 0);
  }

  /** returns true if calculation expression is defined */
  get hasCalculation(): boolean {
    return (this.calcExpr != null);
  }

  /**
   * True if amount array can be edited.
   * Otherwise false and the amounts are figured some other way.
   */
  get hasEditableAmounts(): boolean {
    return (!this.isReadOnly && this.children.length === 0 && this.calcExpr == null);
  }

  constructor(srcNode: LedgerAccountNode | undefined) {
    if (srcNode == null) {
      return;
    }

    this.accountCode = srcNode.accountCode;
    this.isHidden = srcNode.isHidden;
    this.isNegated = srcNode.isNegated;
    this.isIgnoredInTotals = srcNode.isIgnoredInTotals;
    this.isReadOnly = srcNode.isReadOnly;

    if (srcNode.calculationExpression) {
      this.calcExpr = new CalcExpression(srcNode.calculationExpression);
    }

    if (srcNode.validationExpression) {
      this.validator = this.compileValidationExpression(srcNode.validationExpression);
    }
    if (srcNode.displayAccountCode != null) {
      this.displayName = (srcNode.displayAccountCode.length > 0) ? srcNode.displayAccountCode + '-' : '';
    } else {
      this.displayName = this.accountCode + '-';
    }
    this.displayName += (srcNode.name || '');
  }

  /** Adds a child and connects it to this and the ledger. */
  addChild(node: AccountEntriesNode) {
    if (node && node.parent) {
      throw new Error(`Member ${node.accountCode} already has parent.`);
    }
    this.children.push(node);
    node.parent = this;
    node.levelIndex = (node.parent.levelIndex || 0) + 1;

    this.root!.registerTreeMember(node);
  }

  /** completes all observables on this and children */
  destroy() {
    this.children.forEach(x => x.destroy());
    this.children.length = 0;
    this.amountChanges.complete();
  }

  /** Gets this node's next sibling or undefined if it doesn't exist. */
  getNextSibling(): AccountEntriesNode | undefined {
    if (this.parent !== undefined) {
      const nextIndex = this.parent.children.indexOf(this) + 1;
      if (nextIndex > 0 && nextIndex < this.parent.children.length) {
        return this.parent.children[nextIndex];
      }
    }
    return undefined;
  }

  /**
   * gets the first child if there are no children or none match critera, then gets next sibling, then looks up
   * until critera is matched or undefined is returned
   * @param criteria optional crtiera to test against nodes
   */
  getDownThenNextSearch(criteria?: (n: AccountEntriesNode) => boolean) {

    if (this.hasChildren) {
      let currentNode: AccountEntriesNode;

      if (criteria == null) {
        return this.children[0];
      }
      else {
        for (currentNode of this.children) {
          if (criteria(currentNode)) {
            return currentNode;
          }
        }
      }
    }

    return this.getNextSiblingThenUpSearch(criteria);
  }

  /** Gets the next sibling, if none is found keeps looking up until an ancestor's sibling can be returned. */
  getNextSiblingThenUpSearch(criteria?: (n: AccountEntriesNode) => boolean) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let current: AccountEntriesNode = this;
    let nextIndex: number;
    if (criteria == null) {
      while (current.parent !== undefined) {
        nextIndex = current.parent.children.indexOf(current) + 1;
        if (nextIndex > 0 && nextIndex < current.parent.children.length) {
          return current.parent.children[nextIndex];
        }
        current = current.parent;
      }
    }
    else {
      while (current.parent !== undefined) {
        nextIndex = current.parent.children.indexOf(current) + 1;
        if (nextIndex > 0 && nextIndex < current.parent.children.length && criteria(current.parent.children[nextIndex])) {
          return current.parent.children[nextIndex];
        }
        current = current.parent;
      }
    }
    return undefined;
  }

  /** get the next leaf node. */
  getNextLeaf(): AccountEntriesNode {
    let node: AccountEntriesNode | undefined;

    // search descendants then siblings
    node = this.getFirstDescendantWhereTrue((d) => !d.hasChildren) || this.getNextLeafSearchNodeSiblings(this);
    if (node) {
      return node;
    }


    // try search up
    if (this.parent != null) {
      let curParent = this.parent;

      // keep searching up until we find an ancestor with a sibling
      while (curParent != null) {
        node = this.getNextLeafSearchNodeSiblings(curParent);
        if (node) {
          return node;
        }
        curParent = curParent.parent!;
      }
    }

    // at this point we loop to the start and get the first visible node
    if (this.root!.hasChildren) {
      return this.root!.getFirstDescendantWhereTrue((d) => !d.hasChildren)!;
    }
    return this.root!;
  }

  /** Used by getNextLeaf to search a node's siblings.  Searching the sibling first and then it's children. */
  protected getNextLeafSearchNodeSiblings(searchNode: AccountEntriesNode) {
    if (searchNode == null) {
      return undefined;
    }
    let foundNode: AccountEntriesNode;

    while ((searchNode = searchNode.getNextSibling()!) != null) {
      if (!searchNode.hasChildren) {
        return searchNode;
      } else {
        foundNode = searchNode.getFirstDescendantWhereTrue((d) => !d.hasChildren)!;
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  }

  /** Gets this node's previous sibling or undefined if it doesn't exist. */
  getPreviousSibling(): AccountEntriesNode | undefined {
    if (this.parent != null) {
      const prevIndex = this.parent.children.indexOf(this) - 1;
      if (prevIndex > -1) {
        return this.parent.children[prevIndex];
      }
    }
    return undefined;
  }
  /**
   * Searches all descendants, trying to find the first that satisfies filter.
   * Searches in the order of child, then descendants then child siblings, descendants.
   */
  getFirstDescendantWhereTrue(filter: (node: AccountEntriesNode) => boolean): AccountEntriesNode | undefined {
    for (const child of this.children) {
      if (filter(child)) {
        return child;
      }
      const foundDescendant = child.getFirstDescendantWhereTrue(filter);
      if (foundDescendant) {
        return foundDescendant;
      }
    }
    return undefined;
  }

  /**
   * Searches all descendants, trying to find the last that satisfies filter.
   * Searches in the order of last sibling descendants, then last sibling, the next to last sib descendants,
   * then next to last sib...
   */
  getLastDescendantWhereTrue(filter: (node: AccountEntriesNode) => boolean): AccountEntriesNode | undefined {
    for (const child of this.children.reverse()) {
      const foundDescendant = child.getLastDescendantWhereTrue(filter);
      if (foundDescendant) {
        return foundDescendant;
      }
      if (filter(child)) {
        return child;
      }
    }
    return undefined;
  }

  /** gets all collection of all descendants. */
  getAllDescendants(): AccountEntriesNode[] {
    return this.children.concat(...this.children.map(x => x.getAllDescendants()));
  }

  /** gets the sum of all the children's amounts or performs calculation. */
  recalculate(amountIndex: number) {
    let newAmount: number;

    if (this.hasCalculation) {
      newAmount = this.calcExpr!.compile()(this.root!.accountMap, amountIndex);
    }
    else if (!this.hasChildren) {
      return;
    }
    else {
      newAmount = this.children.reduce((prev, cur) =>
        prev + (cur.isIgnoredInTotals ? 0 : (cur.amounts[amountIndex] || 0) * cur.negationFactor), 0) * this.negationFactor;
    }

    this.setAmount(amountIndex, newAmount);
  }

  /** Pperforms calculations for all months. */
  recalculateAll() {
    if (!this.hasCalculation && !this.hasChildren) {
      return;
    }

    for (let i = 0, il = this.root!.months!.length; i < il; i++) {
      this.recalculate(i);
    }
  }

  /**
   * Sets amount and calls onAmountChanged if the amount has changed.
   * Preferable to calling to setting array and calling onAmountChanged manually
   */
  setAmount(amountIndex: number, value: number) {
    const originalAmount = this.amounts[amountIndex];
    if (originalAmount !== value) {
      this.amounts[amountIndex] = value;
      this.invalidateTotal();
      this.amountChanges.next({ amountIndex: amountIndex, newValue: value, oldValue: originalAmount });
    }
  }

  /** Sets total property backing value to undefined if an amount has changed. */
  protected invalidateTotal() {
    this._total = undefined;
  }

  /** Converts a validation expression into a function. */
  private compileValidationExpression(exprText: string): (amount: number) => boolean {
    const validatorFunc = <(amount: number) => boolean>new Function('amount', `return (amount ${exprText});`);

    return validatorFunc;
  }
}
