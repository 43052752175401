import { Injectable } from '@angular/core';
import { concat, forkJoin, merge, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, retry, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ClientApi, LedgerDetail, ViewSummary } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';
import { ViewInfo } from './view-info';

@Injectable({
  providedIn: 'root',
})
export class LedgerService {
  private readonly viewsInvalidatedSubject = new Subject<void>();

  readonly ledgerSummaries$ =
    this.clientApi.getLedgers()
      .pipe(
        retry(3),
        map(ledgers => ledgers
          .sort((a, b) => ArrayUtil.comparerFuncGeneric(a.name, b.name))
        ),
        shareReplay(1)
      );

  readonly ledgerSummariesNameMap$ = this.ledgerSummaries$
    .pipe(
      map(ledgers => new Map(ledgers.map(x => [x.name, x]))),
      shareReplay(1)
    );

  readonly ledgerSummariesIdMap$ = this.ledgerSummaries$
    .pipe(
      map(ledgers => new Map(ledgers.map(x => [x.ledgerId, x]))),
      shareReplay(1)
    );

  readonly glViews$ =
    merge(
      this.clientApi.getViews(),
      this.viewsInvalidatedSubject.pipe(
        // clear items and then wait a bit before retrieving new items
        switchMap(() => concat(of(<ViewSummary[]><unknown>undefined), timer(50).pipe(switchMap(() => this.clientApi.getViews()))))
      )
    ).pipe(
      retry(3),
      distinctUntilChanged(),
      switchMap(views =>
        // return empty array if views is empty because otherwise forkJoin will never complete.
        (!views || views.length === 0)
          ? of(<ViewInfo[]>[])
          : forkJoin(
            views.sort((a, b) => ArrayUtil.comparerFuncGeneric(a.name, b.name))
              .map(v => {
                const vi: ViewInfo = { ...v };
                if (v.ledgerId) { /* could this be 0? */
                  return this.getLedgerSummaryById(v.ledgerId).pipe(map(ls => {
                    vi.ledger = ls;
                    return vi;
                  }));
                }
                return of(vi);
              })
          )
      ),
      shareReplay(1)
    );

  readonly glViewsIdMap$ = this.glViews$
    .pipe(
      map(views => new Map(views.map(x => [x.viewId, x]))),
      shareReplay(1)
    );

  constructor(private clientApi: ClientApi) {
  }

  /** gets ledger summary with matching name */
  getLedgerSummaryByName(ledgerName: string) {
    return this.ledgerSummariesNameMap$.pipe(map(m => m.get(ledgerName)));
  }

  getLedgerSummaryById(ledgerId: number) {
    return this.ledgerSummariesIdMap$.pipe(map(m => m.get(ledgerId)));
  }
  /** Gets a ledger with account info */
  getLedger(ledgerId: number) {
    return this.clientApi.getLedgerById(ledgerId);
  }

  /** Gets a ledger by its name */
  getLedgerByName(ledgerName: string) {
    return this.getLedgerSummaryByName(ledgerName)
      .pipe(switchMap(summary => (summary != null) ? this.getLedger(summary.ledgerId) : of(undefined as unknown as LedgerDetail)));
  }

  getViewById(viewId: number) {
    return this.glViewsIdMap$.pipe(take(1), map(x => x.get(viewId)));
  }

  updateViewWorkflows(viewId: number, workflowIds: number[]) {
    return this.clientApi.postViewWorkflowIds(viewId, workflowIds)
      .pipe(tap(() => this.viewsInvalidatedSubject.next()));
  }

}
