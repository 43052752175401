
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FileDownloadService, SubsManager } from '@tcc/ui';
import { merge, of } from 'rxjs';
import { debounceTime, finalize, switchMap, tap, startWith } from 'rxjs/operators';
import { CalculationsService } from '../calculations/calculations.service';
import { CommandsService } from '../commands/commands.service';
import { CommentsStateService } from '../comments/comments-state.service';
import { ComparisonStateService } from '../ledgers/comparisons/comparison-state.service';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { AccountVisibilityService } from '../ledgers/account-visibility.service';
import { MenuTrayStateService } from '../menu-tray/menu-tray-state.service';
import { ExporterService } from '../shared/exporter.service';
import { WorkflowsService } from '../core-services/workflows.service';
import { UserService } from '../core-services/user.service';
import { LedgerAccountMathService } from '../ledgers/ledger-account-math.service';
import { WorkflowEventType } from '../client-api.service';
import { WorkflowManagementComponentOptions } from './workflow-management.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ledger-menu',
  templateUrl: './ledger-menu.component.html'
})
export class LedgerMenuComponent implements OnDestroy, OnInit {

  get hasHelp() { return this.ledgerState.currentStep && !!this.ledgerState.currentStep.helpInfo; }

  /** returns true if leddgerState.ledger is set */
  get hasLedger() { return this.ledgerState.ledger != null; }

  isHelpLoading = false;

  /** if true, the user can force an export of the data */
  manualExportEnabled = false;

  readonly mathActions = this.ledgerAcctMathSvc.actions;

  get suppressZeroes() { return this.accountVizSvc.suppressZeroes; }
  set suppressZeroes(value: boolean) { this.accountVizSvc.suppressZeroes = value; }

  readonly workflowEventTypes = WorkflowEventType;

  /** workflow management options */
  workflowMgmtMode?: WorkflowEventType;

  private subsManager = new SubsManager();

  constructor(
    private accountVizSvc: AccountVisibilityService,
    private cd: ChangeDetectorRef,
    private commandsSvc: CommandsService,
    private commentsState: CommentsStateService,
    public comparisonState: ComparisonStateService,
    private exporterSvc: ExporterService,
    private fileDownloadSvc: FileDownloadService,
    private ledgerAcctMathSvc: LedgerAccountMathService,
    public ledgerState: LedgerStateService,
    public menuTrayState: MenuTrayStateService,
    private userSvc: UserService,
    private calcSvc: CalculationsService,
    private wfsSvc: WorkflowsService) { }

  ngOnInit() {
    this.subsManager.addSub = this.ledgerState.ledger$
      .pipe(
        switchMap(() => this.initUserOptions()),
        tap(x => this.manualExportEnabled = x),
        tap(() => this.cd.detectChanges())
      )
      .subscribe();

    this.subsManager.addSub =
      merge(
        this.commandsSvc.commandEvent$,
        this.ledgerState.selectedAccountChange$,
        this.comparisonState.anyComparisonUpdate$,
        this.accountVizSvc.suppressZeroes$
      ).pipe(
        debounceTime(250),
        tap(() => this.cd.detectChanges()))
        .subscribe();
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }


  /** Determines if user can export. */
  private initUserOptions() {
    const manualExportRoles = [UserService.roles.superApprover, UserService.roles.executiveApprover, UserService.roles.regionalApprover];
    const viewId = this.ledgerState.view?.viewId;
    const orgId = this.ledgerState.organization?.orgId;

    return (viewId && orgId)
      ? this.userSvc.isUserInRoles(manualExportRoles, orgId, viewId).pipe(startWith(false))
      : of(false);
  }

  copyComparisonAmounts() {
    const amounts = (this.comparisonState.comparisonData || {})[this.ledgerState.selectedAccount!.accountCode!];
    if (amounts) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.calcSvc.applyAmounts(this.ledgerState.selectedAccount!, [], (cur, idx, amt) => amounts.amounts[idx] || 0);
    }
  }



  /** returns true if an editor is available by checking the ledger configuration */
  editorAvailable(editorId: string) {
    return this.ledgerState.isEditorOk(editorId);
  }

  exportToCsv() {
    const comparisonInfo = (this.comparisonState.isComparing && this.comparisonState.formattedResults) 
      ? {
        headerName: this.comparisonState.currentHeader,
        formattedResults: this.comparisonState.formattedResults
      }
      : undefined;
    const csvData = this.exporterSvc.createCsv(this.ledgerState.ledger!, this.ledgerState.orderedAccounts, comparisonInfo);

    const fileName = (this.ledgerState.organization!.orgCode + ' ' + this.ledgerState.view!.name).replace(/[^a-zA-Z\d]+/g, '_') + '.csv';
    this.fileDownloadSvc.startRawDownload(fileName, csvData, 'text/csv;charset=utf-8;');
  }

  /** Determines if there are any comments for the selected account. */
  hasComments() {
    const account = this.ledgerState.selectedAccount;
    return account && this.commentsState.accountHasComments(account.accountCode!);
  }

  /**
   * Shows Workflow Management Tray with specified action
   * @param action specifies the type of action that can be performed.
   */
  showWorkflowMgmt(action: WorkflowEventType) {
    this.menuTrayState.openTray('WorkflowManagement', { workflowMgmtMode: action } as WorkflowManagementComponentOptions);
  }

  showExportData() {
    this.menuTrayState.openTray('ManualExport');
  }

  showHelp() {
    this.isHelpLoading = true;
    this.wfsSvc.getHelpFileUrl(this.ledgerState.currentStep!.stepId)!.pipe(
      tap(url => window.open(url)),
      finalize(() => {
        this.isHelpLoading = false;
        this.cd.detectChanges();
      })
    ).subscribe();
  }
  /** Shows Comments in the Tray */
  toggleComments() {
    this.menuTrayState.toggleTray('CommentManagement');
  }

  toggleEditor(editorId: string) {
    this.menuTrayState.toggleTray(editorId);
  }

  /** Shows History in the Tray */
  toggleHistory() {
    this.menuTrayState.toggleTray('AccountHistory');
  }

}

