<div class="d-inline-block small" ngbDropdown #myDrop="ngbDropdown">
  <button class="btn btn-sm btn-link py-0 px-1 border-0" ngbDropdownAnchor
    style="margin-top: -8px;"
    (click)="$event.stopPropagation(); myDrop.open();"
    [title]="getChoice(flag!).description">
    <span class="small">
      <i [ngClass]="getChoice(flag!).iconClass" ></i>
    </span>
  </button>
  <div ngbDropdownMenu style="z-index:1030">
    <ng-container *ngFor="let x of flags">
      <button *ngIf="showChoice(x)" ngbDropdownItem class="small"
        (click)="$event.stopPropagation(); setChoice(x)">

        <i [ngClass]="x.dropdownClass || x.iconClass"></i> {{x.dropdownDescription || x.description}}
      </button>
    </ng-container>
  </div>
</div>
