import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { filter, tap } from 'rxjs/operators';
import { MenuTrayStateService, TrayInfo, TraySize } from './menu-tray-state.service';


@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'menu-tray-wrapper',
  animations: [
    trigger(
      'trayState',
      [
        state('*', style({ height: '0px', opacity: 0 })),
        state('sm', style({ height: '225px', opacity: 1 })),
        state('med', style({ height: '300px', opacity: 1 })),
        state('full', style({ height: 'calc(100vh - 150px)', opacity: 1 })),
        state('closed', style({ height: '0px', opacity: 0 })),
        transition('* => *', animate('.25s'))
      ]
    )
  ],
  templateUrl: './menu-tray-wrapper.component.html'
})
export class MenuTrayWrapperComponent implements TrayInfo, OnInit, OnDestroy {
  private isVisible = false;
  private subsMgr = new SubsManager();

  currentTraySize?: TraySize;

  originalTraySize?: TraySize;

  @HostBinding('class') hostClass = 'd-block mb-2';
  @HostBinding('@trayState')
  trayState?: 'sm' | 'med' | 'full' | 'closed';

  @Input() trayId = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('traySize')
  set sizeInput(value: TraySize) {
    if (value && value !== this.currentTraySize) {
      this.menuTrayStateSvc.onResize({ trayId: this.trayId, size: value });
    }
  }

  constructor(private menuTrayStateSvc: MenuTrayStateService) { }

  ngOnInit() {
    if (!this.currentTraySize) {
      this.currentTraySize = 'sm';
    }
    this.originalTraySize = this.currentTraySize;

    this.subsMgr.addSub = this.menuTrayStateSvc.trayChanges$.pipe(
      tap(x => {
        if (x.trayId === this.trayId) {
          this.isVisible = true;
        }
        else if (this.isVisible) {
          this.isVisible = false;
        }
        this.updateTrayState();
      })
    ).subscribe();

    this.subsMgr.addSub = this.menuTrayStateSvc.trayCloseStart$.pipe(
      filter(x => x.trayId === this.trayId && this.isVisible),
      tap(() => {
        this.isVisible = false;
        this.updateTrayState();
      })
    ).subscribe();

    this.subsMgr.addSub = this.menuTrayStateSvc.trayResize$.pipe(
      filter(x => x.tray.trayId === this.trayId),
      tap(x => {
        this.currentTraySize = x.tray.size;
        this.updateTrayState();
      })
    ).subscribe();
  }


  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  /** report back to the state service that this tray has completed closing */
  @HostListener('@trayState.done')
  onAnimationComplete() {
    if (!this.isVisible) {
      // if the component isn't visible and animation is completed that means the close animation ran.
      this.menuTrayStateSvc.onCloseComplete(this.trayId);
    }
  }

  /** Closes the tray if it isn't already in the process of doing so. */
  startClose() {
    if (this.isVisible) {
      this.menuTrayStateSvc.closeTray(false);
    }
  }

  /** Toggles between original size and full screen. */
  toggleFullScreen() {
    const newSize = (this.originalTraySize === this.currentTraySize) ? 'full' : this.originalTraySize;
    this.menuTrayStateSvc.onResize({ trayId: this.trayId, size: newSize });
  }

  updateTrayState() {
    if (!this.isVisible) {
      this.trayState = 'closed';
    }
    else {
      this.trayState = this.currentTraySize || 'sm';
    }
  }
}

