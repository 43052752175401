import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { EstimateType, RevenueAreaType } from '../../client-api.service';
import { CommentsStateService } from '../../comments/comments-state.service';
import { MenuTrayStateService } from '../../menu-tray/menu-tray-state.service';
import { GLOBAL, IGlobalSettings } from '../../shared/global-settings';
import { tapError } from '../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../estimate-input-debounce-manager';
import { AreaEstimates } from '../models/area-estimates';
import { RevenueStateService, RootAreaState } from '../revenue-state.service';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styles: []
})
export class RevenueComponent implements OnInit, OnDestroy {
  private subsMgr = new SubsManager();

  /** reference to the EstimateType enum */
  readonly comments$ = this.revState.comments$;
  readonly cyLabel = `${this.globalSettings.budgetYear}`;
  readonly estimateTypeEnumRef = EstimateType;
  readonly fpOutOfSync$ = this.revState.fpOutOfSync$;
  readonly isReadOnly$ = this.revState.isReadOnly$;
  readonly org$ = this.revState.org$;
  readonly specialAccounts = this.globalSettings.specialAccounts;
  readonly state$ = this.revState.areas$.pipe(
    filter((x): x is RootAreaState => !!x),
    map(({ areas }) => (areas && areas.length) ? 'ready' : 'loading'),
    startWith('loading'));
  readonly inputBounceMgr = new EstimateInputDebounceManager(this.revState.periodSettings);
  readonly tgtPeriodIndex = this.revState.periodSettings.cyJan.index;
  readonly trayRequest$ = this.trayStateSvc.trayChanges$;

  amenityAverages: { amenityName: string; pyAvg: number; cyAvg: number; count: number; }[] | undefined;

  commentAccount = this.specialAccounts.gpr;

  /** estimates on the org level */
  orgArea: AreaEstimates | undefined;

  constructor(
    private commentsState: CommentsStateService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private notifySvc: NotificationsService,
    public revState: RevenueStateService,
    private route: ActivatedRoute,
    private trayStateSvc: MenuTrayStateService
  ) { }

  ngOnInit() {
    this.inputBounceMgr.defaultAppliedOn = this.revState.periodSettings.cyJan.value;

    this.subsMgr.addSub = this.route.params.pipe(
      filter((x): x is { orgId: string} => typeof x.orgId === 'string'),
      tap(x => {
        const orgId = parseInt(x.orgId, 10);
        this.revState.orgId = orgId;
      }))
      .subscribe();

    this.subsMgr.addSub = this.revState.areas$.pipe(
      filter((x): x is RootAreaState => !!x),
      tap(({ typeMap }) => {
        this.orgArea = typeMap.get(RevenueAreaType.Self)?.[0];
        this.inputBounceMgr.defaultRevAreaId = (this.orgArea) ? this.orgArea.revAreaId : undefined;
        this.initializeAmenities(typeMap.get(RevenueAreaType.AddOn) || []);
        this.initializeUnitTypes(typeMap.get(RevenueAreaType.UnitType) || []);
      })
    ).subscribe();

    // this stream saves changes
    this.subsMgr.addSub = this.inputBounceMgr.estimateChange$.pipe(
      tap(x => this.revState.enqueueEstimateSave(x.value.revAreaId!, {
        appliedOn: x.value.appliedOn!,
        estimateType: x.value.estimateType!,
        estimateId: 0,
        value: x.value.value
      })),
      tapError(() => this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.'))
    ).subscribe();
  }

  ngOnDestroy() {
    this.trayStateSvc.closeTray(true);
    this.inputBounceMgr.clear();
    this.subsMgr.onDestroy();
    this.revState.orgId = undefined;
  }



  getTargetEstimateClass(estimateType: EstimateType) {
    const estTgtDiff = this.estimateVsTarget(estimateType);
    return estTgtDiff < -.01 ? 'text-danger' : estTgtDiff > .01 ? 'text-info' : '';
  }

  showComments(accountCode: string) {
    this.commentAccount = accountCode;
    this.commentsState.selectedAccountCode = accountCode;
    this.trayStateSvc.openTray('CommentManagement');
  }

  updateEstimatePct(estimateType: EstimateType, rawValue: string) {
    try {
      this.inputBounceMgr.updateRawEstimatePct(rawValue, { estimateType });
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${rawValue}.  Nothing was saved.`);
    }
  }

  updateEstimateForce(estimateType: EstimateType) {
    this.inputBounceMgr.updateForce({ estimateType });
  }

  updateFp() {
    this.revState.updateFp();
  }

  updateNewLeasesFromOccupancyTarget() {
    if (!this.orgArea || !this.orgArea.children) {
      return;
    }

    const tgtOccPct = (this.orgArea.periods[this.tgtPeriodIndex].tgtOccPct || 0);
    for (const ut of this.orgArea.children) {
      for (let pIdx = this.tgtPeriodIndex; pIdx < this.revState.periodSettings.periods.length; pIdx++) {
        const appliedOn = this.revState.periodSettings.periods[pIdx].value;
        const period = ut.periods[pIdx];
        const totalOccUnits = tgtOccPct * (ut.periods[pIdx].unitCount || 0);
        // subtract renewal leases from occupied units to get new lease.  Don't go negative
        const newNewLeases = +Math.max(0, totalOccUnits - period.estRenewalLeases! || 0).toFixed(2);
        this.inputBounceMgr.updateEstimate(newNewLeases, {
          revAreaId: ut.revAreaId,
          estimateType: EstimateType.EstNewLeases,
          appliedOn
        }, { forceUpdate: true });
      }
    }
  }


  updateRenewalLeasesFromRenewalTarget() {
    if (!this.orgArea || !this.orgArea.children) {
      return;
    }

    const renewalPct = (this.orgArea.periods[this.tgtPeriodIndex].tgtRenewalPct || 0);
    for (const ut of this.orgArea.children) {
      for (let pIdx = this.tgtPeriodIndex; pIdx < this.revState.periodSettings.periods.length; pIdx++) {
        const appliedOn = this.revState.periodSettings.periods[pIdx].value;
        const period = ut.periods[pIdx];
        const estNewLeases = (period.estNewLeases || 0);
        const totalLeasedUnits = estNewLeases + (period.estRenewalLeases || 0);
        let newRenewalLeases = (renewalPct * (ut.periods[pIdx].unitCount || 0));
        if (totalLeasedUnits > 0) {
          const newNewLeases = +Math.max(0, totalLeasedUnits - newRenewalLeases).toFixed(2);
          this.inputBounceMgr.updateEstimate(newNewLeases, {
            revAreaId: ut.revAreaId,
            estimateType: EstimateType.EstNewLeases,
            appliedOn
          }, { forceUpdate: true });
        }
        newRenewalLeases = +newRenewalLeases.toFixed(2);
        this.inputBounceMgr.updateEstimate(newRenewalLeases, {
          revAreaId: ut.revAreaId,
          estimateType: EstimateType.EstRenewalLeases,
          appliedOn
        }, { forceUpdate: true });
      }
    }
  }

  /** updates new rates based on tgtRevenueIncreasePct */
  updateNewRates() {
    if (!this.orgArea || !this.orgArea.children) {
      return;
    }
    const tgtPeriod = this.orgArea.periods[this.tgtPeriodIndex];
    const rateIncrease = tgtPeriod.tgtRateIncreasePct || 0;
    for (const ut of this.orgArea.children) {
      // revenue increase is based on the period before.
      const estimates = ut.periods[this.tgtPeriodIndex - 1];
      const baseRate = estimates.calcAvgRate || estimates.aggs.avg?.origFpRate || 0;
      const newRate = baseRate * (1 + rateIncrease);

      this.inputBounceMgr.updateEstimate(newRate, { revAreaId: ut.revAreaId, estimateType: EstimateType.EstNewRate },
        { forceUpdate: true, forCy: true });
    }
  }
  /**
   * Takes an estimate that's a percentage of a unit type and converts it to a real number
   */
  updateAreaUnitCountPctEstimate(area: AreaEstimates, estimateType: EstimateType, pctVal: number) {
    for (let pIdx = this.tgtPeriodIndex; pIdx < this.revState.periodSettings.periods.length; pIdx++) {
      const appliedOn = this.revState.periodSettings.periods[pIdx].value;
      const value = +(pctVal * area.periods[pIdx].unitCount! || 0).toFixed(2);
      this.inputBounceMgr.updateEstimate(value, { revAreaId: area.revAreaId, estimateType, appliedOn }, { forceUpdate: true });
    }
  }

  /** Returns the difference between estimate vs Target, with a positive number meaning that the estimate exceeds the target. */
  private estimateVsTarget(estimateType: EstimateType) {
    let targetAmt = 0;
    let estimateAmount = 0;
    switch (estimateType) {
      case EstimateType.TargetRenewalPct:
        targetAmt = this.orgArea?.periods[this.tgtPeriodIndex].tgtRenewalPct || 0;
        estimateAmount = this.orgArea?.summary?.estOccRenewalPct || 0;
        break;
      case EstimateType.TargetOccupancyPct:
        targetAmt = this.orgArea?.periods[this.tgtPeriodIndex].tgtOccPct || 0;
        estimateAmount = this.orgArea?.summary?.estOccPct || 0;
        break;
      case EstimateType.TargetRateIncreasePct:
        targetAmt = this.orgArea?.periods[this.tgtPeriodIndex].tgtRateIncreasePct || 0;
        estimateAmount = this.orgArea?.summary?.estRateGrowth || 0;
        break;
    }
    return estimateAmount - targetAmt;
  }
  /**
   * umm, this is a hacky being here.  There needs to be a more hollistic approach to area initialization.
   * @param amenities
   */
  private initializeAmenities(amenities: AreaEstimates[]) {
    if (!amenities || amenities.length === 0) {
      return;
    }
    if (!amenities.map(x => x.periods.slice(this.tgtPeriodIndex)).flat().some(x => !!x.estNewRate)) {
      for (const amenity of amenities) {
        for (let i = this.tgtPeriodIndex; i < this.revState.periodSettings.periods.length; i++) {
          this.revState.enqueueEstimateSave(amenity.revAreaId, {
            appliedOn: this.revState.periodSettings.periods[i].value,
            estimateType: EstimateType.EstNewRate,
            estimateId: 0,
            value: amenity.periods[i].origFpRate!
          }, undefined, true);
        }
      }
    }
  }

  private initializeUnitTypes(unitTypes: AreaEstimates[]) {
    if (!unitTypes || unitTypes.length === 0) {
      return;
    }

    if (!unitTypes.map(x => x.periods.slice(this.tgtPeriodIndex)).flat().some(x => !!x.estNewRate)) {
      for (const ut of unitTypes) {
        for (let i = this.tgtPeriodIndex; i < this.revState.periodSettings.periods.length; i++) {
          if (ut.periods[this.tgtPeriodIndex].aggs.avg!.estRenewalRate) {
            this.revState.enqueueEstimateSave(ut.revAreaId, {
              appliedOn: this.revState.periodSettings.periods[i].value,
              estimateType: EstimateType.EstNewRate,
              estimateId: 0,
              value: ut.periods[this.tgtPeriodIndex].aggs.avg!.estRenewalRate!
            }, undefined, true);
          }
        }
      }
    }
  }
}
