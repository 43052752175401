import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import { AccountEntriesNode } from '../ledgers/account-entries-node';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { OrganizationService } from '../core-services/organization.service';
import { CalcExpression } from '../shared/calculation-expression';
import { AccountEntriesMap } from '../shared/entry-amounts';


/** Function that runs a conversion on an amount
 * @param currentAmount: The current amount of the target account at the corresponding monthIndex
 * @param monthIndex: The index of the current month being calculated
 * @param adjustmentAmount: an arbritrary value used in the calculation
 */
export type ApplyToTransformFunction = (currentAmount: number, monthIndex: number, adjustmentAmount: number) => number;

/** Ledger specific calculations */
@Injectable({ providedIn: 'root'})
export class CalculationsService {

  constructor(private ledgerState: LedgerStateService, private orgSvc: OrganizationService) { }

  /**
   * Applies values to an account using a transformation
   * @param applyTo AccountEntiresNode to update
   * @param valuesToApply values to set to node
   * @param transformFunc tranform function to run when applying
   */
  applyAmounts(applyTo: AccountEntriesNode, valuesToApply: number[], transformFunc: ApplyToTransformFunction) {
    const changes = this.ledgerState.ledger!.months!.map((_, i) => {
      const originalAmount = applyTo.amounts[i] || 0;
      const adjustmentAmount = valuesToApply[i];
      const newValue = Math.round(transformFunc(originalAmount, i, adjustmentAmount) * 100) / 100;
      return { amountIndex: i, newValue };
    });
    this.ledgerState.executeAmountChanges(applyTo.accountCode!, changes);
  }

  /** Executes a calc expression using information from ledger state */
  executeCalculation(calcExpr: CalcExpression): Observable<number[]>;
  /** Executes a calc expression from a snapshot of data.  If any of these values are null or 0 then ledger state will be used. */
  executeCalculation(calcExpr: CalcExpression, knownData: AccountEntriesMap, orgId: number, ledgerId: number, year: number):
    Observable<number[]>;
  executeCalculation(calcExpr: CalcExpression, knownData?: AccountEntriesMap, orgId?: number, ledgerId?: number, year?: number):
    Observable<number[]> {

    if (!knownData && !orgId && !ledgerId && !year) {
      ({ knownData, orgId, ledgerId, year } = this.getRequiredLedgerInfoFromLedgerState());
    }

    const calculation = calcExpr.compile();

    return this.resolveCalculationVariables(calcExpr.requiredVariables, knownData!, orgId!, ledgerId!, year!)
      .pipe(map(variableLookup => Array.from({ length: 12 }).map((_, index) => calculation(variableLookup, index))));
  }

  /** executes an expression returning 12 months of account values using information from ledger state */
  executeRawExpression(rawExpression: string): Observable<number[]>;
  /** executes an expression returning 12 months of account values. */
  executeRawExpression(rawExpression: string, knownData: AccountEntriesMap, orgId: number, ledgerId: number, year: number):
    Observable<number[]>;
  executeRawExpression(rawExpression: string, knownData?: AccountEntriesMap, orgId?: number, ledgerId?: number, year?: number):
    Observable<number[]> {

    if (!knownData && !orgId && !ledgerId && !year) {
      ({ knownData, orgId, ledgerId, year } = this.getRequiredLedgerInfoFromLedgerState());
    }
    if (rawExpression == null || rawExpression.trim() === '') {
      return of<number[]>([]);
    }

    const calcExpr = new CalcExpression(rawExpression);
    return this.executeCalculation(calcExpr, knownData!, orgId!, ledgerId!, year!);
  }


  /**
   * Retrieves an entries map of variable values.
   * @param variables the variables where values are needed from
   * @param knownData a collection of known data such as an account ledger or previously resovled variables
   * @param orgId an organization to get missing variables from
   * @param ledgerId the ledger to get missing variables
   * @param year the year needed for the missing variables
   */
  resolveCalculationVariables(variables: string[], knownData: AccountEntriesMap, orgId: number, ledgerId: number, year: number) {
    return from(variables).pipe(
      concatMap(varName => {
        const foundEntryAmounts = knownData[varName];
        return (foundEntryAmounts)
          ? of({ varName, entryAmounts: foundEntryAmounts })
          : this.resolveAccountAmounts(orgId, ledgerId, varName, year)
            .pipe(map((amts) => ({ varName, entryAmounts: { amounts: amts, total: amts.reduce((acc, cur) => acc + cur, 0) } })));
      }),
      toArray(), // put all results into an array.
      map(res => res.reduce((acc, cur) => { acc[cur.varName] = cur.entryAmounts; return acc; }, <AccountEntriesMap>{}))
    );
  }

  /**
   * First tries to get amounts from ledger state.  If the account code is not found it then gets calculated entries from org
   */
  private resolveAccountAmounts(orgId: number, ledgerId: number, accountCode: string, year: number) {
    if (this.ledgerState.organization && this.ledgerState.organization.orgId === orgId
      && this.ledgerState.ledger && this.ledgerState.ledger.ledger.ledgerId === ledgerId
      && this.ledgerState.ledger.ledgerYear === year
      && this.ledgerState.ledger.accountMap[accountCode]) {

      return of(this.ledgerState.ledger.accountMap[accountCode].amounts);
    }

    return this.orgSvc.getCalculatedAccountEntries(orgId, ledgerId, accountCode, year)
      .pipe(map((entries) => {
        const amts: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        entries.forEach(x => amts[x.appliedOn.getMonth()] = x.amount);
        return amts;
      }));
  }

  private getRequiredLedgerInfoFromLedgerState() {
    const ledger = this.ledgerState.ledger;

    return {
      knownData: ledger!.accountMap,
      orgId: this.ledgerState.organization!.orgId,
      ledgerId: ledger!.ledger.ledgerId,
      year: ledger!.ledgerYear
    };
  }

}
