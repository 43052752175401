import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlagItemChoice, FLAG_ITEMS } from '../flags';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-flag-items-menu',
  templateUrl: './flag-items-menu.component.html',
  styles: [
  ]
})
export class FlagItemsMenuComponent {


  /** The key of the currently selected flag */
  @Input()
  flag?: string;

  /** flag choices. The first choice is always*/
  @Input()
  readonly flags: FlagItemChoice[] = [ ...FLAG_ITEMS ];

  @Output()
  flagChange = new EventEmitter<string>();

  constructor() { }

  flagItems(choice: FlagItemChoice | undefined) {
    const flag = (!choice || choice.isEmptyChoice) ? undefined : choice.key;
    this.flagChange.emit(flag);
  }

}
