import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService, ScrollService, SubsManager } from '@tcc/ui';
import { from, of } from 'rxjs';
import { catchError, filter, mapTo, switchMap, tap } from 'rxjs/operators';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { AggregateUtil } from '../shared/aggregate-util';
import { tapError } from '../shared/tap-error-operator';
import { PayrollEmployeeModelUtil } from './payroll-employee';
import { PayrollEmployeeControlComponent } from './payroll-employee-control.component';
import { PayrollStateService } from './payroll-state.service';
import { PayrollWageMathService } from './payroll-wage-math.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.Default,
  selector: 'payroll',
  templateUrl: './payroll.component.html'
})

export class PayrollComponent implements OnDestroy, OnInit {
  @ViewChildren(PayrollEmployeeControlComponent) employeeControls: QueryList<PayrollEmployeeControlComponent> | undefined;

  /** value of hours to send in the math menu */
  hoursToAllocate = 40;

  @ViewChild('deleteModal') deleteModal: ElementRef | undefined;

  readonly mathActions = this.payrollWageMathSvc.actions;

  @ViewChild(NgForm) myForm: NgForm | undefined;

  orgId: number | undefined;

  @ViewChild('PayrollTable', { static: true }) payrollTable: ElementRef | undefined;

  subView: 'WagesAndAllowances' | 'HourAllocations' = 'WagesAndAllowances';

  state: 'loading' | 'ready' = 'loading';

  private subsManager = new SubsManager();

  constructor(
    public ledgerState: LedgerStateService,
    private modalSvc: NgbModal,
    private notifySvc: NotificationsService,
    private payrollWageMathSvc: PayrollWageMathService,
    public payrollState: PayrollStateService,
    private scrollSvc: ScrollService) { }

  ngOnInit() {
    this.state = 'loading';
    this.subsManager.addSub = this.payrollState.readyChangeSubject
      .pipe(tap(x => this.state = x ? 'ready' : 'loading'))
      .subscribe();
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  addEmployee() {
    this.payrollState.employeeAdd();
    this.scrollSvc.scrollToDirection(this.payrollTable!, 'bottom');
  }

  getAvgProposedVarianceFromCurrentPercent(payTypeId: number) {
    const payAmounts = this.payrollState.employees.map(x => x.payItemAmounts[payTypeId]).filter(x => x != null);
    const currentSum = AggregateUtil.sum(payAmounts.map(x => x.currentAmount));
    return (currentSum !== 0)
      ? (AggregateUtil.sum(payAmounts.map(x => x.proposedAmount)) - currentSum) / currentSum
      : Number.POSITIVE_INFINITY;
  }

  getTotalPayTypeCurrentAmount(payTypeId: number) {
    return AggregateUtil.sum(this.payrollState.employees.map(x => x.payItemAmounts[payTypeId].currentAmount));
  }

  getTotalPayTypeProposedAmount(payTypeId: number) {
    return AggregateUtil.sum(this.payrollState.employees.map(x => x.payItemAmounts[payTypeId].proposedAmount));
  }

  getTotalHours(monthIndex: number) {
    return AggregateUtil.sum(this.payrollState.employees.map(x => x.monthlyHourAllocations[monthIndex].hours));
  }

  getTotalBasePay() {
    return this.payrollState.employees.reduce((pv, emp) => pv + this.payrollState.getEmployeeTotalBasePay(emp), 0);
  }
  getTotalBasePayForMonth(monthIndex: number) {
    return this.payrollState.employees.reduce((pv, emp) => pv + this.payrollState.getEmployeeTotalBasePayForMonth(emp, monthIndex), 0);
  }

  getTotalCompensation() {
    return this.payrollState.employees.reduce((pv, emp) =>
      pv + this.payrollState.getEmployeeTotalBasePay(emp) + this.payrollState.getEmployeeOtherPayItemsTotal(emp, true), 0);
  }

  /**
   * sets the hours of the currently selected hours from hoursToAllocate
   */
  setSelectedHours() {

    if (this.payrollState.selectedEmployee && this.hoursToAllocate != null) {
      const updatedEmp = PayrollEmployeeModelUtil.DeepClone(this.payrollState.selectedEmployee);
      let hasChanges = false;
      for (const allocation of updatedEmp.monthlyHourAllocations) {
        if (allocation.hours !== this.hoursToAllocate) {
          allocation.hours = this.hoursToAllocate;
          hasChanges = true;
        }
      }
      if (hasChanges) {
        this.payrollState.employeeUpdate(updatedEmp);
      }
    }
  }

  startDelete() {
    // Dismiss causes an error from the promise by design.
    // So that is converted to true or false depending on if there is an error.
    from(this.modalSvc.open(this.deleteModal).result).pipe(
      mapTo(true),
      catchError(() => of(false)),
      filter(x => x),
      switchMap(() => this.payrollState.employeeDelete(this.payrollState.selectedEmployee!)),
      tapError(() => this.notifySvc.addError('Failed deleting record.  Please refresh your browser and try again.')),
      tap(() => this.payrollState.selectedEmployee = undefined)
    ).subscribe();
  }

  updateFp() {
    this.payrollState.updateFp();
  }



}
