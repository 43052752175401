import { Injectable } from '@angular/core';
import { PanelSize } from '@tcc/ui/lib/simple-panel/models';
import { BehaviorSubject } from 'rxjs';
import { ClientApi } from 'src/app/client-api.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private maintenanceComponenetPanelSize =  new BehaviorSubject<PanelSize>('large');
  readonly maintenanceComponenetPanelSize$ = this.maintenanceComponenetPanelSize.asObservable();

  constructor(private apiClient: ClientApi) { }

  clearMaintenanceComponenetPanelSize() {
    this.maintenanceComponenetPanelSize.next('large');
  }

  getMaintenanceComponenetPanelSize() {
    return this.maintenanceComponenetPanelSize.asObservable();
  }

  setMaintenanceComponenetPanelSize(panalSize: PanelSize) {
    this.maintenanceComponenetPanelSize.next( panalSize );
  }


  
}
