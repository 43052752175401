<!-- using different elements so that tooltip will refresh. -->
<div [ngSwitch]="state" class="outer d-print-none shadow-sm" (click)="showDebugInfo()">
  <div *ngSwitchCase="'ok'" class="rounded-lg ok">
    <i class="fas fa-compact-disc fa-fw"></i>
  </div>
  <div *ngSwitchCase="'saving'" class="rounded-lg saving" [ngbTooltip]="savingTt" placement="bottom">
    <i class="fas fa-compact-disc fa-fw"></i>
  </div>
  <div *ngSwitchCase="'error'" class="rounded-lg error" [ngbTooltip]="errorTt" placement="bottom">
    <i class="fas fa-exclamation-triangle fa-fw"></i>
  </div>
</div>
<ng-template #savingTt>
  <div class="tooltipLong">
    <div>
      There
      <span [ngSwitch]="commandCount">
        <ng-template ngSwitchCase="1"> is an update occurring.</ng-template>
        <ng-template ngSwitchDefault> are {{commandCount}} updates occurring,</ng-template>
      </span>
    </div>
    <div>
      Don't close your browser until
      <span [ngSwitch]="commandCount">
        <ng-template ngSwitchCase="1">it is complete.</ng-template>
        <ng-template ngSwitchDefault>they are complete.</ng-template>
      </span>
    </div>
  </div>
</ng-template>
<ng-template #errorTt>
  <div class="tooltipLong">
    <div>There has been an error.</div>
    <div>Please reload the page and try again.</div>
  </div>
</ng-template>


