import { Injectable, Inject } from '@angular/core';
import { OrgCalculationTransform } from './org-calculation-models';
import { OrganizationService } from '../core-services/organization.service';
import { map } from 'rxjs/operators';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { CalcExpression } from '../shared/calculation-expression';

@Injectable({
  providedIn: 'root',
})
export class PayrollSplitsService {

  constructor(private orgSvc: OrganizationService, @Inject(GLOBAL) private globalSettings: IGlobalSettings) {  }

  /** Returns OrgCalculationTransform for each org with payrollSplit configuration. */
  getConfig() {
    return this.orgSvc.orgs$.pipe(
      map(orgs => orgs
        .filter(x => !!x.meta?.payrollSplit)
        .map(x => this.createPayrollSplitCalc(x.orgCode, x.meta!.payrollSplit!, this.globalSettings.payrollSplitAccounts))
        .flat()
      )
    );
  }

  /** Creates OrgCalculationTransform for individual organization. */
  private createPayrollSplitCalc(srcOrgCode: string, splits: { [orgCode: string]: number }, accountsToSplit: string[]) {
    return accountsToSplit.map(prAcct => {
      const calcInfo = new OrgCalculationTransform();
      calcInfo.orgCode = srcOrgCode;
      calcInfo.sourceViewName = this.globalSettings.defaultCalculationView;
      calcInfo.sourceCalculations = [{ varName: 'VALUE', calcExpr: new CalcExpression(`[${prAcct}]`) }];
      // we'll add the source adjustment after splits are figured out.
      calcInfo.assignmentCalculations = Object.keys(splits)
        .map(orgCode => ({
          varName: prAcct,
          calcExpr: new CalcExpression(`[VALUE] * ${splits[orgCode]}`),
          orgCode: orgCode
        }));
      return calcInfo;
    });
  }
}
