export class CommandConstants {
  public static AccountCommentSave = 'accountCommentSave';
  public static AccountEntrySave = 'accountEntrySave';
  public static AccountEntrySuperUserSave = 'accountEntrySuperUserSave';
  public static PayrollFpUpdate = 'payrollFpUpdate';
  public static PayrollEmployeeSave = 'employeeSave';
  public static PayrollItemSave = 'payItemSave';
  public static PayrollHourAllocationSave = 'payHourAllocation';
  public static RevenueEstimateSave = 'revenueEstimateSave';
}
