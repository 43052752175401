import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationViewsResource, ClientApi } from 'src/app/client-api.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationViewsService {

  private applicationViews = new BehaviorSubject<ApplicationViewsResource[]>([]);
  readonly applicationViews$ = this.applicationViews.asObservable();

  constructor(private apiClient: ClientApi) { }

  deleteApplicationView(id: number) {
    return this.apiClient.deleteApplicationView(id);
  }

  setApplicationViews() {
    this.getApplicationViews().subscribe(res => this.applicationViews.next(res));
  }

  getApplicationViews() {
    return this.apiClient.getApplicationViews().pipe(map(res => res));
  }

  getApplicationView(id: number) {
    return this.apiClient.getApplicationView(id);
  }

  getApplicationViewsCategories() {
    return this.apiClient.getApplicationViewCategories();
  }

  getApplicationViewsByCategory(view: string) {
    return this.apiClient.getApplicationViewsByCategory(view);
  }

  putApplicationView(applicationViewResource: ApplicationViewsResource) {
    return this.apiClient.putApplicationView(applicationViewResource);
  }

  refreshApplicationViews() {
    this.setApplicationViews();
  }

}
