<tcc-simplePanel size="small" [options]="{isFloating: true}" [buttons]="['close']" (buttonClick)="onPanelButtonClick()">
  <div panel-header>Fp Rate Summary</div>
  <div panel-body>
    <table class="table table-striped small">
      <thead>
        <tr>
          <th>Name</th>
          <th class="text-right">FP Rate</th>
          <ng-container *ngIf="hasAmenities$ | async">
            <th class="text-right">Amenity Offset</th>
            <th class="text-right">Total Rate</th>
          </ng-container>
        </tr>
      </thead>
      <tr *ngFor="let area of areas$ | async">
        <td>
          {{area.name}}
        </td>
        <td class="text-right">
          ${{area.periods[finalPeriodIndex].estBaseFpRate | number:'1.0-0'}}
        </td>
        <ng-container *ngIf="hasAmenities$ | async">
          <td class="text-right">${{area.periods[finalPeriodIndex].aggs.avg?.estAmenityNewRate | number:'1.0-0'}}</td>
          <td class="text-right">${{area.periods[finalPeriodIndex].estNewRate | number:'1.0-0'}}</td>
        </ng-container>
      </tr>
    </table>
  </div>
</tcc-simplePanel>
