import { LowerCasePipe } from '@angular/common';
import { Subject } from 'rxjs';

export interface SimpleLogItem {
  /** index of category in SimpleLog's categories array */
  catIndex: number;
  /** The message logged. */
  message: string;
  /** The date the item was logged. */
  time: Date;
}

/** A simple way to store a log of messages. */
export class SimpleLog {
  private static readonly defaultCategory = 'general';

  private logAppenededSubject = new Subject<SimpleLogItem>();
  private logClearedSubject = new Subject();
  private lowerCasePipe = new LowerCasePipe();

  /** items in log */
  readonly items: SimpleLogItem[] = [];

  /** a list of categories */
  readonly categories: string[] = [SimpleLog.defaultCategory];

  /** observable when log is appeneded */
  readonly logAppened$ = this.logAppenededSubject.asObservable();

  /** observable when log is cleared */
  readonly logCleared$ = this.logClearedSubject.asObservable();

  /** Clears log */
  clear() {
    this.items.length = 0;
    this.categories.length = 0;
    this.categories.push(SimpleLog.defaultCategory);
    this.logClearedSubject.next();
  }

  /**
   * Appends an item to the log
   * @param messageOrMessages single message or array of messages
   */
  append(messageOrMessages: string | string[], category?: string) {
    const catIndex = this.getOrCrateCategoryIndex(category);
    const messages = (typeof messageOrMessages === 'string')  ? [ messageOrMessages ] : messageOrMessages;
    messages
      .map(message => ({ time: new Date(), message, catIndex }) as SimpleLogItem)
      .forEach(x => {
        this.items.push(x);
        this.logAppenededSubject.next(x);
      });
  }

  /**
   * Returns the category index of the category.
   * If not found, creates a new category.
   * If a null or empty category is passed then the default category is used.
   */
  private getOrCrateCategoryIndex(category: string | undefined) {
    if (!category) {
      category = SimpleLog.defaultCategory;
    }
    const normalizedCategory = this.lowerCasePipe.transform(category);
    const catIndex = this.categories.indexOf(normalizedCategory);
    return catIndex !== -1 ? catIndex : this.categories.push(category) - 1;
  }
}
