import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NotificationsService, ScrollService, SubsManager } from '@tcc/ui';
import { combineLatest } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { LedgerComment, UserSummary } from '../client-api.service';
import { tapError } from '../shared/tap-error-operator';
import { CommentsStateService } from './comments-state.service';
import { UserService } from '../core-services/user.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'comment-management',
  templateUrl: './comment-management.component.html'
})
export class CommentManagementComponent implements OnDestroy, OnInit {
  /** backing for accountCode property */
  private _newCommentText = '';
  private accountCode?: string;
  private subsManager = new SubsManager();

  state: 'loading' | 'ready' | 'saving' = 'loading';

  @ViewChild('CommentsHost', { static: true }) commentsHostElemRef?: ElementRef;

  /** returns true if not already saving and comment text is valid */
  get canAddComment() {
    return !this.commentsState.isReadOnly && this.state === 'ready' && this.accountCode && this.validateCommentText();
  }
  get isReadOnly() {
    return this.commentsState.isReadOnly;
  }

  get minCommentLength() { return this.commentsState.minCommentLength; }

  /** text for new comment */
  get newCommentText() {
    return this._newCommentText;
  }
  set newCommentText(value: string) {
    if (value !== this._newCommentText) {
      this._newCommentText = value;
      this.cd.detectChanges();
    }
  }

  readonly comments$ = combineLatest([this.commentsState.comments$, this.commentsState.selectedAccountCode$, this.userSvc.userIdMap$]).pipe(
    debounceTime(50),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_, accountCode, userIdMap]) => {
      this.accountCode = accountCode;
      const acctComments = this.commentsState.accountMap.get(accountCode!) || [];
      this.cd.detectChanges();
      return acctComments.map(x => this.ledgerCommentToViewComment(x, userIdMap || new Map<number, UserSummary>()));
    }));

  readonly currentUserId$ = this.userSvc.currentUser$.pipe(map(x => x.userId));

  readonly title$ = this.commentsState.selectedAccountCode$.pipe(map(x => (!x) ? 'No account selected' : x));


  constructor(
    private cd: ChangeDetectorRef,
    private userSvc: UserService,
    private scrollSvc: ScrollService,
    private notifSvc: NotificationsService,
    private commentsState: CommentsStateService,
  ) {

  }

  ngOnInit() {
    this.state = 'loading';

    this.subsManager.addSub = this.commentsState.state$.pipe(
      tap(state => {
        this.state = state;
        this.cd.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  hideComment(comment: LedgerComment) {
    const commentCopy = { ...comment, isHidden: true };
    return this.saveCommentCommon(commentCopy).subscribe();
  }

  saveNewComment() {
    if (!this.validateCommentText()) {
      // TODO: Notify validation error;
      return undefined;
    }
    const comment = <LedgerComment>{ accountCode: this.accountCode, comment: this.newCommentText, isHidden: false };
    return this.saveCommentCommon(comment).pipe(tap(() => this.newCommentText = '')).subscribe();
  }

  validateCommentText() {
    return (this.newCommentText != null && this.newCommentText.length >= this.commentsState.minCommentLength);
  }

  private saveCommentCommon(comment: LedgerComment) {
    return this.commentsState.saveComment(comment)
      .pipe(
        tap(() => this.scrollSvc.scrollToDirection(this.commentsHostElemRef!, 'bottom')),
        tapError(() => this.notifSvc.addError('There was an error adding the comment'))
      );
  }



  private ledgerCommentToViewComment(comment: LedgerComment, userIdMap: Map<number, UserSummary>) {
    return { ...comment, creator: (userIdMap.get(comment.creatorId!) || { displayName: 'Unknown User' }).displayName };
  }

}

