<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    <ng-container>{{(org$ | async)?.name}}</ng-container> Renewals
  </div>
  <div panel-subheader class="d-print-none d-flex" *ngIf="(state$ | async) !== 'loading'">
    <div class="flex-grow-1 form-inline d-inline-flex">
      <div class="btn-group me-3">
        <button type="button" class="btn btn-sm btn-primary" [disabled]="!(hasSelections$ | async)" (click)="clearSelected()">
          Clear
          <ng-container [ngPlural]="(selectedUnitCount$ | async)!">
            <ng-template ngPluralCase="=0">Selections</ng-template>
            <ng-template ngPluralCase="=1">Selection</ng-template>
            <ng-template ngPluralCase="other">{{selectedUnitCount$ | async}} Selections</ng-template>
          </ng-container>
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="selectAll()">
          Select All
        </button>
      </div>
      <div class="me-3" *ngIf="!(isReadOnly$ | async)">
        <app-math-menu [actions]="renewalMathActions" [buttonClass]="'btn-danger'"></app-math-menu>
      </div>
      <div class="me-3">
        <app-renewal-filter></app-renewal-filter>
      </div>
      <div class="me-3">
        <button type="button" class="btn btn-sm btn-warning" [disabled]="!(hasWarnings$ | async)" (click)="clearUpdateWarnings()">
          <ng-container [ngPlural]="(warningCount$ | async)!">
            <ng-template ngPluralCase="=0">No Update Warnings</ng-template>
            <ng-template ngPluralCase="=1">Clear Update Warning</ng-template>
            <ng-template ngPluralCase="other">Clear {{warningCount$ | async}} Update Warnings</ng-template>
          </ng-container>
        </button>
      </div>
      <div class="me-3">
        <button type="button" class="btn btn-sm btn-light me-1" [disabled]="!(revState.estimateSaveCmd.canRollback$ | async)"
          (click)="revState.estimateSaveCmd.rollback()" title="Undo Renewal Offer">
          <i class="fas fa-fw fa-undo"></i>
        </button>
        <button type="button" class="btn btn-sm btn-light me-1" [disabled]="!(revState.estimateSaveCmd.canRedo$ | async)"
          (click)="revState.estimateSaveCmd.redo()" title="Redo Renewal Offer">
          <i class="fas fa-fw fa-redo"></i>
        </button>
      </div>
      <div *ngIf="(hasSelections$ | async)">
        <app-flag-items-menu (flagChange)="updateItemsFlags($event)"></app-flag-items-menu>
      </div>
    </div>
    <div class="flex-shrink-1">
      <div class="btn-group">
        <button [routerLink]="['../..', 'rollup']" class="btn py-0 btn-light" title="Rollup">
          <i class="fas fa-dollar-sign"></i>
          <span class="ps-2 d-none d-lg-inline d-xl-inline">Rollup</span>
        </button>
        <button [routerLink]="['../..', (org$ | async)?.orgId,'revenue']" class="btn py-0 btn-light" [title]="'Revenue Planning for ' + (org$ | async)?.name">
          <i class="fas fa-file-invoice-dollar"></i>
          <span class="ps-2 d-none d-lg-inline d-xl-inline">Revenue Planning</span>
        </button>
      </div>
    </div>
  </div>
  <div panel-body style=" overflow-y:scroll; height: 100%">
    <table class="table table-striped table-sm small">
      <thead class="table-dark">
        <tr>
          <th class="sticky-top text-nowrap">Unit Code</th>
          <th class="sticky-top">Amenities</th>
          <th class="sticky-top">Approved</th>
          <th class="sticky-top text-nowrap">Lease Start</th>
          <th class="sticky-top text-nowrap">Lease End</th>
          <th class="sticky-top">Category</th>
          <th class="text-right sticky-top text-nowrap">Base Fp Rate</th>
          <th class="text-right sticky-top text-nowrap">Amenity Rate</th>
          <th class="text-right sticky-top text-nowrap">Actual Rate</th>
          <th class="text-right sticky-top text-nowrap" style="max-width:10rem">Renewal Offer</th>
          <th class="text-right sticky-top text-nowrap"
            title="The % change of Renewal Offer from Total (Base + Amenity) Fp Rate.  Only rows with both values are counted in aggregates.">
            vs Fp %
          </th>
          <th class="text-right sticky-top text-nowrap"
            title="The % change of Renewal Offer from Actual Rate.  Only rows with both values are counted in aggregates.">
            vs Actual %
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let ut of unitTypes$ | async; trackBy: trackByRevAreaId; even as even">
          <ng-container *ngTemplateOutlet="unitTypeTemplate; context: { $implicit: ut, even: even}">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(totalEsts$ | async) as totalEsts">
          <tr *ngFor="let a of totalAggCategories"
              class="table-dark text-light font-weight-bold">
            <td colspan="6">{{a.label}}</td>
            <td class="text-right">{{totalEsts.aggs[a.aggType]?.origFpRate | number:'0.2-2' }}</td>
            <td class="text-right">{{totalEsts.aggs[a.aggType]?.origAmenityFpRate | number: '0.2-2'}}</td>
            <td class="text-right">{{totalEsts.aggs[a.aggType]?.origAvgRate | number: '0.2-2'}}</td>
            <td class="text-right">{{totalEsts.aggs[a.aggType]?.estRenewalRate | number: '0.2-2'}}</td>
            <td class="text-right" title="The avg change of Renewal Rate from Total Fp rate for all rows with both values.">
              {{totalEsts.aggs[a.aggType]?.estRenewalRateVsOrigFpPct | percent: '0.2-2'}}
            </td>
            <td class="text-right" title="The avg change of Renewal Rate from Actual rate for all rows with both values.">
              {{totalEsts.aggs[a.aggType]?.estRenewalRateVsOrigAvgRatePct | percent: '0.2-2'}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <tcc-loadingOverlay *ngIf="(state$ | async) !== 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>

<ng-template #unitTypeTemplate let-ut let-even="even">
  <ng-container *ngIf="asAreaEstimates(ut)">
  <tr [ngClass]="even ? 'table-primary' : 'table-info'" class="font-weight-bold"
      (click)="toggleSelected(ut.children)">
    <td colspan="2" class="sticky-top"><a #utAnchor [name]="ut.revAreaId.toString()">{{ut.displayName}}</a></td>
    <td colspan="20"></td>
  </tr>
  <tr *ngFor="let c of ut.children; trackBy: trackByRevAreaId"
      (click)="toggleSelected(c)"
      [ngClass]="{'highlighted-text': (selectedUnitIds$ | async)!.indexOf(c.revAreaId) !== -1}">
    <ng-container *ngIf="asAreaEstimatesWithMeta(c)">
    <td>
      <app-flag-item [(flag)]="c.meta.flag" (flagChange)="updateFlag(c)" ></app-flag-item>
      {{c.displayName}}
      <i *ngIf="hasUpdateWarning(c)" class="text-danger fas fa-exclamation-triangle"
         title="The rates for this unit type have changed in Entrata since the last time rates were updated in Fp."></i>
    </td>
    <td>{{c.meta.amenityCode}}</td>
    <td>{{c.meta.leaseInfo?.approved | date:'shortDate'}}</td>
    <td>{{c.meta.leaseInfo?.occupiedStart | date:'shortDate'}}</td>
    <td>{{c.meta.leaseInfo?.occupiedEnd | date:'shortDate'}}</td>
    <td>{{c.meta.leaseInfo?.leaseCategory}}</td>
    <td class="text-right">{{c.periods[tgtPeriodIndex].origFpRate | number:'0.2-2' }}</td>
    <td class="text-right">{{c.periods[tgtPeriodIndex].aggs.sum?.origFpRate | number: '0.2-2'}}</td>
    <td class="text-right">{{c.periods[tgtPeriodIndex].origAvgRate | number: '0.2-2'}}</td>
    <td class="text-right" (click)="null" [tccStopPropagation]="'click'">
      <input #offerElem #thisInput="ngModel" *ngIf="!(isReadOnly$ | async); else readOnly" type="text"
             class="text-right input-subtle"
             [ngModel]="c.periods[tgtPeriodIndex].estRenewalRate | number: '0.2-2'"
             (ngModelChange)="thisInput.valid ? updateUnitEstimate(c, $event) : undefined"
             (blur)="updateUnitEstimateForce(c.revAreaId)"
             pattern="^\s*\$?\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*$"
             [ngClass]="{'is-invalid': thisInput.invalid, 'bg-light': !thisInput.invalid }"
             [appKeyboardDirectionalEvents]="'ArrowUp, ArrowDown, Enter'" (directionalEvent)="onDirectionEvent($event)"
             tccSelectOnFocus />
      <ng-template #readOnly>
        {{c.periods[tgtPeriodIndex].estRenewalRate | number: '0.2-2'}}
      </ng-template>
    </td>
    <td class="text-right">{{c.periods[tgtPeriodIndex].estRenewalRateVsOrigFpPct | percent: '0.2-2'}}</td>
    <td class="text-right">{{c.periods[tgtPeriodIndex].estRenewalRateVsOrigAvgRatePct | percent: '0.2-2'}}</td>
    </ng-container>
  </tr>
  <tr *ngFor="let a of unitTypeAggCategories"
      [ngClass]="even ? 'table-primary' : 'table-info'" class="font-weight-bold">
    <td colspan="6">{{ut.displayName}} {{a.label}}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.origFpRate | number:'0.2-2' }}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.origAmenityFpRate | number: '0.2-2'}}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.origAvgRate | number: '0.2-2'}}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.estRenewalRate | number: '0.2-2'}}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.estRenewalRateVsOrigFpPct | percent: '0.2-2'}}</td>
    <td class="text-right">{{ut.periods[tgtPeriodIndex].aggs[a.aggType]?.estRenewalRateVsOrigAvgRatePct | percent: '0.2-2'}}</td>
  </tr>
</ng-container>
</ng-template>
