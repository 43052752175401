import { Injectable } from '@angular/core';
import { concat, Observable, Subject } from 'rxjs';
import { bufferTime, concatMap, filter, map, retry, shareReplay, tap } from 'rxjs/operators';
import { ClientApi, LedgerEntry, ReopenRequest, Snapshot, WorkflowEvent, WorkflowEventType } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';
import { tapError } from '../shared/tap-error-operator';
import { groupWith, sortBy } from 'ramda';

export interface IGetLedgerOptions {
  viewLedgerId?: number;
  snapshotId?: number;
  minAppliedDate?: Date;
  maxAppliedDate?: Date;
}

interface BatchedEntry {
  orgId: number;
  ledgerId: number;
  viewId: number;
  entry: LedgerEntry;
  subject: Subject<LedgerEntry>;
}

/** This service needs to be broken up, so does the server side too. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private entrySaveStartsSubject = new Subject<BatchedEntry>();

  /** number of Milliseconds to wait to update a batch of ledger entires */
  ledgerEntryBatchDelay = 1000;

  /** gets an array of all organizations */
  readonly orgs$ = this.clientApi.getOrganizations()
    .pipe(
      retry(3),
      map(x => x.sort(ArrayUtil.compareSelectorStringsFuncFactory(y => y.name, { ignoreCase: true }))),
      shareReplay(1)
    );

  /** Gets an Map of orgs keyed by org id */
  readonly orgIdMap$ = this.orgs$
    .pipe(
      map(orgs => new Map(orgs.map(x => [x.orgId, x]))),
      shareReplay(1)
    );

  /** Gets an Map of orgs keyed by org code */
  readonly orgCodeMap$ = this.orgs$
    .pipe(
      map(orgs => new Map(orgs.map(x => [x.orgCode, x]))),
      shareReplay(1)
    );

  constructor(private clientApi: ClientApi) {
    this.entrySaveStartsSubject.pipe(
      bufferTime(this.ledgerEntryBatchDelay),
      filter(x => x.length > 0),
      map(entries => {
        // groupWith needs items pre sorted
        const entriesSorted =  sortBy(x => `${x.orgId}-${x.ledgerId}-${x.viewId}`, entries);
        return groupWith((a, b) => a.orgId === b.orgId && a.viewId === b.viewId && a.ledgerId === b.ledgerId, entriesSorted);
      }),
      concatMap(batches => {
        return concat(...batches.map(batch => {
          // all entries should have the same orgId, ledgerId, viewId due to the chunking above.  Use the first for those ids.
          const { orgId, ledgerId, viewId } = batch[0];
          // only save the latest entries, assume the ones added last are the latest - that is why the batch is reversed.
          const latestEntries = batch
            .map(x => x.entry)
            .reverse()
            .reduce((acc, cur) => {
              if (!acc.some(x => x.accountCode === cur.accountCode && x.appliedOn.valueOf() === cur.appliedOn.valueOf()
                && x.entryType === cur.entryType)
              ) {
                acc.push(cur);
              }
              return acc;
            }, [] as LedgerEntry[])
            .reverse(); // preserve original order.
          return this.saveLedgerEntries(orgId, ledgerId, viewId, latestEntries).pipe(
            tap(() => batch.forEach(y => { // report all entries in batch as updated even though only some technically changed.
              y.entry.createdOn = new Date();
              y.subject.next(y.entry);
              y.subject.complete();
            })),
            tapError((err) => batch.forEach(x => { x.subject.error(err); x.subject.complete(); }))
          );
        }));
      })
    ).subscribe();
  }

  /** Exports the budget associated with the view. */
  exportBudget(orgId: number, ledgerId: number, viewId: number) {
    return this.clientApi.exportBudget({ ledgerId, orgId, viewId });
  }

  /**
   * gets organization with matching id or returns undefined
   * @param orgId id of organization to find
   */
  getOrganization(orgId: number) {
    return this.orgIdMap$.pipe(map(orgMap => orgMap.get(orgId)));
  }

  getLedger(orgId: number, ledgerId: number, opts?: IGetLedgerOptions) {
    if (opts == null) {
      opts = {};
    }
    return this.clientApi.getLedgerDetail(orgId, ledgerId, opts.snapshotId || undefined, opts.viewLedgerId || undefined,
      opts.minAppliedDate || undefined, opts.maxAppliedDate || undefined);
  }

  /** Gets all the workflow States for an OrgView */
  getWorkflowsStates(orgId: number, viewId: number) {
    return this.clientApi.getOrgViewWorkflowStates(orgId, viewId);

  }

  /** returns current entries for an account.  DOES NOT PERFORM CALCULATIONS */
  getCurrentAccountEntries(orgId: number, ledgerId: number, accountCode: string, year?: number) {
    const dates = this.yearToDates(year);
    return this.clientApi.getCurrentAccountEntries(orgId, ledgerId, accountCode,
      dates.minDate, dates.maxDate);
  }

  /** gets entries for a possibly calculated or parent account */
  getCalculatedAccountEntries(orgId: number, ledgerId: number, accountCode: string, year?: number, viewLedgerId?: number) {

    const dates = this.yearToDates(year);
    let obs: Observable<LedgerEntry[]>;
    if (!viewLedgerId) {
      obs = this.clientApi.getCalculatedLedgerEntries(
        orgId, ledgerId, accountCode, dates.minDate, dates.maxDate);
    }
    else {
      obs = this.clientApi.getCalculatedViewEntries(
        orgId, ledgerId, viewLedgerId, accountCode, dates.minDate, dates.maxDate);
    }
    return obs;
  }

  /** gets all snapshots created for organization and ledger */
  getSnapshots(orgId: number, ledgerId: number, viewId: number) {
    return this.clientApi.getViewSnapshots(orgId, ledgerId, viewId);
  }

  getUserActions(orgId: number, viewId: number) {
    return this.clientApi.getCurrentUserActions(orgId, viewId);
  }

  /** reopens a workflow at a particular step. in the future we'll have to specifiy ledger? */
  reopenWorkflow(orgId: number, ledgerId: number, viewId: number, workflowId: number, stepId: number) {
    const reopenRequest: ReopenRequest = { workflowId, stepId };
    return this.clientApi.reopenWorkflow(orgId, ledgerId, viewId, reopenRequest);
  }

  /** saves a ledger entry without batching it */
  saveLedgerEntryNow(orgId: number, ledgerId: number, viewId: number, entry: LedgerEntry) {
    return this.clientApi.addLedgerEntry(orgId, ledgerId, viewId, entry);
  }

  /**  Does not require view to save ledger entries, but user must be super user  */
  saveLedgerEntiresSuperUser(orgId: number, ledgerId: number, entries: LedgerEntry[]) {
    return this.clientApi.addLedgerEntriesSuperUser(orgId, ledgerId, entries);
  }
  /** Saves a group of entries in one go */
  saveLedgerEntries(orgId: number, ledgerId: number, viewId: number, entries: LedgerEntry[]) {
    return this.clientApi.addLedgerEntries(orgId, ledgerId, viewId, entries);
  }

  /**
   * adds leger entry to batch to be updated in time specified in ledgerEntryBatchDelay
   * @returns promise that is executed when batch completes
   */
  saveLedgerEntry(orgId: number, ledgerId: number, viewId: number, entry: LedgerEntry) {
    const resultSubj = new Subject<LedgerEntry>();
    const batchEntry: BatchedEntry = { orgId, ledgerId, viewId, entry, subject: resultSubj };
    this.entrySaveStartsSubject.next(batchEntry);
    return resultSubj.asObservable();

  }

  /** Saves a snapshot a returns a new one if succesful */
  saveSnapshot(orgId: number, ledgerId: number, description: string) {
    const snapshot: Snapshot = { description, snapshotId: 0 };
    return this.clientApi.postSnaphshot(orgId, ledgerId, snapshot);
  }

  saveWorkflowEvent(orgId: number, ledgerId: number, viewId: number, action: WorkflowEventType, comments?: string) {
    const wfEvent: WorkflowEvent = { action, comments };

    return this.clientApi.addWorkflowEvent(orgId, ledgerId, viewId, wfEvent);
  }

  /** converts year to min and max dates.  If year is null then dates are undefined */
  private yearToDates(year?: number) {
    const result: { minDate?: Date, maxDate?: Date } = {};
    if (year != null) {
      result.minDate = new Date(Date.UTC(year, 0, 1));
      result.maxDate = new Date(Date.UTC(year + 1, 0, 1));
    }
    return result;
  }
}
