import { Injectable } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { map, mergeMap, retry, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AccessMatrixItem, ClientApi, RequestAccessForm, UserStateInfo } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  /** TODO:  This shouldn't be hardcoded - remove this... someday. */
  static readonly roles = {
    superApprover: 'Super Approver',
    executiveApprover: 'Executive Approver',
    regionalApprover: 'Regional Approver',
    communityApprover: 'Community Approver',
    reader: 'Reader'
  };

  private readonly currentUserSource = this.clientApi.getCurrentUser().pipe(retry(3));

  private readonly currentUserRefresh = new Subject<void>();

  private readonly usersSource = this.clientApi.getUsers()
    .pipe(
      retry(3),
      map(roles => roles.sort((a, b) => ArrayUtil.comparerFuncGeneric(a.displayName, b.displayName)))
    );

  private readonly usersRefersh = new Subject<void>();

  /**
   * Gets information about the current user.
   */
  readonly currentUser$ = merge(this.currentUserSource, this.currentUserRefresh.pipe(switchMap(() => this.currentUserSource)))
    .pipe(shareReplay(1));

  /** Gets a collection of all possible roles. */
  readonly roles$ = this.clientApi.getRoles()
    .pipe(
      retry(3),
      map(roles => roles.sort((a, b) => ArrayUtil.comparerFuncGeneric(a.name, b.name))),
      shareReplay(1)
    );


  /** Gets a collection of all possible roles mapped by id. */
  readonly roleIdMap$ = this.roles$
    .pipe(
      map(roles => new Map(roles.map(x => [x.roleId, x]))),
      shareReplay(1)
    );

  /** Gets all user */
  readonly users$ = merge(this.usersSource, this.usersRefersh.pipe(switchMap(() => this.usersSource)))
    .pipe(shareReplay(1));

  readonly userIdMap$ = this.users$
    .pipe(
      map(roles => new Map(roles.map(x => [x.userId!, x]))),
      shareReplay(1)
    );



  constructor(private clientApi: ClientApi) {
  }


  isUserInRoles(roles: string[], orgId: number, viewId?: number) {

    let user: UserStateInfo;
    return this.currentUser$
      .pipe(
        map(u => user = u),
        mergeMap(() => this.roleIdMap$),
        map(rolesMap =>
          user.accessMatrix.items?.some(i =>
            i.orgId === orgId
            && ((viewId === undefined) ? true : i.viewId === viewId)
            && roles.indexOf(rolesMap.get(i.roleId)!.name!) > -1)
        )
      );
  }


  /**
      * Gets a specific user by id
      * @params userId of user to get
      * @returns a promise that resolves the user
      */
  getUserSummary(userId: number) {
    return this.userIdMap$.pipe(map(userMap => userMap.get(userId)));
  }

  /**
   * gets user info with access information
   * @param userId
   */
  getUserDetail(userId: number) {
    return this.clientApi.getUserDetail(userId);
  }


  updateAccessMatrix(userId: number, accessMatrixItems: AccessMatrixItem[]) {
    return this.clientApi.updateAccessMatrix(userId, { items: accessMatrixItems })
      .pipe(tap(() => this.currentUserRefresh.next()));
  }

  addUser(user: { displayName: string, email: string }) {
    return this.clientApi.syncUser(user)
      .pipe(tap(() => {
        // invalidate cached user data
        this.currentUserRefresh.next();
        this.usersRefersh.next();
      }));
  }

  requestRoleAccess(userId: number, requestAccessForm: RequestAccessForm) {
    return this.clientApi.requestRoleAccess(userId, requestAccessForm)
      .pipe(tap((x) => console.log(x)));
  }
}
