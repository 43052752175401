import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FlagItemChoice, FLAG_ITEMS } from '../flags';

const EMPTY_FLAG: FlagItemChoice = {
  description: 'Click to flag item',
  dropdownClass: 'fas fa-fw fa-eraser text-muted ',
  dropdownDescription: 'Clear',
  iconClass: 'fas fa-fw fa-ellipsis-v text-dark',
  isEmptyChoice: true,
  key: '_clear'
};
@Component({
  selector: 'app-flag-item',
  templateUrl: './flag-item.component.html',
  styles: [`
/** rig to hide ngDropdownAnchor.  Would get errors without it. */
.dropdown-toggle::after{
  display: none;
}
`]
})
export class FlagItemComponent {

  /** The key of the currently selected flag */
  @Input()
  flag?: string;

  /** emits when the key changes */
  @Output()
  flagChange = new EventEmitter<string>();

  /** flag choices. The first choice is always*/
  @Input()
  readonly flags: FlagItemChoice[] = [...FLAG_ITEMS, EMPTY_FLAG ];

  constructor() { }

  /** gets the choice for a key.  If no choice is matched returns the emptyChoice. */
  getChoice(key: string) {
    let choice: FlagItemChoice | undefined;
    if (key) {
      choice = this.flags.find(x => x.key === key)!;
    }
    return choice ?? this.flags.find(x => x.isEmptyChoice) ?? EMPTY_FLAG;
  }

  /** sets flag from choice. */
  setChoice(choice: FlagItemChoice | undefined) {
    const flag = (!choice || choice.isEmptyChoice) ? undefined : choice.key;
    if (flag !== this.flag) {
      this.flag = flag;
      this.flagChange.emit(flag);
    }
  }

  /** returns true if choice should be displayed. */
  showChoice(choice: FlagItemChoice) {
    return !choice.isEmptyChoice || this.flag || false;
  }
}
