import { Subject } from 'rxjs';
import { LedgerAccountNode, LedgerDetail, LedgerEntry, LedgerEntryType } from '../client-api.service';
import { AccountEntriesNode, AmountChangedArgs, IAccountEntriesNodeMap } from './account-entries-node';
import { AmountChangeCommand } from './amount-change-command';


/**
  * Ledger Detail from rest service.
  * In the future I would like to tear this down just have an array of accounts and linked dependencies.
  */
export class LedgerTree extends AccountEntriesNode {

  /** retains a history of all entry amount changes */
  accountHistoryMap = new Map<string, LedgerEntry[]>();

  /** a flat list of all accounts in the tree */
  accountMap: IAccountEntriesNodeMap = {};


  get editableAmountChanges() {
    return this.editableAmountChangesSubject.asObservable();
  }

  /** the months that correspond to amount Indexes in entries */
  months?: Date[];

  private accountDependents: Map<string, string[]>;
  private editableAmountChangesSubject = new Subject<AmountChangeCommand>();

  /** Sorts by Sort Index first then account codes */
  private static ledgerAccountNodeSorter(a: LedgerAccountNode, b: LedgerAccountNode): number {
    if (a.sortIndex !== b.sortIndex) {
      const sortIndex = a.sortIndex - b.sortIndex;
      if (sortIndex !== 0) {
        return sortIndex;
      }
    }

    return (a.accountCode! > b.accountCode!) ? 1 : (a.accountCode! < b.accountCode!) ? -1 : 0;
  }

  /**
   * Initializes Ledger Tree
   * @param ledger The source ledger detail
   * @param ledgerYear The year of the ledger
   * @param amountsFromDefaultEntriesOnly if true, only entries with entry type Default be used for month amounts
   * @param entrylessChildrenDontRecalcParent if true parent nodes without child entries aren't automatically recalculated.
   * This is incase there are overriden amounts on a parent.
   */
  constructor(
    public ledger: LedgerDetail, 
    public ledgerYear: number, 
    private amountsFromDefaultEntriesOnly: boolean,
    private entrylessChildrenDontRecalcParent: boolean
  ) {
    super(undefined);

    this.displayName = ledger.name!;

    this.root = this;
    this.initMonths(ledgerYear);
    this.accountDependents = new Map<string, string[]>();
    this.populateChildAccounts(ledger.accountTree.children!, this);

    for (const accountCode in this.accountMap) {
      if (this.accountMap[accountCode].hasCalculation) {
        this.accountMap[accountCode].recalculateAll();
      }
    }
  }

  /** Creates months for the ledgerYear */
  private initMonths(ledgerYear: number) {
    this.months = [];
    for (let i = 0; i < 12; i++) {
      this.months.push(new Date(ledgerYear, i, 1));
    }
  }

  /** Transform children to AccountEntriesNodes and adds them to parent. */
  private populateChildAccounts(children: LedgerAccountNode[], parentEntry: AccountEntriesNode) {
    children.sort(LedgerTree.ledgerAccountNodeSorter).forEach(c => {
      this.createEntryNode(c, parentEntry);
    });
  }

  /** Creates an AccountEntriesNode from an ILedgerAccountNode. */
  private createEntryNode(account: LedgerAccountNode, parentEntry: AccountEntriesNode) {
    const entryNode = new AccountEntriesNode(account);
    parentEntry.addChild(entryNode);

    if (account.children && account.children.length > 0) {
      if (this.entrylessChildrenDontRecalcParent) {
        // add parent amounts only if children might not recalculate
        this.createEntryNodeLoadValues(entryNode);
      }
      this.populateChildAccounts(account.children, entryNode);
    } else if (!account.calculationExpression) {
      this.createEntryNodeLoadValues(entryNode);
    }
    return entryNode;
  }

  /** Loads values onto newly created entry node */
  private createEntryNodeLoadValues(entryNode: AccountEntriesNode) {
    const accountEntries = this.ledger.ledgerEntries!.filter((e) => e.accountCode === entryNode.accountCode);

    this.accountHistoryMap.set(entryNode.accountCode!, accountEntries);
    this.months?.forEach((m, i) => {
      // filter entries to month, keeping only Default entries if amountsFromDefaultEntriesOnly is true.
      const monthEntries = accountEntries.filter((e) => e.appliedOn.valueOf() === m.valueOf()
        && (!this.amountsFromDefaultEntriesOnly || e.entryType === LedgerEntryType.Default));
      if (monthEntries.length > 0) {
        const maxAmount = monthEntries.sort((a, b) => b.ledgerEntryId - a.ledgerEntryId)[0];
        entryNode.setAmount(i, maxAmount.amount * entryNode.negationFactor);
      } else {
        entryNode.setAmount(i, 0);
      }
    });
  }
  /**
      * Returns a list of accounts that are filtered by filter parameter
      */
  filterAccounts(filter: (account: AccountEntriesNode) => boolean): AccountEntriesNode[] {
    return Object.keys(this.accountMap)
      .map(acctCode => this.accountMap[acctCode])
      .filter(acct => acct && filter(acct));

  }

  /**
      * DO NOT CALL DIRECTLY
      * Called by accountNode when a member is added so that it can be added to the accountMap and
      * listeners are set that are called when node is updated.
      * @param entryTreeMember member that was added
      */
  registerTreeMember(node: AccountEntriesNode) {
    node.root = this;

    // add to global account map
    this.accountMap[node.accountCode!] = node;

    // if any nodes depend on this for calculation then watch then create watches
    const dependentNodes = this.filterAccounts((i) => i.calcExpr != null 
      && i.calcExpr.tokens.some(t => t.type === 'variable' && t.text === node.accountCode))
      .map(x => x.accountCode)
      .filter((x): x is string => !!x);

    if (!node.isIgnoredInTotals && node.parent && node.parent.accountCode) {
      dependentNodes.push(node.parent.accountCode);
    }
    if (dependentNodes.length !== 0) {
      (this.accountDependents.get(node.accountCode!)! || this.accountDependents.set(node.accountCode!, []).get(node.accountCode!))
        .push(...dependentNodes);
    }

    if (node.calcExpr != null) {
      const requiredAccountCodes = node.calcExpr.tokens.filter(t => t.type === 'variable').map(t => t.text);
      for (const reqAcctCode of requiredAccountCodes) {
        (this.accountDependents.get(reqAcctCode)! || this.accountDependents.set(reqAcctCode, []).get(reqAcctCode))
          .push(node.accountCode!);
      }
    }

    node.amountChanges.subscribe(x => this.nodeMemberAmountChange(node, x));
  }



  /**
    * Calls destory for all accountEntiriesNodes and completes any observables
    */
  destroy() {
    super.destroy();
    this.editableAmountChangesSubject.complete();
  }

  /** recalculates depedent amts and notifies and observables*/
  private nodeMemberAmountChange(node: AccountEntriesNode, amountInfo: AmountChangedArgs) {

    const dependentAcctCodes = this.accountDependents.get(node.accountCode!);
    if (dependentAcctCodes && dependentAcctCodes.length !== 0) {

      if (!this.entrylessChildrenDontRecalcParent || amountInfo.newValue) {
        // recalculate all dependents
        for (const depAcctCode of dependentAcctCodes) {
          this.accountMap[depAcctCode].recalculate(amountInfo.amountIndex);
        }
      }
      else {
        // dont recalculate dependents if they are parent, and all children have 0 amounts
        for (const depAcctCode of dependentAcctCodes) {
          const depAcct = this.accountMap[depAcctCode];
          if (node.parent !== depAcct || depAcct.children.some(x => x.amounts.some(y => !!y))) {
            depAcct.recalculate(amountInfo.amountIndex);
          }
        }
      }
    }

    if (node.hasEditableAmounts) {
      this.editableAmountChangesSubject.next({ accountCode: node.accountCode!, ...amountInfo });
    }
  }

}
