import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type BatchOperation = 'normal' | 'rollback' | 'redo';
export type CommandStatus = 'canceled' | 'enqueued' | 'executed' | 'failed';

export interface CommandBatch<TCommandInfo> {
  batchId: number;
  commands: TCommandInfo[];
  batchType: BatchOperation;
}

export interface CommandBatchStatusChange<TCommandInfo> extends CommandBatch<TCommandInfo> {
  status: CommandStatus;
  message?: string;
}

export class CommandManager<TCommandInfo> {

  private static batchIdSeed = 0;

  private commandQueue: CommandBatch<TCommandInfo>[] = [];
  private executionHistory: CommandBatch<TCommandInfo>[] = [];
  private rollbackHistory: CommandBatch<TCommandInfo>[] = [];

  private statusChangeSubject = new Subject<CommandBatchStatusChange<TCommandInfo>>();

  maxHistorySize = 100;

  /** All batch status change events */
  readonly batchStatusChange$ = this.statusChangeSubject.asObservable();
  
  /** batchStatusChange$ with filter applied so only canceled batches are returned */
  readonly canceled$ = this.statusChangeSubject.pipe(filter(x => x.status === 'canceled'));

  /** batchStatusChange$ with filter applied so only batches queued for execution are returned */
  readonly enqueued$ = this.statusChangeSubject.pipe(filter(x => x.status === 'enqueued'));

  /** batchStatusChange$ with filter applied so only executed batches are returned */
  readonly executed$ = this.statusChangeSubject.pipe(filter(x => x.status === 'executed'));

  /** batchStatusChange$ with filter applied so only failed batches are returned */
  readonly failed$ = this.statusChangeSubject.pipe(filter(x => x.status === 'failed'));

  canRollback$ = this.batchStatusChange$.pipe(map(() => this.canRollback));
  canRedo$= this.batchStatusChange$.pipe(map(() => this.canRedo));

  previousCommand$ = this.batchStatusChange$.pipe(map(() => this.previousCommand));

  /** Returns true if there are items that can be undone */
  get canRollback() {
    return this.executionHistory.length > 0;
  }

  /** Returns true if there are items in that can be redone */
  get canRedo() {
    return this.rollbackHistory.length > 0;
  }

  get previousCommand() {
    return this.rollbackHistory;
  } 

  /** amount of items in queue */
  get queueSize() {
    return this.commandQueue.length;
  }

  clear() {
    this.commandQueue.length = 0;
    this.executionHistory.length = 0;
    this.rollbackHistory.length = 0;
  }

  /**
   * removed a queued batch and fired cancelled event.  If it was already removed then nothing will occur.
   */
  cancel(batchOrBatchId: CommandBatch<TCommandInfo> | number, message?: string) {
    const batchIndex = this.getQueuedBatchIndexByBatchId(batchOrBatchId);
    if (batchIndex !== -1) {
      const batch = this.commandQueue.splice(batchIndex, 1)[0];
      this.statusChangeSubject.next({ ...batch, status: 'canceled', message: message });
    }
  }

  /** Removes a queued batch and adds it to execuion history. */
  executed(batchOrBatchId: CommandBatch<TCommandInfo> | number) {
    const batchIndex = this.getQueuedBatchIndexByBatchId(batchOrBatchId);
    if (batchIndex === -1) {
      throw new Error('Batch not found in queue.');
    }
    const batch = this.commandQueue.splice(batchIndex, 1)[0];
    return this.executedInternal(batch);
  }

  /** Creates a copy of commands in the command queue. */
  getQueuedCommands() {
    return [ ...this.commandQueue ];
  }

  /** Add command(s) and stores it for execution */
  enqueue(command: TCommandInfo): CommandBatch<TCommandInfo>;
  enqueue(commands: TCommandInfo[]): CommandBatch<TCommandInfo> | undefined;
  enqueue(commandOrCommands: TCommandInfo | TCommandInfo[]): CommandBatch<TCommandInfo> | undefined {
    const batch = this.batchify(commandOrCommands);
    if (batch.commands.length > 0) {
      return this.queueInternal(batch);
    }
    else {
      return undefined;
    }
  }

  /** Makes sure command batch is removed from command queue.
   * Use this in Obs->finalize when not concerned about correctness of command history. */
  ensureDequeued(batchOrBatchId: CommandBatch<TCommandInfo> | number) {
    const batchIndex = this.getQueuedBatchIndexByBatchId(batchOrBatchId);
    if (batchIndex !== -1) {
      this.commandQueue.splice(batchIndex, 1);
    }
  }

  /** removes queued batch and fires a failed event.  */
  failed(batchOrBatchId: CommandBatch<TCommandInfo> | number, message?: string) {
    const batchIndex = this.getQueuedBatchIndexByBatchId(batchOrBatchId);
    const batch = (batchIndex !== -1) ? this.commandQueue.splice(batchIndex, 1)[0] : this.batchify([]);
    this.statusChangeSubject.next({ ...batch, status: 'failed', message: message });
  }

  /** Begins a rollback of the last batch executed by removing it from execution history adding to the queue */
  rollback() {
    console.log('we want to rollback!');
    console.log(this);
    if (!this.canRollback) {
      return undefined;
    }

    const srcBatch = this.executionHistory.pop();
    const batch: CommandBatch<TCommandInfo> = { ...srcBatch, batchType: 'rollback' } as CommandBatch<TCommandInfo>;
    return this.queueInternal(batch);
  }

  /** Begins a redo of the last rolledback batch by removing it from rollback history and adding it to the queue */
  redo() {
    console.log('we want to redo!');
    console.log(this);
    if (!this.canRedo) {
      return undefined;
    }

    const srcBatch = this.rollbackHistory.pop();
    const batch: CommandBatch<TCommandInfo> = { ...srcBatch, batchType: 'redo' } as CommandBatch<TCommandInfo>;
    return this.queueInternal(batch);
  }

  /** Converts a commandOrBatch parameter to a batch */
  private batchify(commandOrCommands: TCommandInfo | TCommandInfo[]): CommandBatch<TCommandInfo> {
    if (!commandOrCommands) {
      throw new Error('command cannot be null or undefined.');
    }

    const commands = (commandOrCommands instanceof Array) ? commandOrCommands : [commandOrCommands];

    return {
      batchId: CommandManager.batchIdSeed++,
      batchType: 'normal',
      commands: commands
    };
  }

  /** Common behavior for executed batches */
  private executedInternal(batch: CommandBatch<TCommandInfo>) {
    const clearRollbackHistory = (batch.batchType === 'normal');
    const addToExecutionHistory = (batch.batchType !== 'rollback');
    const addToRollbackHistory = (batch.batchType === 'rollback');

    if (clearRollbackHistory) {
      this.rollbackHistory.length = 0;
    }

    if (addToRollbackHistory) {
      this.rollbackHistory.push(batch);
    }

    if (addToExecutionHistory) {
      this.executionHistory.push(batch);
      if (this.executionHistory.length > this.maxHistorySize) {
        // remove oldest items from history if limit is exceeded
        const excess = this.executionHistory.length - this.maxHistorySize;
        this.executionHistory.splice(0, excess);
      }
    }

    this.statusChangeSubject.next({ ...batch, status: 'executed' });

    return batch;
  }

  private getQueuedBatchIndexByBatchId(batchOrBatchId: CommandBatch<TCommandInfo> | number) {
    const batchId = (typeof batchOrBatchId === 'number') ? batchOrBatchId : batchOrBatchId.batchId;
    const batchIndex = this.commandQueue.findIndex(x => x.batchId === batchId);
    return batchIndex;
  }

  private queueInternal(batch: CommandBatch<TCommandInfo>) {
    // kill rollback history if a normal command has been queued
    if (batch.batchType === 'normal') {
      this.rollbackHistory.length = 0;
    }
    this.commandQueue.push(batch);
    this.statusChangeSubject.next({ ...batch, status: 'enqueued' });

    return batch;
  }
}

