import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { WorkflowEventType } from '../client-api.service';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { OrganizationService } from '../core-services/organization.service';
import { MenuTrayStateService, TrayRequest } from '../menu-tray/menu-tray-state.service';
import { tapError } from '../shared/tap-error-operator';

export interface WorkflowManagementComponentOptions {
  workflowMgmtMode?: WorkflowEventType;
}
@Component({
  selector: 'workflow-management',
  templateUrl: './workflow-management.component.html',
  styles: ['span.instructions { display:inline-block; margin:2px 0 0 5px }']
})

export class WorkflowManagementComponent implements OnInit, OnDestroy {

  action?: WorkflowEventType;

  comment = '';

  get commentPlaceHolderText() {
    return (!this.isCommentRequired)
      ? 'Please enter an optional comment'
      : `Please describe your submission (minimum ${this.minCommentLength} characters)`;
  }
  /** true if for some reason currentStep is null or if requireCommentOnSubmit is true on current step */
  get isCommentRequired() {
    return !this.ledgerState.currentStep || this.ledgerState.currentStep.requireCommentOnSubmit;
  }

  get accountChangeCommentsMissing() {
    return Array.from(this.ledgerState.accountCodesRequiringComment.values()).some(x => x);
  }
  /** true if isCommentRequired is false, or comment length is > minCommentLength */
  get isCommentValid() {
    return !this.isCommentRequired || (this.comment != null && this.comment.length >= this.minCommentLength);
  }

  /** the minmium length for a comment */
  minCommentLength = 20;

  /** state of compoment */
  state: 'loading' | 'ready' = 'loading';

  private subsMgr = new SubsManager();

  constructor(private ledgerState: LedgerStateService, private menuTrayState: MenuTrayStateService, private notifSvc: NotificationsService,
    private orgSvc: OrganizationService, private router: Router) {

  }

  ngOnInit() {
    this.state = 'ready';
    this.subsMgr.addSub = this.menuTrayState.trayChanges$.pipe(
      filter((x): x is TrayRequest<WorkflowManagementComponentOptions> => x.trayId === 'WorkflowManagement'),
      tap((x) => this.action = x.options?.workflowMgmtMode)
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }
  saveEvent() {
    this.state = 'loading';
    const originalReadOnly = this.ledgerState.readOnly;
    const originalReadOnlyAmounts = this.ledgerState.readOnlyAmounts!;
    this.ledgerState.readOnly = true;
    this.ledgerState.readOnlyAmounts = true;
    this.orgSvc.saveWorkflowEvent(this.ledgerState.organization!.orgId, this.ledgerState.ledgerSummary!.ledgerId,
      this.ledgerState.view!.viewId, this.action!, this.comment)
      .pipe(
        tap(x => console.log(x)),
        map(() => this.showPostSubmitMessage()),
        tapError((err) => this.notifSvc.addError(err)),
        finalize(() => {
          this.ledgerState.readOnly = originalReadOnly;
          this.ledgerState.readOnly = originalReadOnlyAmounts;
          this.state = 'ready';
        })
      )
      .subscribe();
  }

  private showPostSubmitMessage() {
    let title: string;
    let message: string;
    if (this.action === WorkflowEventType.Approve) {
      title = 'Approval Complete';
      message = 'Your changes have been sent to the next step in the process';
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      title = 'Rejection Submitted';
      message = 'Your changes have been sent to the previous step.';
    }

    this.notifSvc.addSuccess(message);
    this.router.navigate(['./home']);
  }
}


