import { Injectable } from '@angular/core';
import { PayrollStateService } from './payroll-state.service';
import { of, throwError } from 'rxjs';
import { PayrollEmployeeModel, PayrollEmployeeModelUtil } from './payroll-employee';
import { MathAction } from '../controls/math-menu/math-action';

type PayrollFunc = (emp: PayrollEmployeeModel, amt: number) => PayrollEmployeeModel;

/** All functions work on the currently selected employee. */
@Injectable({ providedIn: 'root' })
export class PayrollWageMathService {

  readonly actions: MathAction[] = [
    {
      name: 'Add',
      func: (expr) => this.addFromCurrent(expr),
      description: 'Add amount to current wages and set proposed',
      iconTemplate: `<i class='fa fa-plus'></i>`,
      requiresValue: true
    },
    {
      name: 'Subtract',
      func: (expr) => this.subtractFromCurrent(expr),
      description: 'Subtract amount from current wages and set proposed.',
      iconTemplate: `<i class='fa fa-minus'></i>`,
      requiresValue: true
    },
    {
      name: 'Multiply',
      func: (expr) => this.multiplyFromCurrent(expr),
      description: 'Multiply current wages by amount and set proposed',
      iconTemplate: `<i class='fa fa-times'></i>`,
      requiresValue: true
    },
    {
      name: 'Divide',
      func: (expr) => this.divideFromCurrent(expr),
      description: 'Divide current wages by amount and set proposed',
      iconTemplate: `<span class='font-icon'>&#xf7;</span>`,
      requiresValue: true
    },
  ];


  constructor(private payrollState: PayrollStateService) { }


  /** Calls applyFunc with a function that adds to each entry the resolved amount */
  addFromCurrent(expr: string) {
    const func: PayrollFunc = (emp, amt) =>
      this.updateProposedPayTypeFromCurrent(emp, this.payrollState.basePayType!.payTypeId!, curAmt => curAmt + amt);
    return this.applyFunc(func, expr);
  }

  /** Calls applyFunc with a function that subtracts each entry by the resovled amt */
  subtractFromCurrent(expr: string) {
    const func: PayrollFunc = (emp, amt) =>
      this.updateProposedPayTypeFromCurrent(emp, this.payrollState.basePayType!.payTypeId!, curAmt => curAmt - amt);
    return this.applyFunc(func, expr);
  }
  /** Calls applyFunc with a function that multiplies each entry by the resolved amt */
  multiplyFromCurrent(expr: string) {
    const func: PayrollFunc = (emp, amt) =>
      this.updateProposedPayTypeFromCurrent(emp, this.payrollState.basePayType!.payTypeId!, curAmt => curAmt * amt);
    return this.applyFunc(func, expr);
  }

  /** Calls applyFunc with a function that divides each entry by the resolved amount */
  divideFromCurrent(expr: string) {
    const func: PayrollFunc = (emp, amt) =>
      this.updateProposedPayTypeFromCurrent(emp, this.payrollState.basePayType!.payTypeId!, curAmt => curAmt / amt);
    return this.applyFunc(func, expr);
  }

  /** calls applyFunc on ledger controller, passing the current applyToExpression */
  private applyFunc(func: PayrollFunc, applyToExpression: string) {
    if (applyToExpression != null && this.payrollState.selectedEmployee != null) {
      try {
        const value = parseFloat(applyToExpression);
        const updatedEmp = func(this.payrollState.selectedEmployee, value);
        this.payrollState.employeeUpdate(updatedEmp);
        return of(undefined);
      }
      catch (err) {
        return throwError(err);
      }
    }

    return of(undefined);
  }

  private updateProposedPayTypeFromCurrent(emp: PayrollEmployeeModel, payTypeId: number, transformFunc: (current: number) => number) {
    const updatedEmp = PayrollEmployeeModelUtil.DeepClone(emp);
    const payTypeAmounts = updatedEmp.payItemAmounts[payTypeId];
    const result = transformFunc(payTypeAmounts.currentAmount);
    if (result == null || isNaN(result)) {
      throw new Error('Result was not a valid amount');
    }
    payTypeAmounts.proposedAmount = Math.round(result);
    return updatedEmp;
  }
}
