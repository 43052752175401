import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AUTH_CONFIG, AppInsightsModule, AppInsightsTelemtryItem, AuthModule, TccUiModule } from '@tcc/ui';
import { environment } from '../environments/environment';
import { AccessMatrixComponent } from './admin/access-matrix.component';
import { BatchExportComponent } from './admin/batch-export.component';
import { ExportQueueComponent } from './admin/export-queue.component';
import { TargetedEditComponent } from './admin/targeted-edit.component';
import { UserAdminComponent } from './admin/user-admin.component';
import { WorkflowAddComponent } from './admin/workflow-add/workflow-add.component';
import { WorkflowAdminComponent } from './admin/workflow-admin.component';
import { AppErrorComponent } from './app-error.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterCalcComponent } from './calculations/master-calc.component';
import { API_BASE_URL, ClientApi } from './client-api.service';
import { CommandHistoryComponent } from './commands/command-history.component';
import { CommentsModule } from './comments/comments.module';
import { ControlsModule } from './controls/controls.module';
import { DirectivesModule } from './directives/directives.module';
import { AccountHistoryComponent } from './ledger-menu/account-history.component';
import { LedgerMenuComponent } from './ledger-menu/ledger-menu.component';
import { ManualExportComponent } from './ledger-menu/manual-export.component';
import { WorkflowManagementComponent } from './ledger-menu/workflow-management.component';
import { ComparisonMenuSectionComponent } from './ledgers/comparisons/comparison-menu-section/comparison-menu-section.component';
import { HomeComponent } from './ledgers/home.component';
import { LedgerAccountTitleComponent } from './ledgers/ledger-account-title.component';
import { LedgerAccountComponent } from './ledgers/ledger-account.component';
import { LedgerComponent } from './ledgers/ledger.component';
import { MenuTrayModule } from './menu-tray/menu-tray.module';
import { PayrollEmployeeControlComponent } from './payroll/payroll-employee-control.component';
import { PayrollComponent } from './payroll/payroll.component';
import { ReportCubeDisplayComponent } from './reports/report-cube-display.component';
import { ReportsComponent } from './reports/reports.component';
import { RevenueModule } from './revenue/revenue.module';
import { DynamicNumberPipe } from './shared/dynamic-number.pipe';
import { GLOBAL, IGlobalSettings } from './shared/global-settings';
import { RequestAccessFormComponent } from './ledgers/request-access-form/request-access-form.component';
import { ConfigService } from './config.service';
import { FinancialPlanningMaintenanceMenuComponent } from './admin/maintenance/financial-planning-maintenance-menu.component';
import { FinancialPlanningInstallerComponent } from './admin/maintenance/installer/financial-planning-installer.component';
import { FinancialPlanningInstallerTrackingComponent } from './admin/maintenance/installer/financial-planning-installer-tracking.component';
import { ApplicationViewsComponent } from './admin/maintenance/ApplicationViews/application-views.component';
import { ApplicationViewsUpsertComponent } from './admin/maintenance/ApplicationViews/application-views-upsert.component';


export function aiTelemInitializer(env: AppInsightsTelemtryItem) {
  env.tags!['ai.cloud.role'] = 'Tcc.Fp.App';
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ControlsModule,
    DirectivesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    AppRoutingModule,
    TccUiModule.forRoot(),
    AppInsightsModule.forRoot(
      { instrumentationKey: environment.appInsightsId },
      [aiTelemInitializer]
    ),
    AuthModule.forRoot(),
    MenuTrayModule,
    CommentsModule,
    RevenueModule
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => () => configService.load(environment),
      multi: true,
    },
    {
      provide: API_BASE_URL,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) =>
        configService?.get('apiBaseUrl'),
    },
    {
      provide: AUTH_CONFIG,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) =>
        configService?.getAuthConfig()
    },
    ClientApi,
    {
      provide: GLOBAL,
      useValue: <IGlobalSettings>{
        actBudLedgerName: '2024 Act/Bud',
        budgetYear: 2025,
        defaultCalculationView: 'Full FP',
        fpLedgerName: '2025 Financial Plan',
        monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        payrollCashPayDaysPerMonth: [2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 3],
        payrollExemptSalaryThreshold: 23660,
        payrollSplitAccounts: ['548000', '548050', '548100', '548150', '511000.1', '548191', '548192', '548193', '548194',
          '548200', '548201', '548202', '548203', '549400'],
        specialAccounts: {
          gainLossToLease: '402220',
          gpr: '401100',
          vacancyLoss: '402210'
        }
      }
    }
  ],
  declarations: [
    AccessMatrixComponent,
    AccountHistoryComponent,
    AppComponent,
    AppErrorComponent,
    BatchExportComponent,
    DynamicNumberPipe,
    CommandHistoryComponent,
    ExportQueueComponent,
    HomeComponent,
    LedgerAccountComponent,
    LedgerAccountTitleComponent,
    LedgerComponent,
    LedgerMenuComponent,
    MasterCalcComponent,
    PayrollComponent,
    PayrollEmployeeControlComponent,
    ReportCubeDisplayComponent,
    ReportsComponent,
    TargetedEditComponent,
    UserAdminComponent,
    WorkflowAdminComponent,
    WorkflowManagementComponent,
    ManualExportComponent,
    ComparisonMenuSectionComponent,
    WorkflowAddComponent,
    RequestAccessFormComponent,
    FinancialPlanningMaintenanceMenuComponent,
    FinancialPlanningInstallerComponent,
    FinancialPlanningInstallerTrackingComponent,
    ApplicationViewsComponent,
    ApplicationViewsUpsertComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
