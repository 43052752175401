import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AppInsightsRef, AuthService, SubsManager } from '@tcc/ui';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { UserStateInfo } from './client-api.service';
import { UserService } from './core-services/user.service';


@Component({
  selector: 'app-root',
  template: `
<nav class="navbar navbar-fixed-top bg-gradient-collier text-white">
  <a class="navbar-brand">
    <img src="/assets/images/logo.png" />
    Financial Plan
  </a>
  <commandHistory></commandHistory>
  <tcc-user-status></tcc-user-status>
</nav>


<div class="contentPanel" #container style="position:relative">
  <simpleMenu [isMainMenu]="true" [isExpandable]="true" *ngIf="!hideNav">
    <button type="button" class="btn btn-sm btn-light" title="Home" routerLink="./home" routerLinkActive="active">
      <i class="fa fa-home fa-fw"></i><span class="ml-1 visible-expanded">Home</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Revenue Management" routerLink="./revenue" routerLinkActive="active">
      <i class="fas fa-dollar-sign fa-fw"></i><span class="ml-1 visible-expanded">Revenue Management</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Reports" routerLink="./reports" routerLinkActive="active">
      <i class="fa fa-file-alt fa-fw"></i><span class="ml-1 visible-expanded">Reports</span>
    </button>
    <span *ngIf="(userChangeSubject | async)?.isAdmin">
      <button type="button" class="btn btn-sm btn-light" title="Master Calculations" routerLink="./masterCalc" routerLinkActive="active">
        <i class="fa fa-calculator fa-fw"></i><span class="ml-1 visible-expanded">Master Calculations</span>
      </button>
      <button type="button" class="btn btn-sm btn-light" title="Targeted Edits" routerLink="./targetedEdit"  routerLinkActive="active">
        <i class="fa fa-bullseye fa-fw"></i><span class="ml-1 visible-expanded">Targeted Edits</span>
      </button>
      <button type="button" class="btn btn-sm btn-light" title="Batch Export" routerLink="./batchExport" routerLinkActive="active">
        <i class="fa fa-plane fa-fw"></i><span class="ml-1 visible-expanded">Batch Export</span>
      </button>
      <button type="button" class="btn btn-sm btn-light" title="User Access" routerLink="./accessMatrix" routerLinkActive="active">
        <i class="fa fa-user fa-fw"></i><span class="ml-1 visible-expanded">User Access</span>
      </button>
      <button type="button" class="btn btn-sm btn-light" title="Workflow Admin" routerLink="./workflowAdmin" routerLinkActive="active">
        <i class="fa fa-cog fa-fw"></i><span class="ml-1 visible-expanded">Workflow Admin</span>
      </button>
      <button type="button" class="btn btn-sm btn-light" title="Maintenance" routerLink="./maintenance" routerLinkActive="active">
        <i class="fa fa-wrench fa-fw"></i><span class="ml-1 visible-expanded">Maintenance</span>
      </button>
    </span>
  </simpleMenu>
  <router-outlet></router-outlet>
</div>

<tcc-preloader *ngIf="state !== 'ready'">Financial Plan</tcc-preloader>
<tcc-notifications [defaultDuration]="10000"></tcc-notifications>

`
})
export class AppComponent implements OnDestroy, OnInit {
  private subsMgr = new SubsManager();

  hideNav = false;
  state: 'loading' | 'ready' = 'loading';
  userChangeSubject = new BehaviorSubject<UserStateInfo | undefined>(undefined);


  constructor(
    private aiSvc: AppInsightsRef,
    private authSvc: AuthService,
    private router: Router,
    private userSvc: UserService
  ) {

  }


  ngOnInit() {
    this.state = 'loading';

    if (window.self !== window.top) {
      // this is page has been loaded in an iframe.  Possible because it is handling the login response.
      return;
    }

    // this.subsMgr.addSub = this.authSvc
    //   .getHasValidAccessToken()
    //   .pipe(
    //     switchMap(isValid => {
    //       if (isValid) {
    //         return of(true);
    //       }
    //       return this.authSvc.renewToken()
    //         .pipe(
    //           catchError(() => of(false)), // there are a lot of reasons a renew wouldn't work initially
    //           tap(x => { if (!x) { throwError('Unable to retrieve token'); } }),
    //           switchMap(() => this.authSvc.getHasValidAccessToken()),
    //           retry(5)
    //         );
    //     }),
    //     filter(isValid => isValid),
    //     switchMap(() => this.userSvc.currentUser$),
    //     tap(x => this.userChangeSubject.next(x))
    //   )
    //   .subscribe();

    this.subsMgr.addSub = this.userSvc.currentUser$.pipe(
      filter((u): u is Required<UserStateInfo> => !!u?.email),
      tap(u => this.aiSvc.appInsights?.setAuthenticatedUserContext(u.email))
    ).subscribe((u) => {
        // environment.userInfo.email = u.email;
        // environment.userInfo.workflowMasterUser = u.isWorkflowMasterSuperUser;
        // environment.userInfo.name = u.name;
        // environment.userInfo.userId = u.userId;
        this.userChangeSubject.next(u);
      },
      err => {
        alert('Error Authenticating');
      });



    // set to ready once the first navigation event completes.
    this.subsMgr.addSub = this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd),
      tap(() => this.state = 'ready'),
      take(1)
    ).subscribe();

    // Hide the side navigation when specified by the current route.
    this.subsMgr.addSub = this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd),
      tap(() => {
        this.hideNav = (this.router.routerState.root)
          ? this.router.routerState.root.firstChild?.snapshot.data.hideNavOptions || false
          : false;
      })
    ).subscribe();

    // when the user and Ai context is set then set the authenticatedId on the context to the user email
    this.subsMgr.addSub = this.userChangeSubject
      .pipe(filter((u) => u! && true), take(1))
      .subscribe(u => this.aiSvc.appInsights?.setAuthenticatedUserContext(u!.email));
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

}
