import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { tap, first, filter } from 'rxjs/operators';
import { EstimateType, RevenueAreaType } from '../../../client-api.service';
import { CommentsStateService } from '../../../comments/comments-state.service';
import { MenuTrayStateService } from '../../../menu-tray/menu-tray-state.service';
import { AggregateUtil } from '../../../shared/aggregate-util';
import { GLOBAL, IGlobalSettings } from '../../../shared/global-settings';
import { tapError } from '../../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../../estimate-input-debounce-manager';
import { AreaEstimates } from '../../models/area-estimates';
import { AreaType } from '../../models/area-type';
import { PeriodEstimates } from '../../models/period-estimates';
import { RevenueStateService, RootAreaState } from '../../revenue-state.service';

interface RowInfo {
  /** optional alternative area to use for row. */
  area?: AreaEstimates;
  badgeInfo?: string;
  commentAcctSrc?: 'gpr' | 'vacancyLoss';
  estType?: EstimateType;
  label: string;
  isDebugRow?: boolean;
  rowCssClass?: string;
  selector: (x: PeriodEstimates) => number;
  template: TemplateRef<unknown>;
  /** description that appears in UI */
  desc?: string;
}
@Component({
  selector: 'app-total',
  templateUrl: './total.component.html'
})
export class TotalComponent implements OnInit, OnDestroy {
  private static readonly fpRateLabel = 'Fp Rate';

  private areaIdMap: Map<number, AreaEstimates> | undefined;
  private areaTypeMap: Map<AreaType, AreaEstimates[]> | undefined;
  /** this is set with data to populate rows in ngInit so that the templates exist */
  private orgRowInfo: RowInfo[] | undefined;
  private subsMgr = new SubsManager();
  private unitTypeRowInfoTemplate: RowInfo[] | undefined;
  private utConsolidatedAmenityMap: Map<number, AreaEstimates[]> | undefined;

  readonly comments$ = this.revState.comments$;
  readonly inputDebounceMgr = new EstimateInputDebounceManager(this.revState.periodSettings);

  curArea: AreaEstimates | undefined;
  isDebugRowsVisible = false;
  isReadOnly$ = this.revState.isReadOnly$;
  periodSettings = this.revState.periodSettings;
  rowInfo: RowInfo[] = [];


  @ViewChild('intRow', { static: true })
  templateInt: TemplateRef<unknown> | undefined;
  @ViewChild('numberRow', { static: true })
  templateNumber: TemplateRef<unknown> | undefined;
  @ViewChild('occupancyEditingRow', { static: true })
  templateOccEdit: TemplateRef<unknown> | undefined;
  @ViewChild('pctRow', { static: true })
  templatePct: TemplateRef<unknown> | undefined;

  constructor(
    private commentsState: CommentsStateService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private notifySvc: NotificationsService,
    private revState: RevenueStateService,
    private trayStateSvc: MenuTrayStateService
  ) {

  }

  ngOnInit() {
    // tslint:disable: max-line-length
    this.orgRowInfo = [
      { label: 'Total Units', template: this.templateInt!, selector: x => x.unitCount!, desc: 'Count of Units.' },
      { label: 'Occupancy', template: this.templateOccEdit!, selector: x => x.estOccPct!, desc: 'The known Occupancy for Current Year, or the projected Occupancy in Budgeted Year.' },
      { isDebugRow: true, label: 'Goal New Leases', template: this.templateNumber!, selector: x => x.estNewLeases!, rowCssClass: 'text-muted', desc: 'Number of new leases to reach.' },
      { isDebugRow: true, label: 'Goal Renewal Leases', template: this.templateNumber!, selector: x => x.estRenewalLeases!, rowCssClass: 'text-muted', desc: 'Number of renewal leases to reach.' },
      { isDebugRow: true, label: 'Original Lease Count', template: this.templateNumber!, selector: x => x.origLeaseCount!, rowCssClass: 'text-muted', desc: 'Original number of leases imported from the property management system.' },
      { isDebugRow: true, label: 'Early Expiration Count', template: this.templateNumber!, selector: x => x.calcOrigEarlyExpiryCnt!, rowCssClass: 'text-muted', desc: 'Cummulative leases that must be taken away because they exceed the goal lease count for a unit type.' },
      { isDebugRow: true, label: 'Revised Original Lease Count', template: this.templateNumber!, selector: x => x.calcOrigRevisedLeaseCnt!, rowCssClass: 'text-muted', desc: 'Original Lease Count - Early Expirations.' },
      { isDebugRow: true, label: 'Period Created Leases', template: this.templateNumber!, selector: x => x.calcLeaseCntDelta!, rowCssClass: 'text-muted', desc: 'Leases added to meet goal.' },
      { isDebugRow: true, label: 'Original Revenue', template: this.templateNumber!, selector: x => x.origTotalRevenue!, rowCssClass: 'text-muted', desc: 'Revenue Associated with original lease count.' },
      { isDebugRow: true, label: 'Revised Original Revenue', template: this.templateNumber!, selector: x => x.calcOrigRevisedRevenue!, rowCssClass: 'text-muted', desc: 'Original Revenue after expirations.  The average for the unit type is used.' },
      { isDebugRow: true, label: 'Growth Rate Revenue Amt', template: this.templateNumber!, selector: x => x.calcGrowthRateRevenue!, rowCssClass: 'text-muted', desc: 'Revenue amount used to calculate Target Rate Growth.' },
      { label: TotalComponent.fpRateLabel, template: this.templateInt!, selector: x => x.estNewRate!, desc: 'The Total Budgeted Rate.' },
      { label: 'Final Avg Rent', template: this.templateInt!, selector: x => x.calcAvgRate!, desc: 'The known average rate for the current year, or the projected average rate for Budgeted Year.' },
      // { label: `${this.revState.periodSettings.py} Rate`, template: this.templateInt, selector: x => x.pyRate },
      { label: 'Projected Rate Growth %', template: this.templatePct!, selector: x => x.estRateGrowth!, desc: 'The change in rates from the existing avg rate (or FP rate of a unit if there are no leases) with projected Fp Rates.  If there is no rate for a Unit Type, the current FP rate is used as a substitute.' },
      { label: 'Rent Revenue (w/o delinquencies)', template: this.templateInt!, selector: x => x.calcTotalRevenue!, desc: 'The total rent that should be collected for the Current Year, or projected total for the Budgeted Year.' },
      { label: `${this.revState.periodSettings.py} Rent Revenue`, template: this.templateInt!, selector: x => x.pyRevenue!, desc: 'The Prior Year Rent Revenue take from GPR + GLtL + Vacancy Loss.' },
      { label: 'Projected Revenue Growth %', template: this.templatePct!, selector: x => x.estRevenueGrowth!, desc: 'The Year over Year change in Revenue.' },
      { label: 'GPR', template: this.templateInt!, selector: x => x.estGpr!, commentAcctSrc: 'gpr', desc: 'Gross Potential Revenue.' },
      // { label: 'GPR Month Variance', template: this.templatePct, selector: x => x.estGprMonthVariance },
      { label: `${this.revState.periodSettings.py} GPR Act/Bud`, template: this.templateInt!, selector: x => x.pyGpr! },
      { label: 'Vacancy Loss', template: this.templateInt!, selector: x => x.estVacancyLoss!, commentAcctSrc: 'vacancyLoss', desc: 'Projected Vacancy Loss for the Year.' },
      { label: `${this.revState.periodSettings.py} Vacancy Loss Act/Bud`, template: this.templateInt!, selector: x => x.pyVacancyLoss! },
      { label: 'Gain (Loss)/Lease', template: this.templateInt!, selector: x => x.estGainLoss!, desc: 'Projected Gain/Loss to Lease.' },
      { label: `${this.revState.periodSettings.py} Gain (Loss)/Lease Act/Bud`, template: this.templateInt!, selector: x => x.pyGainLoss! },
    ];
    // tslint:enable: max-line-length

    /** Rows for a unit type area. Removes PY rows and Growth rows*/
    this.unitTypeRowInfoTemplate = this.orgRowInfo.filter(x => !x.label.startsWith(this.revState.periodSettings.py.toString())
      && ['Projected Revenue Growth %'].indexOf(x.label) === -1);

    this.subsMgr.addSub = this.revState.areas$.pipe(
      filter((x): x is RootAreaState => !!x),
      tap(({ idMap, typeMap, utConsolidatedAmenityMap }) => {
        this.areaIdMap = idMap;
        this.areaTypeMap = typeMap;
        this.utConsolidatedAmenityMap = utConsolidatedAmenityMap;
        this.setCurrentArea(this.areaTypeMap!.get(RevenueAreaType.Self)![0]);
      })
    ).subscribe();

    this.subsMgr.addSub = this.revState.selectedIds.setChange$.pipe(tap(ids => {
      const area = (ids.length === 0)
        ? this.areaTypeMap?.get(RevenueAreaType.Self)?.[0]
        : this.areaIdMap?.get(ids[0]);
      this.setCurrentArea(area!);
    })).subscribe();
    // this stream saves changes
    this.subsMgr.addSub = this.inputDebounceMgr.estimateChange$.pipe(
      tap(x => this.doOccupancyAdjustments(x.value.revAreaId!, x.value.appliedOn!, x.value.value)),
      tapError(() => this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.'))
    ).subscribe();


  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  showComment(commentAccountSrc: 'gpr' | 'vacancyLoss') {
    this.comments$.pipe(first()).subscribe(x => {
      this.commentsState.selectedAccountCode = x[commentAccountSrc].accountCode;
      this.trayStateSvc.openTray('CommentManagement');
    });

  }

  updateOccEstimate(revAreaId: number, periodIndex: number, valueRaw: string) {
    const appliedOn = this.revState.periodSettings.periods[periodIndex].value;
    try {
      this.inputDebounceMgr.updateRawEstimatePct(valueRaw, { revAreaId, appliedOn });
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${valueRaw}.  Nothing was saved.`);
    }
  }

  updateOccEstimateForce(revAreaId: number, periodIndex: number) {
    const appliedOn = this.revState.periodSettings.periods[periodIndex].value;
    this.inputDebounceMgr.updateForce({ revAreaId, appliedOn });
  }


  private doOccupancyAdjustments(revAreaId: number, appliedOn: Date, newOccupancyPct: number) {
    const periodIndex = this.revState.periodSettings.datePeriodIndexMap.get(appliedOn.valueOf()) || 0;
    const unitTypes = this.getUnitTypeAreas(revAreaId);
    const unitCount = AggregateUtil.sum(unitTypes.map(x => x.periods[periodIndex].unitCount) || []);
    const renewalLeases = AggregateUtil.sum(unitTypes.map(x => x.periods[periodIndex].estRenewalLeases) || []);
    const newLeases = AggregateUtil.sum(unitTypes.map(x => x.periods[periodIndex].estNewLeases) || []);
    const totalLeases = renewalLeases + newLeases;
    const curOccupancy = totalLeases / unitCount;
    const occupancyChange = newOccupancyPct - curOccupancy;
    if (occupancyChange === 0) {
      return;
    }
    const orgArea = this.areaTypeMap?.get(RevenueAreaType.Self)?.[0];
    if (orgArea == null) {
      throw new Error('Top level area was not found.');
    }
    const utChanges = unitTypes.map(x => ({ area: x, leasesDelta: 0, boundsReached: false }));

    // keeps track of changes adjustment by adjustment;
    let remainingUnitsToAdjust = occupancyChange * unitCount;
    // it's hard to land on 0.  As long as the number is within an acceptable tolerance then stop looping.
    while (remainingUnitsToAdjust > 0.0001 || remainingUnitsToAdjust < -.0001) {
      const unitsToAdjustAtLoopStart = remainingUnitsToAdjust;
      // keep looping, proportionately assigning excess until all bounds have been met or all unit adjustments assigned
      const openUnitTypes = utChanges?.filter(x => !x.boundsReached);
      if (openUnitTypes?.length === 0) {
        console.warn('Reached end of assinging units without exhausting expected adjustment amount.');
        break;
      }
      for (const ut of openUnitTypes) {
        const estimates = ut.area.periods[periodIndex];
        const unitTypeUnitCount = estimates.unitCount || 0;
        const utTotalLeases = (estimates.estNewLeases || 0) + (estimates.estRenewalLeases || 0) + ut.leasesDelta;
        const sizeFactor = unitTypeUnitCount / unitCount; // use this to get proportional adjustment amount.
        let leasesDelta = sizeFactor * unitsToAdjustAtLoopStart;

        if (utTotalLeases + leasesDelta <= 0) {
          leasesDelta -= utTotalLeases + leasesDelta;
          ut.boundsReached = true;
        }
        else if (utTotalLeases + leasesDelta >= unitTypeUnitCount) {
          leasesDelta += unitTypeUnitCount - (utTotalLeases + leasesDelta);
          ut.boundsReached = true;
        }
        ut.leasesDelta += leasesDelta;
        remainingUnitsToAdjust -= leasesDelta;
      }
    }

    for (const ut of utChanges.filter(x => x.leasesDelta !== 0)) {
      const estimates = ut.area.periods[periodIndex];
      const estNewLeases = estimates.estNewLeases || 0;
      const estRenewalLeases = estimates.estRenewalLeases || 0;
      const tgtRenewalPct = orgArea.periods[this.periodSettings.cyJan.index].tgtRenewalPct || 0;
      const tgtOccPct = orgArea.periods[this.periodSettings.cyJan.index].tgtOccPct || 0;
      // determine the newToOverallOccupancy ratio for the unit type.  If there are no estimates, use the targets.
      const newRenewalRatio = (estNewLeases || estRenewalLeases)
        ? estNewLeases / (estNewLeases + estRenewalLeases)
        : (tgtOccPct) ? (1 - tgtRenewalPct / tgtOccPct) : 0;
      const utEstNewLeases = estNewLeases + newRenewalRatio * ut.leasesDelta;
      const utEstRenewalLeases = estRenewalLeases + (1 - newRenewalRatio) * ut.leasesDelta;
      this.revState.enqueueEstimateSave(ut.area.revAreaId, {
        appliedOn: appliedOn,
        estimateId: 0,
        estimateType: EstimateType.EstNewLeases,
        value: utEstNewLeases
      });
      this.revState.enqueueEstimateSave(ut.area.revAreaId, {
        appliedOn: appliedOn,
        estimateId: 0,
        estimateType: EstimateType.EstRenewalLeases,
        value: utEstRenewalLeases
      });

    }
  }
  /**
   * Gets an array of UnitTypes from an Org area's children, or returns an array of just the revArea if the id past is a unit type's.
   * Otherwise returns an empty array.
   */
  private getUnitTypeAreas(revAreaId: number) {
    const revArea = this.areaIdMap?.get(revAreaId);
    if (revArea?.areaType === RevenueAreaType.Self) {
      return revArea.children || [];
    }
    else if (revArea?.areaType === RevenueAreaType.UnitType) {
      return [revArea];
    }
    return [];
  }

  private setCurrentArea(area: AreaEstimates) {
    this.curArea = area;
    if (!area) {
      this.rowInfo = [];
    }
    else if (area.areaType === RevenueAreaType.Self) {
      this.rowInfo = this.orgRowInfo!;
    }
    else if (area.areaType === RevenueAreaType.UnitType) {
      const rowInfo = [... this.unitTypeRowInfoTemplate!];
      const amenities = this.utConsolidatedAmenityMap?.get(area.revAreaId) || [];
      if (amenities.length > 0) {
        const fpRateIndex = this.unitTypeRowInfoTemplate!.findIndex(x => x.label === TotalComponent.fpRateLabel);
        const newRows = amenities.map(x => <RowInfo>{
          area: x,
          badgeInfo: x.summary!.unitCount !== area.summary!.unitCount ? x.summary!.unitCount!.toString() : undefined,
          label: x.displayName,
          rowCssClass: 'table-light',
          selector: y => y.estNewRate,
          template: this.templateInt
        });
        newRows[newRows.length - 1].rowCssClass = 'table-light border-bottom';
        const baseFpRow: RowInfo = { label: 'Base Fp Rate', selector: x => x.estBaseFpRate!, template: this.templateInt! };
        rowInfo.splice(fpRateIndex, 0, baseFpRow, ...newRows);
      }

      this.rowInfo = rowInfo;
    }
  }
}
