
export interface FlagItemChoice {
  /** A tooltip to show when hovering or next to flag when there is no dropdownDescription. */
  description?: string;
  /** Optional class to show in the drop down. */
  dropdownClass?: string;
  /** Tooltip to show when a dropdown. */
  dropdownDescription?: string;
  /** icon shown when flag is selected. */
  iconClass: string;
  /** If true will represent the choice for no options.  It's key will be ignored. */
  isEmptyChoice?: boolean;
  /** When omitted, will be considered the class t */
  key: string;
}

export const FLAG_ITEMS: FlagItemChoice[] = [
  { description: 'Error', iconClass: 'fas fa-fw fa-flag text-danger', key: 'error' },
  { description: 'Good', iconClass: 'fas fa-fw fa-flag text-success', key: 'good' },
  { description: 'Warning', iconClass: 'fas fa-fw fa-flag text-warning', key: 'warning' }
];