import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { merge } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { LedgerEntry } from '../client-api.service';
import { UserService } from '../core-services/user.service';
import { WorkflowsService } from '../core-services/workflows.service';
import { AccountEntriesNode } from '../ledgers/account-entries-node';
import { LedgerStateService } from '../ledgers/ledger-state.service';

@Component({
  selector: 'account-history',
  templateUrl: './account-history.component.html'
})

export class AccountHistoryComponent implements OnDestroy, OnInit {
  private subsManager = new SubsManager();

  private workflowSteps$ = this.wfsSvc.workflows$.pipe(
    map(x => new Map(x!.map(y => y.relatedSteps.map(z => ({ workflowName: y.name, ...z }))).flat().map(y => [y.stepId, y]))),
    shareReplay(1)
  );
  /** number of columns */
  columns = 3;

  /** The result of filtering and sorting historyItems */
  filteredItems: (LedgerEntry & { stateName: string })[] = [];

  private _filterMonth: Date | null | undefined;
  /** filters history items by month  */
  get filterMonth() {
    return this._filterMonth;
  }
  set filterMonth(value: Date | null | undefined) {
    if (value !== this._filterMonth) {
      this._filterMonth = value;
      this.updateFilteredItems();
    }
  }

  /** source data */
  historyItems: (LedgerEntry & { stateName: string })[] = [];

  selectedAccount?: AccountEntriesNode;

  constructor(public ledgerState: LedgerStateService, private userSvc: UserService, private wfsSvc: WorkflowsService) {

  }

  ngOnInit() {
    this._filterMonth = null;
    this.subsManager.addSub =
      merge(
        this.workflowSteps$,
        this.ledgerState.selectedAccountChange$,
        this.ledgerState.entrySaves$
          .pipe(
            filter(x => this.ledgerState.selectedAccount! && x.accountCode === this.ledgerState.selectedAccount.accountCode),
            debounceTime(50)
          )
      ).pipe(
        switchMap(() => this.getOrderedStates()),
        tap(states => this.setHistoryFromCurrentAccount(states))
      ).subscribe();
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }



  /**
   * Gets a cache user.  If not found, returns an 'Unknown User'
   * @param userId the user to find
  */
  getUserName(userId: number) {
    return this.userSvc.getUserSummary(userId).pipe(map(x => (x != null) ? x.displayName : 'Unknown User'));
  }

  getSubTitle() {
    return ((this.ledgerState.selectedAccount) ? this.ledgerState.selectedAccount.accountCode : 'No Account Selected');
  }

  private dateComparer(a: Date, b: Date) {
    const aVal = (a) ? a.valueOf() : 0;
    const bVal = (b) ? b.valueOf() : 0;
    return aVal - bVal;
  }

  private getOrderedStates() {

    return this.workflowSteps$.pipe(
      map(stepMap => {
        let stateBoundaries: { createdOn: Date, name: string }[];
        if (this.ledgerState.workflowHistory!.states!.length > 0) {
          stateBoundaries = this.ledgerState.workflowHistory!.states!.map(x => {
            const step = stepMap.get(x.stepId);
            return { createdOn: x.createdOn, name: `${step!.workflowName}: ${step!.name}` };
          });
          stateBoundaries[0].createdOn = new Date(1900, 0, 1);
        }
        else {
          const step = stepMap.get(this.ledgerState.workflowHistory!.nextStep!.stepId);
          stateBoundaries = [{ createdOn: new Date(1900, 0, 1), name: `${step!.workflowName}: ${step!.name}` }];
        }
        return stateBoundaries.sort((a, b) => this.dateComparer(a.createdOn, b.createdOn));
      })
    );
  }
  private setHistoryFromCurrentAccount(stateBoundaries: { createdOn: Date, name: string }[]) {
    this.selectedAccount = this.ledgerState.selectedAccount;
    this.historyItems = [];
    if (this.selectedAccount) {
      let itemSrc = this.ledgerState.ledger!.accountHistoryMap.get(this.selectedAccount.accountCode!);

      if (itemSrc) {
        itemSrc = itemSrc.sort((a, b) =>
          this.dateComparer(a.createdOn!, b.createdOn!) * -1 || this.dateComparer(a.appliedOn, b.appliedOn));
        this.historyItems = itemSrc.map(x => {
          let maxStateIndex = 0;
          for (let i = 1; i < stateBoundaries.length; i++) {
            if (stateBoundaries[i].createdOn.valueOf() < x.createdOn!.valueOf()) {
              maxStateIndex = i;
            }
            else {
              break;
            }
          }
          return { ...x, stateName: stateBoundaries[maxStateIndex].name };
        });
      }
    }

    this.updateFilteredItems();
  }

  /** Sets filteredItems based on the value of filterMonth. */
  private updateFilteredItems() {

    this.filteredItems = (this._filterMonth)
      ? this.historyItems.filter(x => x.appliedOn.valueOf() === this._filterMonth?.valueOf())
      : this.historyItems;

  }


}


