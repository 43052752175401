import { Inject, Injectable } from '@angular/core';
import { AppInsightsRef, NotificationsService } from '@tcc/ui';
import { groupWith, sortBy, splitEvery } from 'ramda';
import { BehaviorSubject, concat, EMPTY, merge, Observable, of, Subject } from 'rxjs';
import {
  bufferTime, catchError, concatMap, distinctUntilChanged, filter, finalize, map, mergeMap, shareReplay, startWith, switchMap, take, tap,
  toArray
} from 'rxjs/operators';
import { Estimate, EstimateType, LedgerEntry, Organization, RevenueArea, RevenueAreaEstimate, RevenueAreaMeta, RevenueAreaType } from '../client-api.service';
import { CommandBatchStatusChange, CommandManager } from '../commands/command-manager';
import { CommandsService } from '../commands/commands.service';
import { CommentsStateService } from '../comments/comments-state.service';
import { OrganizationService } from '../core-services/organization.service';
import { UserService } from '../core-services/user.service';
import { LedgerService } from '../ledgers/ledger.service';
import { AggregateUtil } from '../shared/aggregate-util';
import { ArrayUtil } from '../shared/array-util';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { ObservableSet } from '../shared/observable-set';
import { tapError } from '../shared/tap-error-operator';
import { getEstimateValueByEstimateType, setPeriodEstimateValue } from './estimate-conversions';
import { AreaEstimates } from './models/area-estimates';
import { AreaType, ExtendedRevenueAreaType } from './models/area-type';
import { PeriodEstimatesBase } from './models/period-estimates';
import { RevenueCalcService } from './revenue-calc-service';
import { EstimateSimpleBatchItem, RevenueService } from './revenue.service';

interface EstimateSaveCommandInfo {
  estimate: EstimateSimpleBatchItem;
  oldValue?: number;
  orgId: number;
}
interface RevnueMetaUpdateCommandInfo { meta: RevenueAreaMeta; orgId: number; revAreaId: number; }
interface EstimateAccountLink {
  /** Get a period value that should be used on an account for the current year. */
  estGetter: (x: PeriodEstimatesBase) => number;
  /** Sets the estimate value ideally from Act/Bud. */
  estSetter: (x: PeriodEstimatesBase, value: number) => void;
}
export interface RootAreaState {
  areas: AreaEstimates[],
  idMap: Map<number, AreaEstimates>,
  typeMap: Map<AreaType, AreaEstimates[]>,
  /** maps unit types to consolidated amenities */
  utConsolidatedAmenityMap: Map<number, AreaEstimates[]>
}
@Injectable({
  providedIn: 'root',
})
export class RevenueStateService {

  private readonly areasSubj = new BehaviorSubject<RootAreaState>({ areas: [], idMap: new Map(), typeMap: new Map(), utConsolidatedAmenityMap: new Map() });
  /** results after an estimate has been saved */
  private readonly estimateSaveSuccessSubj = new Subject<RevenueAreaEstimate>();
  /** loads items that will be grouped into a batch after a certain buffer time */
  private readonly estimateSaveBatcher = new Subject<EstimateSaveCommandInfo>();

  //private readonly canRedoCommandStatus = this.commandsSvc.getCommand

  /** save commands */
  readonly estimateSaveCmd = this.commandsSvc.getCommand<EstimateSaveCommandInfo>(RevenueService.EstimateSavingCmdName);
  /** command that gets executed when fp is updated */
  private readonly fpUpdateCmd = this.commandsSvc.getCommand('Update FP from Revenue');
  /** command that updates revenue are meta */
  private readonly metaUpdateCmd = this.commandsSvc.getCommand<RevnueMetaUpdateCommandInfo>(RevenueService.MetaUpdateCmdName);
  /** contains changes to the orgId from the orgId property */
  private readonly orgIdSubj = new BehaviorSubject<number | undefined>(undefined);
  /** contains org info after it has been loaded or undefined if the org is cleared */
  private readonly orgSubj = new BehaviorSubject<Organization | undefined>(undefined);
  /** for Self Areas, maps acctCodes to estimate py setters and  cy getters */
  private readonly fpAcctToEstimateAccessorMap = new Map(<[string, EstimateAccountLink][]>[
    [
      this.globalSettings.specialAccounts.gainLossToLease,
      { estGetter: x => x.estGainLoss! * -1, estSetter: (x, y) => x.pyGainLoss = y * -1 }
    ],
    [
      this.globalSettings.specialAccounts.gpr,
      { estGetter: x => x.estGpr! * -1, estSetter: (x, y) => x.pyGpr = y * -1 }
    ],
    [
      this.globalSettings.specialAccounts.vacancyLoss,
      { estGetter: x => x.estVacancyLoss! * -1, estSetter: (x, y) => x.pyVacancyLoss = y * -1 }
    ]
  ]);

  /** current entries in fp that are updated by the revenue system  */
  private fpAccountTotals: { [acctCode: string]: number; } | undefined;

  /** fires every time the revenue areas are loaded */
  readonly areas$ = this.areasSubj.asObservable();
  readonly comments$ = this.commentsState.comments$.pipe(
    filter(x => x != null),
    map(comments => ({
      gpr: {
        accountCode: this.globalSettings.specialAccounts.gpr,
        hasComments: comments?.some(x => x.accountCode === this.globalSettings.specialAccounts.gpr),
      },
      vacancyLoss: {
        accountCode: this.globalSettings.specialAccounts.vacancyLoss,
        hasComments: comments?.some(x => x.accountCode === this.globalSettings.specialAccounts.vacancyLoss)
      }
    })),
    startWith({
      gpr: { accountCode: this.globalSettings.specialAccounts.gpr, hasComments: false },
      vacancyLoss: { accountCode: this.globalSettings.specialAccounts.vacancyLoss, hasComments: false },
    })
  );

  /** fired after an estimate has been changed and committed */
  readonly estimateChange$ = this.estimateSaveSuccessSubj.asObservable();

  /** returns true if fp is out of sync with org area */
  readonly fpOutOfSync$ = merge(this.fpUpdateCmd.executed$, this.areasSubj).pipe(map(() => {
    const orgArea = this.areasSubj.value?.typeMap.get(RevenueAreaType.Self)?.[0];
    return orgArea && Array.from(this.fpAcctToEstimateAccessorMap).
      some(([acctCode, { estGetter }]) => Math.round(this.fpAccountTotals?.[acctCode] || 0) !== Math.round(estGetter(orgArea.summary!) || 0));
  }));

  /** only the current user can modify data if isAdmin is true */
  readonly isReadOnly$ = this.userSvc.currentUser$.pipe(
    map(x => !x || !x.isAdmin),
    startWith(true),
    shareReplay(1)
  );

  /** fires every time the org changes */
  readonly org$ = this.orgSubj.asObservable();


  /** sets the currently selected org and initiates the loading of its data */
  get orgId() { return this.orgIdSubj.value; }
  set orgId(value: number | undefined) { this.orgIdSubj.next(value); }

  readonly periodSettings = this.revSvc.getPeriodSettings(this.globalSettings.budgetYear);

  readonly selectedIds = new ObservableSet<number>();

  constructor(
    private aiSvc: AppInsightsRef,
    public commandsSvc: CommandsService,
    private commentsState: CommentsStateService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerSvc: LedgerService,
    private notifySvc: NotificationsService,
    private orgSvc: OrganizationService,
    private revCalc: RevenueCalcService,
    private revSvc: RevenueService,
    private userSvc: UserService
  ) {

    this.isReadOnly$.pipe(tap(isReadOnly => this.commentsState.isReadOnly = isReadOnly)).subscribe();

    this.orgIdSubj.pipe(
      distinctUntilChanged(),
      // first remove the old org by returning undefined, then load the new one.
      switchMap(orgId => this.setStateFromOrg(orgId!))
    ).subscribe();

    // the batcher will buffer saves for 100 ms, and then enqueue them as commands
    // This screws with undo/redos if the batch size is greater than the batch limit.
    // Possible solution is using an innner command for the batching, or some how being able to stitch the original batch back together.
    this.estimateSaveBatcher.pipe(
      bufferTime(100),
      filter(x => x.length > 0),
      // only keep the last changes
      map(ests => 
        ests.reverse()
          .filter((x, i, ary) => ary.findIndex(compareTo => 
            compareTo.estimate.revAreaId === x.estimate.revAreaId 
            && compareTo.estimate.estimateType === x.estimate.estimateType 
            && compareTo.estimate.appliedOn?.valueOf() === x.estimate.appliedOn?.valueOf()) === i)
          .reverse()
      ),
      map(x => {
        const newEstimates = x.map(y => {
          const periodIdx = this.periodSettings.datePeriodIndexMap.get(y.estimate.appliedOn.valueOf()) || 0;
          const periodEstimate = this.areasSubj.value.idMap.get(y.estimate.revAreaId)?.periods[periodIdx];
          const oldValue = getEstimateValueByEstimateType(y.estimate.estimateType, periodEstimate!);
          // wrong!!! make this apply to all components
          // const oldValue = this.areasSubj.value.idMap.get(y.estimate.revAreaId)?.periods[periodIdx].estRenewalRate;
          return { ...y, oldValue: oldValue };
        });
        // split into batches of orgs with a maximum of 50 items each.
        return groupWith((a: EstimateSaveCommandInfo, b) => a.orgId === b.orgId, sortBy(y => y.orgId, newEstimates)) // group by org code.
          .map(y => splitEvery(50, y)) // split innerorg group up arrays into batches of 50.
          .flat(); // flatten outer array so that the result is an array of arrays with at most 50 items.
      }),
      tap(x => x.forEach(y => this.estimateSaveCmd.enqueue(y)))
    ).subscribe();

    // buffer saves, chunk them into managable sizes, and save
    this.estimateSaveCmd.enqueued$.pipe(
      this.readOnlyCheck(this.estimateSaveCmd),
      map((batch) => {
        const isRollback = batch.batchType === 'rollback';
        const batchCommands = batch.commands.map(y => {
          // Necessary for a rollbacks and redos
          const value = (isRollback) ? y.estimate.oldValue! : y.estimate.value;
          const oldValue = (isRollback) ? y.estimate.value : y.estimate.oldValue;
          const est = { ...y.estimate, oldValue, value };
          return est;
        });
        return ({ batch, batchCommands });
      }),
      // optimistically assume changes will be okay and update estimates.
      tap(({ batchCommands }) => this.onEstimateChanges(batchCommands.map(x => x))),
      concatMap(({ batch, batchCommands }) => {
        return this.revSvc.saveRevenueAreaEstimates(batch.commands[0].orgId, batchCommands)
          .pipe(
            tap(results => {
              results.forEach(est => this.estimateSaveSuccessSubj.next({ ...est, revAreaId: est.revAreaId }));
              this.estimateSaveCmd.executed(batch);
            }),
            tapError(err => {
              this.estimateSaveCmd.failed(batch);
              this.aiSvc.appInsights?.trackException({ exception: err });
            }),
            finalize(() => this.estimateSaveCmd.ensureDequeued(batch))
          );
      }),
      catchError(() => {
        this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.');
        return of(undefined);
      })
    ).subscribe();

    this.metaUpdateCmd.enqueued$.pipe(
      this.readOnlyCheck(this.metaUpdateCmd),
      concatMap((batch) => concat(...batch.commands.map(x => this.revSvc.saveRevenueAreaMeta(x.orgId, x.revAreaId, x.meta))).pipe(
        tap(() => {
          // unfortunately copying results back to the area meta is impossbile since amenityCode is added to unit amenity.
          const areaInfo = this.areasSubj.value;
          // just indicate that areas were updated.
          this.areasSubj.next(areaInfo);
          this.metaUpdateCmd.executed(batch);
        }),
        tapError(err => {
          this.metaUpdateCmd.failed(batch);
          this.aiSvc.appInsights?.trackException({ exception: err });
        }),
      )),
      catchError(() => {
        this.notifySvc.addError('Unable to update meta.  Please refresh and try again.');
        return of(undefined);
      })
    ).subscribe();


    this.estimateSaveSuccessSubj.pipe(
      bufferTime(50),
      filter((x) => x.length > 0),
      tap((savedEsts) => {
        const queuedEsts = this.estimateSaveCmd.getQueuedCommands().flatMap(x => x.commands);
        const unqueuedChangedSavedEsts = savedEsts.filter(x => {
          const periodIdx = this.periodSettings.datePeriodIndexMap.get(x.appliedOn.valueOf()) || 0;
          return !this.changeQueued(x, queuedEsts)
            && getEstimateValueByEstimateType(x.estimateType, this.areasSubj.value.idMap.get(x.revAreaId)?.periods[periodIdx]) !== x.value;
        });
        if (unqueuedChangedSavedEsts.length) {
          this.onEstimateChanges(unqueuedChangedSavedEsts);
        }
      })
    ).subscribe();
  }

  private changeQueued(estimate: RevenueAreaEstimate, queuedCommands: EstimateSaveCommandInfo[]) {
    return queuedCommands.some(x => x.estimate.estimateType === estimate.estimateType 
      && x.estimate.revAreaId === estimate.revAreaId
      && x.estimate.appliedOn.valueOf() === estimate.appliedOn.valueOf());
  }

  /**
   * enqueues a revenue estimate so that it can be saved as part of a batch.
   * @param revAreaId The area the setimate is part of
   * @param est the estimate to save
   * @param orgId optional orgId.  If not provided, the org in org$ is used
   * @param forceInsert if true, saving will always create a new record even if a matching one is the latest.
   */
  enqueueEstimateSave(revAreaId: number, est: Estimate, orgId?: number, forceInsert: boolean = false) {
    const periodIdx = this.periodSettings.datePeriodIndexMap.get(est.appliedOn.valueOf()) || 0;
    const periodEstimate = this.areasSubj.value.idMap.get(revAreaId)?.periods[periodIdx];
    const oldValue = getEstimateValueByEstimateType(est.estimateType, periodEstimate!);
    const cmd: EstimateSaveCommandInfo = {
      estimate: { ...est, revAreaId, forceInsert: forceInsert, oldValue },
      orgId: orgId || (this.orgSubj.value?.orgId || 0)
    };
    this.estimateSaveBatcher.next(cmd);
  }

  /** Doesn't have the same concerns as enqueueEstimateSave, immediately queues individual saves. */
  enqueueMetaSave(revAreaId: number, meta: RevenueAreaMeta, orgId?: number) {
    if (orgId) {
      this.metaUpdateCmd.enqueue({ meta, orgId, revAreaId });
    }
  }

  /**
   * sends fp entries to the appropriate ledger
   */
  updateFp() {
    const cmdBatch = this.fpUpdateCmd.enqueue({});
    const orgArea = this.areasSubj.value?.typeMap?.get(RevenueAreaType.Self)?.[0];
    const cyJanIndex = this.periodSettings.cyJan.index;
    const cyPeriods = orgArea?.periods.slice(this.periodSettings.cyJan.index);
    const entries = (<LedgerEntry[]>[]).concat(
      ...Array.from(this.fpAcctToEstimateAccessorMap.entries())
        .map(([accountCode, { estGetter }]) => cyPeriods!.map((estimates, i) =>
          <LedgerEntry>{ accountCode, appliedOn: this.periodSettings.periods[i + cyJanIndex].value, amount: estGetter(estimates) }))
    );

    this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.fpLedgerName)
      .pipe(
        switchMap(ledger => {
          return this.orgSvc.saveLedgerEntiresSuperUser(this.orgIdSubj.value!, ledger!.ledgerId, entries);
        }),
        tap(() => {
          this.setFpTotals(entries);
          this.notifySvc.addSuccess('FP Ledger successfully updated.');
          this.fpUpdateCmd.executed(cmdBatch!);
        }),
        tapError(() => {
          this.notifySvc.addError('Application failed updating estimates.  Please stop your work and refresh the browser');
          this.fpUpdateCmd.failed(cmdBatch!);
        }),
        take(1)
      )
      .subscribe();
  }

  /** Gets org account entries that correspond to estimates that apply to the Org's Self Area. */
  private getEntriesForEstimates(orgId: number, isActBud: boolean) {
    const ledgerName = isActBud ? this.globalSettings.actBudLedgerName : this.globalSettings.fpLedgerName;
    const acctCodes = Array.from(this.fpAcctToEstimateAccessorMap.keys());
    return this.ledgerSvc.getLedgerSummaryByName(ledgerName).pipe(
      switchMap(actBud => {
        const acctRequests = acctCodes.map(acctCode => this.orgSvc.getCurrentAccountEntries(orgId, actBud!.ledgerId, acctCode));
        return concat(...acctRequests);
      }),
      toArray(),
      map(x => x.flat())
    );
  }


  /** Will load comments asyncronously. */
  private loadComments(orgId: number) {
    this.commentsState.clearCommentState();
    if (orgId) {
      this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.fpLedgerName).pipe(
        tap(ledger => this.commentsState.loadCommentState(orgId, ledger!.ledgerId))
      ).subscribe();
    }
  }

  /** Updates areasSubj */
  private onEstimateChanges(changes: RevenueAreaEstimate[]) {

    const { areas, idMap, typeMap, utConsolidatedAmenityMap } = this.areasSubj.value;
    // create an array of all updates in a period
    const periodUpdates: Set<AreaEstimates>[] = [];
    // all rev areas that should be updated after period updates.
    const allRevAreasToUpdate = new Set<AreaEstimates>();
    this.periodSettings.periods.forEach(() => periodUpdates.push(new Set()));

    for (const estimate of changes) {
      // update original area
      const area = idMap.get(estimate.revAreaId);
      if (!area) { continue; }
      const periodIdx = this.periodSettings.datePeriodIndexMap.get(estimate.appliedOn.valueOf())!;
      setPeriodEstimateValue(estimate, area.periods[periodIdx]);
      if (area.warnings && estimate.estimateType === EstimateType.EstRenewalRate && area.warnings.includes('updatesAfterEstRenewal')) {
        area.warnings = area.warnings.filter((x: string) => x !== 'updatesAfterEstRenewal');
      }
      let areaToUpdate = area;
      while (areaToUpdate) {
        // track all updates all the way up the chain that should occur and for what period.
        for (let i = periodIdx; i < this.periodSettings.periods.length; i!++) {
          // update all forward periods
          periodUpdates[periodIdx].add(areaToUpdate);
          if (utConsolidatedAmenityMap.has(areaToUpdate.revAreaId)) {
            // make sure if consolidated amenities are updated if their unit type is also updated
            utConsolidatedAmenityMap.get(areaToUpdate.revAreaId)!.forEach((x: AreaEstimates) => {
              periodUpdates[periodIdx].add(x);
              allRevAreasToUpdate.add(x);
            });
          }
        }
        allRevAreasToUpdate.add(areaToUpdate);
        areaToUpdate = idMap.get(areaToUpdate.parentRevAreaId!)!;
      }
    }
    for (let periodIdx = 0; periodIdx < periodUpdates.length; periodIdx++) {
      // recalculate updated areas
      const revAreasToUpdate = periodUpdates[periodIdx];
      for (const areaType of this.revCalc.areaTypeCalculationOrder) {
        revAreasToUpdate.forEach(x => {
          if (x.areaType === areaType) {
            this.revCalc.updatePeriodCalculations(x, periodIdx, false);
          }
        });
      }
    }
    allRevAreasToUpdate.forEach(x => this.revCalc.updateSummaryCalculations(x));
    this.areasSubj.next({ areas, idMap, typeMap, utConsolidatedAmenityMap });
  }

  /** Creates an operator that checks if the sstate is readonly and cancels the batch if true. */
  private readOnlyCheck<T>(cmdMgr: CommandManager<T>) {
    const isReadOnly$ = this.isReadOnly$;
    const notifySvc = this.notifySvc;
    return function (source: Observable<CommandBatchStatusChange<T>>): Observable<CommandBatchStatusChange<T>> {
      return source.pipe(
        mergeMap(batch => isReadOnly$.pipe(map(isReadOnly => ({ isReadOnly, batch })))),
        tap(({ isReadOnly, batch }) => {
          if (isReadOnly) {
            cmdMgr.failed(batch);
            notifySvc.addWarning('You do not have permission to save changes.');
            throw new Error('Can not save estimates due to read only state');
          }
        }),
        map(({ batch }) => batch)
      );
    };
  }

  /** Updates org and area info */
  private setStateFromOrg(orgId: number) {
    const cmd = this.commandsSvc.getCommand<{ orgId: number }>(RevenueService.RevenueLoadingCmdName);
    const cmdBatch = cmd.enqueue({ orgId });

    // initialize
    this.selectedIds.clear();
    const enumKeys = (<AreaType[]>Object.values(RevenueAreaType)).concat(Object.values(ExtendedRevenueAreaType));
    const typeMap = new Map(enumKeys.map(x => [<AreaType>x, <AreaEstimates[]>[]]));

    this.loadComments(orgId);

    if (!orgId) {
      this.orgSubj.next(undefined);
      this.areasSubj.next({ areas: [], idMap: new Map(), typeMap, utConsolidatedAmenityMap: new Map() });
      cmd.executed(cmdBatch!);
      return EMPTY;
    }
    const tasks = [
      this.orgSvc.getOrganization(orgId),
      this.revSvc.getRevenueAreas(orgId, this.globalSettings.budgetYear),
      this.getEntriesForEstimates(orgId, true),
      this.getEntriesForEstimates(orgId, false)
    ] as const;

    // this is a little more awkward then forkjoin but prevents parallel requests.
    return concat(...tasks).pipe(
      toArray(),
      map((x) => x as [Organization | undefined, RevenueArea[], LedgerEntry[], LedgerEntry[]]), // ugly assertion, maybe we should just use forkJoin, might have new options?
      tap(([org, revAreas, actBudEntries, fpEntries]) => {
        this.orgSubj.next(org);
        if (!revAreas) {
          this.areasSubj.next({ areas: [], idMap: new Map(), typeMap, utConsolidatedAmenityMap: new Map() });
          cmd.executed(cmdBatch);
          return;
        }
        // create sorted areas
        const areas = this.revSvc.mapRevenueAreasToAreaEstimates(revAreas, this.periodSettings)
          .sort(RevenueService.AreaSorter);


        // populate type map
        areas.forEach(x => typeMap.get(x.areaType)?.push(x));
        this.setStateFromOrgSetOrgAreaActBudEstimates(typeMap, actBudEntries);
        this.setFpTotals(fpEntries);
        this.setUtConsolidatedAmenities(typeMap);
        this.setStateFromOrgInitalCalcs(typeMap);
        const utConsolidatedAmenityMap = ArrayUtil.group(
          typeMap.get(ExtendedRevenueAreaType.ConsolidatedUnitTypeAmenity)!, x => x.parentRevAreaId!);

        this.areasSubj.next({ areas, idMap: new Map(areas.map(x => [x.revAreaId, x])), typeMap, utConsolidatedAmenityMap });
        cmd.executed(cmdBatch);
      }),
      tapError(() => {
        this.notifySvc.addError('Unable to load revenue info.  Please refresh and try again.');
        cmd.failed(cmdBatch);
      }),
      finalize(() => cmd.ensureDequeued(cmdBatch))
    );
  }

  private setStateFromOrgInitalCalcs(typeMap: Map<AreaType, AreaEstimates[]>) {
    for (const areaType of this.revCalc.areaTypeCalculationOrder) {
      for (const area of typeMap.get(areaType) || []) {
        for (let periodIdx = 0; periodIdx < this.periodSettings.periods.length; periodIdx++) {
          this.revCalc.updatePeriodCalculations(area, periodIdx, true);
        }
        this.revCalc.updateSummaryCalculationsInitial(area);
        this.revCalc.updateSummaryCalculations(area);
      }
    }
  }

  /**
   * sets area estimates on org from actBud entries for prior year estimates, such as 401100 to GPR.
   */
  private setStateFromOrgSetOrgAreaActBudEstimates(typeMap: Map<AreaType, AreaEstimates[]>, entries: LedgerEntry[]) {

    const orgArea = typeMap.get(RevenueAreaType.Self)?.[0];
    if (orgArea) {
      for (const [acctCode] of this.fpAcctToEstimateAccessorMap) {
        const setter = this.fpAcctToEstimateAccessorMap.get(acctCode)!.estSetter;
        // initialize periods
        orgArea.periods.slice(2).forEach(x => setter(x, 0));
        // sets estimate values
        const acctEntries = entries.filter(x => x.accountCode === acctCode);
        for (const entry of acctEntries) {
          const appliedOn = new Date(entry.appliedOn!.getFullYear() + 1, entry.appliedOn!.getMonth(), entry.appliedOn!.getDate());
          const periodIdx = this.periodSettings.datePeriodIndexMap.get(appliedOn.valueOf());
          setter(orgArea.periods?.[periodIdx!], entry.amount!);
        }
      }
    }
  }

  /**
   * sets fpAccountTotals
   */
  private setFpTotals(entries: LedgerEntry[]) {
    this.fpAccountTotals = {};
    for (const [acctCode] of this.fpAcctToEstimateAccessorMap) {
      const acctEntries = entries.filter(x => x.accountCode === acctCode);
      this.fpAccountTotals[acctCode] = (acctEntries) ? AggregateUtil.sum(acctEntries.map(x => x.amount!)) : 0;
    }
  }

  /** adds to typeMap consolidated ut amenities, creating root amenity groups with child unit amenities under. */
  private setUtConsolidatedAmenities(typeMap: Map<AreaType, AreaEstimates[]>) {
    const nameComparer = ArrayUtil.compareStringsFuncFactory({ ignoreCase: true });
    const consolidatedAmenities: AreaEstimates[] = [];
    for (const ut of typeMap.get(RevenueAreaType.UnitType)!) {
      let utAmenities: AreaEstimates[] = [];
      for (const a of (ut.children!.map(x => x.children || []).flat())) {
        let amenity = utAmenities.find(x => x.name === a.name);
        if (!amenity) {
          amenity = {
            areaType: ExtendedRevenueAreaType.ConsolidatedUnitTypeAmenity,
            children: [],
            displayName: a.displayName,
            name: a.name,
            parentRevAreaId: ut.revAreaId,
            periods: [],
            revAreaId: a.revAreaId + 16777216 * ut.revAreaId
          };
          this.periodSettings.periods.forEach((_, i) => amenity?.periods.push({ monthId: i, aggs: {} }));
          utAmenities.push(amenity);
        }
        amenity.children?.push(a);
      }
      if (utAmenities.length > 0) {
        utAmenities = utAmenities.sort((a, b) => nameComparer(a.displayName, b.displayName));
        consolidatedAmenities.push(...utAmenities);
      }
    }
    typeMap.set(ExtendedRevenueAreaType.ConsolidatedUnitTypeAmenity, consolidatedAmenities);
  }
}
