import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { NotificationsService, ScrollService, SubsManager } from '@tcc/ui';
import { merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { NumberParsingUtil } from '../shared/number-parsing-util';
import { AccountEntriesNode } from './account-entries-node';
import { AccountVisibilityService } from './account-visibility.service';
import { ComparisonStateService } from './comparisons/comparison-state.service';
import { LedgerAccountComponentUtil } from './ledger-account-component-util';
import { LedgerStateService, SelectedAccountChangeEventArgs } from './ledger-state.service';
import { ComparisonResult } from './comparisons/models';
import { AccountComparerService } from './comparisons/account-comparer.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ledger-account',
  template: `
<div class="led-row" [ngClass]="cssClass!">
    <div class="led-amounts" >
        <ng-content *ngIf="isReadonly; then readOnly else writeable"></ng-content>
        <div class="led-amount"
            [ngClass]="{'hl-diff': isComparing && comparisons![12].different}" (click)="selectAccount()" >
            <span class="led-amount-text">{{ account!.total | dynNum:format!.type:format!.decimals }}</span>
            <span *ngIf="isComparing" class="led-amount-text"
                [ngClass]="{'hl-comparison-changes': comparisons![12].updated}">{{ comparisons![12].value }}</span>
        </div>
    </div>
</div>
<ng-template #readOnly>
    <div *ngFor="let amt of account?.amounts; index as $index" class="led-amount"
        [ngClass]="{'hl-diff': isComparing && comparisons![$index].different}" (click)="selectAccount($index)">
        <span class="led-amount-text">{{ account!.amounts[$index] | dynNum:format!.type:format!.decimals }}</span>
        <span *ngIf="isComparing" class="led-amount-text"
            [ngClass]="{'hl-comparison-changes': comparisons![$index].updated}">{{ comparisons![$index].value }}</span>
    </div>
</ng-template>
<ng-template #writeable>
    <div *ngFor="let amt of account?.amounts; index as $index"
            class="led-amount" [ngClass]="{'hl-diff': isComparing && comparisons![$index].different}">
        <div class="input-proxy" *ngIf="$index != focusedAmountIndex; else AmountInput"
            (click)="selectAccount($index)">
            {{ account!.amounts[$index] | dynNum:format!.type:format!.decimals }}
        </div>
        <ng-template #AmountInput>
            <input type="text" [value]="amt" tabindex="-1" #CurrentInput
                [appKeyboardDirectionalEvents] (directionalEvent)="onDirectionEvent(CurrentInput, $event)" />
        </ng-template>
        <div *ngIf="isComparing" class="led-amount-text" (click)="selectAccount($index)"
            [ngClass]="{'hl-comparison-changes': comparisons![$index].updated}">{{comparisons![$index].value}}</div>
    </div>
</ng-template>
`
})
export class LedgerAccountComponent implements OnChanges, OnDestroy, OnInit {

  /** How many Miliseconds a transient state should be visible */
  static tempStateDuration = 2500;

  private subsManager = new SubsManager();

  /** The account for this controller instance */
  @Input() account: AccountEntriesNode | undefined;

  amountStates = {};

  /** Css classes for the account. */
  cssClass?: string[];

  /** comparison info, with total as 13th account */
  comparisons?: ComparisonResult[];

  @ViewChild('CurrentInput') currentInput?: ElementRef;

  /** Essentially the index of the month for the focued amount. */
  focusedAmountIndex?: number = undefined;

  /** The display format being used. */
  format?: { type: string, decimals?: number };

  get isComparing() {
    return (this.ledgerState.ledger && this.comparisonState.isComparing);
  }

  isSelected = false;
  get isReadonly() {
    return this.ledgerState.readOnlyAmounts || !this.account?.hasEditableAmounts;
  }

  constructor(private accountComparer: AccountComparerService, private accountVizSvc: AccountVisibilityService,
    private cd: ChangeDetectorRef, private comparisonState: ComparisonStateService, private el: ElementRef,
    private ledgerState: LedgerStateService, private scrollSvc: ScrollService, private notifSvc: NotificationsService) {

  }

  ngOnInit(): void {
    this.subsManager.addSub = this.ledgerState.selectedAccountChange$.subscribe(x => this.onLedgerSelectedAccountChange(x));

    this.subsManager.addSub = this.ledgerState.scrollRequests
      .pipe(filter(x => this.account! && this.account.accountCode === x.accountCode))
      .subscribe(() => this.scrollSvc.scrollIntoView(this.el));

    this.subsManager.addSub = this.comparisonState.anyComparisonUpdate$.pipe(
      debounceTime(250),
      filter(() => !!this.isComparing),
      tap(() => {
        if (this.account && this.comparisons) {
          const newComparisons = this.comparisonState.getAccountComparisonResult(this.account);
          if (!this.accountComparer.isComparisonResultCollectionsEqual(this.comparisons, newComparisons)) {
            this.comparisons = newComparisons;
            this.cd.detectChanges();
          }
        }
      })
    ).subscribe();

    // make sure an update occurs if the comparison is turned off or on.
    this.subsManager.addSub = this.comparisonState.anyComparisonUpdate$.pipe(
      debounceTime(250),
      map(() => !!this.isComparing),
      distinctUntilChanged(),
      tap(() => this.cd.detectChanges())
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subsManager.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['account']) {
      this.onAccountChange();
    }
  }

  /** sets the focused amount input  */
  setFocusedInput(index?: number) {
    if (!this.isReadonly) {

      if (this.focusedAmountIndex !== undefined && this.currentInput) {
        this.saveValue(this.currentInput.nativeElement.value, this.focusedAmountIndex);
        this.currentInput.nativeElement.blur();
      }

      this.focusedAmountIndex = index;

      this.cd.detectChanges();
      if (index != null) {

        // this is necessary because we some sort of bug was occurring setting the amounts to 0
        this.currentInput!.nativeElement.value = this.account!.amounts[this.focusedAmountIndex!];
        this.cd.detectChanges();
        setTimeout(() => { // this code is from 2017.  Is it still neccessary?
          if (this.currentInput) {
            this.currentInput.nativeElement.focus();
            this.currentInput.nativeElement.select();
          }
        });
      }
    }
  }

  onDirectionEvent(elem: HTMLInputElement, evt: KeyboardEvent) {
    // for ArrowLeft and Right, treat shift as resizing of selection area.
    switch (evt.key) {
      case 'Tab':
        this.gotoRelativeAmount((evt.shiftKey) ? -1 : 1);
        break;
      case 'ArrowLeft':
        if (elem.selectionStart === 0 && !evt.shiftKey) {
          this.gotoRelativeAmount(-1);
        }
        else {
          elem.selectionStart = Math.max(0, elem.selectionStart! - 1);
          if (!evt.shiftKey) {
            elem.selectionEnd = elem.selectionStart;
          }
        }
        break;
      case 'ArrowUp':
        this.gotoRelativeAmount(-12);
        break;
      case 'ArrowRight':
        const valueLength = (elem.value != null) ? elem.value.toString().length : 0;
        if (elem.selectionEnd === valueLength && !evt.shiftKey) {
          // presense of shift key indicates resizing selection area.
          this.gotoRelativeAmount(1);
        }
        else {
          elem.selectionEnd = Math.min(valueLength, elem.selectionEnd! + 1);
          if (!evt.shiftKey) {
            elem.selectionStart = elem.selectionEnd;
          }
        }
        break;
      case 'ArrowDown':
        this.gotoRelativeAmount(12);
        break;
      case 'Enter':
        this.gotoRelativeAmount((evt.shiftKey) ? -12 : 12);
        break;
      case 'End':
        this.gotoRelativeAmount(11 - (this.focusedAmountIndex || 0));
        break;
      case 'Home':
        this.gotoRelativeAmount((this.focusedAmountIndex || 0) * -1);
        break;
      case 'PageUp':
        this.gotoRelativeAmount(-240);
        break;
      case 'PageDown':
        this.gotoRelativeAmount(240);
        break;
    }
  }


  /**
   * selects amount by bubbling it up to ledger state
   * @param amountIndex
   */
  selectAccount(amountIndex?: number) {
    this.ledgerState.selectAccount(this.account, amountIndex);
  }

  private gotoRelativeAmount(distance: number) {
    const relativeChange = distance + (this.focusedAmountIndex || 0);
    this.accountVizSvc.gotoTabbableAmount(this.account!.accountCode!, relativeChange);
  }

  /** Sets things up if the account Input has changed. */
  private onAccountChange() {
    this.comparisons = [];
    for (let i = 0; i < 13; i++) {
      this.comparisons.push({ value: '-', updated: false, different: false });
    }

    this.subsManager.cancel('amountChanges');
    this.cssClass = LedgerAccountComponentUtil.getCssClasses(this.account!, this.isSelected);

    if (this.account) {
      this.format = this.account.displayFormat;
      this.subsManager.subs['amountChanges'] = merge(this.account.amountChanges)
        .pipe(debounceTime(0))
        .subscribe(() => {
          if (this.isComparing) {
            this.comparisons = this.comparisonState.getAccountComparisonResult(this.account!);
          }
          this.cd.detectChanges();
        });
    } else {
      this.format = { type: 'whole', decimals: 0 };
    }
  }

  /**
   * handles when the selected account is changed on the ledger
   */
  private onLedgerSelectedAccountChange(changeInfo: SelectedAccountChangeEventArgs) {

    let hasStateChange = false;

    if (this.isSelected) {
      if (this.account !== changeInfo.acct) {
        this.isSelected = false;
        this.setFocusedInput(undefined);
        hasStateChange = true;
      }
      else if (this.focusedAmountIndex !== changeInfo.amtIdx) {
        this.setFocusedInput(changeInfo.amtIdx);
        hasStateChange = true;
      }
    } else if (this.account === changeInfo.acct) {
      this.isSelected = true;
      this.setFocusedInput(changeInfo.amtIdx);
      hasStateChange = true;
    }
    if (hasStateChange) {
      this.cssClass = LedgerAccountComponentUtil.getCssClasses(this.account!, this.isSelected);
      this.cd.detectChanges();
    }
  }
  /** Saves currentInput's value at amountIndex */
  private saveValue(textValue: string, amountIndex: number) {
    const value = NumberParsingUtil.parseFloat(textValue);
    this.ledgerState.executeAmountChange(this.account!.accountCode!, amountIndex, value!, textValue);
  }

}
