import { Injectable } from '@angular/core';

interface ResolverContext<T> {
  /** source object */
  input: T;
  /** contains objects keyed by their reference id */
  referenceMap: Map<string, unknown>;
  /** items that have been encountered but their reference has not */
  unresolvedItems: { parent: unknown, indexKey: string | number, referenceKey: string }[] ;
}

interface StackItem<T extends Record<string, unknown> | unknown[] = Record<string, unknown> | unknown[]> {
  item: T;
  isArray: boolean;
}


/**
 * JsonReferencesResolver
 * version 2.2.1
 * Last Updated 2021-08-16.
 * class that can be injected as service or used standalone to resolve to references in parsed json object
 */
@Injectable({ providedIn: 'root'})
export class JsonReferencesResolver {
  referenceLimit = 1000000;
  /**
    * Resolves json references by replace properties with $ref with references to properties with matching $ids.
    * @param input parsed json
    */
  resolveReferences<T>(input: T) {
    const ctxt: ResolverContext<T> = { input, referenceMap: new Map<string, unknown>(), unresolvedItems: [] };

    this.findJsonIds(ctxt);
    this.setUnresolvedReferences(ctxt);

    return input;
  }

  /**
   * populates resolvedReferences with objects found in input with $id property as their key
   * and finds unresolved resferences and adds them to unresolvedItems
   */
  private findJsonIds<T>(ctxt: ResolverContext<T>) {

    const stack: StackItem[] = [];
    let cur: StackItem | undefined;

    processChild(ctxt.input, null, null!);
    let cnt = 0;
    while ((cur = stack.pop()) != null) {
      const item = cur.item;
      if (cnt++ > this.referenceLimit) {
        throw new Error('Reference Limit Exceeded');
      }
      if (isArrayStackItem(cur)) {
        cur.item.forEach((x, i) => processChild(x, item, i));
      } else {
        // this is a reference property, add it to referenceMap
        if (isReferencedObject(item) && item.$id) {
          ctxt.referenceMap.set(item.$id, item);
          delete item.$id; // clean up prop
        }
        Object.entries(item as Record<string, unknown>).forEach(([key, value]) => processChild(value, item, key));
        // for (propKey of Object.keys(item as Record<string, unknown>)) {
        //   processChild(item[propKey], item, propKey);
        // }
      }
    }

    /** Adds an item to stack if it is an object or adds its elements if it is an array. */
    function processChild(child: unknown, parent: unknown, indexKey: string | number) {

      if (Array.isArray(child)) {
        stack.push({ item: child, isArray: true });
      }
      else if (isStackItemValue(child)) {
        if (isRefObject(child)) {
          ctxt.unresolvedItems.push({ parent, indexKey, referenceKey: child.$ref });
        } else {
          stack.push({ item: child, isArray: false });
        }
      }
    }
    /** Returns true if the passed value has an $id property. */
    function isReferencedObject(value: unknown): value is { $id?: string } {
      return (value as { $id?: string }).hasOwnProperty('$id');
    }

    function isRefObject(value: unknown): value is { $ref: string } {
      return (value as { $ref?: string }).hasOwnProperty('$ref');
    }

    function isArrayStackItem(value: StackItem): value is StackItem<unknown[]> {
      return value.isArray;
    }

    function isStackItemValue(value: unknown): value is Record<string, unknown> | unknown[] {
      return typeof value === 'object' && value != null;
    }
  }

  private setUnresolvedReferences<T>(ctxt: ResolverContext<T>) {
    for (const unresolved of ctxt.unresolvedItems) {
      (unresolved.parent as Record<string | number, unknown>)[unresolved.indexKey] = ctxt.referenceMap.get(unresolved.referenceKey);
    }
  }
}

