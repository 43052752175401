<menu-tray-wrapper trayId="CommentManagement">
  <div menu-tray-header>
    <strong>Comments: {{title$ | async}}</strong>
  </div>
  <ng-container menu-tray-panel>
    <div class="d-flex flex-row" style="min-height: 100%; max-height: 100%">
      <div class="flex-grow-1" style="overflow-y: auto" #CommentsHost>
        <table class="table table-striped mb-1 table-sm" >
          <thead class="thead-light">
            <tr>
              <th class="sticky-top pl-2" style="width:1%"></th>
              <th class="sticky-top">Creator</th>
              <th class="sticky-top text-nowrap">Created On</th>
              <th class="sticky-top pr-2" style="width:70%">Comment</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-c [ngForOf]="comments$ | async">
              <tr *ngIf="!c.isHidden">
                <td>
                  <div class="btn-link cursor-pointer" title="Hide Comment"
                       [hidden]="c.creatorId != (currentUserId$ | async) || state != 'ready' || isReadOnly"
                       (click)="hideComment(c)">
                    <i class="fa fa-trash"></i>
                  </div>
                </td>
                <td class="text-nowrap">{{c.creator}}</td>
                <td class="text-nowrap">{{c.createdOn | date: 'short' }}</td>
                <td class="pr-2">{{c.comment}}</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="d-flex flex-column mb-2 mx-1" style="min-width:300px;" *ngIf="!isReadOnly">
        <div class="d-flex d-row">
          <h5 class="flex-grow-1">New Comment</h5>
          <div *ngIf="!validateCommentText()" class="small text-muted flex-shrink-1 d-flex flex-column pb-2 justify-content-end">
            <div>
              {{newCommentText?.length || 0}} characters ({{(minCommentLength - (newCommentText?.length || 0))}} to go)
            </div>
          </div>
        </div>
        <textarea name="newCommentText" [(ngModel)]="newCommentText" class="w-100 mb-2 flex-grow-1"></textarea>
        <div>
          <button type="button" class="btn btn-primary btn-sm" (click)="canAddComment && saveNewComment()"
                  [disabled]="!canAddComment">
            Add Comment
          </button>
        </div>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="state != 'ready'">
      <span *ngIf="state == 'loading'">Loading</span>
      <span *ngIf="state == 'saving'">Saving</span>
    </tcc-loadingOverlay>
  </ng-container>
</menu-tray-wrapper>
