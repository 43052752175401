import { Injectable } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { MathAction } from '../../controls/math-menu/math-action';
import { RenewalsStateService } from './renewals-state.service';
@Injectable({
  providedIn: 'root'
})
export class RenewalMathService {

  private estimateUpdateSubject = new Subject<{ revAreaId: number, value: number | null | undefined }>();

  readonly actions: MathAction[] = [
    { func: () => EMPTY, name: 'Adjust Selected', isPlaceholder: true },
    { func: (expr) => this.selectedModifyAbsolute(expr), name: 'Add $', requiresValue: true },
    { func: (expr) => this.selectedModifyPercent(expr), name: 'Add %', requiresValue: true },
    { func: (expr) => this.selectedSetAbsolute(expr), name: 'Set $', requiresValue: true },
    { func: (expr) => this.selectedSetPercent(expr), name: 'Set % (vs. Act)', requiresValue: true },
    { func: () => this.selectedClearOffers(), name: 'Clear Offers', requiresValue: false },
  ];

  readonly estimateUpdate$ = this.estimateUpdateSubject.asObservable();

  constructor(private renewalState: RenewalsStateService) { }

  selectedClearOffers() {
    return this.getCurrentUnitAreas$().pipe(
      tap(units => units
        .filter(x => x.estimates.estRenewalRate != null)
        .forEach(x => this.estimateUpdateSubject.next({ revAreaId: x.revAreaId, value: null }))
      )
    );
  }

  selectedModifyAbsolute(expr: string) {
    const exprValue = parseFloat(expr);
    return this.getCurrentUnitAreas$().pipe(
      tap(units => units
        .filter(x => x.estimates.estRenewalRate != null)
        .forEach(x => {
          const value = Math.round(x.estimates.estRenewalRate! + exprValue);
          this.estimateUpdateSubject.next({ revAreaId: x.revAreaId, value });
        })
      )
    );
  }

  selectedModifyPercent(expr: string) {
    const modificationPct = 1 + parseFloat(expr) / 100;
    return this.getCurrentUnitAreas$().pipe(
      tap(units => units
        .filter(x => x.estimates.estRenewalRate != null)
        .forEach(x => {
          const value = Math.round(x.estimates.estRenewalRate! * modificationPct);
          this.estimateUpdateSubject.next({ revAreaId: x.revAreaId, value });
        })
      )
    );
  }
  selectedSetAbsolute(expr: string) {
    const exprValue = parseFloat(expr);
    return this.getCurrentUnitAreas$().pipe(
      tap(units => units
        .filter(x => x.estimates.estRenewalRate !== exprValue)
        .forEach(({ revAreaId }) => this.estimateUpdateSubject.next({ revAreaId, value: exprValue }))
      )
    );
  }

  selectedSetPercent(expr: string) {
    const modificationPct = 1 + parseFloat(expr) / 100;
    return this.getCurrentUnitAreas$().pipe(
      tap(units => units
        .forEach(({ estimates, revAreaId }) => {
          const value = (estimates.origAvgRate != null)
            ? Math.round(estimates.origAvgRate * modificationPct)
            : undefined;
          if (value !== estimates.estRenewalRate) {
            this.estimateUpdateSubject.next({ revAreaId, value });
          }
        })
      )
    );
  }

  /** Returns relevent info needed to perform calculations from current value of selectedUnitAreas . */
  private getCurrentUnitAreas$() {
    return this.renewalState.selectedUnitAreas$.pipe(
      take(1),
      map(x => x.map(u => ({ revAreaId: u!.revAreaId, estimates: u!.periods[this.renewalState.tgtPeriodIndex] })))
    );
  }
}
