<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    Reports<span *ngIf="displayedReport?.name">: {{displayedReport?.name}}</span>
  </div>
  <div panel-body>

    <div class="d-print-none d-flex flex-row">
      <div class="form-group mr-3 mb-2">
        <label tccLabelForNext class="mr-1">Report</label>
        <select [(ngModel)]="selectedReport" class="form-control" style="width: auto">
          <option [ngValue]="undefined">- Select Report -</option>
          <option *ngFor="let opt of reportsSvc.reportDefinitions" [ngValue]="opt">{{opt.name}}</option>
        </select>
      </div>
      <div class="form-group mr-3 mb-2" *ngFor="let p of selectedReport?.parameters">
        <label tccLabelForNext class="mr-1">
          {{p.name}}
        </label>
        <ng-container *ngIf="!p.options && !p.dynamicOptions; else HasOptions">
          <input type="text" [(ngModel)]="p.value" class="form-control" style="width: auto" />
        </ng-container>
        <ng-template #HasOptions>
          <select [(ngModel)]="p.value" class="form-control" style="width: auto">
            <option *ngFor="let opt of getParamOptions(p)" [ngValue]="opt.value">{{opt.label}}</option>
          </select>
        </ng-template>
      </div>
    </div>
    <div class="d-print-none mb-2">
      <button [disabled]="!canRunSelectedReport()" class="btn btn-sm btn-primary mr-1" (click)="runSelectedReport()">
        Run Report
      </button>
      <button [disabled]="!cube" class="btn btn-sm btn-primary" (click)="exportReport()">
        Export
      </button>
    </div>

    <report-cube-display [cube]="cube"></report-cube-display>

  </div>
  <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
</tcc-simplePanel>
