import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NotificationsService } from '@tcc/ui';
import { WorkflowActionType } from '../client-api.service';

/** Guards against a user ledger route where there are improper args. */
@Injectable({ providedIn: 'root' })
export class LedgerGuard implements CanActivate {
  constructor(private router: Router, private notifSvc: NotificationsService) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const orgId = parseInt(route.queryParamMap.get('orgId')!, 10);
    const viewId = parseInt(route.queryParamMap.get('viewId')!, 10);
    const action: WorkflowActionType = WorkflowActionType[route.queryParamMap.get('action') as WorkflowActionType];

    if (orgId == null || isNaN(orgId) || viewId == null || isNaN(viewId) || action == null || !(action in WorkflowActionType)) {
      this.notifSvc.addError('Invalid parameters in url.  Please select your activity from the home menu');
      this.router.navigate(['./home']);
      return false;
    }

    return true;
  }
}
