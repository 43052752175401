import { Injectable } from '@angular/core';
import { concat, merge, Observable, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ClientApi, Workflow, WorkflowStep, WorkflowStepUpsertInfo, WorkflowSummary } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';
import { FileUtil } from '../shared/file-util';

@Injectable({
  providedIn: 'root'
})
export class WorkflowsService {
  private static readonly wfNameSorter = ArrayUtil.compareSelectorStringsFuncFactory<Workflow>(x => x.name,
     { ignoreCase: true });
  private readonly helpFileObjectUrlCache = new Map<number, Observable<string>>();
  private readonly workflowsInvalidatedSubject = new Subject<unknown>();

  // returns cached workflows, can be undefined if the workflows were invalidated.
  readonly workflows$ = merge(
    this.clientApi.getWorkflows(),
    this.workflowsInvalidatedSubject.pipe(
      // clear items and then wait a bit before retrieving new items
      switchMap(() => concat(of(<Workflow[]><unknown>undefined), timer(50).pipe(switchMap(() => this.clientApi.getWorkflows()))))
    )
  ).pipe(
    distinctUntilChanged(),
    map(x => x ? x.sort(WorkflowsService.wfNameSorter) : undefined),
    shareReplay(1)
  );


  constructor(private clientApi: ClientApi) { }

  deleteWorkflow(workflowId: number) {
    return this.clientApi.deleteWorkflowById(workflowId)
      .pipe(tap(() => this.invalidateWorkflowsCache()));
  }

  deleteStep(stepId: number) {
    return this.clientApi.deleteWorkflowStep(stepId)
      .pipe(tap(() => this.invalidateStepCache(stepId)));
  }

  /** Gets dataUrl for help file */
  getHelpFileUrl(stepId: number) {
    if (this.helpFileObjectUrlCache.has(stepId)) {
      return this.helpFileObjectUrlCache.get(stepId);
    }
    const url$ = this.clientApi.getWorkflowStepHelpInfoById(stepId).pipe(
      map(x => URL.createObjectURL(x.data)),
      shareReplay(1)
    );
    this.helpFileObjectUrlCache.set(stepId, url$);
    return url$;
  }

  /** gets all workflow states */
  getWorkflowStates() {
    return this.clientApi.getWorkflowStates();
  }

  /**
   * invalidates helpfile cache for step and all workflows.
   */
  invalidateStepCache(stepId: number) {
    if (this.helpFileObjectUrlCache.has(stepId)) {
      this.helpFileObjectUrlCache.delete(stepId);
    }
    // invalidate all workflows cached.
    this.workflowsInvalidatedSubject.next();
  }

  /** invalidates all caches */
  invalidateWorkflowsCache() {
    this.helpFileObjectUrlCache.clear();
    this.workflowsInvalidatedSubject.next();
  }

  /**
   * Adds a step invalidating workflows in the process
   */
  upsertStep(step: WorkflowStepUpsertInfo, file?: File) {
    let obs$: Observable<WorkflowStep>;
    if (file && !step.helpInfoClear) {
      obs$ = FileUtil.blobOrFileToDataUrl(file).pipe(
        map(x => FileUtil.removePrefixFromDataUrl(x)),
        switchMap(x => {
          step.helpFileData = x;
          return this.clientApi.postWorkflowStep(step);
        })
      );
    } else {
      obs$ = this.clientApi.postWorkflowStep(step);
    }
    return obs$.pipe(tap(x => this.invalidateStepCache(x.stepId)));
  }

  /**
   * Upserts a workflow, invalidating workflow cache in the process
   */
  upsertWorkflow(workflow: WorkflowSummary) {
    return this.clientApi.upsertWorkflow(workflow)
      .pipe(tap(() => this.invalidateWorkflowsCache()));
  }
}
