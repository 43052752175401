<ng-container>
  <ng-container *ngIf="displayMode == 'NoModel'">
    <td class="text-left">
      &mdash;
    </td>
    <td class="text-left border-right">
      &mdash;
    </td>
    <td class="text-left">
      &mdash;
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'">
      &mdash;
    </td>
    <td [ngClass]="{'border-right':subView != 'WagesAndAllowances'}">
      &mdash;
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'" class="border-right">
      &mdash;
    </td>
    <ng-container *ngIf="subView == 'WagesAndAllowances'; else HourAllocationsReadOnly">
      <ng-template ngFor let-pt [ngForOf]="payrollState.otherPayTypes" let-$index="index">
        <td>
          &mdash;
        </td>
        <td>
          &mdash;
        </td>
        <td class="border-right">
          &mdash;
        </td>
      </ng-template>
    </ng-container>
    <ng-template #HourAllocationsReadOnly>
      <ng-template ngFor let-m [ngForOf]="payrollState.months" let-$index="index">
        <td [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
          &mdash;
        </td>
        <td class="border-right"
            [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
          &mdash;
        </td>
      </ng-template>
    </ng-template>
    <td>
      &mdash;
    </td>
  </ng-container>
  <ng-container *ngIf="displayMode == 'Edit'" ngForm [formGroup]="employeeForm!">
    <td class="text-left" title="Employee's Name">
      <input type="text" formControlName="name" tccValidityClasses="is-invalid"
             required tccSelectOnFocus  style="min-width:200px;width:100%;" />
    </td>
    <td class="text-left" title="Position" style="width:1%">
      <select formControlName="position" tccValidityClasses="is-invalid">
        <option *ngFor="let pos of payrollState.positionNames" [value]="pos">{{pos}}</option>
      </select>
    </td>
    <td class="text-left border-right" title="Full Time or Part Time Status" style="width:1%">
      <select formControlName="isFullTime" tccValidityClasses="is-invalid">
        <option *ngFor="let so of statusOptions" [ngValue]="so.value">{{so.text}}</option>
      </select>
    </td>
    <td  class="text-left" title="Hourly or Annual Pay Rate" style="width:1%">
      <select formControlName="basePayItem_payPeriodType" tccValidityClasses="is-invalid">
        <option *ngFor="let pt of basePayPeriods" [ngValue]="pt.val">{{pt.name}}</option>
      </select>
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'" title="Current Wages" style="width:1%">
      <input type="number" formControlName="basePayItem_current_amount"  tccValidityClasses="is-invalid" tccSelectOnFocus class="payrollInput" style="min-width:4.5em"/>
    </td>
    <td [ngClass]="{'border-right':subView != 'WagesAndAllowances' }" title="Proposed Wages" style="width:1%">
      <input type="number" formControlName="basePayItem_proposed_amount"  tccValidityClasses="is-invalid" tccSelectOnFocus class="payrollInput" style="min-width:4.5em" />
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'" class="border-right" title="Wages Variance">
      {{payrollState.getProposedVarianceFromCurrentPercent(model!.payItemAmounts[payrollState.basePayType!.payTypeId]) | percent:'1.1-1'}}
    </td>
    <ng-container *ngIf="subView == 'WagesAndAllowances'; else HourAllocations">
      <ng-template ngFor let-pt [ngForOf]="payrollState.otherPayTypes" let-$index="index">
        <td [title]="'Current ' + payrollState.payTypeMap!.get(pt.payTypeId)!.name">
          <input type="number" [formControlName]="'otherPayItems_' + pt.payTypeId + '_current_amount'"
                 class="payrollInput" tccValidityClasses="is-invalid" tccSelectOnFocus />
        </td>
        <td [title]="'Proposed ' + payrollState.payTypeMap!.get(pt.payTypeId)!.name">
          <input type="number" [formControlName]="'otherPayItems_' + pt.payTypeId + '_proposed_amount'"
                 class="payrollInput" tccValidityClasses="is-invalid" tccSelectOnFocus />
        </td>
        <td class="border-right" [title]="payrollState.payTypeMap!.get(pt.payTypeId)!.name + ' Variance'">
          {{payrollState.getProposedVarianceFromCurrentPercent(model!.payItemAmounts[pt.payTypeId]) | percent:'1.1-1'}}
        </td>
      </ng-template>
    </ng-container>
    <ng-template #HourAllocations>
      <ng-template ngFor let-m [ngForOf]="payrollState.months" let-$index="index">
        <td [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index) }" [title]="'Hours Worked per week in ' + m">
          <input type="number" *ngIf="!payrollState.isOvertimeExempt(model!); else isOtExempt" [formControlName]="'hourAllocations_' + $index + '_hours'"
                 tccValidityClasses="is-invalid" tccSelectOnFocus class="text-right" style="width:2em" />
          <ng-template #isOtExempt>
            <span class="text-muted" title="Employee is exempt from Over Time pay">—</span>
          </ng-template>
        </td>
        <td class="border-right" [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
            {{payrollState.getPeriodWeekFactor($index) * payrollState.getWeeklyBasePay(model!, $index).totalPay | number:'1.0-0'}}

        </td>
      </ng-template>
    </ng-template>
    <td>{{payrollState.getEmployeeTotalCompensation(model!) | number:'1.2-2' }}</td>
  </ng-container>
  <ng-container *ngIf="displayMode == 'ReadOnly'">
    <td title="Employee's Name" class="text-left">
      {{model!.name}}
    </td>
    <td title="Position" class="text-left">
      {{model!.position}}
    </td>
    <td class="text-center border-right" title="Full Time or Part Time Status">
      {{getStatusOptionText(model!.isFullTime)}}
    </td>
    <td title="Hourly or Annual Pay Rate">
      {{model!.payItemAmounts[payrollState.basePayType!.payTypeId].payPeriodType}}
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'" title="Current Wages">
      {{model!.payItemAmounts[payrollState.basePayType!.payTypeId].currentAmount | number:'1.2-2'}}
    </td>
    <td [ngClass]="{'border-right':subView != 'WagesAndAllowances'}" title="Proposed Wages">
      {{model!.payItemAmounts[payrollState.basePayType!.payTypeId].proposedAmount | number:'1.2-2'}}
    </td>
    <td [hidden]="subView != 'WagesAndAllowances'" class=" border-right" title="Wages Variance">
      {{payrollState.getProposedVarianceFromCurrentPercent(model!.payItemAmounts[payrollState.basePayType!.payTypeId]) | percent:'1.1-1'}}
    </td>
    <ng-container *ngIf="subView == 'WagesAndAllowances'; else hourAllocationsReadOnly">
      <ng-template ngFor let-pt [ngForOf]="payrollState.otherPayTypes" let-$index="index">
        <td [title]="'Current ' + payrollState.payTypeMap!.get(pt.payTypeId)!.name">
          {{model!.payItemAmounts[pt.payTypeId].currentAmount | number:'1.2-2'}}
        </td>
        <td [title]="'Proposed ' + payrollState.payTypeMap!.get(pt.payTypeId)!.name">
          {{model!.payItemAmounts[pt.payTypeId].proposedAmount | number:'1.2-2'}}
        </td>
        <td class="border-right" [title]="payrollState.payTypeMap!.get(pt.payTypeId)!.name + ' Variance'">
          {{payrollState.getProposedVarianceFromCurrentPercent(model!.payItemAmounts[pt.payTypeId]) | percent:'1.1-1'}}
        </td>
      </ng-template>
    </ng-container>
    <ng-template #hourAllocationsReadOnly>
      <ng-template ngFor let-m [ngForOf]="payrollState.months" let-$index="index">
        <td [title]="'Hours Worked per week in ' + m" class="text-right" style="width: 2em"
            [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
          <span *ngIf="!payrollState.isOvertimeExempt(model!)" class="pr-1">
            {{model!.monthlyHourAllocations[$index].hours | number:'1.0-0' }}
          </span>
          <span *ngIf="payrollState.isOvertimeExempt(model!)" class="pr-1 text-muted" title="Employee is exempt from Over Time pay">—</span>
        </td>
        <td class="border-right"
            [ngClass]="{'hl-col': payrollState.hourlyPayConfig.highlightMonth($index)}">
          <span>{{payrollState.getPeriodWeekFactor($index) * payrollState.getWeeklyBasePay(model!, $index).totalPay | number:'1.0-0'}}</span>
        </td>
      </ng-template>
    </ng-template>
    <td>{{payrollState.getEmployeeTotalCompensation(model!) | number:'1.2-2' }}</td>
  </ng-container>
</ng-container>
