import { Injectable } from '@angular/core';
import { CalculationsService, ApplyToTransformFunction } from '../calculations/calculations.service';
import { LedgerStateService } from './ledger-state.service';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MathAction } from '../controls/math-menu/math-action';

/** Functions that work on the currently selected account from the ledger state. */
@Injectable({
  providedIn: 'root'
})
export class LedgerAccountMathService {

  readonly actions: MathAction[] = [
    // tslint:disable: max-line-length
    { name: 'Add', func: (x) => this.add(x), description: 'Add amount to all months', iconTemplate: `<i class='fa fa-plus'></i>`, requiresValue: true },
    { name: 'Subtract', func: (x) => this.subtract(x), description: 'Subtract amount from all months', iconTemplate: `<i class='fa fa-minus'></i>`, requiresValue: true },
    { name: 'Multiply', func: (x) => this.multiply(x), description: 'Multiply all months by amount', iconTemplate: `<i class='fa fa-times'></i>`, requiresValue: true },
    { name: 'Divide', func: (x) => this.divide(x), description: 'Divide all months by amount', iconTemplate: `<span class='font-icon'>&#xf7;</span>`, requiresValue: true },
    { name: 'Distribute Evenly', func: (x) => this.distribute(x, 1), description: 'Distribute amount evenly across all months', iconTemplate: `<span class='font-icon frac'><sup>1</sup>&frasl;<sub>12</sub></span>`, requiresValue: true },
    { name: 'Distribute Alternating', func: (x) => this.distribute(x, 2), description: 'Distribute amount every other month', iconTemplate: `<span class='font-icon frac'><sup>1</sup>&frasl;<sub>6</sub></span>`, requiresValue: true },
    { name: 'Distribute Quarterly', func: (x) => this.distribute(x, 3), description: 'Distribute amount Quarterly', iconTemplate: `<span class='font-icon frac'><sup>1</sup>&frasl;<sub>4</sub></span>`, requiresValue: true },
    { name: 'Copy to All', func: (x) => this.overwrite(x, 1), description: 'Copy amount to all months', iconTemplate: `<span class='font-icon frac'>12&#xd7;</span>`, requiresValue: true },
    { name: 'Copy Alternating', func: (x) => this.overwrite(x, 2), description: 'Copy amount to every other month', iconTemplate: `<span class='font-icon frac'>6&#xd7;</span>`, requiresValue: true },
    { name: 'Copy Quarterly', func: (x) => this.overwrite(x, 3), description: 'Copy amount to the start of each Quarter', iconTemplate: `<span class='font-icon frac'>4&#xd7;</span>`, requiresValue: true },
    // tslint:enable: max-line-length
  ];

  constructor(private calcSvc: CalculationsService, private ledgerState: LedgerStateService) { }


  /** Calls applyFunc with a function that adds to each entry the resolved amount */
  add(expr: string) {
    return this.applyFunc((cur, _, amt) => cur + amt, expr);
  }
  /** Calls applyFunc with a function that subtracts each entry by the resovled amt */
  subtract(expr: string) {
    return this.applyFunc((cur, _, amt) => cur - amt, expr);
  }
  /** Calls applyFunc with a function that multiplies each entry by the resolved amt */
  multiply(expr: string) {
    return this.applyFunc((cur, _, amt) => cur * amt, expr);
  }

  /** Calls applyFunc with a function that divides each entry by the resolved amount */
  divide(expr: string) {
    return this.applyFunc((cur, _, amt) => cur / amt, expr);
  }

  /**
   * Calls applyFunc with a function that distributes the resolved amt evenly to each entry
   * @param fillEveryNEntry defaults to 1, skips every N entry
   */
  distribute(expr: string, fillEveryNEntry: number = 1) {
    return this.applyFunc((_, index, amt) => (index % fillEveryNEntry === 0) ? amt / (12 / fillEveryNEntry) : 0, expr);
  }
  /**
   * Calls applyFunc with a function that sets the entry to resolved amt
   * @param fillEveryNEntry defaults to 1, skips every N entry
   */
  overwrite(expr: string, fillEveryNEntry: number = 1) {
    return this.applyFunc((_, index, amt) => (index % fillEveryNEntry === 0) ? amt : 0, expr);
  }

  /** Calls applyFunc on ledger controller, passing the current applyToExpression. */
  private applyFunc(func: ApplyToTransformFunction, applyToExpression: string) {
    if (applyToExpression && this.ledgerState.selectedAccount) {
      return this.calcSvc
        .executeRawExpression(applyToExpression)
        .pipe(
          tap(results => this.calcSvc.applyAmounts(this.ledgerState.selectedAccount!, results, func)),
        );
    }

    return of(undefined);
  }
}
