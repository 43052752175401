export type AggregationType = 'avg' | 'max' | 'min' | 'sum';

interface AggregateOptions {
  /** if true averages will ignore blank entries.  Otherwise they will be treated as 0. */
  skipBlanks?: boolean;
}
/** performs aggregations functions on arrays.
 * Note: Keeping arrays seperate from selectors allows us to handle undefined arrays. */
export class AggregateUtil {


  static avg(src: (number | null | undefined)[], opts: AggregateOptions = {}) {
    let sum = 0;
    let count = 0;

    for (const n of (src || [])) {
      if (n != null) {
        sum += n;
        count++;
      }
      else if (!opts.skipBlanks) {
        count++;
      }
    }
    return (count !== 0) ? sum / count : undefined;
  }

  static max<TItem>(src: TItem[]) {
    const filteredSrc = (src || []).filter(x => x != null);
    return (filteredSrc && filteredSrc.length !== 0)
      ? filteredSrc.reduce((acc, cur) => acc > cur ? acc : cur)
      : undefined;
  }

  static min<TItem>(src: TItem[]) {
    const filteredSrc = (src || []).filter(x => x != null);
    return (filteredSrc && filteredSrc.length !== 0)
      ? filteredSrc.reduce((acc, cur) => acc < cur ? acc : cur)
      : undefined;
  }

  static sum(src: (number | null | undefined)[]) {
    return (src || [])
      .filter((x): x is number => x != null)
      .reduce((acc, cur) => acc + cur, 0);
  }

  static sumMulti<TItem>(src: TItem[], selectors: ((x: TItem) => number)[]) {
    const sums = selectors.map(() => 0);
    let value: number;
    for (const item of src) {
      if (item != null) {
        for (let selectorIdx = 0; selectorIdx < selectors.length; selectorIdx++) {
          value = selectors[selectorIdx](item);
          if (value) {
            sums[selectorIdx] += value;
          }
        }
      }
    }
    return sums;
  }
}
