<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    Revenue Rollup
  </div>
  <div panel-body style="overflow-y:auto; height: 100%" class="d-print-block">
    <table class="table table-striped table-sm small">
      <thead>
        <tr class="thead-dark border-bottom-0">
          <th colspan="2" class="sticky-top text-center border-bottom-0"></th>
          <th colspan="5" class="bg-secondary sticky-top text-center border-bottom-0">Current Year</th>
          <th colspan="4" class="sticky-top text-center border-bottom-0">Renewals</th>
          <th colspan="3" class="bg-secondary sticky-top text-center border-bottom-0">Targets</th>
        </tr>
        <tr class="thead-dark border-top-0">
          <th class="sticky-top d-print-none" style="width:1px"></th>
          <th class="sticky-top">Name</th>
          <th class="text-right sticky-top bg-secondary">Fp Total</th>
          <th class="text-right sticky-top bg-secondary">Actual Total</th>
          <th class="text-right sticky-top bg-secondary">Fp Avg</th>
          <th class="text-right sticky-top bg-secondary">Act Avg</th>
          <th class="text-right sticky-top bg-secondary">Act vs Fp %</th>
          <th class="text-right sticky-top">Renewal Total</th>
          <th class="text-right sticky-top">Renewal Avg</th>
          <th class="text-right sticky-top">Ren vs Fp %</th>
          <th class="text-right sticky-top">Ren vs Act %</th>
          <th class="text-right sticky-top bg-secondary">Renewal Target %</th>
          <th class="text-right sticky-top bg-secondary">Occupancy Target %</th>
          <th class="text-right sticky-top bg-secondary">Rate Target %</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let x of summaries">
          <td class="p-0 d-print-none" style="width:1px">
            <div class="btn-group">
              <button [routerLink]="['..', x.orgId ,'renewals']" class="btn py-0 btn-sm btn-link bg-light" [title]="'Renewal Planning for ' + x.name">
                <i class="fas fa-funnel-dollar"></i>
              </button>
              <button [routerLink]="['..', x.orgId ,'revenue']" class="btn py-0 btn-sm btn-link bg-light" [title]="'Revenue Planning for ' + x.name">
                <i class="fas fa-file-invoice-dollar"></i>
              </button>
            </div>
          </td>
          <td>{{x.name}}</td>
          <td class="text-right">{{x.fpTotal | number: '0.0-0'}}</td>
          <td class="text-right">{{x.actTotal | number: '0.0-0'}}</td>
          <td class="text-right">{{x.fpAvg | number: '0.0-0'}}</td>
          <td class="text-right">{{x.actAvg | number: '0.0-0'}}</td>
          <td class="text-right">{{x.actVsFpPct | percent: '0.2-2'}}</td>
          <td class="text-right">{{x.renewalOfferTotal | number: '0.0-0'}}</td>
          <td class="text-right">{{x.renewalOfferAvg | number: '0.0-0'}}</td>
          <td class="text-right">{{x.renewalVsFpPct | percent: '0.2-2'}}</td>
          <td class="text-right">{{x.renewalVsActPct | percent: '0.2-2'}}</td>
          <ng-container *ngIf="!(isReadOnly$ | async); else ReadOnly">
            <td class="text-right">
              <input #renewalPctInput="ngModel"
                     style="width: 6rem" class="text-right input-subtle" tccSelectOnFocus
                     pattern="^\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*%?\s*$"
                     [ngClass]="{'is-invalid': renewalPctInput.invalid, 'bg-light': !renewalPctInput.invalid }"
                     [ngModel]="x.targetRenewalPct || 0| percent: '0.2-2'"
                     (ngModelChange)="renewalPctInput.valid ? queueTargetChange(x.orgId, estimateTypeEnumRef.TargetRenewalPct, $event) : null"
                     (blur)="forceTargetChange(x.orgId, estimateTypeEnumRef.TargetRenewalPct)"/>
            </td>
            <td class="text-right">
              <input #occupancyPctInput="ngModel"
                     style="width: 6rem" class="text-right input-subtle" tccSelectOnFocus
                     pattern="^\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*%?\s*$"
                     [ngClass]="{'is-invalid': occupancyPctInput.invalid, 'bg-light': !occupancyPctInput.invalid }"
                     [ngModel]="x.targetOccupancyPct || 0 | percent: '0.2-2'"
                     (ngModelChange)="occupancyPctInput.valid ? queueTargetChange(x.orgId, estimateTypeEnumRef.TargetOccupancyPct, $event) : null"
                     (blur)="forceTargetChange(x.orgId, estimateTypeEnumRef.TargetOccupancyPct)" />
            </td>
            <td class="text-right">
              <input #revIncreasePctInput="ngModel"
                     style="width: 6rem" class="text-right input-subtle" tccSelectOnFocus
                     pattern="^\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*%?\s*$"
                     [ngClass]="{'is-invalid': revIncreasePctInput.invalid, 'bg-light': !revIncreasePctInput.invalid }"
                     [ngModel]="x.targetRevenueIncreatePct || 0 | percent: '0.2-2'"
                     (ngModelChange)="revIncreasePctInput.valid ? queueTargetChange(x.orgId, estimateTypeEnumRef.TargetRateIncreasePct, $event) : null"
                     (blur)="forceTargetChange(x.orgId, estimateTypeEnumRef.TargetRateIncreasePct)" />
            </td>
          </ng-container>
          <ng-template #ReadOnly>
            <td class="text-right">{{x.targetRenewalPct | percent: '0.2-2'}}</td>
            <td class="text-right">{{x.targetOccupancyPct | percent: '0.2-2'}}</td>
            <td class="text-right">{{x.targetRevenueIncreatePct | percent: '0.2-2'}}</td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
