import { CalcExpression } from '../shared/calculation-expression';

export interface IOrgCalculationRule {
  /** An expression to be evaluated */
  calcExpr: CalcExpression;
  /** optional orgCode for assignments but not used in preCalculations */
  orgCode?: string;
  /**
   * Account code or variable name to assign to.
   * In the context of a preCalculation it will be a variable that can be used in the assignment calculation
   * In the context of an assignmentCalulcation it is the destination accountCode.
   */
  varName: string;
}

export class OrgCalculationTransform {
  /** source organization where data is pulled from.  Destination organizations are in assignmentCalculations */
  orgCode?: string;
  /** how many months (up to 12) to lag source values */
  sourceLag = 0;
  /** calculations that are exeucted to create variables used in assignment calculations */
  sourceCalculations: IOrgCalculationRule[] = [];
  /** calculations that are run and set an account for an organization. */
  assignmentCalculations: IOrgCalculationRule[] = [];
  /** Optional source view where to get calculations from */
  sourceViewName?: string;
}
