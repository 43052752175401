import { Directive, HostListener, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, tap, takeUntil } from 'rxjs/operators';

/**  Closes an ngbDropDown after a period timeout if there is no mouse activity. */
@Directive({
  selector: '[appDropDownTimerClose]'
})
export class DropDownTimerCloseDirective implements AfterViewInit, OnDestroy {
  /** If timeout is not set, then defaultTimeout should be used. */
  private readonly defaultTimeout = 10000;
  private destroyedSubject = new Subject();
  private dropdownActivitySubject = new Subject();
  /** Timeout value set by default directive input. */
  private timeout: number = this.defaultTimeout;

  /** Sets timer from directive input. */
  @Input()
  set appDropDownTimerClose(value: string | undefined) {
    this.timeout = this.defaultTimeout;
    if (value) {
      const timeout = parseFloat(value);
      if (!isNaN(timeout)) {
        this.timeout = timeout;
      }
    }
  }

  constructor(private dropDown: NgbDropdown) { }

  ngAfterViewInit() {
    this.dropdownActivitySubject.pipe(
      takeUntil(this.destroyedSubject),
      filter(() => this.dropDown.isOpen()),
      switchMap(() => timer(this.timeout)),
      filter(() => this.dropDown.isOpen()),
      tap(() => this.dropDown.close())
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroyedSubject.next();
  }

  /** emits destroyedSubject due to a user interaction event. */
  @HostListener('click')
  @HostListener('input')
  @HostListener('mousemove')
  onActivity() {
    this.dropdownActivitySubject.next();
  }

}
