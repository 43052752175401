import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[myTooltip]'
})
export class ScrollingtooltipDirective {

  @Input('myTooltip') myTooltipContent!: string;
  tooltip: HTMLElement | undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  showTooltip() {
    this.tooltip = this.renderer.createElement('div');
    this.tooltip!.innerHTML = '<div class="tooltipClass"' + this.myTooltipContent + '</div>';
    this.renderer.addClass(this.tooltip, 'tooltipContainer');
    this.renderer.appendChild(this.el.nativeElement, this.tooltip);
  }

  hideTooltip() {
    this.renderer.removeChild(this.el.nativeElement, this.tooltip);
  }

}
