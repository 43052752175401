export class SortUtil {

    static readonly baseComparableSortFunc = (a: any, b: any) => (a < b) ? -1 : (a > b) ? 1 : 0;
    static readonly baseNumericSortFunc = (a: number, b: number) => a - b;
    static readonly caseInsensitveStringSortFunc = (a: string, b: string) =>
      SortUtil.baseComparableSortFunc(a.toLowerCase(), b.toLowerCase());
  
  
    /**
     * Creates a generic sort comprarer, using valueFunc to get the comparison value, and comparer to evalute results
     */
    static genericSortFactory(valueFunc: (item: any) => any, comparer: (a: any, b: any) => number) {
      return (a: any, b: any) => {
        const valA = valueFunc(a);
        const valB = valueFunc(b);
        if (valA === valB) { return 0; }
        if (valA == null && valB != null) { return -1; }
        if (valA != null && valB == null) { return 1; }
        return comparer(valA, valB);
      };
    }
  
    /**
     * creates a case insensitive string comparer, using valueFunc to get string values to compare.
     */
    static caseInsensitiveStringSortFactory<T>(valueFunc: (item: T) => string) {
      return this.genericSortFactory(valueFunc, this.caseInsensitveStringSortFunc);
    }
    /**
     * creates a numeric sort comparer, using valueFunc to get number values to compare.
     */
    static numericSortFactory<T>(valueFunc: (item: T) => number) {
      return this.genericSortFactory(valueFunc, this.baseNumericSortFunc);
    }
  
    /**
     * creates a value sort comparer, using valueFunc to get number values to compare.
     */
    static valueSortFactory<T>(valueFunc: (item: T) => any) {
      return this.genericSortFactory(valueFunc, this.baseComparableSortFunc);
    }
  
  }
  