import { Directive, StaticProvider, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidatorFn, Validator, ValidationErrors } from '@angular/forms';

export interface FileValidationRule {
  /** the extension of the file that is allowed. */
  ext?: string;
  /** the type of the file that is allowed. */
  type?: string;
}

// validation function
function fileValidationFunc(allowedFileRules: (FileValidationRule | string)[]): ValidatorFn {
  function getExtension(text: string) {
    if (text) {
      const extIndex = text.lastIndexOf('.');
      if (extIndex > -1) {
        return text.substring(extIndex + 1);
      }
    }
    return '';
  }

  return (ctrl: AbstractControl) => {
    if (!allowedFileRules || !ctrl.value) {
      return null;
    }

    let files: File[];
    if (ctrl.value instanceof File) {
      files = [ctrl.value];
    }
    else if (Array.isArray(ctrl.value) && ctrl.value.every(x => x instanceof File)) {
      files = ctrl.value;
    }
    else {
      return { file: { error: 'invalid control value.' } };
    }

    // make sure the rules are all FileValidationInfo by converting strings to two seperate instances,
    // one where the string is the extension and the other where it is the type.
    const normalizedRules: FileValidationRule[] = ([] as FileValidationRule[]).concat(
      ...allowedFileRules.map(x => typeof x === 'string' ? [{ ext: x }, { type: x }] : [x]));

    const invalidFiles: string[] = [];
    for (const file of files) {
      const extension = getExtension(file.name);
      let isAllowed = false;
      for (const rule of normalizedRules) {
        isAllowed = ((!rule.ext || extension === rule.ext) && (!rule.type || file.type === rule.type));

        if (isAllowed) {
          break;
        }
      }
      if (!isAllowed) {
        invalidFiles.push(file.name);
      }
    }
    return (invalidFiles.length === 0) ? null : { file: { invalidFiles } };
  };
}



export const FILE_VALIDATOR: StaticProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FileValidatorDirective),
  multi: true
};

@Directive({
  selector: '[tccFileValidator]',
  providers: [FILE_VALIDATOR]
})
export class FileValidatorDirective implements Validator {
  private allowedFileRules?: (FileValidationRule | string)[];

  private onChange?: () => void;

  @Input()
  get tccFileValidator() { return this.allowedFileRules; }
  set tccFileValidator(value: (FileValidationRule | string)[] | undefined) {
    this.allowedFileRules = value;
    if (this.onChange) {
      this.onChange();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null  {
    if (this.allowedFileRules) {
      return fileValidationFunc(this.allowedFileRules)(control);
    }
    return null;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

  constructor() { }

}
