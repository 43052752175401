import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, forkJoin, merge, of, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AccessMatrixItem, Organization, Role, UserDetail, UserSummary } from '../client-api.service';
import { LedgerService } from '../ledgers/ledger.service';
import { ViewInfo } from '../ledgers/view-info';
import { OrganizationService } from '../core-services/organization.service';
import { ArrayUtil } from '../shared/array-util';
import { tapError } from '../shared/tap-error-operator';
import { UserService } from '../core-services/user.service';
import { defaultCheckStyle } from '../shared/check-styles';



@Component({
  selector: 'app-access-matrix',
  styleUrls: ['./access-matrix.component.scss'],
  templateUrl: './access-matrix.component.html'
})
export class AccessMatrixComponent implements OnDestroy, OnInit {

  private readonly roleSubject = new BehaviorSubject<Role | undefined>(undefined);
  private readonly userSubject = new BehaviorSubject<UserSummary | undefined>(undefined);
  private readonly userUpdatedSubject = new Subject<UserDetail>();
  private originalOrgViewMatrix: { [key: string]: boolean } = {};
  private readonly subsMgr = new SubsManager();

  /** default style for tccFaCheckStyle */
  readonly checkStyle = defaultCheckStyle;

  highlightedIntersection: { org?: Organization, view?: ViewInfo } = { org: undefined, view: undefined };

  get isDisabledAll() {
    return this.state !== 'ready';
  }
  get isDisabledButtons() {
    return this.state !== 'ready' || !this.isModified;
  }
  get isDisabledChangeUser() {
    return this.state !== 'ready' || this.isModified;
  }
  get isDisabledMatrix() {
    return this.state !== 'ready' || !this.user || !this.role;
  }
  isModified = false;

  orgs$ = this.orgSvc.orgs$.pipe(
    map(x => x.sort((a, b) => ArrayUtil.comparerFuncGeneric(a.orgCode, b.orgCode))),
    shareReplay(1)
  );

  orgViewMatrix: { [key: string]: boolean } = {};

  state: 'loading' | 'ready' = 'loading';

  get role() { return this.roleSubject.value!; }
  set role(value: Role) {
    if (this.roleSubject.value !== value) {
      this.roleSubject.next(value);
    }
  }

  readonly roles$ = this.userSvc.roles$;

  get user() { return this.userSubject.value!; }
  set user(value: UserSummary) {
    if (this.userSubject.value !== value) {
      this.userSubject.next(value);
    }
  }

  readonly userDetail$ = merge(
    this.userSubject.pipe(
      switchMap(x => !x ? of(undefined as unknown as UserDetail) : this.userSvc.getUserDetail(x.userId))
    ),
    this.userUpdatedSubject
  ).pipe(shareReplay(1));

  readonly users$ = this.userSvc.users$;

  readonly views$ = this.ledgerSvc.glViews$;

  constructor(
    private ledgerSvc: LedgerService,
    private orgSvc: OrganizationService,
    private userSvc: UserService,
    private notifSvc: NotificationsService
  ) { }

  ngOnInit() {

    this.state = 'loading';

    this.subsMgr.addSub =
      forkJoin([
        this.orgs$.pipe(take(1)),
        this.roles$.pipe(take(1)),
        this.users$.pipe(take(1)),
        this.views$.pipe(take(1))
      ])
        .pipe(tapError((err) => this.notifSvc.addError(err)))
        .subscribe(() => this.state = 'ready');

    this.subsMgr.addSub = combineLatest([this.roleSubject, this.userDetail$]).pipe(
      debounceTime(100),
      tap(([role, user]) => this.setOrgViewMatrix(role!, user))
    ).subscribe();

  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  getMatrixKey(orgId: number, viewId: number) {
    return orgId + ':' + viewId;
  }

  intersectionDim(org: Organization, view: ViewInfo) {
    if (this.highlightedIntersection.org === org && this.highlightedIntersection.view === view) {
      this.highlightedIntersection.org = undefined;
      this.highlightedIntersection.view = undefined;
    }
  }
  intersectionHighlight(org: Organization, view: ViewInfo) {
    this.highlightedIntersection = {
      org: org,
      view: view
    };
  }
  /**
   * sets isModified to true
   */
  makeModified() {
    this.isModified = true;
  }

  parseMatrixKey(matrixKey: string) {
    const parts = matrixKey.split(':');
    return { orgId: parseInt(parts[0], 10) - 0, viewId: parseInt(parts[1], 10) - 0 };
  }

  saveChanges() {
    this.state = 'loading';
    combineLatest([this.userDetail$, this.roleSubject]).pipe(
      take(1),
      map(([user, role]) => {
        // get access items with out edited role.
        const accessItems = user.accessMatrix.items.filter(x => x.roleId !== role!.roleId);
        // update access from orgViewMatrix
        const roleItems = Object.keys(this.orgViewMatrix)
          .filter(x => !!this.orgViewMatrix[x])
          .map(key => ({ ...this.parseMatrixKey(key), roleId: this.role.roleId }) as AccessMatrixItem);
        accessItems.push(...roleItems);
        return { user, accessItems };
      }),
      take(1),
      switchMap(({ user, accessItems }) =>
        this.userSvc.updateAccessMatrix(user.userId, accessItems).pipe(
          tap((updatedItems) => {
            user.accessMatrix = updatedItems;
            this.userUpdatedSubject.next(user);
            this.notifSvc.addSuccess(`Access for ${user.displayName} was updated succesfully.`);
          })
        )
      ),
      tapError(err => this.notifSvc.addError(err)),
      finalize(() => this.state = 'ready')
    ).subscribe();
  }

  revertToOriginalMatrix() {
    this.orgViewMatrix = {};
    this.isModified = false;
    for (const key in this.originalOrgViewMatrix) {
      if (this.originalOrgViewMatrix[key]) {
        this.orgViewMatrix[key] = true;
      }
    }
  }

  toggleViewItems(viewId: number) {
    combineLatest([this.userSubject, this.roleSubject, this.orgs$]).pipe(
      take(1),
      filter(([user, role, orgs]) => user! && role! && orgs && true),
      tap(([, , orgs]) => {
        const matchedParsedKeys = Object.keys(this.orgViewMatrix)
          .filter(x => !!this.orgViewMatrix[x])
          .map(key => ({ ...this.parseMatrixKey(key), key }))
          .filter((x) => x.viewId === viewId);
        const matchedKeys = matchedParsedKeys.map(x => x.key);
        const uncheckedOrgIds = orgs.map(x => x.orgId).filter(orgId => !matchedParsedKeys.some(y => y.orgId === orgId));

        // if all orgs are checked then uncheck them, otherwise check the unchecked.
        if (uncheckedOrgIds.length > 0) {
          uncheckedOrgIds.forEach(orgId => this.orgViewMatrix[this.getMatrixKey(orgId, viewId)] = true);
        } else {
          matchedKeys.forEach(x => this.orgViewMatrix[x] = false);
        }
        this.isModified = true;
      })
    ).subscribe();
  }

  /**
   * sets the org view matrix if role and userDetail is set
   */
  private setOrgViewMatrix(role: Role, user: UserDetail) {
    this.orgViewMatrix = {};
    this.originalOrgViewMatrix = {};
    this.isModified = false;

    if (role && user) {
      const roleItems = user.accessMatrix.items.filter(x => x.roleId === this.role.roleId);

      for (const ov of roleItems) {
        this.orgViewMatrix[this.getMatrixKey(ov.orgId, ov.viewId)] = true;
        this.originalOrgViewMatrix[this.getMatrixKey(ov.orgId, ov.viewId)] = true;
      }
    }
  }


}
