<div class="d-flex flex-row text-nowrap flex-wrap ">

  <div class="d-flex flex-grow-1 flex-nowrap">
    <div class="d-flex flex-row flex-shrink-0 mr-3">

      <a class="btn btn-sm btn-light mr-1" routerLink='/home'>
        <i class="fa fa-fw fa-home"></i>
      </a>
      <button type="button" class="btn btn-sm btn-light mr-1" (click)="suppressZeroes = !suppressZeroes"
        [disabled]="!hasLedger" [title]="suppressZeroes ? 'Show Zeroes' : 'Suppress Zeros'">
        <i class="fas fa-fw fa-ban fa-lg text-danger" [ngStyle]="{opacity: suppressZeroes ? 0 : 1 }"></i>
        <div style="font-weight:bold;position:relative;top:-9px;line-height:0;display:block">0</div>
      </button>
      <button type="button" class="btn btn-sm btn-light mr-1" (click)="exportToCsv()" [disabled]="!hasLedger"
        title="Download CSV">
        <i class="fa fa-fw fa-download"></i>
      </button>

      <ng-container *ngIf="hasLedger">
        <ng-container *ngIf="!ledgerState.readOnlyAmounts">
          <button type="button" class="btn btn-sm btn-light mr-1" [disabled]="!ledgerState.amountCommands.canRollback"
            (click)="ledgerState.amountCommands.rollback()" title="Undo Account">
            <i class="fas fa-fw fa-undo"></i>
          </button>
          <button type="button" class="btn btn-sm btn-light mr-1" [disabled]="!ledgerState.amountCommands.canRedo"
            (click)="ledgerState.amountCommands.redo()" title="Redo Account">
            <i class="fas fa-fw fa-redo"></i>
          </button>
        </ng-container>
        <app-comparison-menu-section></app-comparison-menu-section>
      </ng-container>
    </div>

    <div class="d-flex flex-row flex-shrink-0 mr-3" *ngIf="ledgerState.selectedAccount">
      <span class="mr-2 align-self-center">
        {{ledgerState.selectedAccount.displayName}}
      </span>
      <button type="button" (click)="toggleComments()" class="btn btn-sm mr-1"
        [ngClass]="hasComments() ? 'btn-info' : 'btn-light'" title="Enter/View Account Comments">
        <i [ngClass]="hasComments() ? 'fas fa-comment' : 'far fa-comment'"></i>
      </button>
      <ng-container *ngIf="!ledgerState.selectedAccount.hasChildren && !ledgerState.selectedAccount.hasCalculation">
        <button type="button" class="btn btn-sm btn-light mr-1" (click)="toggleHistory()" title="Show Account History">
          <i class="fas fa-fw fa-hourglass"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="!ledgerState.readOnlyAmounts && ledgerState.selectedAccount.hasEditableAmounts">
        <button type="button" class="btn btn-sm btn-light mr-1" [disabled]="!comparisonState.isComparing"
          (click)="copyComparisonAmounts()" title="{{'Copy amounts from ' + comparisonState.currentSource?.name}}">
          <i class="fas fa-fw fa-clone"></i>
        </button>
      </ng-container>
      <app-math-menu *ngIf="!ledgerState.readOnlyAmounts" [actions]="mathActions" [buttonClass]="'btn-light'">
      </app-math-menu>
    </div>
  </div>

  <div class="d-flex flex-row flex-shrink-1 flex-nowrap">
    <button type="button" class="btn btn-sm btn-light" *ngIf="hasHelp" (click)="showHelp()" title="View Help">
      <i *ngIf="!isHelpLoading; else helpLoadingIcon" class="fas fa-question fa-fw"></i>
      <ng-template #helpLoadingIcon>
        <i class="fas fa-spinner fa-pulse fa-fw"></i>
      </ng-template>
    </button>
    <button type="button" class="btn btn-sm btn-light ml-1" *ngIf="editorAvailable('Payroll')"
      (click)="toggleEditor('PayrollEditor')">
      <i class="far fa-fw fa-money-bill-alt"></i> Payroll
    </button>
    <div class="btn-group btn-group-sm btn-light ml-1" ngbDropdown *ngIf="!ledgerState.readOnly">
      <button type="button" class="btn btn-sm btn-light" ngbDropdownToggle>
        Workflow Actions
      </button>
      <div class="dropdown-menu" ngbDropdownMenu style="z-index:1030">
        <button ngbDropdownItem *ngIf="manualExportEnabled" title="Exports data directly" (click)="showExportData()">
          <i class="fa fa-fw fa-plane"></i> Export Data
        </button>
        <button ngbDropdownItem title="Reject changes and send back for review"
          [disabled]="!ledgerState.workflowHistory?.previousStep" (click)="showWorkflowMgmt(workflowEventTypes.Reject)">
          <i class="fa fa-fw fa-times-circle"></i> Reject
        </button>
        <button ngbDropdownItem title="Approve changes and send to the next in workflow chain"
          (click)="showWorkflowMgmt(workflowEventTypes.Approve)">
          <i class="fa fa-fw fa-check-circle"></i> Approve
        </button>
      </div>
    </div>

  </div>
</div>
