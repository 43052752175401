import { InjectionToken } from '@angular/core';

export const GLOBAL = new InjectionToken<IGlobalSettings>('GLOBAL');

export interface IGlobalSettings {
  /** the ledger from the previous year */
  actBudLedgerName: string;
  /** the year the budget starts */
  budgetYear: number;
  /** name of the view used when updating community */
  defaultCalculationView: string;
  /** the ledger we are modifying */
  fpLedgerName: string;
  /** month labels */
  monthLabels: string[];
  /** explicit monthly paychecks for cash properties. */
  payrollCashPayDaysPerMonth?: number[];
  /** overtime exempt salary threshold */
  payrollExemptSalaryThreshold: number;
  /** Optional: the first pay period of the budget year.  Use this to get cash pay periods if cashPayDaysPerMonth isn't set. */
  payrollFirstPayPeriod?: Date;
  /** Accounts that will be split by payrollSplitsService */
  payrollSplitAccounts: string[];
  /** accounts that will be referenced in the application */
  specialAccounts: {
    gainLossToLease: string,
    gpr: string,
    vacancyLoss: string
  };
}
