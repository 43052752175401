
<form [formGroup]="requestAccessForm" (ngSubmit)="onSubmit()">
  <tcc-simplePanel size="small" [options]="{isFloating: true}" [buttons]="['close']" (buttonClick)="close()">
    <div panel-header>Request Access Roles</div>
    <div panel-footer>
      <button type="submit" class="btn btn-primary" [disabled]="requestAccessForm.invalid || (vm$ | async)?.state !== 'ready'" type="submit">
        Request Access
      </button>
    </div>
    <div panel-body *ngIf="(vm$ | async) as vm">
      <div class="mb-4">
        Requesting access for <b>{{ vm.user.displayName }}</b>
      </div>
        <div class="form-group mb-4">
          <label for="rolesRequestedIds">Select desired access role(s)</label>
          <select multiple class="form-control" id="rolesRequestedIds" formControlName="rolesRequestedIds" size="5">
            <option *ngFor="let r of vm.roles" [ngValue]="r.roleId">
              {{ r.name }}
            </option>
          </select>
        </div>
        <div class="form-group mb-4">
          <label for="organizationsRequestedIds">Select community(ies)</label>
          <select multiple class="form-control" id="organizationsRequestedIds" formControlName="organizationsRequestedIds" size="10">
            <option *ngFor="let o of vm.orgs" [ngValue]="o.orgId">
              {{ o.name }}
            </option>
          </select>
        </div>
        <div class="form-group mb-4">
          <label for="comments">Comments <small>(optional)</small></label>
          <textarea id="comments" class="form-control" formControlName="comment" rows="6"></textarea>
        </div>
      <tcc-loadingOverlay *ngIf="(vm$ | async)?.state !== 'ready'"></tcc-loadingOverlay>
    </div>
  </tcc-simplePanel>
</form>