
import { AccountEntriesMap } from './entry-amounts';

declare type CalcExprTokenType = 'variable' | 'format' | 'myAmount' | 'number' | 'operator';

interface CalcExprToken {
  text: string;
  type: CalcExprTokenType;
}

/** Function that takes a map of Accounts and amountIndex and returns the result of a calculation for that amountIndex */
declare type AccountCalculation = (variableLookup: AccountEntriesMap, amtIndexOrNegForTotal: number) => number;


export class CalcExpression {
  private _cachedCalculation: AccountCalculation | undefined;

  /** Tokens parsed from expression */
  public tokens: CalcExprToken[];

  /** Text formatting for result */
  public format: string;

  /** Accounts required by this calculation */
  public requiredVariables: string[];

  constructor(exprText?: string) {
    if (exprText != null) {
      const calcExpr = CalculationExpressionParser.instance.parse(exprText);
      this.tokens = calcExpr.tokens;
      this.requiredVariables = calcExpr.tokens.filter(t => t.type === 'variable').map(t => t.text);
      this.format = calcExpr.format || 'whole';
    } else {
      this.tokens = [];
      this.requiredVariables = [];
      this.format = 'whole';
    }
  }


  /**
      * Creates a function that performs the calculation represented by this expression.
      * The function is of type AccountCalculation and accepts an object literal keyed by account codes with
      * a collection of values, and an index indicated which month to get the result for (negative month == total).
      * @param ignoreCached if true, will recompile the function, otherwise a saved version is returned if it has been compiled already.
      */
  public compile(ignoreCached: boolean = false): AccountCalculation {
    if (!ignoreCached && this._cachedCalculation != null) {
      return this._cachedCalculation;
    }

    let funcBody = 'try { var res = ';

    for (const token of this.tokens) {
      switch (token.type) {
        case 'variable':
          funcBody += `
(((amountIndex >= 0)
? ((variableLookup['${token.text}'] || { amounts: [] }).amounts[amountIndex])
: ((variableLookup['${token.text}'] || { total: 0 }).total)) || 0)
`;
          break;
        case 'number':
          // comma's are allowed in numbers but in order for math to occur the commas are removed.
          // (obviously if the thousands seperator is . and decimal , then we're screwed.)
          funcBody += token.text.replace(',', '') + ' ';
          break;
        default:
          funcBody += token.text + ' ';
          break;
      }
    }
    funcBody += '; return ((isNaN(res)) ? 0 : res); } catch (ex) { console.warn(ex); return 0; }';
    this._cachedCalculation = <AccountCalculation>new Function('variableLookup', 'amountIndex', funcBody);
    return this._cachedCalculation;
  }
}

class CalculationExpressionParser {
  public static instance = new CalculationExpressionParser();
  /**
  * regular expression to capture tokens
  * grp 1: matches formatting at the end
  * grp 2: an account [xxxxxxx]
  * grp 3: special variable for my amount
  * grp 4: a number, which might mistakenly grab an addition or subtraction operator, but js will handle it fine
  * grp 5: an operator (hopefully), otherwise some crap character
  */
  private readonly reTokens = /\|\s*(\S+$)|\[([^\]]+)\]|(my)|([+\-]?(?:[\d,]+(?:\.\d*)?|\.\d+))|(\S)/g;

  /**
      * Parses expression text and returns a calculation expression
      * @param exprText a string to parse
      */
  public parse(exprText: string): CalcExpression {
    const calcExpr = new CalcExpression();
    let match: RegExpExecArray | null;
    let token: CalcExprToken;

    match = this.reTokens.exec(exprText);
    while (match != null) {
      token = this.getTokenFromMatch(match);
      switch (token.type) {
        case 'format':
          calcExpr.format = (match[1] === 'P') ? 'percent' : 'whole';
          break;
        default:
          calcExpr.tokens.push(token);
          break;
      }
      match = this.reTokens.exec(exprText);
    }

    return calcExpr;
  }

  /**
      * returns the type of token depdendant on the first group that is not null in the match
      * @param match A match from parse method
      */
  private getTokenFromMatch(match: RegExpExecArray): CalcExprToken {
    if (match[1] != null) { return { text: match[1], type: 'format' }; }
    if (match[2] != null) { return { text: match[2], type: 'variable' }; }
    if (match[3] != null) { return { text: match[3], type: 'myAmount' }; }
    if (match[4] != null) { return { text: match[4], type: 'number' }; }
    if (match[5] != null) { return { text: match[5], type: 'operator' }; }
    throw (new Error('Invalid match'));
  }
}

