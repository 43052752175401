import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { groupWith, sortBy } from 'ramda';
import { filter, tap } from 'rxjs/operators';
import { EstimateType, RevenueAreaType } from '../../../client-api.service';
import { AggregateUtil } from '../../../shared/aggregate-util';
import { GLOBAL, IGlobalSettings } from '../../../shared/global-settings';
import { tapError } from '../../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../../estimate-input-debounce-manager';
import { AreaEstimates } from '../../models/area-estimates';
import { RevenueStateService, RootAreaState } from '../../revenue-state.service';

interface AmenityAvgInfo {
  displayName: string;
  id: number;
  name: string;
  childIds: number[];
  cyAvg: number;
  cyCount: number;
  pyAvg: number;
  pyCount: number;
  unitTypesWithAmenity: string[];
  unitsWithAmenity: string[];
  toolTip: string;
}
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-amenity-averages',
  templateUrl: './amenity-averages.component.html'
  //styles: ['./amenity-averages.component.css']
})
export class AmenityAveragesComponent implements OnInit, OnDestroy {

  private subsMgr = new SubsManager();

  readonly currentYear = this.globalSettings.budgetYear;
  readonly inputDebounceMgr = new EstimateInputDebounceManager(this.revState.periodSettings);
  readonly isReadOnly$ = this.revState.isReadOnly$;
  readonly priorYear = this.globalSettings.budgetYear - 1;

  amenityAverages: AmenityAvgInfo[] | undefined;
  totalRow: { cyAvg: number; pyAvg: number; } | undefined;
  tooltip!: string;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private notifySvc: NotificationsService,
    private revState: RevenueStateService) {

  }

  ngOnInit() {

    this.subsMgr.addSub = this.revState.areas$.pipe(
      filter((x): x is RootAreaState => !!x),
      //map(({ typeMap }) => typeMap.get(RevenueAreaType.AddOn) || []),
      //tap(amenityEstimates => {
      tap(({typeMap}) => {
        const amenityEstimates = typeMap.get(RevenueAreaType.AddOn);
        const self = typeMap.get(RevenueAreaType.Self)?.[0];
        //console.log(self);
        this.amenityAverages = [];
        this.totalRow = { cyAvg: 0, pyAvg: 0 };
        this.tooltip = `<b>test</b><br><br>test`;
        const chunks = groupWith((a: AreaEstimates, b) => a.name === b.name, sortBy(x => x.name, amenityEstimates!));        
        for (const amenityChunk of chunks) {
          const amenityUnitTypes = [];
          const amenityUnits = [];
          const amenityAvgInfo: AmenityAvgInfo = {
            displayName: amenityChunk[0].displayName,
            name: amenityChunk[0].name,
            childIds: [],
            cyAvg: 0,
            cyCount: amenityChunk.length,
            pyAvg: 0,
            pyCount: amenityChunk.length,
            id: amenityChunk[0].revAreaId,
            unitsWithAmenity: [],
            unitTypesWithAmenity: [],
            toolTip: ''
          };

          if (self?.children) {
            for (const selfUnitType of self?.children) {
              let unitTypeContainsAmenity = false;
              if (selfUnitType.children) {
                for (const selfUnitTypeUnit of selfUnitType.children) {
                  if (selfUnitTypeUnit.children) {
                    for (const selfUnitTypeUnitAmenity of selfUnitTypeUnit.children) {
                      if (selfUnitTypeUnitAmenity.displayName == amenityChunk[0].displayName) {
                        amenityUnits.push(selfUnitTypeUnit.name);
                        unitTypeContainsAmenity = true;
                      }
                    }
                  }
                }
              }
              if (unitTypeContainsAmenity) {
                amenityUnitTypes.push(selfUnitType.displayName);
              }
            }
          }

          const unitsWithoutAmenities = [];
          unitsWithoutAmenities.push(self?.children?.filter(x => x.children?.filter(y => y.children?.length == 0)));
          // console.log('Units without amenities:');
          // console.log(unitsWithoutAmenities);

          // console.log(amenityAvgInfo.displayName);
          // console.log(amenityUnitTypes);
          // console.log(amenityUnits);



          amenityAvgInfo.unitTypesWithAmenity = amenityUnitTypes;
          amenityAvgInfo.unitsWithAmenity = amenityUnits;
          amenityAvgInfo.toolTip = this.generateTooltipString(amenityAvgInfo);

          this.amenityAverages.push(amenityAvgInfo);
          let cySum = 0;
          let pySum = 0;

          for (const amenityType of amenityChunk) {
            amenityAvgInfo.childIds.push(amenityType.revAreaId);
            //console.log(amenityType);
            // amenities are not summarized.
            cySum += AggregateUtil.avg(
              amenityType.periods.slice(this.revState.periodSettings.cyJan.index, this.revState.periodSettings.periods.length - 1)
                .map(x => x.estNewRate))!;
            pySum += (amenityType.periods[this.revState.periodSettings.cyJan.index].origFpRate || 0);

          }

          amenityAvgInfo.cyAvg = cySum / amenityAvgInfo.cyCount;
          amenityAvgInfo.pyAvg = pySum / amenityAvgInfo.pyCount;
        }
        this.totalRow.cyAvg = AggregateUtil.sum(this.amenityAverages.map(x => x.cyAvg * x.cyCount)) /
          AggregateUtil.sum(this.amenityAverages.map(x => x.cyCount));
        this.totalRow.pyAvg = AggregateUtil.sum(this.amenityAverages.map(x => x.pyAvg * x.pyCount)) /
          AggregateUtil.sum(this.amenityAverages.map(x => x.pyCount));
        this.cd.detectChanges();
      })
    ).subscribe();

    // this stream saves changes
    this.subsMgr.addSub = this.inputDebounceMgr.estimateChange$.pipe(
      tap(x => {
        const amenityInfo = this.amenityAverages?.find(a => a.id === x.value.revAreaId);
        for (const childRevAreaId of amenityInfo!.childIds) {
          this.revState.enqueueEstimateSave(childRevAreaId, {
            appliedOn: x.value.appliedOn!,
            estimateType: x.value.estimateType!,
            estimateId: 0,
            value: x.value.value
          });
        }
        this.cd.detectChanges();
      }),
      tapError(() => {
        this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.');
        this.cd.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  updateEstimate(amenityId: number, valueRaw: string) {
    this.inputDebounceMgr.updateRawEstimate(valueRaw, { revAreaId: amenityId, estimateType: EstimateType.EstNewRate }, { forCy: true });
  }

  updateEstimateForce(amenityId: number) {
    this.inputDebounceMgr.updateForce({ revAreaId: amenityId, estimateType: EstimateType.EstNewRate }, true);
  }

  private generateTooltipString(amenityAvgInfo: AmenityAvgInfo) {
    let toolTipString = '';
    toolTipString += '<br><b>Unit Types with ' + amenityAvgInfo.displayName + '</b>'
      + '<small style="padding:3px; color:navy;"><b>(' + amenityAvgInfo.unitTypesWithAmenity.length +' total)</b></small><br>'
      + amenityAvgInfo.unitTypesWithAmenity + '<br><br>'
      + '<b>Units with ' + amenityAvgInfo.displayName + '</b>'
      + '<small style="padding:5px; color:navy;"><b>(' + amenityAvgInfo.unitsWithAmenity.length +' total)</b></small><br>'
      + amenityAvgInfo.unitsWithAmenity;
    
    return toolTipString;
  }
}
