import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TccUiModule } from '@tcc/ui';
import { CommentsModule } from '../comments/comments.module';
import { DirectivesModule } from '../directives/directives.module';
import { RenewalsComponent } from './renewals/renewals.component';
import { RevenueRoutingModule } from './revenue-routing.module';
import { AmenityAveragesComponent } from './revenue/amenity-averages/amenity-averages.component';
import { RateSummaryComponent } from './revenue/rate-summary/rate-summary.component';
import { RevenueComponent } from './revenue/revenue.component';
import { TotalComponent } from './revenue/total/total.component';
import { UnitTypesComponent } from './revenue/unit-types/unit-types.component';
import { RollupComponent } from './rollup/rollup.component';
import { RenewalFilterComponent } from './renewals/renewal-filter/renewal-filter.component';
import { ControlsModule } from '../controls/controls.module';

@NgModule({
  declarations: [
    RenewalsComponent,
    RollupComponent,
    RevenueComponent,
    AmenityAveragesComponent,
    UnitTypesComponent,
    TotalComponent,
    RateSummaryComponent,
    RenewalFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TccUiModule,
    ControlsModule,
    DirectivesModule,
    CommentsModule,
    RevenueRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule
  ]
})
export class RevenueModule { }
