import { AccountEntriesNode } from './account-entries-node';

export class LedgerAccountComponentUtil {

  /** Gets Css Classes based upon the state of the account */
  static getCssClasses(account: AccountEntriesNode, isSelected: boolean) {
    const classList: string[] = [];
    if (account) {
      classList.push('led-lvl-' + account.levelIndex);
      if (isSelected) {
        classList.push('led-row-selected');
      }
      if (account.hasChildren) {
        classList.push('led-row-parent');
      }
      if (account.maxDescendantDepth === 1) {
        classList.push('led-row-subtotal');
      }
      if (!account.hasChildren && !account.hasEditableAmounts) {
        classList.push('led-row-readonly');
      }
    }
    return classList;
  }


}
