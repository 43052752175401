import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'dynNum'
})
export class DynamicNumberPipe implements PipeTransform {
  private thousoundsRe = /\B(?=(\d{3})+(?!\d))/g;
  private numberPipe = new DecimalPipe(undefined || '');

  transform(value: string | number, filterName: string, decimalsValue?: string | number): string {
    const input = (isNaN(+value)) ? (+value || 0) * 1 : +value;

    if (input === Number.POSITIVE_INFINITY) {
      return '∞';
    } else if (input === Number.NEGATIVE_INFINITY) {
      return '-∞';
    }
    switch (filterName) {
      case 'whole':
        return this.formatNumber(input, 0);
      case 'usd':
        return '$' + this.formatNumber(input, 2);
      case 'percent':
      case 'pct':
        return this.formatNumber(input * 100, decimalsValue ? +decimalsValue : 0) + '%';
    }
    return this.numberPipe.transform(value, `1.${(decimalsValue || '0')}`) || '';
  }

  private formatNumber(amount: number, decimals: number) {
    const formatted = amount.toFixed(decimals);

    if (decimals > 3) {
      // split so decimals do get numarals
      const parts = formatted.split('.');
      return parts[0].replace(this.thousoundsRe, ',') + '.' + parts[1];
    } else {
      return formatted.replace(this.thousoundsRe, ',');
    }
  }
}
