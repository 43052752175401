import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { EstimateType, RevenueAreaType } from '../../../client-api.service';
import { NumberParsingUtil } from '../../../shared/number-parsing-util';
import { tapError } from '../../../shared/tap-error-operator';
import { EstimateInputDebounceManager } from '../../estimate-input-debounce-manager';
import { AreaEstimates } from '../../models/area-estimates';
import { RevenueStateService, RootAreaState } from '../../revenue-state.service';

interface UnitTypeAmenityArrays {
  unitTypes: AmenityArrays[];
}

interface AmenityArrays {
  unitTypeDisplayName: string;

}

@Component({
  selector: 'app-unit-types',
  templateUrl: './unit-types.component.html',
  styles: []
})
export class UnitTypesComponent implements OnDestroy, OnInit {
  private allUnitTypes: AreaEstimates[] | undefined;
  private filterChangeSubj = new BehaviorSubject<string>('');
  private subsMgr = new SubsManager();

  readonly estimateTypeEnumRef = EstimateType;
  readonly isReadOnly$ = this.revState.isReadOnly$;
  readonly tgtPeriodIndex = this.revState.periodSettings.cyJan.index;
  readonly inputDebounceMgr = new EstimateInputDebounceManager(this.revState.periodSettings);

  /** provides access to fitler changes */
  set filter(value: string) {
    this.filterChangeSubj.next(value);
  }
  get filter() {
    return this.filterChangeSubj.value;
  }

  /** The maximum number of consolidated amenities a unit type has. */
  maxUtAmenities: number | undefined;

  /** consolidated amenities mapped to unit type revareaids */
  utAmenityMap: Map<number, AreaEstimates[]> | undefined;

  /** all unit types filtered by fitlerChangeSubj */
  unitTypes: AreaEstimates[] | undefined;

  constructor(private notifySvc: NotificationsService, private revState: RevenueStateService) { }

  ngOnInit() {
    this.inputDebounceMgr.defaultAppliedOn = this.revState.periodSettings.cyJan.value;

    this.subsMgr.addSub = this.filterChangeSubj.subscribe(() => this.applyFilter());

    this.subsMgr.addSub = this.revState.areas$.pipe(
      filter((x): x is RootAreaState => !!x),
      tap(({ typeMap, utConsolidatedAmenityMap }) => {
        const self = typeMap.get(RevenueAreaType.Self)?.[0];
        if (!self) {
          this.allUnitTypes = [];
          this.utAmenityMap = new Map();
          this.applyFilter();
          return;
        }
        //console.log('allUnitTypes:');
        //console.log(this.allUnitTypes);
        this.allUnitTypes = typeMap.get(RevenueAreaType.UnitType) || [];
        //console.log('allUnitTypes:');
        //console.log(this.allUnitTypes);
        this.utAmenityMap = utConsolidatedAmenityMap;
        console.log('utAmenityMap');
        console.log(this.utAmenityMap);
        this.maxUtAmenities = Math.max(0, ...Array.from(this.utAmenityMap!.values()).map(x => x.length));
        this.applyFilter();
        console.log('unitTypes');
        console.log(this.unitTypes);
        this.populateUnitTypeAmenityArrays(this.unitTypes);
      })
    ).subscribe();

    // this stream saves changes
    this.subsMgr.addSub = this.inputDebounceMgr.estimateChange$.pipe(
      tap(x => this.revState.enqueueEstimateSave(x.value.revAreaId!, {
        appliedOn: x.value.appliedOn!,
        estimateId: 0,
        estimateType: x.value.estimateType!,
        value: x.value.value
      })),
      tapError(() => this.notifySvc.addError('Unable to save estimates.  Please refresh and try again.'))
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  /** Warning if there is no fpRates */
  hasRateWarning(area: AreaEstimates) {
    return !area.summary!.estBaseFpRate || area.summary!.estBaseFpRate < 0;
  }

  /** Warning if there is no estimated renewal rate but there are renewal leases. */
  harRenewalWarning(area: AreaEstimates) {
    const estRenewalRate = area.periods[this.revState.periodSettings.cyJan.index].aggs.avg?.estRenewalRate;
    return !estRenewalRate && area.periods.some(x => x.estRenewalLeases! > 0);
  }

  isSelected(area: AreaEstimates) {
    return this.revState.selectedIds.has(area.revAreaId);
  }

  toggleSelected(revAreaId: number) {
    this.revState.selectedIds.has(revAreaId)
      ? this.revState.selectedIds.clear()
      : this.revState.selectedIds.set(revAreaId);
  }

  updateAggAmenityEstimates(utRevAreaId: number, aggAmenId: number, estimateType: EstimateType, valueRaw: string) {
    try {
      const aggAmenity = this.utAmenityMap?.get(utRevAreaId)?.find(x => x.revAreaId === aggAmenId);
      for (const amenity of aggAmenity!.children!) {
        this.inputDebounceMgr.updateRawEstimate(valueRaw, { revAreaId: amenity.revAreaId, estimateType }, { forCy: true });
      }
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${valueRaw}.  Nothing was saved.`);
    }
  }

  updateAggAmenityForce(utRevAreaId: number, aggAmenId: number, estimateType: EstimateType) {
    const aggAmenity = this.utAmenityMap!.get(utRevAreaId)!.find(x => x.revAreaId === aggAmenId);
    for (const amenity of aggAmenity!.children!) {
      this.inputDebounceMgr.updateForce({ revAreaId: amenity.revAreaId, estimateType }, true);
    }
  }

  updateEstimate(revAreaId: number, estimateType: EstimateType, valueRaw: string) {
    try {
      this.inputDebounceMgr.updateRawEstimate(valueRaw, { revAreaId, estimateType }, { forCy: true });
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${valueRaw}.  Nothing was saved.`);
    }
  }

  updateEstimateForce(revAreaId: number, estimateType: EstimateType) {
    this.inputDebounceMgr.updateForce({ revAreaId, estimateType }, true);
  }

  /**
   * Takes an estimate that's a percentage of a unit type and converts it to a real number
   */
  updateUnitCountPctEstimate(revAreaId: number, estimateType: EstimateType, valueRaw: string) {
    try {
      let pctVal = NumberParsingUtil.parseFloat(valueRaw, { normalizePercent: true });
      if (isNaN(pctVal!)) {
        throw new Error();
      }
      if (pctVal! > 1) {
        pctVal = pctVal! / 100;
      }
      const area = this.unitTypes?.find(x => x.revAreaId === revAreaId);
      for (let pIdx = this.tgtPeriodIndex; pIdx < this.revState.periodSettings.periods.length; pIdx++) {
        const appliedOn = this.revState.periodSettings.periods[pIdx].value;
        const value = +(pctVal! * area!.periods[pIdx].unitCount!).toExponential(2);
        this.inputDebounceMgr.updateEstimate(value, { revAreaId, estimateType, appliedOn });
      }
    }
    catch {
      this.notifySvc.addError(`Invalid value for estimate: ${valueRaw}.  Nothing was saved.`);
    }
  }


  /** Applies current value from filterChangeSubj to allUnitTypes and sets unitTypes with it. */
  private applyFilter() {
    const filterText = (this.filterChangeSubj.value || '').trim().toLowerCase();
    this.unitTypes = (this.allUnitTypes || [])
      .filter(x => (x.displayName || '').toLowerCase().indexOf(filterText) !== -1
        || (x.name || '').toLowerCase().indexOf(filterText) !== -1);
  }

  private populateUnitTypeAmenityArrays(unitTypes: AreaEstimates[] | undefined) {
    if (unitTypes != undefined) {

    }
  }

  generateAmenityTooltip(amenityDisplayName: string, unitType: AreaEstimates) {
    let amenityTooltip = '';
    let unitsWithAmenity = [];
    let unitsWithoutAmenity = [];
    amenityTooltip += amenityDisplayName + unitType.children?.length;
    if (unitType.children) {
      for (const unit of unitType.children) {
        let unitHasAmenity = false;
        if (unit.children) {
          for (const amenity of unit.children) {
            if (amenity.displayName == amenityDisplayName) {
              unitHasAmenity = true;
            }
          }
        }
        unitHasAmenity ? unitsWithAmenity.push(unit.displayName) : unitsWithoutAmenity.push(unit.displayName);
      }
    }
    amenityTooltip += '<b>Units with ' + amenityDisplayName + '</b>'
      + '<small style="padding:3px; color:navy;"><b>(' + unitsWithAmenity.length +' total)</b></small><br>'
      + unitsWithAmenity + '<br><br>'
      + '<b>Units without ' + amenityDisplayName + '</b>'
      + '<small style="padding:5px; color:navy;"><b>(' + unitsWithoutAmenity.length +' total)</b></small><br>'
      + unitsWithoutAmenity;
      
    return amenityTooltip;
  }

}
