import { Observable, Observer } from 'rxjs';

export class FileUtil {
  static readonly dataUrlPrefixRe = /^data\:([^\;]+)\;base64,/gmi;

  /**
  * Uses file reader to read a blob or file to return dataUrl.
  * @param {Blob|File} blob
  */
  static blobOrFileToDataUrl(blob: Blob | File): Observable<string> {

    return new Observable<string>((observer: Observer<string>) => {
      const fr = new FileReader();

      fr.addEventListener('load', () => {
        observer.next(<string>fr.result);
        observer.complete();
      });

      fr.addEventListener('error', () => {
        observer.error('Error Occurred reading data url');
        observer.complete();
      });

      fr.addEventListener('abort', () => {
        observer.complete();
      });

      fr.readAsDataURL(blob);
    });
  }

  static removePrefixFromDataUrl(s: string): string {
    return s.replace(FileUtil.dataUrlPrefixRe, '');
  }
}
