import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { AccountEntriesNode } from '../ledgers/account-entries-node';
import { LedgerTree } from '../ledgers/ledger-tree';
import { DynamicNumberPipe } from '../shared/dynamic-number.pipe';
import { CSV_FORMAT, covertArrayToFlatFile } from '@tcc/ui';

@Injectable({
  providedIn: 'root',
})
export class ExporterService {
  private dynNum = new DynamicNumberPipe();

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  createCsv(ledger: LedgerTree, orderedAccounts: AccountEntriesNode[],
    comparisonInfo?: { headerName: string, formattedResults: Map<string, { amounts: string[], total: string }> } | undefined) {

    const data: (string | number)[][] = [];

    if (comparisonInfo) {
      const monthRow = [''].concat(...ledger.months!.map(m => [formatDate(m, 'MMM', this.locale), '']), ['Total', '']);
      const compsRow = [''].concat(...ledger.months!.map(() => ['FP', comparisonInfo.headerName]), ['FP', comparisonInfo.headerName]);
      data.push(monthRow, compsRow);
      for (const acct of orderedAccounts) {
        const comparisonAmounts = comparisonInfo.formattedResults.get(acct.accountCode!);
        const acctRow: (string | number)[] = [this.getTabs(acct.levelIndex!, '  ') + acct.displayName];
        for (let i = 0; i < 12; i++) {
          acctRow.push(this.dynNum.transform(acct.amounts[i] || 0, acct.displayFormat.type, acct.displayFormat.decimals));
          acctRow.push(comparisonAmounts!.amounts[i] || 0);
        }
        acctRow.push(acct.total, comparisonAmounts!.total);
        data.push(acctRow);
      }
    }
    else {
      const monthRow = [''].concat(...ledger.months!.map(m => formatDate(m, 'MMM', this.locale)), ['Total']);
      data.push(monthRow);
      for (const acct of orderedAccounts) {
        const acctRow = ([this.getTabs(acct.levelIndex!, '  ') + acct.displayName] as (string | number)[])
          .concat(
            ledger.months!.map((_, i) => this.dynNum.transform(acct.amounts[i] || 0, acct.displayFormat.type, acct.displayFormat.decimals)),
            acct.total);
        data.push(acctRow);
      }
    }
    return covertArrayToFlatFile(data, CSV_FORMAT);
    //return new FlatFileUtil().arrayToText(data);
  }

  private getTabs(cnt: number, tabChar: string) {
    let str = '';
    for (let i = 0; i < cnt; i++) {
      str += tabChar;
    }
    return str;
  }
}
