import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuTrayWrapperComponent } from './menu-tray-wrapper.component';

@NgModule({
  declarations: [
    MenuTrayWrapperComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MenuTrayWrapperComponent
  ]
})
export class MenuTrayModule { }
