
export class DateHelper {
  private static cachedWeekdaysPerMonth: { [yyyy: number]: number[] } = {};
  private static cachedPayDatesForYear: { [yyyymmdd: number]: Date[] } = {};
  private static cachedPayDaysPerMonth: { [yyyymmdd: number]: number[] } = {};

  public static weekDaysPerMonth(year: number) {
    let weekDays = DateHelper.cachedWeekdaysPerMonth[year];
    if (weekDays !== undefined) {
      return weekDays;
    }

    weekDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const dt = new Date(year, 0, 1);
    let day: number;

    while (dt.getFullYear() === year) {
      day = dt.getDay();
      // 0 && 6 = sunday;
      if (day > 0 && day < 6) {
        weekDays[dt.getMonth()] += 1;
      }
      dt.setDate(dt.getDate() + 1);
    }
    DateHelper.cachedWeekdaysPerMonth[year] = weekDays;
    return weekDays;
  }

  /**
   * Computes an array with all pay days for a year.
   * The result is cached so don't modify.
   * @param firstPayDay The first payday of the year
   */
  public static biWeeklyPayDatesForYear(firstPayDay: Date) {
    const key = firstPayDay.getFullYear() * 10000 + firstPayDay.getMonth() * 100 + firstPayDay.getDate();
    let payPeriodsForYear = DateHelper.cachedPayDatesForYear[key];
    if (payPeriodsForYear !== undefined) {
      return payPeriodsForYear;
    }

    payPeriodsForYear = [];
    const currentYear = firstPayDay.getFullYear();
    // date is an Object in JavaScript, so I have to create a new one or firstPayDay will get modified.
    const dt = new Date(firstPayDay.getFullYear(), firstPayDay.getMonth(), firstPayDay.getDate());
    while (dt.getFullYear() === currentYear) {
      // date is an object so save a copy
      payPeriodsForYear.push(new Date(dt.getTime()));
      dt.setDate(dt.getDate() + 14);

    }
    DateHelper.cachedPayDatesForYear[key] = payPeriodsForYear;
    return payPeriodsForYear;
  }

  /**
   * Computes an array with the count of pay days per month
   * @param firstPayDay The first payday of the year
   */
  public static biWeeklyPayDaysPerMonth(firstPayDay: Date) {
    const key = firstPayDay.getFullYear() * 10000 + firstPayDay.getMonth() * 100 + firstPayDay.getDate();
    let monthPeriods = DateHelper.cachedPayDaysPerMonth[key];
    if (monthPeriods) {
      return monthPeriods;
    }

    const allPayDays = DateHelper.biWeeklyPayDatesForYear(firstPayDay);
    monthPeriods = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (const payDate of allPayDays) {
      monthPeriods[payDate.getMonth()] += 1;
    }
    DateHelper.cachedPayDaysPerMonth[key] = monthPeriods;
    return monthPeriods;
  }


}
