import { Component, OnInit } from '@angular/core';
import { FinancialPlanningService } from './installer/services/financial-planning.service';
import { PanelSize } from '@tcc/ui/lib/simple-panel/models';
import { Subscription } from 'rxjs';
import { MaintenanceService } from './services/maintenance.service';
//import { FinancialPlanningService } from '../services/financial-planning.service';
//import { IFpOrganizationResource } from 'src/app/shared/client-api.service';

@Component({
  selector: 'app-financial-planning-maintenance-menu',
  templateUrl: './financial-planning-maintenance-menu.component.html',
  styleUrls: ['./financial-planning-maintenance-menu.component.scss']
})
export class FinancialPlanningMaintenanceMenuComponent implements OnInit {

  state: 'initializing' | 'loading' | 'ready' | 'saving';
  tabs: string[] = ['Installer', 'Installer Tracking', 'Application Views'];
  panalSize: PanelSize = 'large';
  activeTab = 0;
  panalSizeSubscription: Subscription | undefined;


  hasNoChildBladesOpen = false;


  constructor(private fpService: FinancialPlanningService,
              private maintenanceService: MaintenanceService ) {
    this.state = 'initializing';
    this.panalSizeSubscription = this.maintenanceService.getMaintenanceComponenetPanelSize().subscribe(
      res => {
        this.panalSize = res;
      });
  }

  ngOnInit(): void {
    this.state = 'ready';
  }

  // menu selector
  setActiveTab(index: number): void {    
    this.activeTab = index;
  }

}
