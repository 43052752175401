import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { RevenueAreaType } from '../../../client-api.service';
import { RevenueStateService } from '../../revenue-state.service';

@Component({
  selector: 'rate-summary',
  templateUrl: './rate-summary.component.html'
})
export class RateSummaryComponent {
  readonly areas$ = this.revState.areas$.pipe(map(({ typeMap }) => typeMap.get(RevenueAreaType.UnitType)));
  readonly finalPeriodIndex = this.revState.periodSettings.periods.length - 1;
  readonly hasAmenities$ = this.areas$.pipe(map(x => x?.some(
    (y) => (y.periods[this.finalPeriodIndex]?.aggs?.avg?.estAmenityNewRate || 0) > 0)));

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  close = new EventEmitter<void>();


  constructor(
    private revState: RevenueStateService,
    private route: ActivatedRoute,
    private router: Router) { }


  onPanelButtonClick() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
