import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SubsManager } from '@tcc/ui';

@Component({
  selector: 'app-error',
  template: `
<tcc-simplePanel size="small">
  <div panel-header>{{category}}</div>
  <div panel-body>
    <div>
      {{message}}
    </div>
    <div class="pt-3">
      <a routerLink="/">Home</a>
    </div>
  </div>
</tcc-simplePanel>
`
})
export class AppErrorComponent implements OnInit, OnDestroy {
  public message = '';
  public category = '';

  private subsMgr = new SubsManager();

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.subsMgr.addSub = this.route.queryParams.subscribe(params => this.readParams(params));
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }
  private readParams(params: { [key: string]: Params }) {
    const error = params['error'] || {};

    switch (error.message) {
      case 'noedit':
        this.message = 'You do not have permission to Edit this data.';
        break;
      case 'noread':
        this.message = 'You do not have permission to View this data.';
        break;
      default:
        this.message = <string>error.message || 'An error has occurred.  Please try again later.';
        break;
    }

    switch (error.category) {
      case 'access':
        this.category = 'Access Denied';
        break;
      case 'server':
        this.category = 'Server Error';
        break;
      default:
        this.category = 'Error';
        break;
    }
  }
}
