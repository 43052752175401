<form #myForm="ngForm" (ngSubmit)="saveChanges()">
  <tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
    <div panel-header>Workflow Admin</div>
    <div panel-footer class="d-flex">
      <div class="flex-grow-1">
        <button type="button" [disabled]="!workflow" class="btn btn-primary mr-1" (click)="addStep()">
          Add Step
        </button>
        <button type="submit" [disabled]="!workflow || myForm.invalid || myForm.pristine || (state$ | async) != 'ready'" class="btn btn-primary  mr-1">
          Save Changes
        </button>
        <button type="button" [disabled]="!workflow || myForm.pristine" class="btn btn-danger" (click)="undoChanges()">
          Cancel Changes
        </button>
      </div>
      <div class="flex-shrink-1">
        <button type="button" [disabled]="myForm.dirty" class="btn btn-primary" [routerLink]="['workflowAdd']">
          Add Worfklow
        </button>
      </div>
    </div>
    <div panel-body>
      <div class="form-group form-inline">
        <label tcc-labelForNext>Workflow </label>
        <select class="form-control ml-1" [disabled]="myForm.dirty!"
                [ngModel]="workflow"
                (ngModelChange)="setWorkflow($event)"
                [ngModelOptions]="{standalone: true}">
          <option [ngValue]="undefined">- Select Workflow to edit -</option>
          <option *ngFor="let wf of workflows" [ngValue]="wf">{{wf.name}}</option>
        </select>
        <input type="checkbox" [(ngModel)]="workflowMarkedForDeletion" [disabled]="!workflow"
               name="workflowMarkedForDeletion" title="Delete Workflow (this cannot be undone)"
               [tccFaCheckStyle]="checkDeleteStyle" />
      </div>
      <h4>Steps</h4>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Order</th>
            <th class="text-center">Amounts<br />Read Only</th>
            <th class="text-center">Export<br />on Complete</th>
            <th class="text-center">Changes<br />Require Comment</th>
            <th class="text-center">Submit<br />Require Comment</th>
            <th class="text-center">Entries are<br />Suggestions Only</th>
            <th>Participant Role(s)</th>
            <th>Help File</th>
            <th class="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of workflowStepModels">
            <td>
              <input type="text" class="form-control" [(ngModel)]="s.name" [name]="'name_' + s.stepId" required
                     tccValidityClasses="is-invalid"
                     [disabled]="s.markedForDeletion || workflowMarkedForDeletion!" />
            </td>
            <td style="max-width:4rem">
              <input type="number" class="form-control" [(ngModel)]="s.orderIndex" [name]="'orderIndex_' + s.stepId" required
                     tccValidityClasses="is-invalid"
                     [disabled]="s.markedForDeletion || workflowMarkedForDeletion!" />
            </td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="s.amountsReadOnly"
                      [name]="'amountsReadOnly_' + s.stepId"
                      [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                      [tccFaCheckStyle]="checkStyle"  />

            </td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="s.exportOnComplete"
                      [name]="'exportOnComplete_' + s.stepId"
                      [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                      [tccFaCheckStyle]="checkStyle" />
            </td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="s.requireCommentOnChange"
                    [name]="'requireCommentOnChange_' + s.stepId"
                    [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                    [tccFaCheckStyle]="checkStyle"  />
            </td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="s.requireCommentOnSubmit"
                      [name]="'requireCommentOnSubmit_' + s.stepId"
                      [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                      [tccFaCheckStyle]="checkStyle"  />
            </td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="s.entryTypeSuggestion"
                      [name]="'entryTypeSuggestion' + s.stepId"
                      [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                      [tccFaCheckStyle]="checkStyle"  />
            </td>
            <td>
              <span class="d-flex flex-row">
                <ng-container *ngFor="let r of roles$ | async">
                  <label [title]="r.name" class="d-inline-block mb-0 mr-2" style="line-height: 1">
                    <div>
                      <ng-container *ngIf="r.hasClass; else noCssClass">
                        <i *ngIf="r.hasClass; else noCssClass" [ngClass]="r.cssClass!"></i>
                      </ng-container>
                      <ng-template #noCssClass>{{r.name}}</ng-template>
                    </div>

                    <input type="checkbox" [(ngModel)]="s.roleStates[r.roleId]"
                        [name]="'role_' + s.stepId + '_' + r.roleId"
                        [disabled]="s.markedForDeletion || workflowMarkedForDeletion!"
                        [tccFaCheckStyle]="checkStyle" class="fa-stack-sm" />
                  </label>
                </ng-container>
              </span>
            </td>
            <!-- help file -->
            <td>
              <div class="btn-group btn-group-sm">
                <button type="button" (click)="fileInput.click()" class="btn btn-primary"
                        [disabled]="s.helpInfoClear || s.markedForDeletion || workflowMarkedForDeletion">
                  <i class="fas fa-cloud-upload-alt fa-fw"></i>
                </button>
                <!-- buttons for existing help file -->
                <ng-container *ngIf="s.helpInfo && !s.helpFile">
                  <button *ngIf="s.helpInfo" type="button" class="btn btn-warning " title="View File" (click)="viewHelp(s.stepId!)">
                    <i class="fas fa-file-alt fa-fw"></i>
                  </button>
                  <button type="button"
                          [(ngModel)]="s.helpInfoClear"
                          tccModelAccessor #deleteBtnAccessor="tccModelAccessor"
                          [disabled]="!s.helpInfo || s.markedForDeletion || workflowMarkedForDeletion!"
                          [name]="'helpFileDelete_btn_' + s.stepId"
                          class="btn"
                          (click)="deleteBtnAccessor.touched(); deleteBtnAccessor.changed(!s.helpInfoClear);"
                          [ngClass]="s.helpInfoClear ? 'btn-success' : 'btn-danger'">
                    <i class="fas fa-fw" [ngClass]="s.helpInfoClear ? 'fa-undo' : 'fa-trash'"></i>
                  </button>
                </ng-container>
                <!-- buttons for uploaded help file -->
                <ng-container *ngIf="s.helpFile">
                  <button type="button" class="btn btn-warning " title="View File" (click)="viewHelpFile(s.helpFile)">
                    <i class="fas fa-file-alt fa-fw"></i>
                  </button>
                  <button type="button" (click)="fileInput.value = ''; fileAccessor.changed(null)"
                          class="btn btn-danger"
                          [disabled]="s.helpInfoClear || s.markedForDeletion || workflowMarkedForDeletion">
                    <i class="fas fa-eraser fa-fw"></i>
                  </button>
                </ng-container>
              </div>
              <span [(ngModel)]="s.helpFile" #helpFileModel="ngModel"
                    tccModelAccessor #fileAccessor="tccModelAccessor"
                    [tccFileValidator]="acceptedFileTypes"
                    [name]="'helpFile_' + s.stepId"
                    tccValidityClasses="text-danger" [validityControl]="helpFileModel"
                    [title]="helpFileModel.invalid ? 'The selected file is not a valid type.' : ''">
                {{s.helpFile?.name}}
              </span>

              <input #fileInput type="file" [hidden]="true"
                     (change)="fileAccessor.touched(); fileAccessor.changed($any($event.target).files[0])" />
            </td>

            <td class="text-center ml-1">
              <input type="checkbox" [(ngModel)]="s.markedForDeletion"
                      [disabled]="workflowMarkedForDeletion!"
                      [name]="'markedForDeletion_' + s.stepId"
                      [tccFaCheckStyle]="checkDeleteStyle" />
            </td>
          </tr>
        </tbody>
      </table>
      <h4>Views</h4>
      <div>
        <label class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let v of workflowViewModels">
          <input type="checkbox" [(ngModel)]="v.isChecked" [name]="'wfView_' + v.viewId"
                 [disabled]="workflowMarkedForDeletion!" class="fa-stack-sm"
                 [tccFaCheckStyle]="checkStyle" /> {{v.viewName}}
        </label>
      </div>
      <tcc-loadingOverlay *ngIf="(state$ | async) != 'ready'"></tcc-loadingOverlay>
    </div>
  </tcc-simplePanel>
</form>
<router-outlet></router-outlet>
