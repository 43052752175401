<div class="d-flex flex-row flex-nowrap">
  <span class="mr-2 align-self-center">Compare</span>
  <select *ngIf="comparisonState.comparisonSources$ | async as sources; else sourcesLoading"
    [(ngModel)]="comparisonState.currentSource"
    class="form-control form-control-sm" style="width:auto">
    <option *ngFor="let x of sources" [ngValue]="x" >{{x.name}}</option>
  </select>
  <ng-template #sourcesLoading>
    <span class="text-muted ml-1 align-self-center">
      Loading <i class="fa fa-fw fa-spinner fa-pulse"></i>
    </span>
  </ng-template>

  <div class="input-group input-group-sm ml-1" *ngIf="comparisonState.hasSource">
    <select class="form-control" [(ngModel)]="comparisonState.currentType">
      <option *ngFor="let x of comparisonState.comparisonTypes" [ngValue]="x">{{x.displayName}}</option>
    </select>
    <div class="input-group-append">
      <button type="button" class="btn" [disabled]="!comparisonState.isComparing"
              title="Highlight Differences in months were the current value is different from the comparison value."
              (click)="toggleHighlightDifferences()"
              [ngClass]="comparisonState.isHighlightingDifferences ? 'btn-warning' : 'btn-light'">
        <i class="far fa-fw fa-lightbulb"></i>
      </button>
      <button type="button" class="btn" [disabled]="!comparisonState.isComparing"
              title="Highlight Changes that occurred in comparison period."
              (click)="toggleHiglightComparisonPeriodUpdates()"
              [ngClass]="comparisonState.isHighlightingComparisonPeriodUpdates ? 'btn-info' : 'btn-light'">
        <i class="fas fa-fw fa-paint-brush"></i>
      </button>
      <button type="button" class="btn" [disabled]="!comparisonState.isComparing"
              title="Include amounts that were suggestions in comparison values."
              (click)="toggleShowSuggestions()"
              [ngClass]="comparisonState.isSuggestionsVisible ? 'btn-info' : 'btn-light'">
        <i class="fas fa-fw fa-assistive-listening-systems"></i>
      </button>
    </div>
  </div>
</div>
