<div class="btn-group btn-group-sm" ngbDropdown autoClose="outside" [appDropDownTimerClose]>
  <button class="btn btn-primary" ngbDropdownToggle>
    Filter
  </button>
  <div class="shadow-sm px-2" ngbDropdownMenu style="z-index:10000">
    <div class="d-flex flex-nowrap flex-row pb-1">
      <label class="text-nowrap flex-grow-1 justify-content-start pr-2" tcc-labelForNext>Unit Type</label>
      <input type="text" style="max-width: 7rem" class="form-control form-control-sm" [(ngModel)]="filter.unitTypeName" (ngModelChange)="onFilterChange()" />
      <label>
        <input type="checkbox" class="form-control ml-2 mr-1" [(ngModel)]="filter.isUnitTypeNameExact" (ngModelChange)="onFilterChange()" />
        <span class="small">Exact</span>
      </label>
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <label class="text-nowrap flex-grow-1 justify-content-start pr-2" tcc-labelForNext>Space</label>
      <input type="text" style="max-width: 7rem" class="form-control form-control-sm" [(ngModel)]="filter.spaceName" (ngModelChange)="onFilterChange()" />
      <label>
        <input type="checkbox" class="form-control ml-2 mr-1" [(ngModel)]="filter.isSpaceNameExact" (ngModelChange)="onFilterChange()" />
        <span class="small">Exact</span>
      </label>
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <label class="text-nowrap flex-grow-1 justify-content-start pr-2" tcc-labelForNext>Amenity</label>
      <select class="form-control form-control-sm" style="min-width: 8rem"  [(ngModel)]="filter.amenityName" (ngModelChange)="onFilterChange()">
        <option [ngValue]="undefined">No Filter</option>
        <option *ngFor="let a of (amenityNames$ | async)" [ngValue]="a">{{a}}</option>
      </select>
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <label class="text-nowrap flex-grow-1 justify-content-start pr-2" tcc-labelForNext>Category</label>
      <select class="form-control form-control-sm" style="max-width: 8rem"  [(ngModel)]="filter.category" (ngModelChange)="onFilterChange()">
        <option [ngValue]="undefined">All</option>
        <option value="New">New</option>
        <option value="Renewal">Renewal</option>
      </select>
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <label class="text-nowrap flex-grow-1 justify-content-start pr-2"  tcc-labelForNext>Actual Rate</label>
      <input type="number" class="form-control form-control-sm" style="width:4rem" placeholder="Min"
             [(ngModel)]="filter.actualRateMin" (ngModelChange)="onFilterChange()" />
      <label> - </label>
      <input type="number" class="form-control form-control-sm" style="width:4rem" placeholder="Max"
             [(ngModel)]="filter.actualRateMax" (ngModelChange)="onFilterChange()" />
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <div class="btn-group btn-group-sm w-100">
        <label class="text-nowrap btn" [ngClass]="filter.actualRateState == 'blank' ? 'btn-primary' : 'btn-secondary'">
          Blank Actuals
          <input type="checkbox" hidden [checked]="filter.actualRateState == 'blank'"
                 (click)="toggleStateFilter('actualRateState', 'blank')" />
        </label>
        <label class="text-nowrap btn" [ngClass]="filter.actualRateState == 'set' ? 'btn-primary' : 'btn-secondary'">
          Has Actuals
          <input type="checkbox" hidden [checked]="filter.actualRateState == 'set'"
                 (click)="toggleStateFilter('actualRateState', 'set')"  />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap flex-row pb-1">
      <div class="btn-group btn-group-sm w-100">
        <label class="text-nowrap btn" [ngClass]="filter.offerRateState == 'blank' ? 'btn-primary' : 'btn-secondary'">
          Blank Offers
          <input type="checkbox" hidden [checked]="filter.offerRateState == 'blank'"
                 (click)="toggleStateFilter('offerRateState', 'blank')" />
        </label>
        <label class="text-nowrap btn" [ngClass]="filter.offerRateState == 'set' ? 'btn-primary' : 'btn-secondary'">
          Has Offers
          <input type="checkbox" hidden [checked]="filter.offerRateState == 'set'"
                 (click)="toggleStateFilter('offerRateState', 'set')" />
        </label>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <button type="button" class="btn btn-sm btn-danger" (click)="clearFilter()">Clear Filter</button>
    </div>
  </div>
</div>
