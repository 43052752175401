import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * behaves like tap but for errors.
 */
export function tapError<T>(action?: (err: Error, caught: Observable<T>) => unknown): MonoTypeOperatorFunction<T> {
  return (source$: Observable<T>) => source$.pipe(
    catchError((err, caught) => {
      if (action) {
          action(err, caught);
      }
      return throwError(err);
    })
  );
}
