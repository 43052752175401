<div>
    <div class="row">
        <div class="col-12" style="text-align: center;">
            <P>{{installerStatus}}</P>          
        </div>
    </div>
    <div class="row" *ngIf="installerStatus.includes('not')">
        <div class="col-7">
            <select #installerParamsDropdown class="form-select" id="installerParamsDropdown"
                (change)="installerParamsDropdownClicked($event)" [(ngModel)]="selectedParamValue">
                <option value="">Select desired installation</option>
                <option value="-f">-f Sync All Orgs and Ledgers</option>
                <option value="-fab">-fab Sync Act/Bud Values Only</option>
                <option value="-pa">-pa Payroll Allowances Import</option>
                <option value="-pw">-pw Payroll Wages and Salary Import</option>
                <option value="-pr">-pr Payroll Report</option>
                <option value="-r">-r Sync Revenue</option>
                <option value="-u">-u Sync Users</option>
                <option value="-v">-v Sync Views</option>
            </select>
        </div>
        <div class="col-5">
            <div *ngIf="showRunButton()">
                <a class="btn btn-primary btn-sm" (click)="runInstaller()">
                    Run Installer {{commandArgs.join(' ')}}
                </a>
            </div>
        </div>      
    </div>
    <br />
    <div *ngIf="showOrgs && installerStatus.includes('not')">
        <div class="row">
            <div class="col-7">
                <select #orgSelector class="form-select" id="orgSelector" (change)="setCommand()"
                    [(ngModel)]="selectedOrgCode">
                    <option value="-1">All</option>
                    <option *ngFor="let orgCode of orgCodeCheckboxes" [ngValue]="orgCode.orgCode">
                        {{ orgCode.orgCode }}
                    </option>
                </select>
            </div>
            <div class="col-5">
            </div>
        </div>
    </div>
    <br />
    <div *ngIf="showFileSelector">
        <div>
            <input type="file" class="upload" (change)="fileSelected($event)">
        </div>
        <br />
        <table>
            <tr *ngFor="let allowance of payrollAllowances">
                <td>{{allowance.eeCode}}</td>
                <td>{{allowance.eeName}}</td>
                <td>{{allowance.eeStatus}}</td>
                <!-- <td>{{allowance.departmentCode2}}</td>   -->
                <td>{{allowance.departmentDesc}}</td>
                <!-- <td>{{allowance.roleCode}}</td>   -->
                <td>{{allowance.roledesc}}</td>
                <!-- <td>{{allowance.earningCode}}</td>   -->
                <td>{{allowance.earningDesc}}</td>
                <td>{{allowance.amount}}</td>
            </tr>

        </table>
    </div>
</div>

<!-- <tcc-loadingOverlay *ngIf="state !== 'ready'"></tcc-loadingOverlay> -->
