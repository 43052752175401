<tcc-simplePanel [size]="panalSize" [buttons]="['close']" (buttonClick)="close()">

    <div panel-header *ngIf="state === 'ready'">
        <div *ngIf="addOrEdit === 'add'">Add</div>
        <div *ngIf="addOrEdit === 'edit'">Edit {{currentApplicationView?.key}}</div>
    </div>

    <div panel-body *ngIf="state === 'ready'">
        <div class="container">
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <h4>Application View</h4>
                </div>
            </div>           

            <form [formGroup]="applicationViewFormGroup" (ngSubmit)="onSubmit()">

                <input type="text" 
                        formControlName="id"                
                        #id 
                        hidden>
                
                <label for="key">Key: </label>
                <input type="text" 
                       formControlName="key"
                       [readonly]="true" 
                       [(ngModel)]="generatedKey"
                       [ngClass]="{changed: isChanged('key')}"
                       name="key"
                       #key>
                <span *ngIf="duplicateKey && addOrEdit === 'add'" style="color:red">Duplicate Key</span>
                <br />
                <br />
                <label for="view">View: </label>
                <select formControlName="view"
                        [ngClass]="{changed: isChanged('view')}"
                        name="view"
                        [disabled]="isEditMode()" 
                        #view
                        (change)="updateViewName()">
                        <option *ngFor="let cat of applicationViewsCategories" [ngValue]="cat">
                            {{ cat }}
                        </option>
                </select>
             

                <label for="accountCode">Account Code: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('accountCode')}"
                        formControlName="accountCode"
                        name="accountCode"
                        [disabled]="isEditMode()" 
                        #accountCode 
                        (input)="fieldChanged(accountCode)">  

                <label for="parentAccountCode">Parent Account Code: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('parentAccountCode')}"
                        formControlName="parentAccountCode"
                        #parentAccountCode 
                        name="parentAccountCode"
                        (input)="fieldChanged(parentAccountCode)">  

                <label for="Name">Name: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('name')}"
                        formControlName="name"
                        #name
                        name="name" 
                        (input)="fieldChanged(name)">  

                <label for="calculationExpression">Calculation Expression: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('calculationExpression')}"
                        formControlName="calculationExpression"
                        #calculationExpression 
                        name="calculationExpression"
                        (input)="fieldChanged(calculationExpression)">  

                <label for="displayAccountCode">Display Account Code: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('displayAccountCode')}"
                        formControlName="displayAccountCode"
                        #displayAccountCode 
                        name="displayAccountCode"
                        (input)="fieldChanged(displayAccountCode)">  

                <br />
                <br />

                <label for="isNegated">Is Negated: </label>
                <select formControlName="isNegated"
                        [ngClass]="{changed: isChanged('isNegated')}"
                        name="isNegated"
                        #isHidden>
                        <option [selected] value="true">True</option>
                        <option [selected] value="false">False</option>
                </select>
        

                <label for="sortIndex">Sort Index: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('sortIndex')}"
                        formControlName="sortIndex"
                        #sortIndex
                        name="sortIndex" 
                        (input)="fieldChanged(sortIndex)">  

                <br />
                <br />
                <label for="isReadOnly">Is Readonly: </label>
                <select formControlName="isReadOnly"
                        [ngClass]="{changed: isChanged('isReadOnly')}"
                        name="isReadOnly"
                        #isHidden>
                        <option [selected] value="true">True</option>
                        <option [selected] value="false">False</option>
                </select>

                <br />
                <br />
                <label for="isReadOnly">Is Ignored In Totals: </label>
                <select formControlName="isIgnoredInTotals"
                        [ngClass]="{changed: isChanged('isIgnoredInTotals')}"
                        name="isIgnoredInTotals"
                        #isHidden>
                        <option [selected] value="true">True</option>
                        <option [selected] value="false">False</option>
                </select>
        

                <label for="customValidation">Custom  Validation: </label>
                <input type="text" 
                        [ngClass]="{changed: isChanged('customValidation')}"
                        formControlName="customValidation"
                        #customValidation 
                        name="customValidation"
                        (input)="fieldChanged(customValidation)">  

                <br />
                <br />
                <label for="isHidden">Is Hidden: </label>
                <select formControlName="isHidden"
                        [ngClass]="{changed: isChanged('isHidden')}"
                        name="isHidden"
                        #isHidden>
                        <option [selected] value="true">True</option>
                        <option [selected] value="false">False</option>
                </select>

                <div style="margin-top: 10px;">
                    <button type="submit" [disabled]="!applicationViewFormGroup.valid || 
                                                      !applicationViewFormGroup.dirty || 
                                                      (duplicateKey && addOrEdit === 'add')">Save</button>
                </div>
            </form>
        </div>
    </div>

</tcc-simplePanel>
<tcc-loadingOverlay *ngIf="state !== 'ready'"></tcc-loadingOverlay>