import { Injectable, Inject } from '@angular/core';
import { OrganizationService } from '../core-services/organization.service';
import { OrgCalculationTransform } from './org-calculation-models';
import { shareReplay, map } from 'rxjs/operators';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { CalcExpression } from '../shared/calculation-expression';

@Injectable({
  providedIn: 'root',
})
export class TaxCalculationsService {
  private configSource = this.orgSvc.orgs$
    .pipe(
      map(orgs => {
        const config: OrgCalculationTransform[] = [];
        for (const org of orgs) {
          const calcInfo = new OrgCalculationTransform();
          calcInfo.orgCode = org.orgCode;
          calcInfo.sourceViewName = this.globalSettings.defaultCalculationView;
          calcInfo.sourceCalculations = [
            { varName: 'FICA', calcExpr: new CalcExpression('([548169] + [548299] - [548198]) * .062'), orgCode: org.orgCode },
            { varName: 'MEDICARE', calcExpr: new CalcExpression('([548169] + [548299] - [548198]) * .0145'), orgCode: org.orgCode }
          ];
          calcInfo.assignmentCalculations = [
            { varName: '548801', calcExpr: new CalcExpression('[FICA]'), orgCode: org.orgCode },
            { varName: '548802', calcExpr: new CalcExpression('[MEDICARE]'), orgCode: org.orgCode }
          ];

          config.push(calcInfo);
        }
        return config;
      }),
      shareReplay(1)
    );

  constructor(private orgSvc: OrganizationService, @Inject(GLOBAL) private globalSettings: IGlobalSettings) {

  }

  public getConfig() {
    return this.configSource;

  }
}


