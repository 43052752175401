import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationsService } from '@tcc/ui';
import { finalize, take } from 'rxjs/operators';
import { MathAction } from './math-action';
import { tapError } from 'src/app/shared/tap-error-operator';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-math-menu',
  templateUrl: './math-menu.component.html'
})
export class MathMenuComponent implements OnChanges {
  @Input()
  actions?: MathAction[];

  /** the amount or function to use when calling process */
  applyToExpression = '0';

  @Input()
  buttonClass?: string;

  /** the currently selected action */
  selectedAction: MathAction | undefined;

  state: 'none' | 'executing' = 'none';
  constructor(private cd: ChangeDetectorRef, private notifSvc: NotificationsService) { }


  /** Returns true if not in executing state and there is a selected action and the applyToExpression meets the action's requirements. */
  get canExecute() {
    return this.state === 'none' && this.selectedAction
      && (!this.selectedAction.requiresValue || /\S/.test(this.applyToExpression || ''));
  }
  /** returns true if executing state */
  get isDisabled() {
    return this.state === 'executing';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions) {
      this.selectedAction = this.actions!.find(x => x.isPlaceholder) ?? this.actions?.[0];
    }
  }

  /** Executes an action and makes it the currently selected action */
  executeAction(action: MathAction) {
    this.selectedAction = action;
    if (this.canExecute) {
      this.state = 'executing';
      action.func(this.applyToExpression).pipe(
        take(1),
        tapError(err => this.notifSvc.addError(err)),
        finalize(() => {
          this.state = 'none';
          this.cd.detectChanges();
        })
      ).subscribe();
    }
  }
}
