import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { forkJoin, of, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, finalize, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { LedgerAccountNode, LedgerEntry, LedgerEntryType, LedgerSummary, Organization } from '../client-api.service';
import { LedgerService } from '../ledgers/ledger.service';
import { OrganizationService } from '../core-services/organization.service';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { tapError } from '../shared/tap-error-operator';


interface AccountInfo { 
  code: string; 
  isLeaf?: boolean;
  name: string; 
}

@Component({
  selector: 'targetedEdit',
  templateUrl: './targeted-edit.component.html'
})
export class TargetedEditComponent implements OnDestroy, OnInit {

  private _acct?: AccountInfo;
  private _org?: Organization;

  private readonly entryDependencyChange = new Subject<void>();
  private readonly ledgerSubject = new BehaviorSubject<LedgerSummary | undefined>(undefined);
  private readonly subsMgr = new SubsManager();

  get acct() {
    return this._acct;
  }
  set acct(value: AccountInfo | undefined) {
    if (this._acct !== value) {
      this._acct = value;
      this.entryDependencyChange.next();
    }
  }

  accts: AccountInfo[] = [];

  get canEditEntries() {
    return (this.org && this.ledger && this.acct);
  }
  get ledger() {
    return this.ledgerSubject.value;
  }
  set ledger(value: LedgerSummary | undefined) {
    if (this.ledgerSubject.value !== value) {
      this.ledgerSubject.next(value);
    }
  }
  ledgers: LedgerSummary[] = [];

  months: string[] = [];

  get org() {
    return this._org;
  }
  set org(value: Organization | undefined) {
    if (this._org !== value) {
      this._org = value;
      this.entryDependencyChange.next();
    }
  }

  organizations: Organization[] = [];

  values: { new: number, original: number }[] = [];

  state: 'loading' | 'ready' = 'ready';


  constructor(
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerSvc: LedgerService,
    private orgSvc: OrganizationService,
    private notifSvc: NotificationsService
  ) { }

  ngOnInit() {
    this.months = this.globalSettings.monthLabels;

    this.state = 'loading';

    let viewLedgers: number[];
    this.subsMgr.addSub = forkJoin([
      this.orgSvc.orgs$.pipe(tap(x => this.organizations = x)),
      this.ledgerSvc.glViews$.pipe(tap(x => viewLedgers = x.map(y => y.ledger?.ledgerId || 0)), take(1)),
    ])
      .pipe(mergeMap(() => this.ledgerSvc.ledgerSummaries$.pipe(tap(x =>
        this.ledgers = x.filter(y => viewLedgers.indexOf(y.ledgerId) === -1)
          .sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))
      ))))
      .subscribe(() => this.state = 'ready');

    this.subsMgr.addSub = this.entryDependencyChange.pipe(
      debounceTime(250),
      switchMap(() => this.loadEntryValues$())
    ).subscribe();

    this.subsMgr.addSub = this.ledgerSubject.pipe(
      tap(() => this.state = 'loading'),
      switchMap(ledger => ledger ? this.ledgerSvc.getLedger(ledger.ledgerId) : of<undefined>(undefined)),
      tap(ledger => {
        this.accts = (ledger?.accountTree ? this.getLedgerNodes(ledger.accountTree, false) : [])
          .sort((a, b) => a.code < b.code ? -1 : (a.code > b.code ? 1 : 0));
        if (this.acct) {
          this.acct = this.accts.find(a => a.code === this.acct?.code);
        }
        this.state = 'ready';
        // There is something I don't like about this.  Changes to accounts, acct, org, ledger
        // should all be in subjects and then the changes merge and having entries loaded after.
        this.entryDependencyChange.next();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  getAcctLabel(acct: LedgerAccountNode) {
    return acct.displayAccountCode ? acct.displayAccountCode : acct.accountCode + ' ' + acct.name;
  }

  /** Constructs a label for an organiztion by placing it's orgCode in parenthesis after its name */
  getOrgLabel(org: Organization) {
    return `${org.name} (${org.orgCode})`;
  }

  saveNewValues() {
    const entriesToSave: LedgerEntry[] = [];
    let value: { new: number, original: number };
    if (!this.acct || !this.ledger || !this.org) {
      return;
    }
    for (let i = 0; i < 12; i++) {
      value = this.values[i];
      if (value.new !== value.original) {
        if (value.new == null || isNaN(value.new)) {
          this.notifSvc.addError(`Value at ${this.months[i]} is not a number.`);
          return;
        }
        entriesToSave.push({
          accountCode: this.acct.code,
          amount: value.new,
          appliedOn: new Date(this.getApplicableYear(), i, 1),
          entryType: LedgerEntryType.Default,
          ledgerEntryId: 0
        });
      }
    }

    if (entriesToSave.length === 0) {
      this.notifSvc.addInfo(`There were no targeted edits to save.`);
      return;
    }

    this.state = 'loading';
    this.orgSvc.saveLedgerEntiresSuperUser(this.org.orgId, this.ledger.ledgerId, entriesToSave)
      .pipe(
        tap(() => {
          this.values.forEach(x => x.original = x.new);
          this.notifSvc.addSuccess('Entries Saved');
        }),
        tapError((err) => this.notifSvc.addError(err)),
        finalize(() => this.state = 'ready')
      ).subscribe();
  }

  /**
   * gets this year that applies to the current ledger, as actBud will be one less than budget year.
   */
  private getApplicableYear() {
    return (this.ledger?.name === this.globalSettings.actBudLedgerName)
      ? this.globalSettings.budgetYear - 1
      : this.globalSettings.budgetYear;
  }

  private getLedgerNodes(rootNode: LedgerAccountNode, leafNodesOnly: boolean) {
    if (!rootNode.children || rootNode.children.length === 0) {
      return [{ name: this.getAcctLabel(rootNode), code: rootNode.accountCode, isLeaf: true }];
    }

    const leafNodes: AccountInfo[] = [];
    const stack: { node: LedgerAccountNode, childIndex: number }[] = [];
    let stackItem: { node: LedgerAccountNode, childIndex: number } | undefined;
    let childNode: LedgerAccountNode;
    let isLeaf: boolean;
    stack.push({ node: rootNode, childIndex: 0 });

    while ((stackItem = stack.pop()) != null) {
      while (stackItem.childIndex < stackItem.node.children.length) {
        childNode = stackItem.node.children[stackItem.childIndex];
        stackItem.childIndex++;
        isLeaf = !childNode.children || childNode.children.length === 0;
        if ((!leafNodesOnly || isLeaf) && !childNode.isReadOnly) {
          leafNodes.push({
            name: this.getAcctLabel(childNode),
            code: childNode.accountCode,
            isLeaf
          });
        }
        if (!isLeaf) {
          stack.push(stackItem);
          stackItem = { node: childNode, childIndex: 0 };
        }
      }
    }
    return leafNodes;
  }

  /** Resets new and existing values arrays and returns an observable responsible for updating the values */
  private loadEntryValues$() {

    this.values = [];
    for (let i = 0; i < this.months.length; i++) {
      this.values[i] = { new: 0, original: 0 };
    }

    if (this.canEditEntries && this.acct && this.ledger && this.org) {
      this.state = 'loading';
      return this.orgSvc.getCurrentAccountEntries(this.org.orgId, this.ledger.ledgerId, this.acct.code, this.getApplicableYear())
        .pipe(
          tap(entries => {
            for (const entry of entries) {
              const monthIndex = entry.appliedOn.getMonth();
              this.values[monthIndex].original = entry.amount;
              this.values[monthIndex].new = entry.amount;
            }
          }),
          finalize(() => this.state = 'ready')
        );
    }

    return of(null);
  }

}


