import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Workflow } from '../../client-api.service';
import { OrganizationService } from '../../core-services/organization.service';
import { WorkflowsService } from '../../core-services/workflows.service';
import { LedgerService } from '../../ledgers/ledger.service';
import { ReportCube, ReportCubeAxis, ReportCubeDimension, ReportCubeDimensionMember, ReportCubeMeasure } from '../report-cube';
import { ReportDefinition } from '../reports.service';
import { WorkflowStatusDetail } from './workflow-status-detail';

@Injectable({
  providedIn: 'root'
})
export class WorkflowStatusReportService implements ReportDefinition<WorkflowStatusDetail> {

  readonly name = 'Workflow Status';

  constructor( private ledgerSvc: LedgerService, private orgSvc: OrganizationService,  private wfsSvc: WorkflowsService) { }


  retrieveData() {
   

    return forkJoin([
      this.wfsSvc.getWorkflowStates(),
      this.wfsSvc.workflows$.pipe(filter((x): x is Workflow[] => !!x), take(1)),
      this.orgSvc.orgs$,
      this.ledgerSvc.glViews$.pipe(take(1), map(x => x.filter(() => true /* filter views here to those that have no children*/)))
    ]).pipe(map(([states, workflows, orgs, views]) => {
      const reportData = new ReportCube<WorkflowStatusDetail>();
      reportData.options.hideSoloMeasureHeader = true;
      const orgDimension = new ReportCubeDimension('Organization',
        orgs.map(o => <ReportCubeDimensionMember><unknown>{ key: o.orgId, label: o.name }), ReportCubeAxis.Row);
      reportData.addDimension(orgDimension);
      const viewDimension = new ReportCubeDimension('View',
        views.map(v => <ReportCubeDimensionMember><unknown>{ key: v.viewId, label: v.name }), ReportCubeAxis.Col);
      reportData.addDimension(viewDimension);
      const workflowDimension = new ReportCubeDimension('Workflow',
        workflows.map(wf => <ReportCubeDimensionMember><unknown>{ key: wf.workflowId, label: wf.name }), ReportCubeAxis.None);
      reportData.addDimension(workflowDimension);
      const workflowLookup = new Map(workflows.map(x => [x.workflowId, x]));
      for (const wfState of states) {
        // it is possible we can have more than one workflow going for an org and view.
        const detail = new WorkflowStatusDetail(workflowLookup.get(wfState.workflowId)!, wfState);
        reportData.addData(detail, [`${wfState.orgId}`, `${wfState.viewId}`, `${wfState.workflowId}`]);
      }
      reportData.addMeasure(new ReportCubeMeasure<WorkflowStatusDetail>(
        'State',
        (measures) => {
          return (measures.length === 1)
            ? getMeasureName(measures[0])
            : measures.map(m => m.workflowName + ': ' + getMeasureName(m)).join(',');
        })
      );

      return reportData;
    }));

    function getMeasureName(m: WorkflowStatusDetail)  {
      return (m.isWorkflowComplete)
        ? 'Complete'
        : ((m.stateName) ? `${m.currentStep!.name}` : 'Not Started');
    }
  }
}
