<tcc-simplePanel [size]="panalSize" [ngClass]="{ 'print-hide': !hasNoChildBladesOpen }">

    <div panel-header>Financial Planning Maintenance</div>
    <div panel-body>

      <ul class="nav nav-tabs mt-4 mb-3" id="myTab" role="tablist">
        <li class="nav-item" *ngFor="let tab of tabs; let i = index">
          <a class="nav-link nonLink"
            [class.active]="activeTab === i"
            (click)="setActiveTab(i)" 
            id="tab{{i}}"
            role="tab"
            aria-controls="feed"
            [attr.aria-selected]="activeTab === i ? true : false">
            {{tab}}
          </a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <ng-container *ngFor="let tab of tabs; let i = index">
          <div class="tab-pane fade" 
            [class.show]="activeTab === i"
            [class.active]="activeTab === i"
            id="content{{i}}" 
            role="tabpanel" 
            [attr.aria-labelledby]="'tab' + i"
            *ngIf="activeTab === i">  

            <ng-container *ngIf="tab === 'Installer'">
              <app-financial-planning-installer></app-financial-planning-installer>               
            </ng-container>  


            <ng-container *ngIf="tab === 'Installer Tracking'">
                <app-financial-planning-installer-tracking></app-financial-planning-installer-tracking>
            </ng-container>
           
            
            <ng-container *ngIf="tab === 'Application Views'">
              <app-application-views></app-application-views>              
            </ng-container>  
            
          </div>
        </ng-container>
      </div>
    </div>
</tcc-simplePanel>
<router-outlet></router-outlet>

<tcc-loadingOverlay *ngIf="state !== 'ready'"></tcc-loadingOverlay>