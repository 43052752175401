import { Inject, Injectable } from '@angular/core';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ApiException, ClientApi, EstimateBatchItem, EstimateType, EstimateUpsertBehavior, RevenueArea, RevenueAreaEstimate,
  RevenueAreaMeta, RevenueAreaType
} from '../client-api.service';
import { AggregateUtil } from '../shared/aggregate-util';
import { ArrayUtil } from '../shared/array-util';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { setPeriodEstimateValue } from './estimate-conversions';
import { AreaEstimates } from './models/area-estimates';
import { PeriodSettings } from './models/period-settings';

export interface EstimateSimpleBatchItem extends EstimateBatchItem { 
  forceInsert: boolean;
  oldValue?: number;
}

@Injectable({
  providedIn: 'root',
})
export class RevenueService {
  static readonly AreaSorter = ArrayUtil.compareSelectorStringsFuncFactory(
    (x: AreaEstimates) => x.displayName, { ignoreCase: true });

  static EstimateSavingCmdName = 'revEstimateSaving';
  static MetaUpdateCmdName = 'rewMetaUpdate';
  static RevenueLoadingCmdName = 'revenueLoading';

  constructor(private clientApi: ClientApi, @Inject(GLOBAL) private globalSettings: IGlobalSettings) {
  }


  /**
   * Creates an array of dates starting with August 1st and December 31st of the prior budget year,
   * and the 12 months of the current year.
   */
  getPeriodSettings(budgetYear: number) {
    const dates = [
      new Date(budgetYear - 1, 7, 1),
      new Date(budgetYear - 1, 11, 31),
      ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => new Date(budgetYear, x, 1))
    ];

    const periods = dates.map(x => ({
      label: x.toLocaleDateString('en-us', { month: 'short' }) + ((x.getFullYear() !== budgetYear) ? ` ${x.getFullYear()}` : ''),
      value: x
    }));
    return <PeriodSettings>{
      cy: budgetYear,
      cyJan: { index: 2, ...periods[2] },
      datePeriodIndexMap: new Map(dates.map((x, i) => [x.valueOf(), i])),
      periods,
      py: budgetYear - 1
    };
  }

  /**
   * gets a map of orgIds to their root revenue areas
   */
  getRootOrgAreas(budgetYear: number) {
    return this.clientApi.getOrganizationAreas(budgetYear)
      .pipe(map(orgAreas => new Map(orgAreas.map(x => [x.orgId, x]))));
  }

  /** gets ledger summary with matching name */
  getRevenueAreas(orgId: number, budgetYear: number) {
    return this.clientApi.getRevenueAreas(orgId, budgetYear);
  }


  /**
   * gets a rollup of estimates for all orgs
   */
  getRollup(budgetYear: number, appliedOn: Date) {
    return this.clientApi.getRollup(budgetYear, appliedOn).pipe(map(res => {
      const orgMap = ArrayUtil.group(res, x => x.orgId!);
      return orgMap;
    }));
  }

  /**
   * maps an array of revenue areas to area estimates for single org.
   */
  mapRevenueAreasToAreaEstimates(src: RevenueArea[], periodSettings: PeriodSettings) {
    const areas: AreaEstimates[] = [];
    const idMap = new Map<number, AreaEstimates>();
    for (const ra of src) {
      const area = this.mapRevenueAreaToAreaEstimates(ra, periodSettings);
      areas.push(area);
      idMap.set(area.revAreaId, area);
    }
    // associate unit types to self area
    const selfArea = areas.find(x => x.areaType === RevenueAreaType.Self);
    if (selfArea) {
      areas.filter(x => x.areaType === RevenueAreaType.UnitType).forEach(x => x.parentRevAreaId = selfArea.revAreaId);
    }
    // create hierarchy
    areas.filter(x => x.parentRevAreaId != null)
      .forEach(x => idMap.get(x.parentRevAreaId!)?.children?.push(x));
    // add amenityCode to unit meta
    areas.filter(x => x.areaType === RevenueAreaType.Unit)
      .forEach(x => x.meta!['amenityCode'] = x.children?.map(y => y.displayName).join(','));
    areas.forEach(x => x.children?.sort(RevenueService.AreaSorter));
    return areas;
  }

  /**
   * maps a single revenue area to area estimates
   */
  mapRevenueAreaToAreaEstimates(src: RevenueArea, periodSettings: PeriodSettings) {
    const dest: AreaEstimates = {
      accountCode: src.accountCode,
      areaType: src.areaType!,
      children: [],
      displayName: src.displayName || src.name!,
      name: src.name!,
      meta: src.meta as AreaEstimates['meta'] | undefined,
      parentRevAreaId: src.parentRevAreaId,
      periods: periodSettings.periods.map((x, i) => ({ monthId: i, aggs: {} })),
      revAreaId: src.revAreaId!,
      summary: { monthId: -1, aggs: {} }
    };
    const estRenewalDt = AggregateUtil.max(src.estimates!.filter(x => x.estimateType === EstimateType.EstRenewalRate).map(x => x.createdOn));
    const fpRateDate = AggregateUtil.max(src.estimates!.map(x => x.createdOn));
    if (fpRateDate! > estRenewalDt!) {
      dest.warnings = ['updatesAfterEstRenewal'];
    }
    for (const srcEst of src.estimates || []) {
      const destEst = dest.periods[periodSettings.datePeriodIndexMap.get(srcEst.appliedOn!.valueOf())!];
      setPeriodEstimateValue(srcEst, destEst);
    }
    return dest;
  }


  /** returns an observable that saves a batch of estimates as well as performs individual deletes for items without values */
  saveRevenueAreaEstimates(orgId: number, estimates: EstimateSimpleBatchItem[]) {
    // split operation types
    const upserts: EstimateSimpleBatchItem[] = [];
    const deletes: EstimateSimpleBatchItem[] = [];
    estimates.forEach(x => x.value != null ? upserts.push(x) : deletes.push(x));

    // create upsert operations
    const upsertBatch = upserts.map(x => this.createBatchItem(x));
    const upsertResult$ = (upsertBatch.length > 0)
      ? this.clientApi.postRevenueAreaEstimateBatch(orgId, upsertBatch)
      : of(<RevenueAreaEstimate[]>[]);

    // create delete operations
    const deleteOps = deletes.map(x =>
      this.clientApi.deleteRevenueAreaEstimates(orgId, x.revAreaId!, x.estimateType, x.appliedOn)
        .pipe(
          catchError(err => {
            // ignore 404 errors and rethrow the rest.
            if (ApiException.isApiException(err) && err.status === 404) {
              return of(undefined);
            }
            return throwError(err);
          }),
          map(() => <RevenueAreaEstimate>{ revAreaId: x.revAreaId, appliedOn: x.appliedOn, estimateType: x.estimateType }))
    );
    const deleteResult$ = (deleteOps.length > 0) ? forkJoin(deleteOps) : of(<RevenueAreaEstimate[]>[]);

    return forkJoin([deleteResult$, upsertResult$]).pipe(map(([d, u]) => d.concat(u)));
  }

  /** returns an observable that will initiate a save of revenue area meta. */
  saveRevenueAreaMeta(orgId: number, revAreaId: number, meta: RevenueAreaMeta) {
    return this.clientApi.postRevenueAreaMeta(orgId, revAreaId, meta).pipe(
      map(res => ({ orgId, revAreaId, meta: res }))
    );
  }

  private createBatchItem(estimate: EstimateSimpleBatchItem) {
    return <EstimateBatchItem>{
      appliedOn: estimate.appliedOn,
      createdOn: estimate.createdOn,
      creatorId: estimate.creatorId,
      estimateId: estimate.estimateId,
      estimateType: estimate.estimateType,
      revAreaId: estimate.revAreaId,
      value: estimate.value,
      saveBehavior: estimate.forceInsert ? EstimateUpsertBehavior.ForceInsert : EstimateUpsertBehavior.BasedOnDtoId
    };
  }

}
