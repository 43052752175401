import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { CommandsService, HistoryChangeEvent } from './commands.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'commandHistory',
  styleUrls: ['./command-history.component.scss'],
  templateUrl: './command-history.component.html'
})
export class CommandHistoryComponent implements OnDestroy, OnInit {

  commandCount = 0;
  cssClass = '';
  state: 'error' | 'saving' | 'ok' | 'none' = 'none';

  private subsManager = new SubsManager();

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload($event: BeforeUnloadEvent) {
    // Returning false will show a browser-dependent confirm dialog before navigating away.
    if (this.state === 'saving') {
      $event.preventDefault();
      $event.returnValue = 'There are items saving, please do not close your browser.';
      this.cd.detectChanges();
    }
  }

  constructor(private cd: ChangeDetectorRef, private commandsSvc: CommandsService) {  }

  ngOnInit() {
    this.subsManager.addSub = this.commandsSvc.commandEvent$.subscribe((x) => this.updateState(x));
  }

  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  showDebugInfo() {
    console.log(this.commandsSvc.commandEntries);
  }

  private updateState(evt: HistoryChangeEvent<unknown>) {
    if (this.state === 'error') {
      // never leave error state
      return;
    }
    if (evt.status === 'failed') {
      this.state = 'error';
    }
    else {
      this.commandCount = this.commandsSvc.queuedCommandCount ?? 0;
      this.state = (this.commandCount > 0) ? 'saving' : 'ok';
    }
    this.cd.detectChanges();
  }

}
