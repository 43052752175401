import { Employee, PayPeriodType, PayType } from '../client-api.service';

export interface PayItemAmounts {
  /** The pay period type for amounts, though it may not agree with the current amount */
  payPeriodType: PayPeriodType;
  currentAmount: number;
  currentPayItemId?: number;
  proposedAmount: number;
  proposedPayItemId?: number;
}
declare interface PayItemAmountsMap {
  [payTypeId: number]: PayItemAmounts;
}
export interface PayrollEmployeeModel {

  empId: number;
  externalId: string;
  budgetYear: number;
  isCurrentValuesLocked: boolean;
  isFullTime: boolean;
  name: string;
  position: string;
  /** should have records for each PayType Id */
  payItemAmounts: PayItemAmountsMap;
  /** should have 12 */
  monthlyHourAllocations: { hourAllocationId?: number, hours: number }[];
  /** this is an id for tracking all models since new employees won't have ids empIds */
  trackingId: number;
}

export class PayrollEmployeeModelUtil {
  /** source for tracking ids */
  private static nextTrackingId = 1;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static create(emp: Employee, payTypesSource: PayType[], basePayType: PayType) {

    const payItemAmounts: PayItemAmountsMap = {};
    for (const payType of payTypesSource) {
      payItemAmounts[payType.payTypeId] = {
        currentAmount: 0,
        proposedAmount: 0,
        payPeriodType: payType.defaultPeriodType
      };
    }
    const monthlyHourAllocations: { hourAllocationId?: number, hours: number }[] = [];
    // budget Pay Period Type has priorty, so keep track of when set by budget so current doesn't overwrite it
    const isPayPeriodTypeSetByBudget: { [payTypeId: number]: boolean } = {};

    if (emp.payItems) {
      for (const payItem of emp.payItems) {
        const payItemAmountsEntry = payItemAmounts[payItem.payTypeId];
        if (payItem.isBudget) {
          payItemAmountsEntry.proposedPayItemId = payItem.payItemId;
          payItemAmountsEntry.proposedAmount = payItem.amount;
          if (payItem.payPeriodType) {
            payItemAmountsEntry.payPeriodType = payItem.payPeriodType;
            isPayPeriodTypeSetByBudget[payItem.payTypeId] = true;
          }
        }
        else {
          payItemAmountsEntry.currentPayItemId = payItem.payItemId;
          payItemAmountsEntry.currentAmount = payItem.amount;
          if (payItem.payPeriodType && !isPayPeriodTypeSetByBudget[payItem.payTypeId]) {
            payItemAmountsEntry.payPeriodType = payItem.payPeriodType;
          }
        }
      }
    }

    const empHourAllocations = emp.hourAllocations || [];
    for (let month = 1; month <= 12; month++) {
      const existing = empHourAllocations.find(x => x.month === month);
      if (existing) {
        monthlyHourAllocations.push({ hourAllocationId: existing.hourAllocationId, hours: existing.hours });
      }
      else {
        monthlyHourAllocations.push({ hours: 0 });
      }
    }


    const model: PayrollEmployeeModel = {
      empId: emp.empId,
      externalId: emp.externalId!,
      budgetYear: emp.budgetYear,
      isCurrentValuesLocked: emp.isCurrentValuesLocked || false,
      isFullTime: emp.isFullTime || false,
      name: emp.name,
      position: emp.position,
      payItemAmounts: payItemAmounts,
      monthlyHourAllocations: monthlyHourAllocations,
      trackingId: PayrollEmployeeModelUtil.nextTrackingId++
    };

    return model;
  }

  /**
   * Deep clones a PayrollEmployeeModel
   * @param src
   */
  static DeepClone(src: PayrollEmployeeModel) {
    const model: PayrollEmployeeModel = {
      empId: src.empId,
      externalId: src.externalId,
      budgetYear: src.budgetYear,
      isCurrentValuesLocked: src.isCurrentValuesLocked,
      isFullTime: src.isFullTime,
      name: src.name,
      position: src.position,
      payItemAmounts: {},
      monthlyHourAllocations: src.monthlyHourAllocations.map(x => ({ hourAllocationId: x.hourAllocationId, hours: x.hours })),
      trackingId: src.trackingId
    };

    for (const key in src.payItemAmounts) {
      const srcAmounts = src.payItemAmounts[key];
      model.payItemAmounts[key] = {
        currentAmount: srcAmounts.currentAmount,
        currentPayItemId: srcAmounts.currentPayItemId,
        payPeriodType: srcAmounts.payPeriodType,
        proposedAmount: srcAmounts.proposedAmount,
        proposedPayItemId: srcAmounts.proposedPayItemId
      };
    }

    return model;
  }
}
