<div class="input-group input-group-sm">
  <input type="text" class="text-right form-control" style="width:8em" [(ngModel)]="applyToExpression" [disabled]="isDisabled">
  <div class="btn-group input-group-append" ngbDropdown>
    <button class="btn" [ngClass]="buttonClass || 'btn-light'"
     (click)="executeAction(selectedAction!)"
     [disabled]="isDisabled || !canExecute">
      <ng-container *ngIf="selectedAction">
        <div *ngIf="selectedAction.iconTemplate; else noIcon"
          [innerHtml]="selectedAction.iconTemplate"
          [title]="selectedAction.description || ''"></div>
        <ng-template #noIcon>
          <span [title]="selectedAction.description || ''">{{selectedAction.name}}</span>
        </ng-template>
      </ng-container>
    </button>
    <button class="btn" [ngClass]="buttonClass || 'btn-light'" ngbDropdownToggle></button>
    <div ngbDropdownMenu style="z-index:1030">
      <ng-container *ngFor="let a of actions">
        <button type="button" ngbDropdownItem *ngIf="!a.isPlaceholder" [title]="a.description || ''"
          (click)="executeAction(a)" [ngClass]="buttonClass!">
          <div *ngIf="a.iconTemplate" [innerHtml]="a.iconTemplate" style="min-width:21px; display:inline-block"></div>
          {{a.name}}
        </button>
      </ng-container>
    </div>
  </div>
</div>
