import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { SimpleLog, SimpleLogItem } from './simple-log-models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-simpleLog',
  template: `
<table [ngClass]="tableClass">
  <thead>
    <tr>
      <th class="slog-time-col">Time</th>
      <th class="slog-cat-col">Category</th>
      <th class="slog-msg-col">Message</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of itemsChange$ | async" [ngClass]="getItemClass(i)">
      <td class="slog-time-col">{{i.time | date:'HH:mm:ss'}}</td>
      <td class="slog-cat-col text-capitalize">{{log.categories[i.catIndex]}}</td>
      <td class="slog-msg-col">{{i.message}}</td>
    </tr>
  </tbody>
</table>
`
})
export class SimpleLogComponent {
  /** backing for the log property. */
  private logChangeSubject = new BehaviorSubject<SimpleLog>(new SimpleLog());

  /** row css classes for categories. */
  @Input()
  categoryClassMap: { [category: string]: string } = {
    error: 'table-danger',
    warning: 'table-warning',
    success: 'table-success'
  };

  /** set or get the log source.  Defaults to an empty log. */
  @Input()
  get log() { return this.logChangeSubject.value; }
  set log(value: SimpleLog) { this.logChangeSubject.next(value); }

  /** any time the log items changes returns an array of items.  Includes the initial items when the log changes. */
  readonly itemsChange$ = this.logChangeSubject.pipe(
    switchMap(l => merge(l.logAppened$, l.logCleared$).pipe(
      map(() => l.items),
      startWith(l.items),
    ))
  );

  /** class to apply to log table. */
  @Input()
  tableClass = '';

  public getItemClass(item: SimpleLogItem) {
    const category = this.log.categories[item.catIndex];
    return this.categoryClassMap[category];
  }
}
