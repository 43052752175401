import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RenewalsComponent } from './renewals/renewals.component';
import { RollupComponent } from './rollup/rollup.component';
import { RevenueComponent } from './revenue/revenue.component';
import { RateSummaryComponent } from './revenue/rate-summary/rate-summary.component';

const routes: Routes = [
  {
    path: ':orgId/renewals',
    component: RenewalsComponent
  },
  {
    path: ':orgId/revenue',
    component: RevenueComponent,
    children: [
      { path: 'rate-summary', component: RateSummaryComponent }
    ]
  },
  {
    path: 'rollup',
    component: RollupComponent
  },
  {
    path: '**',
    redirectTo: 'rollup'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RevenueRoutingModule { }
