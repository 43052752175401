import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, finalize, map, mapTo, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { LedgerEntryType, Role, Workflow, WorkflowStep, WorkflowStepUpsertInfo } from '../client-api.service';
import { CommandBatchStatusChange } from '../commands/command-manager';
import { CommandsService } from '../commands/commands.service';
import { UserService } from '../core-services/user.service';
import { WorkflowsService } from '../core-services/workflows.service';
import { LedgerService } from '../ledgers/ledger.service';
import { ViewInfo } from '../ledgers/view-info';
import { defaultCheckStyle, defaultDeleteCheckStyle } from '../shared/check-styles';
import { tapError } from '../shared/tap-error-operator';

@Component({
  selector: 'user-admin',
  styles: [
    '.table td, .table th { padding: 2px 2px; }'
  ],
  templateUrl: './workflow-admin.component.html'
})
export class WorkflowAdminComponent implements OnDestroy, OnInit {
  private readonly saveCmd = this.commandsSvc.getCommand('UpdateWorkflow');

  readonly acceptedFileTypes = [{ ext: 'pdf', type: 'application/pdf' }];

  /** default style for tccFaCheckStyle */
  readonly checkStyle = defaultCheckStyle;
  readonly checkDeleteStyle = defaultDeleteCheckStyle;
  readonly roles$ = this.userSvc.roles$.pipe(
    map(x => x.map(y => this.associateRoleClasses(y)).sort((a, b) => a.orderIdx - b.orderIdx)),
    shareReplay(1)
  );

  @ViewChild('myForm', { static: true })
  myForm?: NgForm;

  state$ = new BehaviorSubject<'loading' | 'ready'>('loading');
  workflow?: Workflow;
  workflowMarkedForDeletion?: boolean;
  workflowStepModels?: WorkflowStepModel[];
  workflowViewModels?: WorkflowViewModel[];
  workflows?: Workflow[];
  views?: ViewInfo[];

  private subsMgr = new SubsManager();


  constructor(
    private commandsSvc: CommandsService,
    private ledgersSvc: LedgerService,
    private notifSvc: NotificationsService,
    private userSvc: UserService,
    private wfsSvc: WorkflowsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.subsMgr.addSub =
      combineLatest([
        this.wfsSvc.workflows$.pipe(tap(wfs => {
          this.workflows = wfs || [];
          if (this.workflow && wfs) {
            // update current workflow;
            this.setWorkflow(this.workflows.find(x => x.workflowId === this.workflow?.workflowId)!);
          }
        })),
        this.roles$,
        this.ledgersSvc.glViews$.pipe(tap(views => this.views = views)),
        this.saveCmd.batchStatusChange$.pipe(startWith(undefined as unknown as CommandBatchStatusChange<unknown>))
      ]).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([wfs, roles, views, _]) =>
          (wfs && roles.length && views && this.saveCmd.queueSize === 0) ? 'ready' : 'loading'),
        tap(x => this.state$.next(x)),
      ).subscribe();

    this.subsMgr.addSub =
      combineLatest([
        this.wfsSvc.workflows$,
        this.roles$,
        this.ledgersSvc.glViews$
      ]).pipe(
        filter(([wfs, roles, views]) => !!(wfs && roles.length && views && this.workflow)),
        tap(([wfs]) => this.setWorkflow(wfs!.find(x => x.workflowId === this.workflow?.workflowId)!))
      ).subscribe();

    // this will update the workflow from route query params after the state is ready
    this.subsMgr.addSub = this.route.queryParamMap.pipe(
      filter(x => x.has('workflowId')),
      map(x => ({ workflowId: parseInt(x.get('workflowId')!, 10) })),
      switchMap((params) => this.state$.pipe(filter(x => x === 'ready'), take(1), mapTo(params))), // wait until ready state to emit changes
      tap((params) => this.setWorkflow(this.workflows!.find(y => y.workflowId === params.workflowId)!))
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  addStep() {
    if (this.workflow && this.workflowStepModels) {
      this.workflowStepModels.push(this.createWorkflowStepModel());
      this.myForm!.form.markAsDirty();
    }
  }


  saveChanges() {
    if (this.myForm!.invalid) {
      return;
    }

    const processes: Observable<unknown>[] = [];

    if (this.workflowMarkedForDeletion) {
      // if the workflow is being deleted then don't bother changing anything else.
      processes.push(this.deleteWorkflow(this.workflow!));
    }
    else {
      const stepUpdates = this.workflowStepModels?.map(x => this.updateStep(x)).filter((x): x is Observable<void> | Observable<WorkflowStep> => !!x) || [];
      processes.push(... stepUpdates);
      const viewProcesses$ = this.updateViews(this.workflowViewModels!);
      if (viewProcesses$) {
        processes.push(viewProcesses$);
      }
    }

    if (processes.length > 0) {
      const cmdBatch = this.saveCmd.enqueue({});
      forkJoin(processes)
        .pipe(
          tap(() => {
            this.notifSvc.addSuccess('Changes to workflow were successfully saved');
            this.saveCmd.executed(cmdBatch!);
          }),
          tapError(() => {
            this.notifSvc.addError('Changes were not saved.  Please refresh and try again');
            this.saveCmd.failed(cmdBatch!);
          })
        ).subscribe();
    }
    else {
      /** refresh the workflow just in case the only thing was changed was deleted new steps which would not cause any processing */
      this.setWorkflow(this.workflow!);
    }
  }

  setWorkflow(workflow: Workflow) {
    this.workflow = workflow;
    this.workflowMarkedForDeletion = false;
    this.workflowStepModels = [];
    this.workflowViewModels = [];
    if (!this.workflow) {
      return;
    }
    const steps = (this.workflow.relatedSteps || []).sort((a, b) => {
      const orderDiff = a.orderIndex - b.orderIndex;
      if (orderDiff !== 0) {
        return orderDiff;
      }
      return (a.name < b.name) ? - 1 : ((a.name > b.name) ? 1 : 0);
    });
    this.workflowStepModels = steps.map(x => this.createWorkflowStepModelFromStep(x));

    for (const view of this.views!) {
      this.workflowViewModels.push(this.createWorkflowViewModel(view, view.workflowIds!.indexOf(this.workflow.workflowId) !== -1));
    }
    this.myForm!.resetForm();
  }

  viewHelp(stepId: number) {
    this.state$.next('loading');
    this.wfsSvc.getHelpFileUrl(stepId)!.pipe(
      tap(url => window.open(url)),
      finalize(() => this.state$.next('ready'))
    ).subscribe();
  }

  viewHelpFile(file: File | Blob) {
    const url = URL.createObjectURL(file);
    window.open(url);
  }
  undoChanges() {
    this.setWorkflow(this.workflow!);
  }

  private associateRoleClasses(role: Role): Role & { cssClass?: string; hasClass: boolean; orderIdx: number; } {
    switch (role.name) {
      case 'Community Approver': return { ...role, cssClass: 'text-dark fas fa-user-ninja', hasClass: true, orderIdx: 4 };
      case 'Executive Approver': return { ...role, cssClass: 'text-success fas fa-user-tie', hasClass: true, orderIdx: 2 };
      case 'Reader': return { ...role, cssClass: 'text-secondary fas fa-user', hasClass: true, orderIdx: 5 };
      case 'Regional Approver': return { ...role, cssClass: 'text-danger fas fa-user-md', hasClass: true, orderIdx: 3 };
      case 'Super Approver': return { ...role, cssClass: 'text-primary fas fa-user-secret', hasClass: true, orderIdx: 1 };
      default: return { ...role, hasClass: false, orderIdx: 6 };
    }
  }

  /**
   * Sets all data in WorkflowStepUpsertInfo from model EXCEPT for helpFileData, which has to be loaded async.
   * @param model
   */
  private createStepFromStepModel(model: WorkflowStepModel) {
    const roleIds: number[] = [];
    for (const roleIdText in model.roleStates) {
      if (model.roleStates[roleIdText]) {
        roleIds.push(parseInt(roleIdText, 10));
      }
    }
    return <WorkflowStepUpsertInfo>{
      amountsReadOnly: model.amountsReadOnly,
      exportOnComplete: model.exportOnComplete,
      entryType: model.entryTypeSuggestion ? LedgerEntryType.Suggestion : LedgerEntryType.Default,
      helpInfoClear: model.helpInfoClear,
      name: model.name,
      orderIndex: model.orderIndex,
      requireCommentOnChange: model.requireCommentOnChange,
      requireCommentOnSubmit: model.requireCommentOnSubmit,
      roleIds: roleIds,
      stepId: model.stepId,
      workflowId: model.workflowId
    };
  }

  private createWorkflowStepModel() {
    return <WorkflowStepModel>{
      amountsReadOnly: false,
      entryTypeSuggestion: false,
      exportOnComplete: false,
      helpInfoClear: false,
      markedForDeletion: false,
      orderIndex: 0,
      requireCommentOnChange: false,
      requireCommentOnSubmit: false,
      roleStates: {},
      stepId: 0,
      workflowId: this.workflow!.workflowId
    };
  }

  private createWorkflowStepModelFromStep(step: WorkflowStep) {
    const model: WorkflowStepModel = {
      amountsReadOnly: step.amountsReadOnly,
      entryTypeSuggestion: step.entryType === LedgerEntryType.Suggestion,
      exportOnComplete: step.exportOnComplete,
      helpInfo: step.helpInfo,
      helpInfoClear: false,
      markedForDeletion: false,
      name: step.name,
      orderIndex: step.orderIndex,
      requireCommentOnChange: step.requireCommentOnChange,
      requireCommentOnSubmit: step.requireCommentOnSubmit,
      roleStates: {},
      stepId: step.stepId,
      workflowId: step.workflowId
    };
    for (const roleId of step.roleIds) {
      model.roleStates[roleId] = true;
    }
    return model;
  }
  private createWorkflowViewModel(src: ViewInfo, isChecked?: boolean) {
    return <WorkflowViewModel>{
      viewId: src.viewId,
      viewName: src.name,
      isChecked: isChecked || false
    };
  }

  private deleteWorkflow(wf: Workflow) {
    return this.wfsSvc.deleteWorkflow(wf.workflowId);
  }

  /** creates an observable that does the appropriate update for the step.  Can return undefined if there is no appropriate action. */
  private updateStep(stepModel: WorkflowStepModel) {
    if (stepModel.markedForDeletion) {
      if (stepModel.stepId) {
        return this.wfsSvc.deleteStep(stepModel.stepId);
      }
    }
    else if (!stepModel.stepId) {
      // new step without file.
      return this.wfsSvc.upsertStep(this.createStepFromStepModel(stepModel), stepModel.helpFile);
    }
    else {
      // existing step, make sure update is necessary.
      const wfStep = this.createStepFromStepModel(stepModel);
      const originalStep = this.workflow?.relatedSteps.find(x => x.stepId === stepModel.stepId);

      if (!originalStep
        || stepModel.helpFile
        || (originalStep.helpInfo && wfStep.helpInfoClear)
        || !!originalStep.amountsReadOnly !== !!wfStep.amountsReadOnly
        || originalStep.entryType !== wfStep.entryType
        || !!originalStep.exportOnComplete !== !!wfStep.exportOnComplete
        || originalStep.name !== wfStep.name
        || originalStep.orderIndex !== wfStep.orderIndex
        || originalStep.roleIds.length !== wfStep.roleIds.length
        || originalStep.roleIds.some(rId => wfStep.roleIds.indexOf(rId) === -1)
        || !!originalStep.requireCommentOnChange !== !!wfStep.requireCommentOnChange
        || !!originalStep.requireCommentOnSubmit !== !!wfStep.requireCommentOnSubmit
      ) {
        return this.wfsSvc.upsertStep(this.createStepFromStepModel(stepModel), stepModel.helpFile);
      }
    }
    return undefined;
  }

  private updateViews(viewModels: WorkflowViewModel[]) {
    const processes: Observable<unknown>[] = [];
    const selectedViewIds = viewModels.filter(x => x.isChecked).map(x => x.viewId);
    for (const view of this.views!) {
      const wfIndex = view.workflowIds!.indexOf(this.workflow!.workflowId);
      if (wfIndex !== -1) {
        if (selectedViewIds.indexOf(view.viewId) === -1) {
          view.workflowIds?.splice(wfIndex, 1);
          processes.push(this.ledgersSvc.updateViewWorkflows(view.viewId, view.workflowIds!));
        }
      }
      else {
        if (selectedViewIds.indexOf(view.viewId) !== -1) {
          view.workflowIds?.push(this.workflow!.workflowId);
          processes.push(this.ledgersSvc.updateViewWorkflows(view.viewId, view.workflowIds!));
        }
      }
    }
    return processes.length > 0 ? forkJoin(processes) : undefined;
  }
}

interface WorkflowStepModel {
  amountsReadOnly?: boolean;
  entryTypeSuggestion?: boolean;
  exportOnComplete?: boolean;
  markedForDeletion?: boolean;
  /** original help file */
  helpInfo?: string;
  helpInfoClear?: boolean;
  /** helpfile info from  ui */
  helpFile?: File;
  /** base64 encoded helpfile. */
  helpFileData?: string;
  name?: string;
  orderIndex?: number;
  requireCommentOnChange?: boolean;
  requireCommentOnSubmit?: boolean;
  roleStates: { [roleId: number]: boolean };
  stepId?: number;
  workflowId?: number;
}

interface WorkflowViewModel {
  viewId: number;
  viewName: string;
  isChecked: boolean;
}
