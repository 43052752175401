import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export type TraySize = 'sm' | 'med' | 'full';

export interface TrayRequest<T = unknown> {
  options?: T;
  trayId: string;
}
export interface TrayInfo {
  size?: TraySize;
  trayId: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuTrayStateService {
  private trayChangesSubject = new BehaviorSubject<TrayRequest>({ trayId: 'none' });
  private trayCloseStartSubject = new Subject<TrayRequest>();
  private trayResizeSubject = new Subject<{ tray: TrayInfo }>();

  get currentTray() { return this.trayChangesSubject.value; }

  /** Observable sequence of tray changes */
  readonly trayChanges$ = this.trayChangesSubject.asObservable();
  readonly trayCloseStart$ = this.trayCloseStartSubject.asObservable();
  readonly trayResize$ = this.trayResizeSubject.asObservable();


  /** Closes currently open tray */
  closeTray(skipAnimation: boolean) {
    if (this.currentTray.trayId !== 'none') {
      if (skipAnimation) {
        this.trayChangesSubject.next({ trayId: 'none' });
      }
      else {
        this.trayCloseStartSubject.next({ trayId: this.currentTray.trayId });
      }
    }
  }

  /** This is called from MenuTrayWrapper to indicate that the tray should be closed. */
  onCloseComplete(trayId: string) {
    if (this.currentTray.trayId === trayId) {
      this.trayChangesSubject.next({ trayId: 'none' });
    }
  }

  onResize(tray: TrayInfo) {
    this.trayResizeSubject.next({ tray });
  }

  /**  adds a new tray Request */
  openTray(trayId: string, options?: unknown) {
    this.trayChangesSubject.next({ trayId, options });
  }

  /**  adds a new tray Request */
  toggleTray(trayId: string, options?: unknown) {
    if (this.currentTray.trayId === trayId) {
      this.trayChangesSubject.next({ trayId: 'none' });
    }
    else {
      this.trayChangesSubject.next({ trayId, options });
    }
  }
}
