import { sortBy, groupWith } from 'ramda';
/**
 * Wow this guy is from the AngularJs days!
 * I've started deleting methods as there are more esscript equivelants.
 * Or just use the ramda equivelant.
 */
export class ArrayUtil {

  /**
   * Creates a GroupMap where each element is an array of items
   * @param ary the array to group
   * @param keySelector function that returns keys for group
   * @returns Map<TKey, TItem>, or undefined if ary was null or undefined;
   */
  static group<TKey extends number | string, TItem>(ary: TItem[], keySelector: (item: TItem) => TKey): Map<TKey, TItem[]> 
  static group(ary: undefined, keySelector: (item: unknown) => unknown): undefined 
  static group<TKey extends number | string, TItem>(ary: TItem[] | undefined, keySelector: (item: TItem) => TKey) {
    if (ary) {
      // at some point they will fix ramda's group by so it would just work on strings.
      // This method might be faster, but it is unreadable.
      // const sorted = sortBy(([key]) => key, ary.map(x => [keySelector(x), x] as [TKey, TItem]));
      // const grouped = groupWith(([aKey], [bKey]) => aKey === bKey, sorted);
      // return new Map(grouped.map(x => [x[0][0], x.map(([, value]) => value)]));
      const grouped = groupWith((a: TItem, b: TItem) => keySelector(a) === keySelector(b), sortBy(keySelector, ary));
      return new Map(grouped.map((x: TItem[]) => [keySelector(x[0]), x]));
    }
    return undefined;
  }


  /***********************
      Comparers
  ************************/
  /** common procedure to handle nulls, if no nulls undefined is returned */
  private static compareFuncHandleNulls<T>(a: T, b: T) {
    if (a == null) {
      return (b == null) ? 0 : -1;
    } else if (b == null) {
      return 1;
    }
    return undefined;
  }

  /** pass as a generic sort function for an array of strings or other comparable object */
  static comparerFuncGeneric<T>(a: T, b: T) {
    const nullCheckResult = ArrayUtil.compareFuncHandleNulls(a, b);
    // tslint:disable-next-line: triple-equals
    return (nullCheckResult === undefined) ? ((a == b) ? 0 : ((a < b) ? -1 : 1)) : nullCheckResult;
  }

  /** Creates a comparer that will sort strings.  */
  static compareStringsFuncFactory(opts: { ignoreCase?: boolean }) {
    const transformer = ArrayUtil.compareStringsFuncFactoryBuildStringTransformer(opts);

    return (a: string, b: string) => {
      a = transformer(a);
      b = transformer(b);

      return (a < b) ? -1 : ((a > b) ? 1 : 0);
    };
  }

  static compareSelectorStringsFuncFactory<T>(selector: (x: T) => string, opts: { ignoreCase?: boolean }) {
    const transformer = ArrayUtil.compareStringsFuncFactoryBuildStringTransformer(opts);

    return (a: T, b: T) => {
      const aText = transformer((a != null) ? selector(a) : '');
      const bText = transformer((b != null) ? selector(b) : '');
      return (aText < bText) ? -1 : ((aText > bText) ? 1 : 0);
    };
  }

  private static compareStringsFuncFactoryBuildStringTransformer(opts: { ignoreCase?: boolean } = {}) {
    function ensureNonNull(x: string) { return (x == null) ? '' : x; }
    function ignoreCaseTrans(x: string) { return x.toLowerCase(); }

    return (opts.ignoreCase)
    ? (x: string) => ignoreCaseTrans(ensureNonNull(x))
      : ensureNonNull;
  }


}

