import { Component, HostListener, OnInit } from '@angular/core';
import { FinancialPlanningService } from './services/financial-planning.service';
import { environment } from 'src/environments/environment';
import { first, map, tap } from 'rxjs/operators';
import { CSV_FORMAT, FileDownloadService, NotificationsService, covertArrayToFlatFile } from '@tcc/ui';
import { FileParameter } from 'src/app/client-api.service';
import { MaintenanceService } from '../services/maintenance.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-financial-planning-installer',
  templateUrl: './financial-planning-installer.component.html',
  styleUrls: ['./financial-planning-installer.component.scss']
})
export class FinancialPlanningInstallerComponent implements OnInit {

  env = environment;
  blob: any;
  commandArgs: string[] = [];
  installerStatus = '';  
  pollingInstaller = false;
  pollInstaller: any; 

  orgCodeCheckboxes: OrgCodesSelected[] = [];
  selectedOrgCode = '';
  selectedParamValue = '';
  showOrgs = false;
  showFileSelector = false;
  state: 'initializing' | 'loading' | 'ready' | 'saving';
  file: any;
  payrollAllowances: PayrollAllowances[] = [];
  userActivity: any;
  userInActive: Subject<any> = new Subject();


  constructor(private fpService: FinancialPlanningService,
    private maintenanceService: MaintenanceService,
    private fileDownloadSvc: FileDownloadService,
    private notifySvc: NotificationsService) {
    this.state = 'initializing';
  }

  ngOnInit(): void {
    this.maintenanceService.setMaintenanceComponenetPanelSize('large');

    this.userInActive.subscribe(() => {
      this.pollingInstaller = false;
      clearInterval(this.pollInstaller);
    });
    this.getFPOrganizations();
    this.checkInstallerStatus();
  }

  fileSelected(event: any) {
    this.file = event.target.files[0];
  }

  installerParamsDropdownClicked(event: any) {
    this.selectedOrgCode = '';

    (event.target.value === '-fab' || event.target.value === '-r') ? this.showOrgs = true :  this.showOrgs = false;    
    (event.target.value === '-pa' || event.target.value === '-pw') ? this.showFileSelector = true : this.showFileSelector = false;

    this.setCommand();
  }

  getFPOrganizations() {
    this.state = 'loading';
    this.fpService.getFPOrganizations().subscribe(
      res => {
        res.forEach(o => {
          const orgCodeCheckBox = new OrgCodesSelected();
          orgCodeCheckBox.orgCode = o.orgCode;
          this.orgCodeCheckboxes?.push(orgCodeCheckBox);
        });
        this.state = 'ready';
      }, err => {
        this.state = 'ready';
        alert('Error getting FP Organizations!');
      });
  }

  setCommand() {
    this.commandArgs = [];
    this.commandArgs.push(this.selectedParamValue);

    if (this.selectedParamValue === '-fab' || this.selectedParamValue === '-r') {
      console.log(this.selectedOrgCode === '');

      if (this.selectedOrgCode === '-1') {
      }
      else if (this.selectedOrgCode === '') {
      }
      else {
        this.commandArgs.push('-org');
        this.commandArgs.push(this.selectedOrgCode);
      }
    }
  }

  @HostListener('window:mousemove') refreshUserStage() {
    // clears the termination timeout b/c the mouse is moving
    clearTimeout(this.userActivity);

    if (!this.pollingInstaller) { // if the polling interval is not started - then start it
      this.checkInstallerStatus();
      this.pollInstaller = setInterval(() => {  // this will continue
        console.log('polling status');
        this.checkInstallerStatus();
      }, 5000);

      this.pollingInstaller = true;
    }
    // rest the inactive timout
    this.setNoActivityDuration();
  }

  setNoActivityDuration() {
    // this set the termination timer - duration for when to stop the backgroun polling in  milliseconds
    // this gets cleared when the mouse is moving
    // but if the mouse stops - this will clear the polling interval routing
    this.userActivity = setTimeout(() => this.userInActive.next(undefined), 10000);
  }

  checkInstallerStatus() {
    console.log('checkInstallerStatus');

    this.fpService.fpInstallerStatus().subscribe(
      res => {        
        console.log('installer status : ' + res);
        this.installerStatus = res;
      }, err => {        
        alert('Error change installer status!');
      });
  }

  runInstallerforPayRollReport() {
    if (confirm('Are yo sure you want to download the Payroll Report?')) {
      this.state = 'loading';
      this.fpService.getPayrollData()
          .pipe(
            first(),
            map(r => covertArrayToFlatFile(r, CSV_FORMAT)),
            tap(r => {
              const todayDate = new Date().toISOString().slice(0, 10);
              this.fileDownloadSvc.startRawDownload('FinancialPlanning_PayrollReport_' + todayDate + '.csv', r, 'text/csv');
            })
          ).subscribe(
            () => {
              this.notifySvc.addSuccess('Export successful. Please check your downloads folder. If download has not begun, please disable any file blockers and try again');
              this.state = 'ready';
            },
            () => {
              this.notifySvc.addError('Export failed.  Exporter could be running already by someone else! Please try again later');
              this.state = 'ready';
            }
          );
    }
  }

  runInstallerforPayRollImport(param: string) {
    //
    // NEED A WAY TO ENSURE THE FILE IS IS THE CORRECT FORMAT FOR THE OPTION SELECTED
    //
    if(!this.file) {
      alert('Error, not file selected to import!');
      return;
    }
       
    if(param === '-pa'){ // parse file for Allowances    
      const fileParameter: FileParameter = { data: this.file, fileName: this.file.name };
      this.fpService.postPayrollAllowanceFile(fileParameter)
                    .subscribe(
                      res => {
                        alert(res);
                      },
                      err => {
                        alert(err);
                      }
                    );
  }
    else if(param === '-pw') {
      const fileParameter: FileParameter = { data: this.file, fileName: this.file.name };
      this.fpService.postPayrollWagesFile(fileParameter)
                    .subscribe(
                      res => {
                        alert(res);
                      },
                      err => {
                        alert(err);
                      }
                    );
    }
    else {
      alert('Error, ' + param + 'command does not accect a file and upput')
    }
  }

  runInstaller() {

    if (this.selectedParamValue === '-pr') {
      this.runInstallerforPayRollReport();
      return;
    }

    if(this.selectedParamValue === '-pa' || this.selectedParamValue === '-pw') {
      this.runInstallerforPayRollImport(this.selectedParamValue);
      return;
    }

    this.commandArgs = [];
    const orgCodes: string[] = [];

    this.commandArgs.push(this.selectedParamValue);

    if (this.selectedParamValue === '-fab' || this.selectedParamValue === '-r') {
      if (this.selectedOrgCode !== '-1') {
        this.commandArgs.push('-org');
        this.commandArgs.push(this.selectedOrgCode);
      }
    }
   
    this.fpService.fpInstaller(this.commandArgs).subscribe(
      res => {
        console.log(res);
      }, err => {        
        alert('The server returned an error for this installer process ' + this.commandArgs + ' IT IS HIGHLY LIKELY DUE TO AN EXCEEDED TIMEOUT AND THE PROCESS IS STILL RUNNING. You should not be able to execute another installer until this is complete.  Appdev is Working on a better way to handle this.');
      });

  }

  showRunButton() {
    if(this.selectedParamValue === '-0') {
      return false;
    }

    if (this.selectedParamValue !== '') {
      if (this.selectedParamValue === '-fab' || this.selectedParamValue === '-r') {
        if (this.selectedOrgCode !== '') {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    }
    else {
      return false;
    }
  }
}

class OrgCodesSelected {
  orgCode: string | undefined;
  //checked: boolean | undefined;
}

class PayrollAllowances {
    eeCode: string | undefined;
    eeName: string | undefined;
    eeStatus: string | undefined;
    departmentCode2: string | undefined;
    departmentDesc: string | undefined;
    roleCode: string | undefined;
    roledesc: string | undefined;
    earningCode: string | undefined;
    earningDesc: string | undefined;
    amount: string | undefined;
}

class PayrollWagesAndSalaries {
  employeeCode: string | undefined;
  employeeName: string | undefined;
  payType: string | undefined;
  rate1: string | undefined;
  hireDate: Date | undefined;
  orgCode: string | undefined;
  roleDesc: string | undefined;
  positionTitle: string | undefined;
  dolStatus: string | undefined;
  employeeStatus: string | undefined;
}