<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>
    <ng-container>{{(org$ | async)?.name}}</ng-container> Revenue
  </div>
  <div panel-subheader  *ngIf="(state$ | async) != 'loading'">
    <div class="d-flex form-inline">
      <div class="flex-grow-1 form-inline" *ngIf="orgArea">

        <div class="mr-2 btn-group" *ngIf="!(isReadOnly$ | async)">
          <button type="button" class="btn btn-sm btn-secondary"
                  title="Update Unit Types from Targets"
                  (click)="updateNewLeasesFromOccupancyTarget()">
            <i class="fas fa-bullseye"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Set Occupancy % from Target</span>
          </button>
          <button type="button" class="btn btn-sm btn-secondary"
                  title="Update Unit Types from Targets"
                  (click)="updateRenewalLeasesFromRenewalTarget()">
            <i class="fas fa-bullseye"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Set Renewal % from Target</span>
          </button>
          <button type="button" class="btn btn-sm btn-secondary"
                  title="Update Unit Types from Targets"
                  (click)="updateNewRates()">
            <i class="fas fa-hand-holding-usd"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Set New Rate from Rate Growth</span>
          </button>
        </div>

        <div class="btn-group btn-group-sm btn-light mr-1" ngbDropdown title="Leave/View Comments">
          <button class="btn" (click)="showComments(commentAccount)" ngbDropdownToggle>
            <i class="fas fa-comment"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Comments</span>
          </button>
          <div ngbDropdownMenu style="z-index:10000">
            <button type="button" ngbDropdownItem (click)="showComments(specialAccounts.gpr)">GPR</button>
            <button type="button" ngbDropdownItem (click)="showComments(specialAccounts.vacancyLoss)">Vacancy Loss</button>
          </div>
        </div>

        <button class="btn btn-sm btn-light mr-1" title="View Fp Rate Summary" type="button" routerLink="./rate-summary">
          <i class="far fa-money-bill-alt"></i>
          <span class="pl-2 d-none d-lg-inline d-xl-inline">Rate Summary</span>
        </button>

        <button class="btn btn-sm" title="Update Financial Plan" type="button" (click)="updateFp()"
                [ngClass]="(fpOutOfSync$ | async) ? 'btn-danger' : 'btn-light'"
                [disabled]="!(fpOutOfSync$ | async) || (isReadOnly$ | async)">
          <i class="fas fa-bolt"></i>
          <span class="pl-2 d-none d-lg-inline d-xl-inline">Update Fp</span>
        </button>
      </div>

      <div class="flex-shrink-1">
        <div class="btn-group">
          <button [routerLink]="['../..', 'rollup']" class="btn py-0 btn-light" title="Rollup">
            <i class="fas fa-dollar-sign"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Rollup</span>
          </button>
          <button [routerLink]="['../..', (org$ | async)?.orgId,'renewals']" class="btn py-0 btn-light"
                  [title]="'Renewals Planning for ' + (org$ | async)?.name">
            <i class="fas fas fa-funnel-dollar"></i>
            <span class="pl-2 d-none d-lg-inline d-xl-inline">Renewal Planning</span>
          </button>
        </div>
      </div>
    </div>
    <div style="margin: 6px -6px; margin-bottom: -14px" [hidden]="(trayRequest$ | async)?.trayId == 'none'">
      <comment-management *ngIf="(trayRequest$ | async)?.trayId == 'CommentManagement'"></comment-management>
    </div>
  </div>
  <div panel-body class="d-flex flex-column" style="margin: -15px">
    <div class="p-3 flex-shrink-1" style="overflow-y: auto">
      <div class="d-flex rounded form-inline flex-row bg-light py-1 mb-3 justify-content-center" *ngIf="orgArea">
        <div class="form-group pr-5" [ngClass]="getTargetEstimateClass(estimateTypeEnumRef.TargetRenewalPct)">
          <label class="mr-1" tccLabelForNext>Renewal Target</label>
          <input class="form-control form-control-sm text-right" style="width: 4rem" [readonly]="isReadOnly$ | async"
                 [ngModel]="orgArea.periods[tgtPeriodIndex].tgtRenewalPct | percent: '0.0-2'"
                 (ngModelChange)="updateEstimatePct(estimateTypeEnumRef.TargetRenewalPct, $event)"
                 (blur)="updateEstimateForce(estimateTypeEnumRef.TargetRenewalPct)" />
          <span class="ml-2">{{cyLabel}}: {{orgArea.summary?.estOccRenewalPct | percent: '0.0-1'}}</span>
        </div>
        <div class="form-group pr-5" [ngClass]="getTargetEstimateClass(estimateTypeEnumRef.TargetOccupancyPct)">
          <label class="mr-1" tccLabelForNext>Occupancy Target</label>
          <input class="form-control form-control-sm text-right" style="width: 4rem" [readonly]="isReadOnly$ | async"
                 [ngModel]="orgArea.periods[tgtPeriodIndex].tgtOccPct | percent: '0.0-2'"
                 (ngModelChange)="updateEstimatePct(estimateTypeEnumRef.TargetOccupancyPct, $event)"
                 (blur)="updateEstimateForce(estimateTypeEnumRef.TargetOccupancyPct)" />
          <span class="ml-2">{{cyLabel}}: {{orgArea.summary?.estOccPct | percent: '0.0-1'}}</span>
        </div>
        <div class=" form-group pr-5" [ngClass]="getTargetEstimateClass(estimateTypeEnumRef.TargetRateIncreasePct)">
          <label class="mr-1" tccLabelForNext>Target Rate Growth</label>
          <input class="form-control form-control-sm text-right" style="width: 4rem" [readonly]="isReadOnly$ | async"
                 [ngModel]="orgArea.periods[tgtPeriodIndex].tgtRateIncreasePct | percent: '0.0-2'"
                 (ngModelChange)="updateEstimatePct(estimateTypeEnumRef.TargetRateIncreasePct, $event)"
                 (blur)="updateEstimateForce(estimateTypeEnumRef.TargetRateIncreasePct)" />
          <span class="ml-2">{{cyLabel}}: {{orgArea.summary?.estRateGrowth | percent: '0.0-1'}}</span>
        </div>
        <div class="form-group" >
          <span class="ml-2">{{cyLabel}} Revenue Growth: {{orgArea.summary?.estRevenueGrowth | percent: '0.0-1'}}</span>
        </div>
      </div>
      <div class="d-flex flex-row" *ngIf="(state$ | async) != 'loading'">
        <app-amenity-averages class="flex-shrink-0 mr-2"></app-amenity-averages>
        <!-- the min width is to help with the scrolling in the child component -->
        <app-unit-types class=" flex-grow-1 flex-shrink-1" style="min-width:0"></app-unit-types>
      </div>
      <div class="mt-2 flex-grow-1" *ngIf="(state$ | async) != 'loading'">
        <app-total></app-total>
      </div>
    </div>
    <tcc-loadingOverlay *ngIf="(state$ | async) != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
