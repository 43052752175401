import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubsManager } from '@tcc/ui';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { PayPeriodType } from '../client-api.service';
import { PayrollEmployeeModel } from './payroll-employee';
import { PayrollStateService } from './payroll-state.service';
import { PayrollService } from './payroll.service';

@Component({
  selector: 'payroll-employee,[payrollEmployee]',
  templateUrl: 'payroll-employee-control.component.html',
  styles: [`
input, select {
  border: 1px solid #ccc;
  line-height: 1em;
}

.payrollInput {
  text-align: right;
  width: 3.5em;
}
:host.rowError td {
  background-color: rgba(255, 0, 0, .1);
}
 `]
})
export class PayrollEmployeeControlComponent implements OnDestroy, OnInit {

  /** only valid pay periods for base pay */
  basePayPeriods = [{ name: 'Hourly', val: PayPeriodType.Hourly }, { name: 'Annual', val: PayPeriodType.Annual }];
  readonly debounceTime = 1000;

  get canUpdateCurrentCompensation(): boolean | undefined {
    return (this.model && !this.model.isCurrentValuesLocked);
  }

  get displayMode() {
    if (!this.model) { return 'NoModel'; }
    if (this.readOnly || !this.employeeForm) { return 'ReadOnly'; }
    return 'Edit';
  }
  /** is the control readonly */
  @Input()
  readOnly?: boolean;

  @Input()
  set employee(value: PayrollEmployeeModel) {
    if (value !== this.model) {
      this.model = value;
      this.setFormFromModel();
    }
  }
  @Output()
  employeeChange = new EventEmitter<PayrollEmployeeModel>();

  model?: PayrollEmployeeModel;

  statusOptions: { value: boolean; text: string }[] = [
    { value: false, text: 'PT' },
    { value: true, text: 'FT' },
  ];

  /** The current view being displayed */
  @Input()
  subView?: string;

  @HostBinding('class.rowError')
  get rowError() {
    return (this.employeeForm && this.employeeForm.invalid);
  }
  employeeForm?: FormGroup;

  /** form controls by name */
  private formControls: {
    name: AbstractControl;
    position: AbstractControl;
    isFullTime: AbstractControl;
    basePayPeriodType: AbstractControl;
    basePayItemCurrentAmount: AbstractControl;
    basePayItemProposedAmount: AbstractControl;
    hourAllocations: AbstractControl[];
    currentAmounts: { [payTypeId: number]: AbstractControl; };
    proposedAmounts: { [payTypeId: number]: AbstractControl; };
  } | undefined;
  /** subscriptions that should be usubscribed when the component is destroyed */
  private subsManager = new SubsManager();
  // Never being used
  // private validatorOnChangeCallback: () => void;

  constructor(private fb: FormBuilder, private payrollSvc: PayrollService, public payrollState: PayrollStateService) {

  }


  ngOnInit() {
    this.subsManager.addSub = this.payrollState.readyChangeSubject.pipe(
      filter(x => x),
      take(1),
      tap(() => {
        this.formCreate();
        this.watchFormChanges();
      })
    ).subscribe();

    this.subsManager.addSub = this.payrollState.employeeChanges$.pipe(
      filter(x => this.model?.trackingId === x.trackingId),
      tap((x) => {
        this.model = x;
        this.setFormFromModel();
        this.employeeChange.emit(this.model);
      })
    ).subscribe();

    this.subsManager.addSub = this.payrollState.canModifyEmployees$.pipe(
      tap((canModify) => this.setFormControlEnabled(this.formControls!.name, canModify))
    ).subscribe();
  }


  ngOnDestroy() {
    this.subsManager.onDestroy();
  }



  /** Get the text for a status option of a given value */
  getStatusOptionText(value: boolean) {
    return ((this.statusOptions || []).find(so => so.value === value) || { text: 'Invalid' }).text;
  }

  /** Gets  PayPeriodType string representation from a payPerios */
  getPayPeriodText(payPeriod: PayPeriodType) {
    return PayPeriodType[payPeriod];
  }

  private formCreate() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const groupInfo: Record<string, any> = {
      name: [''], // name is hidden now. , Validators.required]
      position: ['', Validators.required],
      isFullTime: [false, Validators.required],
      basePayItem_payPeriodType: [PayPeriodType.Annual, Validators.required],
      basePayItem_current_amount: [0, [Validators.required, Validators.min(0)]],
      basePayItem_proposed_amount: [0, [Validators.required, Validators.min(0)]],
    };

    for (const pt of this.payrollState.otherPayTypes!) {
      groupInfo[`otherPayItems_${pt.payTypeId}_current_amount`] = [0, [Validators.required, Validators.min(0)]];
      groupInfo[`otherPayItems_${pt.payTypeId}_proposed_amount`] = [0, [Validators.required, Validators.min(0)]];
    }

    for (let i = 0, il = this.payrollState.months!.length; i < il; i++) {
      groupInfo[`hourAllocations_${i}_hours`] = [0, [Validators.required, Validators.min(0), Validators.max(99)]];
    }

    const formGroup = this.fb.group(groupInfo);
    this.employeeForm = formGroup;

    this.formControls = {
      name: formGroup.get('name')!,
      position: formGroup.get('position')!,
      isFullTime: formGroup.get('isFullTime')!,
      basePayPeriodType: formGroup.get('basePayItem_payPeriodType')!,
      basePayItemCurrentAmount: formGroup.get('basePayItem_current_amount')!,
      basePayItemProposedAmount: formGroup.get('basePayItem_proposed_amount')!,
      hourAllocations: this.payrollState.months!.map((x, i) => formGroup.get(`hourAllocations_${i}_hours`)!),
      currentAmounts: {},
      proposedAmounts: {}
    };
    for (const pt of this.payrollState.otherPayTypes!) {
      this.formControls.currentAmounts[pt.payTypeId] = formGroup.get(`otherPayItems_${pt.payTypeId}_current_amount`)!;
      this.formControls.proposedAmounts[pt.payTypeId] = formGroup.get(`otherPayItems_${pt.payTypeId}_proposed_amount`)!;
    }
    this.setFormFromModel();
  }

  private watchFormChanges() {

    this.subsManager.addSub = this.employeeForm!.valueChanges.pipe(
      filter(() => this.employeeForm!.dirty),
      debounceTime(this.debounceTime),
      tap(() => this.handleFormChanges())
    ).subscribe();
  }

  /**
   * Handles changes if the form is valid.
   * Errors are handled by the PayrollState
   */
  private handleFormChanges() {
    if (this.employeeForm?.valid) {
      this.payrollState.employeeUpdate(this.createModelFromForm());
    }
  }

  /**
   * Sets the employee Form from the model
   */
  private setFormFromModel() {
    if (!this.employeeForm) {
      return;
    }

    this.employeeForm.reset();
    if (this.model) {
      const basePayAmounts = this.model.payItemAmounts[this.payrollState.basePayType!.payTypeId];

      this.formControls?.name.setValue(this.model.name);
      this.formControls?.position.setValue(this.model.position);
      this.formControls?.isFullTime.setValue(this.model.isFullTime);
      this.formControls?.basePayPeriodType.setValue(basePayAmounts.payPeriodType);
      this.formControls?.basePayItemCurrentAmount.setValue(basePayAmounts.currentAmount);
      this.formControls?.basePayItemProposedAmount.setValue(basePayAmounts.proposedAmount);

      for (const pt of this.payrollState.otherPayTypes!) {
        const payPair = this.model.payItemAmounts[pt.payTypeId];
        this.formControls?.currentAmounts[pt.payTypeId].setValue((payPair) ? payPair.currentAmount || 0 : 0);
        this.formControls?.proposedAmounts[pt.payTypeId].setValue((payPair) ? payPair.proposedAmount || 0 : 0);
      }

      for (let i = 0, il = this.payrollState.months!.length; i < il; i++) {
        const value = this.model.monthlyHourAllocations[i] ? this.model.monthlyHourAllocations[i].hours || 0 : 0;
        this.formControls?.hourAllocations[i].setValue(value);
      }
    }

    this.setControlsEnabledState();
  }

  /**
   * Sets if revelant controls are enabled or disabled based upon the state of the model
   */
  private setControlsEnabledState() {
    if (!this.employeeForm) {
      return;
    }

    const payControlsEnabled = this.model != null && this.model.empId && true;
    const canUpdateCurrentPayControls = payControlsEnabled && this.canUpdateCurrentCompensation && true;
    this.setFormControlEnabled(this.formControls!.basePayItemCurrentAmount, canUpdateCurrentPayControls);
    this.setFormControlEnabled(this.formControls!.basePayItemProposedAmount, payControlsEnabled);

    for (const pt of this.payrollState.otherPayTypes!) {
      this.setFormControlEnabled(this.formControls!.currentAmounts[pt.payTypeId], canUpdateCurrentPayControls);
      this.setFormControlEnabled(this.formControls!.proposedAmounts[pt.payTypeId], payControlsEnabled);
    }

    for (let i = 0, il = this.payrollState.months!.length; i < il; i++) {
      this.setFormControlEnabled(this.formControls!.hourAllocations[i], payControlsEnabled);
    }
  }

  /**
   * Calls enable or disabled on a control with the matching name
   */
  private setFormControlEnabled(ctrl: AbstractControl, isEnabled: boolean | 0 | undefined) {
    if (ctrl && isEnabled && ctrl.disabled) {
      ctrl.enable();
    }
    else if (ctrl && !isEnabled && ctrl.enabled) {
      ctrl.disable();
    }
  }

  /**
   * Creats a new model from the data in form, using the original model as a source and then overwriting data.
   * We don't want to update the model directly because we want to detect any changes.
   */
  private createModelFromForm() {

    const updatedEmployee: PayrollEmployeeModel = {
      empId: this.model!.empId,
      budgetYear: this.model!.budgetYear,
      externalId: this.model!.externalId,
      name: this.formControls!.name.value,
      isCurrentValuesLocked: this.model!.isCurrentValuesLocked,
      isFullTime: this.formControls!.isFullTime.value,
      position: this.formControls!.position.value,
      payItemAmounts: {},
      monthlyHourAllocations: [],
      trackingId: this.model!.trackingId
    };


    const basePayAmounts = this.model!.payItemAmounts[this.payrollState.basePayType!.payTypeId];
    updatedEmployee.payItemAmounts[this.payrollState.basePayType!.payTypeId] = {
      currentPayItemId: basePayAmounts.currentPayItemId,
      proposedPayItemId: basePayAmounts.proposedPayItemId,
      payPeriodType: this.formControls!.basePayPeriodType.value,
      currentAmount: this.formControls!.basePayItemCurrentAmount.value,
      proposedAmount: this.formControls!.basePayItemProposedAmount.value
    };

    for (const pt of this.payrollState.otherPayTypes!) {
      updatedEmployee.payItemAmounts[pt.payTypeId] = {
        currentPayItemId: this.model!.payItemAmounts[pt.payTypeId].currentPayItemId,
        proposedPayItemId: this.model!.payItemAmounts[pt.payTypeId].proposedPayItemId,
        payPeriodType: this.model!.payItemAmounts[pt.payTypeId].payPeriodType,
        currentAmount: this.formControls!.currentAmounts[pt.payTypeId].value,
        proposedAmount: this.formControls!.proposedAmounts[pt.payTypeId].value
      };
    }

    for (let i = 0, il = this.payrollState.months!.length; i < il; i++) {
      updatedEmployee.monthlyHourAllocations[i] = {
        hourAllocationId: (this.model!.monthlyHourAllocations[i] || { hourAllocationId: undefined }).hourAllocationId,
        hours: this.formControls!.hourAllocations[i].value
      };
    }
    return updatedEmployee;
  }

}


