<div class="h-100 d-flex flex-column shadow-sm">
  <div class="d-flex flex-row bg-secondary text-white px-2 py-1 form-inline">
    <div class="flex-grow-1">
      <ng-content select="[menu-tray-header]">
      </ng-content>
    </div>
    <div class="flex-shrink-1">
      <ng-content select="[menu-tray-header-right]">
      </ng-content>
      <button type="button" class="btn btn-sm ml-1 btn-dark" (click)="toggleFullScreen()">
        <ng-container *ngIf="currentTraySize != 'full'; else isFull"><i class="fas fa-arrows-alt"></i> Enlarge</ng-container>
        <ng-template #isFull><i class="fas fa-compress-arrows-alt"></i> Collapse</ng-template>
      </button>
      <button type="button" class="btn btn-sm ml-1 btn-dark" (click)="startClose()"><i class="fa fa-times"></i> Close</button>
    </div>
  </div>
  <div class="flex-grow-1 bg-light rounded-bottom border border-top-0" style="overflow-y:auto; position: relative">
    <ng-content select="[menu-tray-panel]"></ng-content>
  </div>
</div>
