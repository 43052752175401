import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';

export type LinkedScrollDir = 'horiz' | 'vert';

export interface LinkedScrollTarget {
  /** direction to update (horiz or vert)*/
  dir: LinkedScrollDir;
  /** target element */
  el: unknown;
  /** invert the value so as the scroll changes positive the linked element change is negative */
  invert?: boolean;
  /** optional prop to update.  Either prop or style must be provided */
  prop?: string;
  /** optioanl css to update. Either prop or style must be provided */
  style?: string;
}

interface Coord2d {
  x: number;
  y: number;
}

/**
 * links the scroll bar of one element to other elements' css styles and properties.
 */
@Directive({
  selector: '[tccLinkedScroll]'
})
export class LinkedScrollDirective {

  @Input('tccLinkedScroll') linkedTargets?: LinkedScrollTarget[];

  private lastPos: Coord2d;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.lastPos = this.getScrollPosition(el);
  }

  @HostListener('scroll') scrolling() {
    this.updateScroll();
  }

  private updateScroll() {
    if (!this.linkedTargets || this.linkedTargets.length === 0) {
      return;
    }

    const pos = this.getScrollPosition(this.el);
    const delta = { x: pos.x - this.lastPos.x, y: pos.y - this.lastPos.y };
    const updateX = delta.x !== 0;
    const updateY = delta.y !== 0;

    for (const tgt of this.linkedTargets) {

      if (tgt.dir === 'horiz' && updateX) {
        if (tgt.prop) {
          this.setProp(tgt.el, tgt.prop, pos.x, tgt.invert);
        }
        else if (tgt.style) {
          this.setStyle(tgt, pos.x);
        }
        else {
          this.setProp(tgt.el, 'scrollLeft', pos.x);
        }

      } else if (tgt.dir === 'vert' && updateY) {
        if (tgt.prop) {
          this.setProp(tgt.el, tgt.prop, pos.y, tgt.invert);
        }
        else if (tgt.style) {
          this.setStyle(tgt, pos.y);
        }
        else {
          this.setProp(tgt.el, 'scrollTop', pos.y);
        }
      }
    }
    this.lastPos = pos;

  }

  private setProp(el: unknown, prop: string, value: number, invert?: boolean) {
    this.renderer.setProperty(el, prop, ((invert) ? value * -1 : value));
  }

  private setStyle(tgt: LinkedScrollTarget, value: number) {
    this.renderer.setStyle(tgt.el, tgt.style!, ((tgt.invert) ? value * -1 : value) + 'px');
  }

  private getScrollPosition(el: ElementRef): Coord2d {
    return {
      x: (<HTMLElement>el.nativeElement).scrollLeft,
      y: (<HTMLElement>el.nativeElement).scrollTop
    };
  }
}

