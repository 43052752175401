<form #myForm="ngForm" (ngSubmit)="saveChanges()">
    <tcc-simplePanel size="small" [options]="{isFloating: true}" [buttons]="['close']" (buttonClick)="close()">
        <div panel-header>Add User</div>
        <div panel-footer>
            <button type="submit" [disabled]="myForm.invalid || state != 'ready'" class="btn btn-primary" >
                Add User
            </button>
        </div>
        <div panel-body>
            <div class="form-group">
                <label tcc-labelForNext class="control-label">Display Name</label>
                <input type="text" class="form-control" [(ngModel)]="user.displayName" name="displayName" required
                       tccValidityClasses="is-invalid"
                       [disabled]="state != 'ready'"/>
                <div class="text-danger visible-error">Name is required</div>
            </div>
            <div class="form-group">
                <label tcc-labelForNext class="control-label">Email Address</label>
                <input type="email" class="form-control" [(ngModel)]="user.email" name="email" email
                       tccValidityClasses="is-invalid"
                       [disabled]="state != 'ready'"/>
                <div class="text-danger visible-error">Email is not a valid email address</div>
            </div>
            <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
        </div>
    </tcc-simplePanel>
</form>
