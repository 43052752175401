import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { forkJoin } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { Organization } from '../client-api.service';
import { LedgerService } from '../ledgers/ledger.service';
import { ViewInfo } from '../ledgers/view-info';
import { OrganizationService } from '../core-services/organization.service';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { tapError } from '../shared/tap-error-operator';
import { defaultCheckStyle } from '../shared/check-styles';


@Component({
  selector: 'targetedEdit',
  templateUrl: './batch-export.component.html'
})
export class BatchExportComponent implements OnInit, OnDestroy {
  private subsMgr = new SubsManager();

  /** default style for tccFaCheckStyle */
  readonly checkStyle = defaultCheckStyle;

  orgs: { isSelected: boolean, name: string, orgId: number }[] = [];

  /** returns the number of orgs where isSelected is true */
  get selectedOrgCount() {
    return this.orgs.filter(x => x.isSelected).length;
  }

  selectedView?: ViewInfo;

  state: 'loading' | 'ready' = 'ready';
  views: ViewInfo[] = [];


  constructor(
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerSvc: LedgerService,
    private orgSvc: OrganizationService,
    private notifSvc: NotificationsService
  ) { }

  ngOnInit() {

    this.state = 'loading';

    this.subsMgr.addSub = forkJoin([
      this.orgSvc.orgs$.pipe(tap(x => this.setOrgModels(x))),
      this.ledgerSvc.glViews$.pipe(tap(x => this.views = x), take(1)),
    ])
    .pipe(finalize(() => this.state = 'ready'))
    .subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  runExports() {
    this.state = 'loading';
    const viewId = this.selectedView?.viewId;
    if (viewId) {
      this.ledgerSvc.getLedgerByName(this.globalSettings.fpLedgerName).pipe(
        switchMap(ledger => forkJoin(this.orgs.filter(x => x.isSelected)
          .map(org => this.orgSvc.exportBudget(org.orgId, ledger.ledgerId, viewId))
        )),
        tap(() => {
          this.orgs.forEach(x => x.isSelected = false);
          this.notifSvc.addSuccess(`Exports have been queued succesfully`);
        }),
        tapError(err => this.notifSvc.addError(err)),
        finalize(() => this.state = 'ready')
      ).subscribe();
    }
  }

  setAllIsSelected(isSelected: boolean) {
    this.orgs.forEach(x => x.isSelected = isSelected);
  }

  // sets this.orgs retaining selections if for some reason the orgs model was updated.
  private setOrgModels(orgs: Organization[]) {
    const orgsSelectedMap = new Map((this.orgs || []).map(x => [x.orgId, x.isSelected]));
    this.orgs = orgs.map(({ name, orgId }) => ({ isSelected: orgsSelectedMap.get(orgId) || false, name, orgId }));
  }
}


