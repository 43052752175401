import { FaCheckStyleDirectiveOptions } from '../directives/fa-check-style.directive';

/** Default check style for application. */
export const defaultCheckStyle: FaCheckStyleDirectiveOptions = {
  $default: { boxClass: 'fas fa-square text-muted', tickStyle: { 'color': '#fff' } },
  '$default.disabled': { boxClass: 'fas fa-square text-light', tickStyle: { 'color': '#999' } },
  true: { boxClass: 'fas fa-square text-info', tickClass: 'fas fa-check' },
};

/** Default delete check style for application. */
export const defaultDeleteCheckStyle: FaCheckStyleDirectiveOptions = {
  $default: { boxClass: 'fa fa-square text-danger', tickClass: 'fas fa-trash', tickStyle: { 'color': '#fff' } },
  '$default.disabled': {
    boxClass: 'fas fa-square',
    boxStyle: { 'color': '#ccc' },
    tickClass: 'fas fa-trash',
    tickStyle: { 'color': '#999' }
  },
  true: { boxClass: 'fa fa-square text-success', tickClass: 'fa fa-undo' },
};
