import { Injectable } from '@angular/core';
import { OrgCalculationTransform } from './org-calculation-models';
import { CalcExpression } from '../shared/calculation-expression';

@Injectable({ providedIn: 'root' })
export class CashFlowZeroOutService {
  config?: OrgCalculationTransform[];

  constructor() {
    this.initConfig();
  }

  private initConfig() {
    this.config = [
      this.createServiceIncomeOffset('it', '441000', '541500'),
      this.createServiceIncomeOffset('accg', '442000', '541000'),
      this.createServiceIncomeOffset('pmt', '442001', '541511')
    ];
  }

  /** Creates an income offset */
  private createServiceIncomeOffset(orgCode: string, offsetAcctCode: string, regIncAcctCode: string) {
    const transform = new OrgCalculationTransform();
    transform.orgCode = orgCode;
    transform.sourceViewName = 'Full FP';
    transform.sourceCalculations = [
      {
        varName: 'INCOME',
        calcExpr: new CalcExpression(`[599800] + [132299] + [609900] + [599949] + [599999]  + [138999] + [999998] - [446000]`),
        orgCode
      }
    ];
    transform.assignmentCalculations = [
      { varName: offsetAcctCode, calcExpr: new CalcExpression(`[INCOME] * -1`), orgCode },
      { varName: regIncAcctCode, calcExpr: new CalcExpression(`[INCOME]`), orgCode: 'reginc' },
    ];
    return transform;
  }
}

