import { Inject, Injectable } from '@angular/core';
import { AppInsightsRef, NotificationsService } from '@tcc/ui';
import { BehaviorSubject, combineLatest, concat, forkJoin, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, last, map, pairwise, switchMap, take, tap, timeout } from 'rxjs/operators';
import { LedgerEntry, LedgerEntryType, LedgerSummary, Organization, UserStateInfo, WorkflowActionType, WorkflowStateHistory } from '../client-api.service';
import { CommandConstants } from '../commands/command-constants';
import { CommandsService } from '../commands/commands.service';
import { CommentsStateService } from '../comments/comments-state.service';
import { OrganizationService } from '../core-services/organization.service';
import { UserService } from '../core-services/user.service';
import { GLOBAL, IGlobalSettings } from '../shared/global-settings';
import { PermissionError } from '../shared/permission-error';
import { tapError } from '../shared/tap-error-operator';
import { AccountEntriesNode } from './account-entries-node';
import { AmountChangeCommand } from './amount-change-command';
import { LedgerTree } from './ledger-tree';
import { LedgerService } from './ledger.service';
import { ViewInfo } from './view-info';


interface SelectedAccountInfo {
  acct?: AccountEntriesNode;
  amtIdx?: number;
}
export interface SelectedAccountChangeEventArgs extends SelectedAccountInfo {
  priorAcct?: AccountEntriesNode;
  priorAmtIdx?: number;
}

@Injectable({
  providedIn: 'root',
})
export class LedgerStateService {
  private _isReadOnly = false;
  private _ledger: LedgerTree | undefined;

  private readonly entrySavesSubject = new Subject<LedgerEntry>();
  private readonly ledgerChangeSubject = new BehaviorSubject<LedgerTree | undefined>(undefined as unknown as LedgerTree);
  private readonly scrollRequestsSubject = new Subject<{ accountCode: string }>();
  private readonly selectedAccountSubject = new BehaviorSubject<SelectedAccountInfo>({ acct: undefined });

  /** codes of accounts that need a comment before submission can occur.  Is empty if there is no reason to track */
  accountCodesRequiringComment = new Map<string, boolean>();

  readonly amountCommands = this.commandsSvc.getCommand<AmountChangeCommand>(CommandConstants.AccountEntrySave);

  get currentStep() {
    return (this.workflowHistory) ? this.workflowHistory.currentStep || this.workflowHistory.nextStep : undefined;
  }

  defaultEntryType?: LedgerEntryType;

  /** observable of when ledger entries are successfully saved */
  readonly entrySaves$ = this.entrySavesSubject.asObservable();

  /** contains all the ledger information from the VIEW */
  get ledger() { return this._ledger; }

  readonly ledger$ = this.ledgerChangeSubject.asObservable();

  /** information about the financial plan ledger */
  ledgerSummary?: LedgerSummary;

  /** ledger entries ordered in how they will appear on the tree.  Do not modifiy */
  orderedAccounts: AccountEntriesNode[] = [];

  /** the current organization.  Do not modify */
  organization?: Organization;

  /** true if nothing on the ledger is modifyable. */
  get readOnly() { return this._isReadOnly; }
  set readOnly(value: boolean) {
    this._isReadOnly = value;
    this.commentsState.isReadOnly = value;
  }

  /** true if amounts in the ledger are readOnly - it is possible the comments are still editable, check readOnly */
  readOnlyAmounts?: boolean;

  readonly scrollRequests = this.scrollRequestsSubject.asObservable();


  /** selected account */
  get selectedAccount() {
    return this.selectedAccountSubject.value.acct;
  }

  /** Observable sequence of selected accounts */
  readonly selectedAccountChange$ = this.selectedAccountSubject.pipe(
    pairwise(),
    map(([prior, cur]) => ({ ...cur, priorAcct: prior.acct, priorAmtIdx: prior.amtIdx }) as SelectedAccountChangeEventArgs)
  );


  /** the selected amount index */
  get selectedAmountIndex() {
    return this.selectedAccountSubject.value.amtIdx;
  }

  /** current user - do not modifiy */
  user?: UserStateInfo;

  /** the current view - Do not modify */
  view?: ViewInfo;

  /** history of the workflow so far */
  workflowHistory?: WorkflowStateHistory;


  constructor(
    private aiSvc: AppInsightsRef,
    private commandsSvc: CommandsService,
    private commentsState: CommentsStateService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerSvc: LedgerService,
    private notifSvc: NotificationsService,
    private orgSvc: OrganizationService,
    private userSvc: UserService

  ) {


    this.amountCommands.enqueued$.pipe(
      map(batch => {
        const isRollback = (batch.batchType === 'rollback');
        return {
          batch,
          commands$: batch.commands.map(cmd => {
            console.log(batch);
            console.log(cmd);
            const newValue = (isRollback) ? cmd.oldValue : cmd.newValue;
            return this.saveAccountEntry(cmd.accountCode, cmd.amountIndex, newValue);
          })
        };
      }),
      concatMap(({ batch, commands$ }) =>
        concat(...commands$).pipe(
          timeout(60000),
          last(),
          tap(() => this.amountCommands.executed(batch)),
          tapError(err => {
            this.amountCommands.failed(batch);
            this.notifSvc.addError('Application failed saving Account Entry.  Please stop your work and refresh the browser');
            this.aiSvc.appInsights?.trackException({ exception: err });
          }),
        )
      ),
    ).subscribe();

    // Keep comments selection in sync.
    this.selectedAccountSubject.pipe(
      map(x => x.acct?.accountCode),
      distinctUntilChanged(),
      tap(acctCode => this.commentsState.selectedAccountCode = acctCode!)
    ).subscribe();

    // Update comments require comment when ledger or commentsState comments change.
    combineLatest([this.commentsState.comments$, this.ledgerChangeSubject]).pipe(
      filter(([comments, ledger]) => !!comments && !!ledger),
      tap(() => this.accountCodesRequiringCommentInit())
    ).subscribe();
  }

  /** Enqueues a change command for an account if it passes validation and is different from the old value. */
  executeAmountChange(accountCode: string, amountIndex: number, newValue: number, userInput?: string) {
    return this.executeAmountChanges(accountCode, [{ amountIndex, newValue, userInput }]);
  }

  /** Enqueues a group of changes for an account if the change passes validation and is different from the old value. */
  executeAmountChanges(accountCode: string, changes: { amountIndex: number; newValue: number; userInput?: string; }[]) {
    const account = this._ledger!.accountMap[accountCode];
    const errors = changes.filter(x => !this.validateAmount(account, x.newValue));
    if (errors.length) {
      const distinctErrorValues = [... new Set(errors.map(x => x.userInput ?? x.newValue?.toString() ?? '(empty)'))];
      const label = distinctErrorValues.length > 1 ? 'values' : 'value';
      const values = distinctErrorValues.join(', ');
      this.notifSvc.addError(`Cannot save invalid ${label} (${values}) to ${account.displayName}.`);
    }
    else {
      const commands = changes
        .map(({ newValue, amountIndex }) => ({ accountCode, newValue, amountIndex, oldValue: account.amounts[amountIndex] }))
        .filter(({ newValue, oldValue }) => newValue !== oldValue);
      this.amountCommands.enqueue(commands);
    }
  }

  initState() {
    // kill the old ledger
    if (this._ledger) {
      this._ledger.destroy();
      this._ledger = undefined;
      this.ledgerChangeSubject.next(undefined);
    }

    // reset selected items
    this.defaultEntryType = LedgerEntryType.Default;
    this.selectAccount(undefined);
    this.orderedAccounts = [];
    this.workflowHistory = undefined;
    this.amountCommands.clear();
    this.accountCodesRequiringComment.clear();
    this.view = undefined;
    this.organization = undefined;
  }


  /** Sets the state by loading a ledger based on passed parameters. */
  loadLedger(orgId: number, viewId: number, action: WorkflowActionType) {
    this.initState();

    this.readOnly = (action !== WorkflowActionType.Edit);
    let amountsFromDefaultEntriesOnly: boolean;

    return this.checkPermissions(orgId, viewId)
      .pipe(
        switchMap(() =>
          forkJoin([
            this.userSvc.currentUser$.pipe(take(1), tap(u => this.user = u)),
            this.ledgerSvc.getLedgerSummaryByName(this.globalSettings.fpLedgerName).pipe(tap(x => this.ledgerSummary = x)),
            this.orgSvc.getOrganization(orgId).pipe(tap(x => this.organization = x)),
            this.ledgerSvc.getViewById(viewId).pipe(tap(x => this.view = x)),
            this.orgSvc.getWorkflowsStates(orgId, viewId).pipe(tap(wfStates => {
              if (wfStates) {
                this.workflowHistory = wfStates[0];
                // if any step allows suggestions then any amount is allowed, otherwise only default is allowed.
                amountsFromDefaultEntriesOnly = !wfStates.some(x => (x.currentStep! || x.nextStep).entryType === LedgerEntryType.Suggestion);
                this.defaultEntryType = wfStates.some(x => (x.currentStep! || x.nextStep).entryType === LedgerEntryType.Default)
                  ? LedgerEntryType.Default
                  : LedgerEntryType.Suggestion;
              }
            }))
          ])
        ),
        switchMap(() => this.orgSvc.getLedger(orgId, this.ledgerSummary!.ledgerId, { viewLedgerId: this.view!.ledger!.ledgerId })),
        tap(ledger => {
          this.readOnlyAmounts = this.readOnly || !this.currentStep || this.currentStep.amountsReadOnly;
          this._ledger = new LedgerTree(ledger, this.globalSettings.budgetYear, amountsFromDefaultEntriesOnly, false);
          this.updateOrderedAccounts();
          this.ledgerChangeSubject.next(this._ledger);
        })
      );
  }

  /**
   * Returns true if editor is in config and orgCode matches allowedOrgs (or allwedOrgs is blank)
   * @param editorId the id of the editor
   */
  isEditorOk(editorId: string) {
    const org = this.organization;
    const view = this.view;
    return org && view && view.ledger!.configuration && view.ledger!.configuration.additionalEditors
      && view.ledger!.configuration.additionalEditors
        .some(ec => ec.id === editorId && (!ec.allowedOrgsRegex || new RegExp(ec.allowedOrgsRegex, 'i').test(org.orgCode!)));
  }

  /** Selects a new account, optionally unselecting a specific index.  Passing undefined unselects the account. */
  selectAccount(account: AccountEntriesNode | undefined, amountIndex?: number) {
    const currentSelectionState = this.selectedAccountSubject.value;
    if (currentSelectionState.acct !== account || currentSelectionState.amtIdx !== amountIndex) {
      this.selectedAccountSubject.next({ acct: account, amtIdx: amountIndex });
    }
  }

  /** Sets accountCodesRequiringCommment */
  private accountCodesRequiringCommentInit() {
    this.accountCodesRequiringComment.clear();
    if (this.currentStep?.requireCommentOnChange && this.workflowHistory?.currentState) {
      this._ledger?.accountHistoryMap.forEach((_, accountCode) => this.accountCodesRequiringCommentUpdateCommon(accountCode));
    }
  }

  private accountCodesRequiringCommentUpdate(accountCode: string, assumeValueUpdated?: boolean) {
    if (this.currentStep?.requireCommentOnChange && this.workflowHistory?.currentState) {
      this.accountCodesRequiringCommentUpdateCommon(accountCode, assumeValueUpdated);
    }
  }

  private accountCodesRequiringCommentUpdateCommon(accountCode: string, assumeValueUpdated?: boolean) {
    const cutoffOn = this.workflowHistory!.currentState!.createdOn;
    const requiresComment =
      (assumeValueUpdated || (this._ledger!.accountHistoryMap.get(accountCode) ?? []).some(x => x.createdOn! > cutoffOn))
      && !(this.commentsState.accountMap.get(accountCode) ?? []).some(x => x.createdOn! > cutoffOn);

    this.accountCodesRequiringComment.set(accountCode, requiresComment);
  }

  /** Throws an error if user doesn't have proper permissions/ */
  private checkPermissions(orgId: number, viewId: number) {
    return this.orgSvc.getUserActions(orgId, viewId)
      .pipe(map(actions => {
        if (!this.readOnly && !actions.some(a => a.actionType === WorkflowActionType.Edit)) {
          throw new PermissionError('User does not have access to edit this document');
        } else if (this.readOnly && !actions.some(a => a.actionType === WorkflowActionType.Read
          || a.actionType === WorkflowActionType.Edit)) {
          throw new PermissionError('User does not have access to read this document');
        }

        return true;
      }));
  }

  /** Updates account amount and creates observable to update in the database */
  private saveAccountEntry(accountCode: string, amountIndex: number, newValue: number) {
    const account = this._ledger!.accountMap[accountCode];

    account.setAmount(amountIndex, newValue);

    const entry: LedgerEntry = {
      accountCode: accountCode,
      amount: newValue * account.negationFactor,
      appliedOn: this._ledger!.months![amountIndex],
      creatorId: this.user!.userId,
      entryType: this.defaultEntryType!,
      ledgerEntryId: 0
    };

    return this.orgSvc.saveLedgerEntry(this.organization!.orgId, this.ledgerSummary!.ledgerId, this.view!.viewId, entry)
      .pipe(
        tap(x => {
          let history = this._ledger?.accountHistoryMap.get(x.accountCode!);
          if (!history) {
            history = [];
            this._ledger?.accountHistoryMap.set(x.accountCode!, history);
          }
          history.push(x);
          this.accountCodesRequiringCommentUpdate(x.accountCode!, true);
          this.entrySavesSubject.next(x); // why?
        })
      );
  }

  /** Adds ledger entries in the order in how they would appear on the tree. */
  private updateOrderedAccounts() {
    this.orderedAccounts.length = 0;
    this._ledger?.children.filter(x => !x.isHidden).forEach(x => addAccountToOrderedAccounts(x, this.orderedAccounts));

    /** Adds an account to orderedAccounts, by first adding descendants and then itself. */
    function addAccountToOrderedAccounts(account: AccountEntriesNode, orderedAccounts: AccountEntriesNode[]) {
      account.children.forEach(x => addAccountToOrderedAccounts(x, orderedAccounts));
      orderedAccounts.push(account);
    }
  }

  /** Ensures that an value is okay for an account. */
  private validateAmount(account: AccountEntriesNode, value: number) {
    return (value != null && !isNaN(value) && (!account.validator || account.validator(value)));
  }

}
