import { Injectable, Inject } from '@angular/core';
import { ComparisonType } from './comparison-type';
import { ComparisonAccountEntriesMap, ComparisonEntryAmounts, ComparisonSource } from './comparison-source';
import { AccountEntriesNode } from '../account-entries-node';
import { GLOBAL, IGlobalSettings } from '../../shared/global-settings';
import { Subject, Observable, of, merge, BehaviorSubject, combineLatest } from 'rxjs';
import { LedgerStateService } from '../ledger-state.service';
import { tap, switchMap, debounceTime, bufferTime, filter, map, mapTo, shareReplay, startWith, distinctUntilChanged } from 'rxjs/operators';
import { ComparisonsService } from './comparisons.service';
import { LedgerTree } from '../ledger-tree';
import { FormattedAmounts } from './models';
import { AccountComparerService } from './account-comparer.service';


/// Add Renewal tagging.

@Injectable({
  providedIn: 'root'
})
export class ComparisonStateService {
  private readonly currentSourceSubject = new BehaviorSubject<ComparisonSource | undefined>(undefined);
  private readonly comparisonDataUpdateSubject = new Subject<ComparisonAccountEntriesMap | undefined>();
  private readonly currentTypeSubject = new BehaviorSubject<ComparisonType | undefined>(undefined);
  private readonly formattedResultsUpdateSubject = new Subject<Map<string, FormattedAmounts>>();
  private readonly isHighlightingDifferencesChangeSubject = new BehaviorSubject<boolean>(false);
  private readonly isHighlightingComparisonPeriodUpdatesSubject = new BehaviorSubject<boolean>(false);
  private readonly isSuggestionsVisibleSubject = new BehaviorSubject<boolean>(true);
  private readonly ledgerChange$ = this.ledgerStateSvc.ledger$.pipe(startWith(undefined as unknown as LedgerTree));

  /* merge of all observables */
  anyComparisonUpdate$ = merge(
    this.currentSourceSubject,
    this.comparisonDataUpdateSubject,
    this.currentTypeSubject,
    this.formattedResultsUpdateSubject,
    this.isHighlightingComparisonPeriodUpdatesSubject,
    this.isHighlightingDifferencesChangeSubject,
    this.isSuggestionsVisibleSubject
  ).pipe(
    debounceTime(10),
    mapTo(this),
    shareReplay(1)
  );

  /** account values being compared */
  comparisonData?: ComparisonAccountEntriesMap;

  /** sources for comparisons based on the current ledgerState */
  comparisonSources$: Observable<ComparisonSource[] | undefined> = this.ledgerChange$.pipe(
    switchMap(() => (!this.ledgerStateSvc.ledger)
      ? of([] as ComparisonSource[])
      : this.comparisonsSvc.getLedgerComparisons(this.ledgerStateSvc.organization!.orgId, this.ledgerStateSvc.ledgerSummary!.ledgerId,
        this.ledgerStateSvc.view!.viewId, true)
    ),
    shareReplay(1)
  );

  /** valid comparison types that can be chosen */
  readonly comparisonTypes: ComparisonType[] = [
    new ComparisonType('none', 'None', () => 'None', () => 0, () => ''),
    new ComparisonType('direct', 'Side By Side', ComparisonType.nameToHeader, (_, c) => c, ComparisonType.formatFlex),
    new ComparisonType('var', 'Variance', () => 'Variance', (e, c) => e - c, ComparisonType.formatFlex),
    new ComparisonType('var%', 'Variance %', () => 'Var. %',
      (e, c) => (c !== 0) ? ((e - c) / c) : Number.POSITIVE_INFINITY, ComparisonType.formatPercent)
  ];

  /** returns the header to be used for comparison columns for the current type and source */
  get currentHeader() {
    return (this.currentType && this.currentSource)
      ? this.currentType.headerFunc(this.currentSource.name)
      : 'None';
  }

  /** current comparisonSource */
  get currentSource() { return this.currentSourceSubject.value!; }
  set currentSource(value: ComparisonSource) {
    if (value !== this.currentSourceSubject.value) {
      this.currentSourceSubject.next(value);
    }
  }

  readonly currentSource$ = this.currentSourceSubject.asObservable();

  /** the current comparison */
  get currentType() { return this.currentTypeSubject.value!; }
  set currentType(value: ComparisonType) {
    if (value !== this.currentTypeSubject.value) {
      this.currentTypeSubject.next(value);
    }
  }

  readonly currentType$ = this.currentTypeSubject.asObservable();

  /** the results of the comparisons, formatted */
  formattedResults?: Map<string, FormattedAmounts>;

  /** Returns true if there is a current source and it's type is not none */
  get hasSource() {
    return this.currentSource && this.currentSource.sourceType !== 'none';
  }

  /** Returns true if there is a currently active comparison. */
  get isComparing() {
    return this.hasSource && this.currentType && this.currentType.id !== 'none' && this.comparisonData && true;
  }

  readonly isComparing$ = combineLatest([this.currentSourceSubject, this.currentTypeSubject, this.comparisonDataUpdateSubject]).pipe(
    map(() => this.isComparing),
    distinctUntilChanged(),
    shareReplay(1)
  );

  /** should comparison differences be highlighted in the ui */
  get isHighlightingDifferences() { return this.isHighlightingDifferencesChangeSubject.value; }
  set isHighlightingDifferences(value: boolean) {
    if (value !== this.isHighlightingDifferencesChangeSubject.value) {
      this.isHighlightingDifferencesChangeSubject.next(value);
    }
  }
  readonly isHighlightingDifferencesChanges = this.isHighlightingDifferencesChangeSubject.asObservable();

  /** should changes in period be highlighed */
  get isHighlightingComparisonPeriodUpdates() { return this.isHighlightingComparisonPeriodUpdatesSubject.value; }
  set isHighlightingComparisonPeriodUpdates(value: boolean) {
    if (value !== this.isHighlightingComparisonPeriodUpdatesSubject.value) {
      this.isHighlightingComparisonPeriodUpdatesSubject.next(value);
    }
  }
  readonly isHighlightingComparisonPeriodUpdatesChanges = this.isHighlightingComparisonPeriodUpdatesSubject.asObservable();

  /** should changes in period be highlighed */
  get isSuggestionsVisible() { return this.isSuggestionsVisibleSubject.value; }
  set isSuggestionsVisible(value: boolean) {
    if (value !== this.isSuggestionsVisibleSubject.value) {
      this.isSuggestionsVisibleSubject.next(value);
    }
  }
  readonly isSuggestionsVisible$ = this.isSuggestionsVisibleSubject.asObservable();

  constructor(
    private accountComparer: AccountComparerService,
    private comparisonsSvc: ComparisonsService,
    @Inject(GLOBAL) private globalSettings: IGlobalSettings,
    private ledgerStateSvc: LedgerStateService) {


    this.ledgerChange$.pipe(
      tap(() => this.initState()),
      switchMap(() => this.watchAccountChanges())
    ).subscribe();

    // Updates the currentSource when the comparison sources collection changes.
    this.comparisonSources$.pipe(
      tap((x) => this.currentSourceSubject.next(x ? x[0] : undefined)))
    .subscribe();

    // when something requires the data to be refreshe changes, this will update the date and recalculate all comparisons.
    merge(this.currentSourceSubject, this.ledgerChange$, this.isSuggestionsVisibleSubject).pipe(
      debounceTime(10),
      switchMap(() => this.updateComparisonData()),
      tap(() => this.calculateAllComparisons())
    ).subscribe();

    // calculates comparies when the type changes.
    this.currentTypeSubject.pipe(tap(() => this.calculateAllComparisons())).subscribe();
  }

  /** Uses the account comparer to get account comparison results based on settings in the comparison state. */
  getAccountComparisonResult(account: AccountEntriesNode) {
    const comparisonAmounts = (this.comparisonData || {})[account.accountCode!];
    const comparisonFormattedResults = this.formattedResults!.get(account.accountCode!);
    return this.accountComparer.getComparisonResults(account, comparisonAmounts, comparisonFormattedResults,
      this.isHighlightingComparisonPeriodUpdates, this.isHighlightingDifferences);
  }

  /** call calculateComparisons for all accounts. */
  private calculateAllComparisons() {
    if (this.isComparing && this.ledgerStateSvc.ledger) {
      const accountMap = this.ledgerStateSvc.ledger.accountMap;
      Object.keys(accountMap).forEach(acctCode => this.calculateComparison(accountMap[acctCode]));
      this.comparisonDataUpdateSubject.next(this.comparisonData);
    }
  }

  /**
   * Recalculates comparisonData if values are calculated and recaluates formattedResults.
   * Any caller should call formattedResultsUpdateSubject.next after this is complete,
   * and comparisonDateUpdateSubject.next if any entryValue are calculated.
   * @param accountCode account to calculate for
   * @param amountIndex amount index to recalculate, if undefined then all amounts are calulated
   */
  private calculateComparison(entryValues: AccountEntriesNode, amountIndex?: number) {

    const minIndex = amountIndex || 0;
    const maxIndex = amountIndex || this.globalSettings.monthLabels.length - 1;
    const compareToValues: ComparisonEntryAmounts = this.comparisonData![entryValues.accountCode!] || { amounts: [], total: 0 };

    if (entryValues.hasCalculation) {
      const calculation = entryValues.calcExpr!.compile();
      for (let i = minIndex; i <= maxIndex; i++) {
        compareToValues.amounts[i] = calculation(this.comparisonData!, i);
      }
    }

    if (!this.formattedResults?.has(entryValues.accountCode!)) {
      this.formattedResults?.set(entryValues.accountCode!, { amounts: [], total: '' });
    }
    const formattedValues = this.formattedResults?.get(entryValues.accountCode!);

    // calculate amount
    const formatExpr = entryValues.hasCalculation ? entryValues.calcExpr!.format : 'whole';
    for (let i = minIndex; i <= maxIndex; i++) {
      formattedValues!.amounts[i] = this.currentType.formatter(
        this.currentType.comparisonFunc(entryValues.amounts[i] || 0, compareToValues.amounts[i] || 0), formatExpr
      );
    }

    // calculate total
    formattedValues!.total = this.currentType.formatter(
      this.currentType.comparisonFunc(entryValues.total, compareToValues.total), formatExpr);
  }

  private initState() {
    this.formattedResults = new Map<string, { amounts: string[], total: string }>();
    this.comparisonData = {};
    this.currentType = this.comparisonTypes[0];
    this.isHighlightingComparisonPeriodUpdates = false;
    this.isHighlightingDifferences = false;
  }

  /** sets comparison information on the ledger */
  private updateComparisonData() {
    let entry$: Observable<ComparisonAccountEntriesMap | undefined>;
    if (!this.ledgerStateSvc.ledger) {
      // short circuit
      return of(undefined);
    } else if (!this.currentSource || this.currentSource.sourceType === 'none') {
      entry$ = of(undefined);
    } else {
      entry$ = this.comparisonsSvc.populateComparisonSourceData(this.currentSource, this.ledgerStateSvc.organization!.orgId,
        this.ledgerStateSvc.view!.ledger!.ledgerId).pipe(
          map(x => x.find(y => y.settings.isDefaultEntriesOnly === !this.isSuggestionsVisible)?.entriesMap)
        );
    }

    return entry$.pipe(tap(entries => {
      this.comparisonData = entries || {};
      this.comparisonDataUpdateSubject.next(this.comparisonData);
    }));
  }

  /** Starts listening to account changes, rolling them up and updating any comparisons. */
  private watchAccountChanges() {
    return this.isComparing$.pipe(
      filter(x => !!x), // only watch if account changes are occurring
      switchMap(() => merge(... this.ledgerStateSvc.orderedAccounts.map(acct =>
          acct.amountChanges.pipe(map((change) => ({ acct, change })))
        ))),
      bufferTime(50),
      filter(x => x.length > 0), // buffer time keeps calling every 50 seconds
      tap(x => {
        x.forEach(({ acct, change }) => this.calculateComparison(acct, change.amountIndex));
        this.comparisonDataUpdateSubject.next(this.comparisonData);
      })
    );
  }
}
