<form #myForm="ngForm" (ngSubmit)="saveChanges()">
  <tcc-simplePanel size="small" [options]="{isFloating: true}" [buttons]="['close']" (buttonClick)="close()">
    <div panel-header>Add Workflow</div>
    <div panel-footer>
      <button type="submit" [disabled]="myForm.invalid || state != 'ready'" class="btn btn-primary">
        Add Workflow
      </button>
    </div>
    <div panel-body>
      <div class="form-group">
        <label tcc-labelForNext class="control-label">Display Name</label>
        <input type="text" class="form-control" [(ngModel)]="workflow.name" name="name" required
               tccValidityClasses="is-invalid"
               [disabled]="state != 'ready'" />
        <div class="text-danger visible-error">Name is required</div>
      </div>
    </div>
  </tcc-simplePanel>
</form>
