<tcc-simplePanel size="full" [options]="{respondsToMainMenu: true}">
  <div panel-header>Targeted Edits</div>
  <div panel-footer>
    <button type="button" class="btn btn-primary" [disabled]="!canEditEntries" (click)="saveNewValues()">
      Save New Values
    </button>
  </div>
  <div panel-body class="px-1">
    <div class="row mb-2">
      <div class="col-auto form-group">
        <label tcc-labelForNext>Organization</label>
        <select *ngIf="organizations.length; else NoOrgs" [(ngModel)]="org" class="form-control">
          <option *ngFor="let o of organizations" [ngValue]="o">{{getOrgLabel(o)}}</option>
        </select>
        <ng-template #NoOrgs>
          <div class="form-control-plaintext text-muted">
            You do not have access to view any organizations
          </div>
        </ng-template>
      </div>
      <div class="col-auto form-group">
        <label tcc-labelForNext>Ledger</label>
        <select *ngIf="ledgers.length; else NoLedgers" [(ngModel)]="ledger" class="form-control">
          <option *ngFor="let l of ledgers" [ngValue]="l">{{l.name}}</option>
        </select>
        <ng-template #NoLedgers>
          <div class="form-control-plaintext text-muted">
            You do not have access to any ledgers
          </div>
        </ng-template>
      </div>
      <div class="col-auto  form-group">

        <ng-container *ngIf="accts.length; else NoAccounts">
          <label tcc-labelForNext>Account</label>
          <select [(ngModel)]="acct" class="form-control">
            <option *ngFor="let a of accts" [ngValue]="a" [ngClass]="{ 'text-danger': !a.isLeaf }">{{a.name}}</option>
          </select>
        </ng-container>

        <ng-template #NoAccounts>
          <label>Account</label>
          <div class="form-control-plaintext text-muted">
            Please select a ledger
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="acct && !acct.isLeaf" class="alert alert-danger text-center">
      {{acct.name}} is a branch account.
      These accounts typically do not have amounts directly because they are aggregates of their children.
      Only enter amounts if you know what you're doing.
    </div>
    <table class="table table-sm table-borderless" *ngIf="canEditEntries; else Incomplete">
      <thead class="thead-light">
        <tr *ngIf="canEditEntries">
          <th></th>
          <th *ngFor="let month of months" class="text-right">
            {{month}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-nowrap"><label class="form-control-plaintext">Current Values</label></td>
          <td *ngFor="let v of values" class="text-right">
            <span class="form-control-plaintext">{{ v.original | number:'1.2-2' }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-nowrap"><label class="py-1">New Values</label></td>
          <td *ngFor="let v of values">
            <input type="number" [(ngModel)]="v.new" tccSelectOnFocus
                   class="text-right pr-0 py-1 border-0 bg-light shadow-sm w-100" style="min-width:4rem" />
          </td>
        </tr>
      </tbody>
    </table>

    <ng-template #Incomplete>
      <div class="alert alert-info text-center">
        Please complete the form above to edit account values.
      </div>
    </ng-template>
    <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
