import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommandBatchStatusChange, CommandManager } from './command-manager';

export interface HistoryChangeEvent<TCommandInfo> extends CommandBatchStatusChange<TCommandInfo> {
  commandName: string;
}

/** Manages all commands. */
@Injectable({
  providedIn: 'root'
})
export class CommandsService {
  private readonly commands = new Map<string, CommandManager<unknown>>();
  private readonly commandEventSubject = new Subject<HistoryChangeEvent<unknown>>();

  readonly commandEvent$ = this.commandEventSubject.asObservable();

  /** returns all command entries. */
  get commandEntries(): { commandName: string, command: CommandManager<unknown> }[] {
    return Array.from(this.commands.entries()).map(([commandName, command]) => ({ commandName, command }));
  }
  /** returns true if there exists a command history with queued items */
  get hasQueuedCommands(): boolean {
    return Array.from(this.commands.values()).some(x => x.queueSize);
  }
  /** gets the count of all queued commands */
  get queuedCommandCount(): number {
    return Array.from(this.commands.values()).reduce((acc, cur) => acc + cur.queueSize, 0);
  }

  /**  gets command history by name, creating it if it doesn't already exist */
  getCommand<TCommandInfo>(commandName: string): CommandManager<TCommandInfo> {
    if (!this.commands.has(commandName)) {
      const command = new CommandManager<TCommandInfo>();
      command.batchStatusChange$.subscribe(x => this.commandEventSubject.next({ ...x, commandName }));
      this.commands.set(commandName, command as CommandManager<unknown>);
      return command;
    }
    else {
      return this.commands.get(commandName) as CommandManager<TCommandInfo>;
    }
  }

}
