
import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SorterComponentChangeEvent } from '@tcc/ui';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormatterFn, ReportCube, ReportCubeAxis } from './report-cube';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'report-cube-display',
  template: `

  <table tccSorterHost (sorterStatesChange)="sortChanged($event)" class="table small table-sm table-bordered table-striped text-nowrap">
    <thead  class="table-dark">
      <tr *ngFor="let row of colHeaders; index as $parentIndex">
        <th *ngFor="let cell of row; index as $index">
          <tcc-sorter *ngIf="$parentIndex == colHeaders!.length - 1" [sorterTag]="$index" [sorterState]="true">
            <span class="pl-1">{{cell}}</span>
          </tcc-sorter>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rowIndex of bodyOrderIndex; let odd = odd">
        <td *ngFor="let ci of rowHeaderRange" class="table-dark text-light" style="width:1%"
          [ngClass]="odd ? 'bg-collier' : 'bg-secondary'" >
            {{reportBody![rowIndex][ci]}}
        </td>
        <td *ngFor="let ci of rowBodyRange; let index = index">
            {{formatMeasure(reportBody![rowIndex][ci], index, rowIndex)}}
        </td>
      </tr>
    </tbody>
  </table>

`
})
export class ReportCubeDisplayComponent implements OnChanges {

  @Input() cube: ReportCube<unknown> | undefined;
  colHeaders: unknown[][] | undefined;
  reportBody: (string | number | Date)[][] | undefined;
  /** has index of each row in the order it should appear */
  bodyOrderIndex: number[] | undefined;
  measureFormatters: (FormatterFn | undefined)[] = [];
  measureAxis: ReportCubeAxis | undefined;
  /** col indexes that are headers on a row */
  rowHeaderRange: number[] | undefined;
  /** col indexes that are data on a row */
  rowBodyRange: number[] | undefined;
  orderByExpr: string | undefined;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cube']) {
      if (this.cube) {
        this.createCubeReport();
      } else {
        this.initReportData();
      }
    }
  }

  /** Clear all report output data */
  initReportData() {
    this.reportBody = [];
    this.bodyOrderIndex = [];
    this.colHeaders = [];
    this.measureFormatters = [];
    this.rowBodyRange = [];
    this.rowHeaderRange = [];
    this.orderByExpr = '';
    this.cd.detectChanges();
  }

  formatMeasure(data: string | number | Date, colIndex: number, rowIndex: number) {
    const formatterIndex = (this.measureAxis === ReportCubeAxis.Row)
      ? rowIndex % this.measureFormatters.length
      : colIndex % this.measureFormatters.length;

    const formatter = this.measureFormatters[formatterIndex];
    return (formatter) ? formatter(data) : data;
  }

  sortChanged(event: SorterComponentChangeEvent[]) {
    const sorted = event.find(x => x.state === 'asc' || x.state === 'desc');
    if (sorted) {
      this.updateSort(sorted.tag, sorted.state === 'desc');
    }
  }

  private createCubeReport() {
    this.initReportData();

    return timer(0)
      .pipe(tap(() => {
        this.reportBody = this.cube!.renderReport();

        const reportRowLength = (this.reportBody[0]) ? this.reportBody[0].length : 0;
        this.measureFormatters = this.cube!.getMeasureFormatters();
        this.measureAxis = this.cube!.measureAxis;
        this.colHeaders = this.reportBody.splice(0, this.cube!.headerColCount);

        for (let i = 0; i < this.cube!.headerRowCount; i++) {
          this.rowHeaderRange!.push(i);
        }
        for (let i = this.cube!.headerRowCount; i < reportRowLength; i++) {
          this.rowBodyRange!.push(i);
        }
        for (let i = 0; i < this.reportBody.length; i++) {
          this.bodyOrderIndex!.push(i);
        }
        this.updateSort();
        this.cd.detectChanges();
      }))
      .subscribe();
  }

  private updateSort(index?: number, isDescending?: boolean) {
    if (index != null) {
      let valA: unknown;
      let valB: unknown;
      if (!isDescending) {
        this.bodyOrderIndex?.sort((a, b) => {
          valA = this.reportBody![a][index];
          valB = this.reportBody![b][index];
          return (!valA < !valB) ? -1 : (!valA > !valB) ? 1 : 0;
        });
      } else {
        this.bodyOrderIndex?.sort((a, b) => {
          valA = this.reportBody![a][index];
          valB = this.reportBody![b][index];
          return (!valA < !valB) ? 1 : (!valA > !valB) ? -1 : 0;
        });
      }
    }
  }
}
