import { Directive, Output, Renderer2, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';

/**
 * Stops propagation of any event on an element.
 * Will return event to tccStopPropagation output emitter.
 **/
@Directive({
  selector: '[tccStopPropagation]'
})
export class StopPropagationDirective implements OnDestroy, OnInit {
  private propEventNames: string[] = [];

  /** can be a string, comma delimited string for multiple events, or array of strings */
  @Input('tccStopPropagation')
  set stopPropEventOrEvents(value: string | string[]) {
    const normalizedValue = (!value)
      ? []
      : (Array.isArray(value))
        ? [...value]
        : value.split(',').map(x => x.trim()).filter(x => x !== '');


    if (this.propEventNames.length !== normalizedValue.length
      || normalizedValue.some(x => !this.propEventNames.includes(x))) {
      // update only if there are actual changes
      this.propEventNames = normalizedValue;
      this.updatePropagationEvents();
    }

    this.updatePropagationEvents();
  }

  @Output('tccStopPropagation') stopPropEvent = new EventEmitter();

  /** method to unsubscribe from listener */
  unsubscribe?: () => void;

  constructor(
    private renderer: Renderer2, 
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.updatePropagationEvents();
  }

  ngOnDestroy() {
    this.unsubscribe?.();
  }

  private updatePropagationEvents() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = undefined;
    }
    if (!this.element) {
      return;
    }

    // start listening to event
    const subUnsubs = this.propEventNames.map(evtName =>
      this.renderer.listen(this.element.nativeElement, evtName, evt => {
        evt.stopPropagation();
        this.stopPropEvent.emit(evt);
      }));

    // create unsubscribe function that calls all sub unsubscribe functions.
    this.unsubscribe = () => subUnsubs.forEach(x => x());

  }

}
