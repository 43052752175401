<div *ngIf="state === 'ready'">
    <div class="row">
        <div class="col-11">
            Filter
            <input type="text" (input)="filterApplicationViews($event)" />        
        </div>
        <div class="col-1">
            <a (click)="openApplicationViewsUpsertPanel(0)" ngbTooltip="Add">
                <i class="fa fa-plus" style="color: blue"></i>
            </a>
        </div>
    </div>
    <hr/>
    <div *ngIf="state === 'ready'" class="tcc-table-div">
        <tcc-table tableClass="table-sm small" 
            [rows]="filteredApplicationViews" 
            [columns]="columns" 
            [buttons]="buttons"
            (cellClick)="rowClicked($event)">
        </tcc-table>
    </div>
</div>
<tcc-loadingOverlay *ngIf="state !== 'ready'"></tcc-loadingOverlay>