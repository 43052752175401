import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { finalize, tap } from 'rxjs/operators';
import { LedgerStateService } from '../ledgers/ledger-state.service';
import { OrganizationService } from '../core-services/organization.service';
import { tapError } from '../shared/tap-error-operator';

@Component({
  selector: 'manual-export',
  template: `
<menu-tray-wrapper trayId="ManualExport" class="sub-editor">
<div menu-tray-header>
  <strong>Export Data</strong>
</div>
<div menu-tray-panel>
  <div class="px-3 py-2">
    <div>
      By clicking proceed you will export budget data to other systems.
      It will take a few minutes to process and show in Financial Analytics.
    </div>
    <br />
    <div>
      <div class="btn btn-primary btn-sm" (click)="sendBudget()">Send Budget</div>
    </div>
  </div>
  <tcc-loadingOverlay *ngIf="state != 'ready'"></tcc-loadingOverlay>
</div>
</menu-tray-wrapper>
`
})

export class ManualExportComponent implements OnDestroy, OnInit {
  state: 'loading' | 'ready' = 'loading';

  private subsManager = new SubsManager();

  constructor(private ledgerState: LedgerStateService, private orgSvc: OrganizationService, private notifSvc: NotificationsService) {

  }

  ngOnInit() {
    this.state = 'ready';
  }
  ngOnDestroy() {
    this.subsManager.onDestroy();
  }

  sendBudget() {
    this.state = 'loading';
    this.subsManager.addSub = this.orgSvc.exportBudget(this.ledgerState.organization!.orgId,
      this.ledgerState.ledgerSummary!.ledgerId, this.ledgerState.view!.viewId)
      .pipe(
        tap(() => this.notifSvc.addSuccess('budget has been queued for export.')),
        tapError(() => this.notifSvc.addError('There was an error exporting budget.')),
        finalize(() => this.state = 'ready')
      ).subscribe();
  }

}
