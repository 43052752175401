import {
  Directive, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Renderer2
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { SubsManager } from '@tcc/ui';


// tslint:disable: member-ordering
/**
 * place on a input[type=checkbox]
 */
@Directive({ selector: '[tccFaCheckStyle]' })
export class FaCheckStyleDirective implements OnChanges, OnDestroy, OnInit {
  private static readonly defaultStyleKey = '$default';
  private currentStateName?: string;

  /** models state like invalid or disabled */
  private currentSubStateName?: 'invalid' | 'disabled';

  /** The current state of styles.  It is initialized to blank values so default state overrides it. */
  private currentState: FaCheckStyleStateClasses = {
    boxClass: '',
    boxStyle: {},
    tickClass: '',
    tickStyle: {}
  };

  private uiElem: HTMLElement;
  private boxElem: HTMLElement;
  private tickElem: HTMLElement;

  private subsMgr = new SubsManager();

  /** the options that determine the appearance of the checkbox */
  @Input('tccFaCheckStyle') options?: FaCheckStyleDirectiveOptions;

  /** This is passed the nativeElement from elementRef to check to see if the object is checked */
  valueResolver: (nativeElem: { checked?: boolean }) => boolean = (nativeElem) => (nativeElem.checked || false);

  constructor(private elemRef: ElementRef, private renderer: Renderer2, @Inject(NgModel) @Optional() private ngModel: NgModel) {
    const elem: HTMLElement = this.elemRef.nativeElement;
    elem.hidden = true;
    this.uiElem = this.renderer.createElement('span');
    this.setElemClasses(this.uiElem, `tcc-fa-check-style fa-stack ${elem.className}`, true);
    this.boxElem = this.renderer.createElement('i');
    this.renderer.appendChild(this.uiElem, this.boxElem);
    this.setElemClasses(this.boxElem, 'fa-stack-2x', true);
    this.tickElem = this.renderer.createElement('i');
    this.renderer.appendChild(this.uiElem, this.tickElem);
    this.setElemClasses(this.tickElem, 'fa-stack-1x', true);
    this.renderer.insertBefore(this.elemRef.nativeElement.parentElement, this.uiElem, this.elemRef.nativeElement);

    this.renderer.listen(this.uiElem, 'click', (event) => {
      event.preventDefault();
      if (!this.ngModel.disabled) {
        const newEvent = new event.constructor(event.type, event);
        this.elemRef.nativeElement.dispatchEvent(newEvent);
      }
    });
  }

  ngOnInit(): void {
    this.checkUpdate();
    if (this.ngModel) {
      this.subsMgr.addSub = this.ngModel.statusChanges!.subscribe(() => {
        this.checkUpdate();
      });
    }
  }

  ngOnChanges(): void {
    this.setStyleState(this.currentStateName!, this.currentSubStateName!);
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.uiElem.parentElement, this.uiElem);
    this.subsMgr.onDestroy();
  }

  @HostListener('change') onChange() {
    this.checkUpdate();
  }

  /**
   * Sets the value of currentStateName and sets the style name if the value changed
   */
  private checkUpdate() {
    if (this.elemRef.nativeElement) {
      const newValue = this.valueResolver(this.elemRef.nativeElement);
      let newStateName = newValue != null ? newValue.toString() : '';
      let newSubStateName = this.getSubStateFromModel();
      if ((!newSubStateName || !this.options![newStateName + '.' + newSubStateName]) && !this.options![newStateName]) {
        newStateName = FaCheckStyleDirective.defaultStyleKey;
        newSubStateName = this.getSubStateFromModel();
      }

      if (newStateName !== this.currentStateName || newSubStateName !== this.currentSubStateName) {
        this.setStyleState(newStateName, newSubStateName!);
      }
    }

    return false;
  }

  private getSubStateFromModel(): 'disabled' | 'invalid' | undefined {
    if (this.ngModel.disabled) {
      return 'disabled';
    }
    else if (this.ngModel.invalid) {
      return 'invalid';
    }
    return undefined;
  }

  /**
   * sets the style of the control to the sourceState
   */
  private setStyleState(sourceStateName: string, subStateName: 'invalid' | 'disabled') {

    let sourceState: FaCheckStyleStateClasses;
    if (subStateName) {
      sourceState = {
        ... this.options![FaCheckStyleDirective.defaultStyleKey] || {},
        ... this.options![FaCheckStyleDirective.defaultStyleKey + '.' + subStateName] || {},
        ... this.options![sourceStateName] || {},
        ... this.options![sourceStateName + '.' + subStateName] || {},
      };
    } else {
      sourceState = {
        ... this.options![FaCheckStyleDirective.defaultStyleKey] || {},
        ... this.options![sourceStateName] || {},
      };
    }


    const priorState = this.currentState || {};
    this.swapStateClasses(this.boxElem, sourceState.boxClass!, priorState.boxClass!);
    this.swapStateClasses(this.tickElem, sourceState.tickClass!, priorState.tickClass!);
    this.swapStateStyles(this.boxElem, sourceState.boxStyle!, priorState.boxStyle!);
    this.swapStateStyles(this.tickElem, sourceState.tickStyle!, priorState.tickStyle!);
    this.currentState = sourceState;
    this.currentSubStateName = subStateName;
  }

  /**
   * Swaps state classes for a control element, removing priorClasses and adding sourceClasses.
   * Uses Default classes as a fallback for source or prior classes
   */
  private swapStateClasses(elem: unknown, sourceClass: string, priorClass: string) {
    this.setElemClasses(elem, priorClass, false);
    this.setElemClasses(elem, sourceClass, true);
  }


  /**
   * Calls renderer.setElemClasses for each element in classes
   * @param elem the element to add or remove classes from
   * @param classes Either a string that can be delimited by whitespace or any array of classes
   * @param isAdd if true, adds classes to the element otherwise removes them
   */
  private setElemClasses(elem: unknown, classes: string | string[], isAdd: boolean) {
    if (!classes) {
      return;
    }
    if (typeof classes === 'string') {
      classes = classes.trim();
      if (classes === '') {
        return;
      }
      classes = classes.split(/\s+/g);
    }

    if (isAdd) {
      classes.forEach(x => this.renderer.addClass(elem, x));
    }
    else {
      classes.forEach(x => this.renderer.removeClass(elem, x));
    }
  }

  /**
   * Swaps state styles for a control element, removing prior styles and adding source styles.
   * Uses Default styles as a fallback for source or prior.
   */
  private swapStateStyles(elem: unknown, sourceStyle: { [key: string]: string }, priorStyle: { [key: string]: string }) {
    this.removeStyles(elem, priorStyle);
    this.setElemStyles(elem, sourceStyle);
  }

  /**
   * removes styles by setting the values associated with the keys in styles to null on the elments styles property
   */
  private removeStyles(elem: unknown, styles: { [key: string]: string }) {
    if (styles) {
      Object.keys(styles).forEach(key => this.renderer.removeStyle(elem, key));
    }
  }
  /**
   * Calls renderer.setElemClasses for each element in classes
   * @param elem the element to add or remove classes from
   * @param styles A collection of styles
   */
  private setElemStyles(elem: unknown, styles: { [key: string]: string }) {
    if (styles) {
      Object.keys(styles).forEach(key => this.renderer.setStyle(elem, key, styles[key]));
    }
  }
}

export interface FaCheckStyleDirectiveOptions { [key: string]: FaCheckStyleStateClasses; }

export interface FaCheckStyleStateClasses {
  /** css Classes for the checkbox box */
  boxClass?: string;
  /** css Styles for the checkbox box */
  boxStyle?: { [key: string]: string };
  /** css Classes for the tick mark */
  tickClass?: string;
  /** css Styles for the tick mark */
  tickStyle?: { [key: string]: string };
}
