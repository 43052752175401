import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export type KeyboardDirectionalEventsDirectiveKey =
  'ArrowDown' | 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'Enter' | 'End' | 'Home' | 'PageUp' | 'PageDown' | 'Tab';

/** Direction that will capture events from Keys and emit them in dirEvent Output */
@Directive({
  selector: '[appKeyboardDirectionalEvents]'
})
export class KeyboardDirectionalEventsDirective {
  private static readonly validKeys: KeyboardDirectionalEventsDirectiveKey[] =
    ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Enter', 'End', 'Home', 'PageUp', 'PageDown', 'Tab'];

  private activeKeys: string[] = [...KeyboardDirectionalEventsDirective.validKeys];

  /** keys that should be captured by this directive.  If set to nothing then all possible directional keys are included.
   * Can be array of KeyboardDirectionalEventsDirectiveKey or string delimited by commas. */
  @Input('appKeyboardDirectionalEvents')
  set eventKeys(value: KeyboardDirectionalEventsDirectiveKey[] | string | undefined) {
    if (!value) {
      this.activeKeys = [...KeyboardDirectionalEventsDirective.validKeys];
      return;
    }
    const valueItems = (typeof value === 'string')
      ? value.split(/,/g).map(x => x.trim())
      : value;

    if (valueItems.some(x => !(<string[]>KeyboardDirectionalEventsDirective.validKeys).includes(x))) {
      const invalidItems = valueItems.filter(x => !(<string[]>KeyboardDirectionalEventsDirective.validKeys).includes(x));
      throw new Error(`Invalid Keys passed to KbdDirEvents: ${invalidItems.join(', ')}.`);
    }
    this.activeKeys = [... valueItems];
  }

  @Output() directionalEvent = new EventEmitter<KeyboardEvent>();


  @HostListener('keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (this.activeKeys.includes(evt.key)) {
      evt.preventDefault();
      evt.stopPropagation();
      this.directionalEvent.emit(evt);
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(evt: KeyboardEvent) {
    if (this.activeKeys.includes(evt.key)) {
      // don't double emit last keydown event for "pressed" keys.
      evt.preventDefault();
      evt.stopPropagation();
    }
  }
}
