import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import { debounceTime, startWith, tap, map, distinctUntilChanged } from 'rxjs/operators';
import { AccountEntriesNode } from './account-entries-node';
import { ComparisonStateService } from './comparisons/comparison-state.service';
import { LedgerStateService } from './ledger-state.service';
import { EntryAmounts } from '../shared/entry-amounts';

@Injectable({
  providedIn: 'root'
})
export class AccountVisibilityService {

  /** A set containing all visible account counts. */
  private readonly visibleAccountCodesChangeSubject = new BehaviorSubject<string[]>([]);

  private readonly suppressZerosSubject = new BehaviorSubject<boolean>(false);
  /** accounts that can be tabbed to in their display order. */
  private tabbableAccounts: AccountEntriesNode[] = [];
  /** maps account counts to tabbableAccountsIndex. */
  private tabbableAccountMap = new Map<string | undefined, number>();

  /** emits when the visible account codes are changed. */
  readonly visibleAccountCodesChange$ = this.visibleAccountCodesChangeSubject.asObservable();

  /** emits when suppressZeroes is toggled */
  readonly suppressZeroes$ = this.suppressZerosSubject.asObservable();

  constructor(private comparisonState: ComparisonStateService, private ledgerState: LedgerStateService) {
    combineLatest([
      this.suppressZeroes$,
      // defer to ensure an initial value is loaded
      defer(() => this.ledgerState.ledger$.pipe(startWith(this.ledgerState.ledger))),
      defer(() => this.comparisonState.anyComparisonUpdate$.pipe(startWith(this.comparisonState))).pipe(
        map(x => ({ data: x.comparisonData, isComparing: x.isComparing, source: x.currentSource?.id, type: x.currentType })),
        distinctUntilChanged((x, y) => (!x.isComparing && !y.isComparing) // if neither are comparing then they are automatically equal.
            || (x.isComparing === y.isComparing && x.source === y.source && x.type === y.type && x.data === y.data )
        ),
      )
    ]).pipe(
      debounceTime(1),
      tap(() => {
        this.setVisibilityState();
        if (this.ledgerState.selectedAccount &&
          !this.visibleAccountCodesChangeSubject.value.includes(this.ledgerState.selectedAccount.accountCode!)) {
          this.ledgerState.selectAccount(undefined);
        }
      })
    ).subscribe();
  }


  /** should all accounts with 0 totals and 0 comparison amounts be suppressed */
  get suppressZeroes() { return this.suppressZerosSubject.value; }
  set suppressZeroes(value: boolean) {
    if (value !== this.suppressZerosSubject.value) {
      this.suppressZerosSubject.next(value);
    }
  }

  /**
   * Makes the next tabbable account selected.
   * @param accountCode the accountCode to start at
   * @param relativeIndex a number relative from the first amount of the account
   */
  gotoTabbableAmount(accountCode: string, relativeIndex: number) {
    const accountsLength = this.tabbableAccounts.length;
    const fromAccountIndex = this.tabbableAccountMap.get(accountCode);
    let newAccountIndex = (fromAccountIndex! + Math.floor(relativeIndex / 12)) % accountsLength;
    if (newAccountIndex < 0) {
      newAccountIndex = newAccountIndex + accountsLength;
    }
    let newAmountIndex = relativeIndex % 12;
    if (newAmountIndex < 0) {
      newAmountIndex = newAmountIndex + 12;
    }
    const selectedAccount = this.tabbableAccounts[newAccountIndex];
    this.ledgerState.selectAccount(selectedAccount, newAmountIndex);
  }

  /**
   * Returns true if supressZeroes is on and account is all zero amounts.
   * Better if this was precalulated on ledgerState.orderedAccounts when SupressZeroes is toggled and comparisonState is changed.
   */
  private isAccountZeroes(account: AccountEntriesNode) {
    let isZeroes = isAllZeroes(account);
    if (isZeroes && this.ledgerState.ledger && this.comparisonState.isComparing) {
      // it would be nice if we made sure comparison children are non-zero, but that would be too much.
      const comparisonAmounts = this.comparisonState.comparisonData![account.accountCode!];
      isZeroes = !comparisonAmounts || amountsZero(comparisonAmounts);
    }
    return isZeroes;

    /** returns true if account has all zero amounts, including its children. */
    function isAllZeroes(initialAccount: AccountEntriesNode) {
      const checkQueue = [ initialAccount ];
      while (checkQueue.length !== 0) {
        const acctToCheck = checkQueue.shift();
        if (!amountsZero(acctToCheck!)) {
          return false;
        }
        checkQueue.push(... acctToCheck!.children);
      }
      return true;
    }

    /** returns true if total and all amounts are 0. */
    function amountsZero(src: EntryAmounts) {
      // check total first because that would be faster than going throuh array
      return (src.total === 0 && src.amounts.every(x => x === 0));
    }
  }

  /** sets the visibility state */
  private setVisibilityState() {
    const visibleAccounts = (!this.ledgerState.orderedAccounts || !this.suppressZerosSubject.value)
      ? this.ledgerState.orderedAccounts
      : this.ledgerState.orderedAccounts.filter(x => !this.isAccountZeroes(x));

    this.tabbableAccounts = visibleAccounts?.length
      ? visibleAccounts.filter(x => !x.isReadOnly && !x.children?.length)
      : [];

    this.tabbableAccountMap = new Map(this.tabbableAccounts.map((x, i) => [x.accountCode, i]));
    this.visibleAccountCodesChangeSubject.next(Array.from(new Set(visibleAccounts.map(x => x.accountCode!))));
  }
}
